/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import {
  EMPLOYMENT_ANALYTICS_ARGYLE_LEARN_MORE_SOURCES,
  EMPLOYMENT_ANALYTICS_EVENTS,
} from '../../lib/employment/analytics_events';
import HTMLText from '../HTMLText';
import ArgyleLearnMoreModal from './ArgyleLearnMoreModal';
import EmploymentTurnaroundTime from './EmploymentTurnaroundTime';

const EmploymentInputTypeSelection = ({
  onSelectArgyle,
  trackArgyleAnalyticsEvent,
}) => {
  const [showLearnMoreModal, setShowLearnMoreModal] = useState(false);
  const bodyText = i18n.getStr(
    'components.EmploymentForm.ArgyleForm.selectionComponents.quick.bodyText',
  );
  const disclosureText = i18n.getStr(
    'components.EmploymentForm.ArgyleForm.selectionComponents.quick.disclosureText',
    [
      i18n.getStr(
        'components.EmploymentForm.ArgyleForm.selectionComponents.quick.endUserTermsUrl',
      ),
      i18n.getStr(
        'components.EmploymentForm.ArgyleForm.selectionComponents.quick.privacyNoticeUrl',
      ),
    ],
  );

  const handleArgyleOnClick = () => {
    trackArgyleAnalyticsEvent(EMPLOYMENT_ANALYTICS_EVENTS.ARGYLE_SELECTED);
    onSelectArgyle();
  };

  const quickCard = () => {
    return (
      <div className='col employment-selection-card quick-card' key='1'>
        <div className='row header-row'>
          <h2 className='col-md'>
            <T
              value='components.EmploymentForm.ArgyleForm.selectionComponents.quick.header'
              dangerousHTML
            />
          </h2>
          <EmploymentTurnaroundTime cardType='quick' className='col-md' />
        </div>

        <p className='bolded-text'>
          <T
            value='components.EmploymentForm.ArgyleForm.selectionComponents.quick.boldText'
            dangerousHTML
          />
        </p>
        <p>
          <HTMLText content={bodyText} />
          <a
            href='#learnMore'
            data-toggle='modal'
            onClick={() => setShowLearnMoreModal(true)}
          >
            {i18n.getStr('components.EmploymentForm.ArgyleForm.learnMoreBtn')}
          </a>
        </p>

        <button
          key='quick'
          type='button'
          className='form-control btn btn-primary card'
          onClick={handleArgyleOnClick}
        >
          <T value='components.EmploymentForm.ArgyleForm.selectionComponents.quick.buttonText' />
        </button>

        <p className='disclosure'>
          <HTMLText content={disclosureText} />
        </p>
      </div>
    );
  };

  return (
    <div className='row' id='inputSelection'>
      <ArgyleLearnMoreModal
        isOpen={showLearnMoreModal}
        handleClose={() => setShowLearnMoreModal(false)}
        trackArgyleAnalyticsEvent={trackArgyleAnalyticsEvent}
        source={EMPLOYMENT_ANALYTICS_ARGYLE_LEARN_MORE_SOURCES.SELECTION_CARD}
      />
      {quickCard()}
    </div>
  );
};

EmploymentInputTypeSelection.propTypes = {
  onSelectArgyle: PropTypes.func.isRequired,
  trackArgyleAnalyticsEvent: PropTypes.func.isRequired,
};

export default EmploymentInputTypeSelection;
