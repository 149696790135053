import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import ArgyleReviewEmploymentExpandable from './ArgyleReviewEmploymentExpandable';

const ArgyleReviewUnverifiedEmployments = ({
  unverifiedArgyleEmployments,
  isPreExpanded,
}) => {
  return (
    <>
      <p>
        {unverifiedArgyleEmployments.length === 1 ? (
          <T value='components.EmploymentForm.ArgyleForm.reviewComponents.unverifiedSingle' />
        ) : (
          <T value='components.EmploymentForm.ArgyleForm.reviewComponents.unverifiedMultiple' />
        )}
      </p>

      {unverifiedArgyleEmployments.map((employment, index) => {
        return (
          <ArgyleReviewEmploymentExpandable
            key={`argyle-review-employment-unverified-${index + 1}`}
            disabled
            maxAllowedEmployersReached={false}
            initialExpanded={isPreExpanded}
            initialSelected={false}
            employment={employment}
            onSelected={() => {}}
            onUnselected={() => {}}
          />
        );
      })}
    </>
  );
};

ArgyleReviewUnverifiedEmployments.propTypes = {
  unverifiedArgyleEmployments: PropTypes.arrayOf(PropTypes.object).isRequired,
  isPreExpanded: PropTypes.bool.isRequired,
};

export default ArgyleReviewUnverifiedEmployments;
