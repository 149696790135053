import React from 'react';
import PropTypes from 'prop-types';
import { M, Download16 } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import './DownloadAcknowledgement.scss';

const DownloadAcknowledgement = ({
  title,
  text,
  acknowledgement,
  filename,
  href,
  className,
}) => {
  return (
    <div className='download-acknowledgement-container'>
      <div className={`download-acknowledgement-inner ${className}`}>
        <div className='download-acknowledgement-title'>{title}</div>
        <div className='download-acknowledgement-text'>{text}</div>
        {href && (
          <div>
            <M.Button
              kind='secondary'
              href={href}
              download={filename}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Download16 />
              &nbsp;
              {i18n.getStr(
                'i18nInternational.downloadAcknowledgement.download',
              )}
            </M.Button>
          </div>
        )}
        <div className='download-acknowledgement-acknowledgement'>
          {acknowledgement}
        </div>
      </div>
    </div>
  );
};

DownloadAcknowledgement.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  acknowledgement: PropTypes.any.isRequired,
  filename: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

DownloadAcknowledgement.defaultProps = {
  className: '',
};

export default DownloadAcknowledgement;
