import React from 'react';
import PropTypes from 'prop-types';
import { M } from '@dashboard-experience/mastodon';

import ResponsiveColumnLayout from './Layouts/ResponsiveColumnLayout';

const InstructionsAndAccordion = ({
  title,
  question,
  text,
  accordionTitle,
  accordionBody,
  isOpen,
}) => {
  const parsedList = accordionBody
    .split(/(?=•)/)
    .map(elem => elem.replace('\n', ''));
  const accordionBodyItems = parsedList.map(d => (
    <li key={d} className='accordion-list-item'>
      {d}
    </li>
  ));
  return (
    <div className='accordion-container-outer'>
      <div>
        <div className='instruction-accordion-h2'>{title}</div>
        <h3 className='instruction-accordion-h3'>{question}</h3>
        <p className='instruction-accordion-p'>{text}</p>
      </div>
      <M.Accordion className='instruction-accordion'>
        <M.AccordionItem
          title={
            <h2 className='instruction-accordion-title-h2'>{accordionTitle}</h2>
          }
          open={isOpen}
        >
          <ul className='no-bullet-list instructions-accordion-body'>
            <ResponsiveColumnLayout content={accordionBodyItems} />
          </ul>
        </M.AccordionItem>
      </M.Accordion>
    </div>
  );
};

InstructionsAndAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  accordionTitle: PropTypes.string.isRequired,
  accordionBody: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
};

InstructionsAndAccordion.defaultProps = {
  isOpen: false,
};

export default InstructionsAndAccordion;
