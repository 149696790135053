/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import OverridableTranslate from './translate/OverrideableTranslate';
import Panel from './Panel';
import Toggle from './Toggle';
import CriminalSelfDisclosureEntry from './CriminalSelfDisclosureEntry';
import {
  handleAdditionalQuestionnaireEntry,
  handleToggleChange,
} from '../actions';

class CriminalSelfDisclosure extends Component {
  static propTypes = {
    change: PropTypes.func,
    criminalSelfDisclosureAccepted: PropTypes.bool,
    criminalSelfDisclosureEntries: PropTypes.array,
    handleAdditionalQuestionnaireEntry: PropTypes.func,
    handleToggleChange: PropTypes.func,
    requiredFields: PropTypes.object,
    labels: PropTypes.object,
    placeholders: PropTypes.object,
    i18nOverrides: PropTypes.object,
    offenseLevelDropdown: PropTypes.bool,
  };

  state = {
    numEntries: 0,
  };

  componentDidMount() {
    const { criminalSelfDisclosureEntries } = this.props;
    const numEntries = criminalSelfDisclosureEntries.length;
    this.setState({ numEntries });
  }

  componentWillReceiveProps(newProps) {
    const { criminalSelfDisclosureAccepted } = newProps;
    const { numEntries } = this.state;

    if (!criminalSelfDisclosureAccepted && numEntries > 0) {
      this.clearEntries();
    }
  }

  clearEntries() {
    // eslint-disable-next-line no-shadow
    const { change, criminalSelfDisclosureEntries } = this.props;

    this.setState({ numEntries: 0 });
    criminalSelfDisclosureEntries.forEach((entry, i) => {
      Object.keys(entry).forEach(field =>
        change(
          'applyform',
          `criminalSelfDisclosureEntries[${i}].${field}`,
          null,
        ),
      );
    });
  }

  addEntry() {
    const { numEntries } = this.state;
    // eslint-disable-next-line no-shadow
    const { handleAdditionalQuestionnaireEntry } = this.props;

    this.setState({ numEntries: numEntries + 1 }, () => {
      handleAdditionalQuestionnaireEntry();
    });
  }

  expandEntriesPrompt() {
    // eslint-disable-next-line no-shadow
    const { handleToggleChange } = this.props;
    const { numEntries } = this.state;
    const expanded = numEntries > 0;

    const toggle = () => {
      this.setState({ numEntries: expanded ? 0 : 1 }, () => {
        const { state } = this;
        const expandedBool = state.numEntries > 0;
        if (!expandedBool) {
          this.clearEntries();
        }
        handleToggleChange(expandedBool);
      });
    };

    return (
      <div id='criminal-self-disclosure-toggle'>
        <Toggle
          text='components.CriminalSelfDisclosure.toggleText'
          expanded={expanded}
          toggle={toggle}
        />
      </div>
    );
  }

  render() {
    const { numEntries } = this.state;
    const {
      criminalSelfDisclosureAccepted,
      criminalSelfDisclosureEntries,
      requiredFields,
      labels,
      placeholders,
      i18nOverrides,
      offenseLevelDropdown,
    } = this.props;
    const expanded = numEntries > 0;

    if (!criminalSelfDisclosureAccepted) {
      return <div />;
    }

    return (
      <Panel
        title='components.CriminalSelfDisclosure.title'
        id='criminal-self-disclosure'
        icon='fa-user'
        i18nOverrides={i18nOverrides}
      >
        {this.expandEntriesPrompt()}
        {expanded && (
          <>
            <hr />
            <div className='row'>
              <div className='col-lg-12'>
                <p>
                  <strong>
                    <OverridableTranslate
                      value='components.CriminalSelfDisclosure.header'
                      overrides={i18nOverrides}
                    />
                  </strong>
                </p>
              </div>
            </div>

            {[...Array(numEntries).keys()].map(i => {
              return (
                <CriminalSelfDisclosureEntry
                  entryNum={i}
                  entry={criminalSelfDisclosureEntries[i]}
                  key={`criminal-self-disclosure-entry-${i}`}
                  requiredFields={requiredFields}
                  labels={labels}
                  placeholders={placeholders}
                  offenseLevelDropdown={offenseLevelDropdown}
                />
              );
            })}

            <div className='row'>
              <div className='col-lg-4'>
                <div
                  className='add-entry'
                  onClick={this.addEntry.bind(this)}
                  role='button'
                  tabIndex='0'
                >
                  <i className='fa fa-plus-circle' aria-hidden='true' />
                  <OverridableTranslate
                    value='components.CriminalSelfDisclosure.addEntry'
                    overrides={i18nOverrides}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </Panel>
    );
  }
}

CriminalSelfDisclosure.defaultProps = {
  criminalSelfDisclosureEntries: [],
  i18nOverrides: {},
  offenseLevelDropdown: false,
};

const selector = formValueSelector('applyform');
const ConnectedCriminalSelfDisclosure = connect(
  state => {
    return {
      criminalSelfDisclosureEntries: selector(
        state,
        'criminalSelfDisclosureEntries',
      ),
      criminalSelfDisclosureAccepted: selector(
        state,
        'criminalSelfDisclosureAccepted',
      ),
    };
  },
  { change, handleAdditionalQuestionnaireEntry, handleToggleChange },
)(CriminalSelfDisclosure);

export default ConnectedCriminalSelfDisclosure;
