import { PureComponent } from 'react';

import responsiveHelpers from 'lib/utils/responsiveHelpers';

const { MEDIA_QUERY_SIZES, constructMediaQuery } = responsiveHelpers;

const mediaQuery = constructMediaQuery(MEDIA_QUERY_SIZES.XL);
class ResponsiveWrapper extends PureComponent<
  { passThroughComponent: { props: { isXL: boolean } } },
  { isXL: boolean }
> {
  state = {
    isXL: mediaQuery.matches,
  };

  componentDidMount() {
    mediaQuery.addEventListener('change', this.handleChange);
  }

  componentWillUnmount() {
    mediaQuery.removeEventListener('change', this.handleChange);
  }

  handleChange = () => {
    const { isXL } = this.state;
    if (mediaQuery.matches && !isXL) this.setState({ isXL: true });
    else this.setState({ isXL: false });
  };

  render() {
    const { passThroughComponent } = this.props;
    const { isXL } = this.state;

    return {
      ...passThroughComponent,
      props: { ...passThroughComponent?.props, isXL },
    };
  }
}

export default ResponsiveWrapper;
