import React, { useRef, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { change, untouch } from 'redux-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA_SITE_KEY } from '../../constants';

type ReCAPTCHAInstance = {
  reset: () => void;
};

type ReCaptchaV2Props = {
  changePage: (pageIndex: number, configuration: any) => void;
  input: {
    onChange: (value: string | null) => void;
    value: string | null;
  };
  locale: string;
  previousPage: number;
  resetCaptchaField: () => void;
  submitErrors?: any;
};

function ReCaptchaV2({
  input: { onChange },
  locale,
  resetCaptchaField,
  submitErrors,
}: ReCaptchaV2Props) {
  const recaptchaRef = useRef<ReCAPTCHAInstance | null>(null);
  const recaptchaKey = `recaptcha-${locale}-${
    submitErrors ? 'error' : 'no-error'
  }`;

  const clearCaptcha = useCallback(() => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
    onChange(null);
    resetCaptchaField();
  }, [onChange, resetCaptchaField]);

  useEffect(() => {
    if (submitErrors) {
      clearCaptcha();
    }
  }, [submitErrors, clearCaptcha]);

  useEffect(() => {
    clearCaptcha();
  }, [locale, clearCaptcha]);

  const handleChange = (value: string | null) => {
    onChange(value);
  };

  const handleExpired = () => {
    clearCaptcha();
  };

  return (
    <ReCAPTCHA
      key={recaptchaKey}
      ref={recaptchaRef}
      sitekey={RECAPTCHA_SITE_KEY}
      onChange={handleChange}
      onExpire={handleExpired}
      hl={locale.slice(0, 2)}
      id={`recaptcha-${locale}`}
    />
  );
}

const mapDispatchToProps = (dispatch: any) => ({
  resetCaptchaField: () => {
    dispatch(change('applyform', 'recaptchaCode', null));
    dispatch(untouch('applyform', 'recaptchaCode'));
  },
});

const mapStateToProps = (state: any) => {
  return {
    locale: state.i18n.locale,
    submitErrors: state.form?.applyform?.submitErrors ?? null,
    previousPage: state.navigation.currentPage - 1,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReCaptchaV2);
