import React from 'react';
import PropTypes from 'prop-types';
import { change, formValueSelector, Field } from 'redux-form';
import { connect } from 'react-redux';

import { Checkbox } from './fields';
import HTMLText from './HTMLText';
import Panel from './Panel';
import * as V from '../lib/validations';

export const StandardConsentComponent = ({
  content, // this is the HTML/text content that is displayed in the body
  noScroll,
  title, // this is an optional custom title for the panel
  consentText, // this is text that is shown for the consent checkbox
}) => {
  const onCopyRequestedUpdate = ({ target: { checked } }) => {
    if (checked) {
      change('applyform', 'copyRequested', null);
    }
  };

  return (
    <Panel title={title} icon='fa-file-text'>
      <div
        data-testid='scroll-wrapper'
        className={noScroll ? '' : 'scrollable'}
      >
        <HTMLText content={content} />
      </div>

      <hr />

      <div className='col-md-12 standard-consent-checkbox'>
        {consentText && (
          <Field
            type='checkbox'
            name='consentAccepted'
            label={consentText}
            component={Checkbox}
            boldLabel
            validate={[V.required]}
          />
        )}
        {!consentText && (
          <Field
            type='checkbox'
            name='copyRequested'
            label='components.StandardConsent.label'
            component={Checkbox}
            boldLabel
            onChange={onCopyRequestedUpdate}
          />
        )}
      </div>
    </Panel>
  );
};

StandardConsentComponent.propTypes = {
  content: PropTypes.string.isRequired,
  consentText: PropTypes.string,
  noScroll: PropTypes.bool,
  title: PropTypes.string,
};

StandardConsentComponent.defaultProps = {
  consentText: null,
  noScroll: false,
  title: 'components.StandardConsent.title',
};

const selector = formValueSelector('applyform');
const ConnectedStandardConsent = connect(
  state => {
    return {
      copyRequested: selector(state, 'copyRequested'),
    };
  },
  { change },
)(StandardConsentComponent);

export default ConnectedStandardConsent;
