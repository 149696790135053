/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate as T } from 'react-redux-i18n';
import { change, formValueSelector } from 'redux-form';

import { requestSchools } from 'actions';
import Panel from './Panel';
import NoHistoryPanel from './education/NoHistoryPanel';
import { DecoratedFieldArray } from './fields';
import Schools from './education/Schools';

class EducationInformationForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    documentCollection: PropTypes.bool.isRequired,
    maximumAllowedSchools: PropTypes.number,
    noEducationHistory: PropTypes.bool,
    requiresCompletedDegrees: PropTypes.bool,
    schools: PropTypes.array,
    showNoHistoryOption: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      typeaheadSchools: {
        highSchools: [],
        higherEducationSchools: [],
      },
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    requestSchools().then(typeaheadSchools => {
      this.setState({ typeaheadSchools });
    });
  }

  render() {
    const {
      dispatch,
      documentCollection,
      maximumAllowedSchools,
      noEducationHistory,
      requiresCompletedDegrees,
      schools,
      showNoHistoryOption,
    } = this.props;
    const { typeaheadSchools } = this.state;

    const handleNoHistoryClick = e => {
      // TODO EVER-6897 MDV project:
      // clearing `education` here may be problematic for prefill, revisit this
      if (e.target.checked) {
        dispatch(change('applyform', 'education', null));
      }
    };

    return (
      <div id='educationInformation'>
        <Panel
          title='components.EducationInformationForm.title'
          icon='fa-graduation-cap'
          id='eduInfoTitle'
        >
          <span className='edu-info-instructions'>
            <p>
              <strong>
                <T value='components.EducationInformationForm.instructions' />
              </strong>
            </p>
            <p>
              <T value='components.EducationInformationForm.educationInputBestPracticeSuggestion' />
            </p>
          </span>
          {showNoHistoryOption && (
            <NoHistoryPanel onChange={handleNoHistoryClick} />
          )}
          {!noEducationHistory && (
            <DecoratedFieldArray
              name='education'
              component={Schools}
              {...{
                dispatch,
                documentCollection,
                maximumAllowedSchools,
                requiresCompletedDegrees,
                schools,
                typeaheadSchools,
              }}
            />
          )}
        </Panel>
      </div>
    );
  }
}

const selector = formValueSelector('applyform');
const ConnectedEducationInformationForm = connect(state => {
  return {
    noEducationHistory: selector(state, 'noEducationHistory'),
    schools: selector(state, 'education'),
  };
})(EducationInformationForm);

export default ConnectedEducationInformationForm;
