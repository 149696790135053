/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

/**
 * @name GhostLoader
 * @function
 * @memberOf GhostLoader.js
 * @description A component that will show a animated loading
 *              box if `loading` prop is truthy. Will render
 *              `children` when `loading` prop is !truthy
 * @returns {element} - The React element
 */
class GhostLoader extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    loading: PropTypes.bool,
    loadingClassName: PropTypes.string,
    loadingCount: PropTypes.number,
  };

  render() {
    const { loading, loadingCount, loadingClassName, children } = this.props;
    if (!loading) return children;
    return (
      <span>
        {Array(...Array(loadingCount || 1)).map((item, i) => {
          return (
            <div
              className={`${loadingClassName} panel-loading no-pagebreak card clearfix mb-3 card-plan`}
              key={`span-${i + 1}`}
            >
              <div className='card-block' />
            </div>
          );
        })}
      </span>
    );
  }
}
export default GhostLoader;
