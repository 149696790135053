/* eslint-disable lines-between-class-members */
import { M } from '@dashboard-experience/mastodon';
import { i18n, moment } from '@international/mastodon-i18n';
import { Translate as T } from 'react-redux-i18n';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { RenderableSchemaProperty } from 'international/Fields/FieldCreationLogic/Field.types';

import {
  Provider as FlagrProvider,
  Switch,
  Case,
} from '@dashboard-experience/react-flagr';
import { clearFormValue } from '../../../../actions/intlForm';
import InternationalContext from '../../../InternationalContext';
import { UploadHeader } from '../../../Components';
import {
  dateInThePast,
  required,
  required2Documents,
} from '../../../../lib/validations';
import generate from '../../FieldCreationLogic/FieldGenerators';
import InstantEducation from '../../../Components/InstantEducation/InstantEducation';
import parseGooglePlacesResult from '../../../../lib/utils/googlePlacesAutoCompleteUtils';

import {
  FLAGR_URL,
  MEASURE_ONE_FLAGR_KEY,
  ADDRESS_AUTOCOMPLETE_FLAGR_KEY,
  ENV,
} from '../../../../constants';

type Props = {
  clearFormValue: any;
  generateCandidateAndDataRequest: any;
  id: string;
  properties: { [prop: string]: RenderableSchemaProperty };
  form?: any;
  accountId?: string;
  partnerAccountUriName?: string;
};

type State = {
  currentEducation: boolean;
  selectedCountry: string;
  m1CurrentSchool: object;
  manualFlowLocalSwitch: boolean;
  m1ErrorOccurred: boolean;
  m1ErrorMsg: string;
  schoolName: string;
  streetAddress: string;
  city: string;
  state: string;
  zipcode: string;
};

class EducationField extends PureComponent<Props, State> {
  static id = 'education';

  static readonly properties: { [key: string]: string } = Object.freeze({
    name: 'name',
    address: 'address',
    city: 'city',
    state: 'state',
    zipcode: 'zipcode',
    country: 'country',
    phone: 'phone',
    name_on_diploma: 'name_on_diploma',
    start_date: 'start_date',
    end_date: 'end_date',
    year_awarded: 'year_awarded',
    degree: 'degree',
    major: 'major',
    minor: 'minor',
    url: 'url',
    documents: 'documents',
    current: 'current',
  });

  static autocompleteList: {
    [key: string]: google.maps.places.Autocomplete;
  } = {};

  state = {
    currentEducation: false,
    selectedCountry: '',
    manualFlowLocalSwitch: false,
    m1ErrorOccurred: false,
    m1ErrorMsg: '',
    m1CurrentSchool: {},
    schoolName: '',
    streetAddress: '',
    city: '',
    state: '',
    zipcode: '',
  };

  setCurrentEducation(currentEducation: boolean, fieldId: string) {
    const { clearFormValue: clearFormValueAction } = this.props;
    this.setState({ currentEducation });
    if (currentEducation) {
      clearFormValueAction(`${fieldId}.${EducationField.properties.end_date}`);
      clearFormValueAction(
        `${fieldId}.${EducationField.properties.year_awarded}`,
      );
    }
  }

  autocompleteInit = (countryCode: string) => {
    // Autocomplete is only enabled based on the country
    if (!countryCode) {
      return;
    }

    const { id = EducationField.id } = this.props;
    EducationField.autocompleteList[
      `${id}-autocomplete`
    ] = new google.maps.places.Autocomplete(
      document.getElementById(`${id}-autocomplete`) as HTMLInputElement,
      {
        fields: ['address_components', 'name'],
        componentRestrictions: { country: [countryCode] },
        types: ['school', 'primary_school', 'secondary_school', 'university'],
      },
    );
    EducationField.autocompleteList[`${id}-autocomplete`].addListener(
      'place_changed',
      this.handlePlaceSelect,
    );
  };

  handlePlaceSelect = () => {
    const { id = EducationField.id } = this.props;

    const addressObject = EducationField.autocompleteList[
      `${id}-autocomplete`
    ]?.getPlace();

    if (addressObject) {
      const schoolName = addressObject.name;
      const [streetAddress, city, state, zipcode] = parseGooglePlacesResult(
        addressObject.address_components,
      );

      this.setState({
        schoolName,
        streetAddress,
        city,
        state,
        zipcode,
      });
    }
  };

  handleM1ErrorOccurred(errorMsg: string) {
    this.setState({
      manualFlowLocalSwitch: true,
      m1ErrorOccurred: true,
      m1ErrorMsg: errorMsg,
    });
  }

  render() {
    const {
      currentEducation,
      selectedCountry,
      m1CurrentSchool,
      manualFlowLocalSwitch,
      m1ErrorOccurred,
      m1ErrorMsg,
      schoolName,
      streetAddress,
      city,
      state,
      zipcode,
    } = this.state;
    const {
      form,
      id = EducationField.id,
      properties,
      accountId,
      partnerAccountUriName,
    } = this.props;
    const schools: any[] = get(form, EducationField.id);
    const hasCurrentEducation = schools.some(
      school => school && school.current,
    );
    const { clearFormValue: clearFormValueAction } = this.props;
    this.setState({
      selectedCountry: get(form, id)?.country,
      m1CurrentSchool: get(form, id),
    });
    this.handleM1ErrorOccurred = this.handleM1ErrorOccurred.bind(this);

    if (
      (get(form, id)?.name || get(form, id)?.degree) &&
      get(form, id)?.provider !== 'MeasureOne'
    ) {
      this.setState({ manualFlowLocalSwitch: true });
    }

    const showAutocompleteAddressSection = (gen: Function) => {
      return M.Container.buildSubContents([
        [gen(EducationField.properties.degree).render()],
        gen(EducationField.properties.name)
          .extendProps(() => ({
            id: `${id}-autocomplete`,
            value: schoolName,
            valueOnInit: () => this.autocompleteInit(selectedCountry),
          }))
          .render(),
        [
          gen(EducationField.properties.address)
            .extendProps(() => ({
              value: streetAddress,
            }))
            .render(),
          gen(EducationField.properties.city)
            .extendProps(() => ({
              value: city,
            }))
            .render(),
        ],
        [
          gen(EducationField.properties.state)
            .extendProps(() => ({
              value: state,
            }))
            .render(),
          gen(EducationField.properties.zipcode)
            .extendProps(() => ({
              value: zipcode,
            }))
            .render(),
        ],
      ]);
    };

    const showDefaultAddressSection = (gen: Function, fieldGenerator: any) => {
      return M.Container.buildSubContents([
        [gen(EducationField.properties.degree).render()],
        gen(EducationField.properties.name).render(),
        [
          gen(EducationField.properties.address).render(),
          gen(EducationField.properties.city).render(),
        ],
        [
          gen(EducationField.properties.state).render(),
          gen(EducationField.properties.zipcode).render(),
        ],
      ]);
    };

    const showDefaultCode = (gen: Function, fieldGenerator: any) => {
      return M.Container.buildSubContents([
        [gen(EducationField.properties.phone).render()],
        [
          gen(EducationField.properties.major).render(),
          gen(EducationField.properties.minor).render(),
        ],
        [
          gen(EducationField.properties.start_date).render(
            (fieldId: string) => ({
              validate: (val: any, all: any, props: any) => {
                const startDate = moment(val, i18n.getMonthYearPattern(), true);
                const endDate = moment(
                  get(all, `${id}.${EducationField.properties.end_date}`),
                  i18n.getMonthYearPattern(),
                  true,
                );
                if (startDate.isValid() && endDate.isValid()) {
                  if (!startDate.isBefore(endDate)) {
                    return 'i18nInternational.educationField.startDateMustPrecede';
                  }
                }
                return properties[fieldId]?.path?.isRequired
                  ? required(val)
                  : undefined;
              },
            }),
          ),
          <>
            {gen(EducationField.properties.end_date).render((_: any) => {
              const field = EducationField.properties.end_date;
              const title = fieldGenerator.getTitle(field, properties[field]);
              return {
                disabled: currentEducation,
                labelText: title,
                validate: (val: any) =>
                  val && dateInThePast(i18n.getMonthYearPattern())(val),
              };
            })}
            {generate
              .checkboxField({
                id: `${id}.${EducationField.properties.current}`,
                title: i18n.getStr(
                  'i18nInternational.educationField.myCurrentEducation',
                ),
                placeholder: '',
                disabled: !currentEducation && hasCurrentEducation,
              })
              .render(_ => ({
                onClick: (e, val, prev) => this.setCurrentEducation(val, id),
                valueOnInit: val => this.setCurrentEducation(!!val, id),
                validate: (checked, all) => {
                  const endDateStr = get(
                    all,
                    `${id}.${EducationField.properties.end_date}`,
                  );
                  const yearAwardedStr = get(
                    all,
                    `${id}.${EducationField.properties.year_awarded}`,
                  );
                  if ((!endDateStr || !yearAwardedStr) && !checked) {
                    return 'i18nInternational.educationField.provideDateOrCheck';
                  }
                  return undefined;
                },
              }))}
          </>,
        ],
        [
          gen(EducationField.properties.year_awarded).render((_: any) => {
            const field = EducationField.properties.year_awarded;
            const title = fieldGenerator.getTitle(field, properties[field]);
            return {
              disabled: currentEducation,
              labelText: title,
              validate: (val: any) => val && dateInThePast('YYYY')(val),
            };
          }),
          gen(EducationField.properties.name_on_diploma).render(),
        ],
        gen(EducationField.properties.url).render(),
        <>
          <UploadHeader
            key='upload-header'
            screening={
              selectedCountry === 'IN' ? 'indiaEducation' : 'education'
            }
          />
          {gen(EducationField.properties.documents).render((_: any) => ({
            validate: (value: any, all: any) => {
              const schoolCountry = get(
                all,
                `${id}.${EducationField.properties.country}`,
              );
              if (schoolCountry === 'IN') {
                const isCurrentStudent = get(
                  all,
                  `${id}.${EducationField.properties.current}`,
                );
                return isCurrentStudent ? undefined : required2Documents(value);
              }

              return undefined;
            },
          }))}
        </>,
      ]);
    };

    return (
      <InternationalContext.Consumer>
        {({ fieldGenerator, getFieldGeneratorForProperties }) => {
          const gen = getFieldGeneratorForProperties(properties, id);
          return (
            <>
              {m1ErrorOccurred &&
                M.Container.buildSubContents([
                  <h3 key='M1 Error'>{m1ErrorMsg}</h3>,
                ])}
              {M.Container.buildSubContents([
                [
                  gen(EducationField.properties.country)
                    .extendProps(() => ({
                      onChange: (selectedItem: any) => {
                        this.setState({
                          selectedCountry: selectedItem
                            ? String(selectedItem)
                            : selectedItem,
                        });
                      },
                      valueOnInit: (val: any) => {
                        this.setState({ selectedCountry: val });
                      },
                    }))
                    .render(),
                ],
              ])}
              {
                <FlagrProvider
                  url={FLAGR_URL}
                  entityId={accountId}
                  key={selectedCountry}
                  // The key element makes the provider relaod with changes
                  entityContext={{
                    env: ENV,
                    partner_account_uri_name: partnerAccountUriName,
                    country: selectedCountry,
                  }}
                >
                  <Switch flagKey={MEASURE_ONE_FLAGR_KEY}>
                    <Case variant='Enabled'>
                      {!manualFlowLocalSwitch &&
                        M.Container.buildSubContents([
                          [
                            <InstantEducation
                              key='instant-education'
                              currentSchool={m1CurrentSchool}
                              selectedCountry={selectedCountry}
                              errorOccurredFunc={this.handleM1ErrorOccurred}
                            />,
                          ],
                          [
                            <>
                              <span>
                                {i18n.getStr(
                                  'i18nInternational.educationField.measureOne.manualInfoTitle',
                                )}{' '}
                              </span>
                              <M.Link
                                href='#'
                                style={{ fontSize: '100%' }}
                                onClick={() => {
                                  this.setState({
                                    manualFlowLocalSwitch: true,
                                  });
                                  clearFormValueAction(`${id}.provider`);
                                  clearFormValueAction(`${id}.provider_id`);
                                }}
                              >
                                <T value='i18nInternational.educationField.measureOne.manualInfoButton' />
                              </M.Link>
                            </>,
                          ],
                        ])}
                      <FlagrProvider
                        // Measureone is enabled and user selects the manual option to enter address
                        url={FLAGR_URL}
                        entityId={accountId}
                        entityContext={{
                          env: ENV,
                          partner_account_uri_name: partnerAccountUriName,
                        }}
                      >
                        <Switch flagKey={ADDRESS_AUTOCOMPLETE_FLAGR_KEY}>
                          <Case variant='on'>
                            {manualFlowLocalSwitch &&
                              showAutocompleteAddressSection(gen)}
                            {manualFlowLocalSwitch &&
                              showDefaultCode(gen, fieldGenerator)}
                          </Case>
                          <Case>
                            {manualFlowLocalSwitch &&
                              showDefaultAddressSection(gen, fieldGenerator)}
                            {manualFlowLocalSwitch &&
                              showDefaultCode(gen, fieldGenerator)}
                          </Case>
                        </Switch>
                      </FlagrProvider>
                    </Case>
                    <Case>
                      <FlagrProvider
                        // Switch case for GlobalAutoAddress flag on/off when Measureone flag is disabled
                        url={FLAGR_URL}
                        entityId={accountId}
                        entityContext={{
                          env: ENV,
                          partner_account_uri_name: partnerAccountUriName,
                        }}
                      >
                        <Switch flagKey={ADDRESS_AUTOCOMPLETE_FLAGR_KEY}>
                          <Case variant='on'>
                            {showAutocompleteAddressSection(gen)}
                            {showDefaultCode(gen, fieldGenerator)}
                          </Case>
                          <Case>
                            {showDefaultAddressSection(gen, fieldGenerator)}
                            {showDefaultCode(gen, fieldGenerator)}
                          </Case>
                        </Switch>
                      </FlagrProvider>
                    </Case>
                  </Switch>
                </FlagrProvider>
              }
            </>
          );
        }}
      </InternationalContext.Consumer>
    );
  }
}

const mapStateToProps = (state: any) => ({
  form: state?.form?.intlForm?.values,
  accountId: state?.configuration?.configuration?.account?.id,
  partnerAccountUriName: state?.configuration?.routeParams?.company,
});

export default connect(mapStateToProps, {
  clearFormValue,
})(EducationField);
