/* eslint-disable no-shadow */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change, Field } from 'redux-form';
import { Validator } from 'jsonschema';
import { I18n } from 'react-redux-i18n';

import { Checkbox, AddFieldButton } from './fields';
import * as V from '../lib/validations';
import isOption from '../lib/validations/optionsPropTypeValidator';
import Panel from './Panel';
import ProLicenseVerificationCertification from './ProLicenseVerificationCertification';
import {
  groupCertOrgsByLocations,
  getSchemaForCertOrgId,
} from '../lib/utils/plvCertOrgUtils';

import {
  clearNavigationInterrupt,
  registerNavigationInterrupt,
} from '../actions';

const MAX_CERTIFICATIONS = 2;

class ProLicenseVerificationForm extends Component {
  static propTypes = {
    options: PropTypes.objectOf(isOption).isRequired,
    change: PropTypes.func.isRequired,
    noCertifications: PropTypes.bool,
    certifications: PropTypes.array,
    registerNavigationInterrupt: PropTypes.func.isRequired,
    clearNavigationInterrupt: PropTypes.func.isRequired,
    inputRequired: PropTypes.bool.isRequired,
    licenseCategories: PropTypes.array,
    locations: PropTypes.array,
  };

  static defaultProps = {
    noCertifications: false,
    certifications: null,
    licenseCategories: [],
    locations: [],
  };

  constructor(props) {
    super(props);

    const { certifications } = props;
    this.state = {
      numCertifications: certifications ? certifications.length : 1,
      errors: [],
    };
  }

  componentDidMount() {
    this.registerInterrupt();
  }

  componentWillUnmount() {
    this.clearInterrupt();
  }

  registerInterrupt() {
    const { registerNavigationInterrupt } = this.props;
    registerNavigationInterrupt(() => this.certificationsContainErrors());
  }

  clearInterrupt() {
    const { clearNavigationInterrupt } = this.props;
    clearNavigationInterrupt();
  }

  certificationsContainErrors() {
    const { options, certifications, noCertifications } = this.props;

    if (noCertifications) {
      return false;
    }

    const didNotSelectOrganization =
      certifications.filter(({ organizationId }) => !organizationId).length > 0;
    if (!certifications || didNotSelectOrganization) {
      return true;
    }

    // The 'react-jsonschema-form' library depends on unsafe-eval to handle validation, which is not allowed by our CSP.
    // The 'jsonschema' library is a workaround to handle validation without unsafe-eval.
    // For more info, see this Github issue: https://github.com/rjsf-team/react-jsonschema-form/issues/812
    const validator = new Validator();
    const evaluatedCertifications = certifications.map(
      ({ organizationId, inputData }) => {
        const formData = JSON.parse(inputData);
        const schema = getSchemaForCertOrgId(options, organizationId);
        const { errors } = validator.validate(formData, schema);

        return errors;
      },
    );
    this.setState({ errors: evaluatedCertifications });
    return evaluatedCertifications.filter(x => x.length > 0).length > 0;
  }

  handleNoCertificationsClicked({ target: { checked } }) {
    this.setState({ numCertifications: 1, errors: [] });

    if (checked) {
      const { change } = this.props;
      change('applyform', 'proLicenseVerification[certifications]', null);
    }
  }

  removeCertification(i) {
    const { change, certifications } = this.props;
    const { numCertifications } = this.state;
    if (certifications) {
      certifications.splice(i, 1);
    }

    this.setState({ numCertifications: numCertifications - 1 });
    change(
      'applyform',
      'proLicenseVerification[certifications]',
      certifications,
    );
  }

  renderCertifications() {
    const { numCertifications, errors } = this.state;
    const { options, locations, licenseCategories } = this.props;
    const hasCategories = licenseCategories.length > 0;

    return Array.from(Array(numCertifications).keys()).map(i => {
      return (
        <ProLicenseVerificationCertification
          errors={errors[i]}
          index={i}
          locations={locations}
          key={`certifications-${i}`}
          optionsById={options}
          optionsByState={groupCertOrgsByLocations(
            options,
            locations,
            hasCategories,
          )}
          removeCertification={() => this.removeCertification(i)}
          showRemoveButton={numCertifications > 1}
          licenseCategories={licenseCategories}
        />
      );
    });
  }

  renderCertificationSection = () => {
    const { numCertifications } = this.state;

    return (
      <React.Fragment>
        {this.renderCertifications()}
        {numCertifications < MAX_CERTIFICATIONS && (
          <AddFieldButton
            handleOnClick={() =>
              this.setState({ numCertifications: numCertifications + 1 })
            }
            buttonText='components.ProLicenseVerificationForm.add'
            count={numCertifications + 1}
          />
        )}
      </React.Fragment>
    );
  };

  renderConsentSection() {
    const { certifications } = this.props;

    return (
      certifications && (
        <Panel>
          <Field
            type='checkbox'
            name='proLicenseVerification[consent]'
            label='components.ProLicenseVerificationForm.consent'
            component={Checkbox}
            validate={[V.requiredCheckbox]}
            boldlabel
          />
        </Panel>
      )
    );
  }

  render() {
    const { noCertifications, inputRequired, licenseCategories } = this.props;

    return (
      <React.Fragment>
        <Panel
          title={`${I18n.t('components.ProLicenseVerificationForm.title')} ${
            licenseCategories && licenseCategories.length > 0
              ? `: ${licenseCategories.join(', ')} ${I18n.t(
                  'components.ProLicenseVerificationForm.titleSuffix',
                )}`
              : ''
          }`}
        >
          {!inputRequired && (
            <div className='alert alert-info'>
              <Field
                type='checkbox'
                name='proLicenseVerification[noCertifications]'
                onChange={e => this.handleNoCertificationsClicked(e)}
                label='components.ProLicenseVerificationForm.noCertifications'
                component={Checkbox}
                boldLabel
              />
            </div>
          )}
          {!noCertifications && this.renderCertificationSection()}
        </Panel>
        {this.renderConsentSection()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  form: {
    applyform: {
      values: { proLicenseVerification },
    },
  },
}) => {
  const { certifications, noCertifications } = proLicenseVerification || {};

  return {
    certifications,
    noCertifications,
  };
};

export default connect(mapStateToProps, {
  clearNavigationInterrupt,
  registerNavigationInterrupt,
  change,
})(ProLicenseVerificationForm);
