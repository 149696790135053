/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { change, Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { I18n, Translate as T } from 'react-redux-i18n';
import { concat, sortBy } from 'lodash';

import { Checkbox, Select, Text } from './fields';

import { SHORT_DATE_FORMAT } from '../constants';

import * as N from '../lib/normalizations';
import * as O from '../lib/options';
import * as V from '../lib/validations';

const InternationalAddressesForm = ({
  changeForm,
  internationalAddresses,
  showCurrentAddressCheckbox,
}) => {
  const [currentAddressIndex, setCurrentAddressIndex] = useState(null);

  /**
   * @name addAddress
   * @function
   * @memberOf InternationalAddressesForm
   * @description Add another address object to the international addresses array
   * @returns {array} - The array with new member
   * @param {array} internationalAddresses - The array of addresses
   */
  const addAddress = () => {
    const addresses = [...internationalAddresses, {}];
    changeForm('applyform', 'internationalAddresses', addresses);
  };

  /**
   * @name removeAddress
   * @function
   * @memberOf InternationalAddressesForm
   * @description Removes an address from the array
   * @param {integer} addressIndex - The index of the item to remove
   */
  const removeAddress = addressIndex => {
    const addresses = [...internationalAddresses];
    addresses.splice(addressIndex, 1);
    changeForm('applyform', 'internationalAddresses', addresses);
  };

  /**
   * @name setCurrentAddress
   * @function
   * @memberOf InternationalAddressesForm
   * @description Handle a checkbox click for current address
   * @param {integer} addressIndex - The index of the address in the array of addresses
   * @param {object} e - The change event object
   */
  const setCurrentAddress = (addressIndex, e) => {
    const isChecked = e.currentTarget.checked;
    setCurrentAddressIndex(isChecked ? addressIndex : null);
    if (isChecked) {
      const addresses = [...internationalAddresses];
      if (addresses[addressIndex].endDate) {
        delete addresses[addressIndex].endDate;
      }
      changeForm('applyform', 'internationalAddresses', addresses);
    }
  };

  const isValidCountryCode = code =>
    typeof V.nonUsTerritoriesValidation(code) === 'undefined';

  return (
    <>
      {internationalAddresses.map((_, addressIndex) => {
        return (
          <span
            key={`address-${addressIndex + 1}`}
            className='international-address'
          >
            <div className='row'>
              <div className='col-md-6'>
                <Field
                  type='select'
                  name={`internationalAddresses[${addressIndex}].countryCode`}
                  label='labels.countryOfResidence'
                  component={Select}
                  validate={[V.required, V.nonUsTerritoriesValidation]}
                  options={concat(
                    O.countries[0],
                    sortBy(O.countries.slice(1), 'name'),
                  )}
                />
              </div>
              <div className='col-md-6'>
                <div className='pull-right'>
                  {internationalAddresses.length > 1 && (
                    <button
                      type='button'
                      className='btn btn-link international-address-history-remove-entry'
                      onClick={() => {
                        removeAddress(addressIndex);
                      }}
                    >
                      <i className='fa fa-remove' />
                    </button>
                  )}
                </div>
              </div>
            </div>
            {isValidCountryCode(
              internationalAddresses[addressIndex].countryCode,
            ) && (
              <>
                <div className='row'>
                  <div className='col-md-6'>
                    <Field
                      type='text'
                      name={`internationalAddresses[${addressIndex}].street`}
                      label='labels.address'
                      component={Text}
                      validate={[V.required]}
                    />
                  </div>
                  <div className='col-md-6'>
                    <Field
                      type='text'
                      name={`internationalAddresses[${addressIndex}].city`}
                      label='labels.city'
                      component={Text}
                      validate={[V.required]}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <Field
                      type='text'
                      name={`internationalAddresses[${addressIndex}].state`}
                      label='labels.stateInternational'
                      component={Text}
                      validate={[V.required]}
                    />
                  </div>
                  <div className='col-md-6'>
                    <Field
                      type='text'
                      name={`internationalAddresses[${addressIndex}].zipcode`}
                      label='labels.postalCode'
                      component={Text}
                      validate={[V.required]}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <Field
                      type='text'
                      name={`internationalAddresses[${addressIndex}].startDate`}
                      label='labels.from'
                      component={Text}
                      placeholder='placeholders.mmYY'
                      normalize={N.monthYear}
                      validate={[
                        V.required,
                        V.validDate(SHORT_DATE_FORMAT),
                        V.dateInThePast(SHORT_DATE_FORMAT),
                      ]}
                    />
                  </div>
                  <div className='col-md-6'>
                    {addressIndex !== currentAddressIndex && (
                      <Field
                        type='text'
                        name={`internationalAddresses[${addressIndex}].endDate`}
                        label='labels.toDate'
                        component={Text}
                        placeholder='placeholders.mmYY'
                        normalize={N.monthYear}
                        validate={[
                          V.required,
                          V.validDate(SHORT_DATE_FORMAT),
                          V.dateInThePast(SHORT_DATE_FORMAT),
                          V.dateWithinFiveYears(SHORT_DATE_FORMAT),
                        ]}
                        disabled={addressIndex === currentAddressIndex}
                      />
                    )}
                    {addressIndex === currentAddressIndex && (
                      <div className='form-group'>
                        <label
                          htmlFor={`internationalAddresses[${addressIndex}]-endDate`}
                          className='form-control-label'
                        >
                          <T value='labels.toDate' />
                        </label>
                        <input
                          name={`internationalAddresses[${addressIndex}].endDate`}
                          id={`internationalAddresses[${addressIndex}]-endDate`}
                          type='text'
                          className='form-control'
                          placeholder={I18n.t('placeholders.mmYY')}
                          disabled
                        />
                      </div>
                    )}
                    {showCurrentAddressCheckbox && (
                      <Field
                        type='checkbox'
                        name={`internationalAddresses[${addressIndex}].isCurrentAddress`}
                        label='labels.currentAddressCheckbox'
                        component={Checkbox}
                        input={{
                          checked: addressIndex === currentAddressIndex,
                          onChange: e => {
                            setCurrentAddress(addressIndex, e);
                          },
                        }}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
            <hr />
          </span>
        );
      })}
      {isValidCountryCode(
        internationalAddresses[internationalAddresses.length - 1].countryCode,
      ) && (
        <button
          className='btn btn-secondary'
          type='button'
          onClick={addAddress}
        >
          <i className='fa fa-plus' /> <T value='buttons.addAddress' />
        </button>
      )}
    </>
  );
};

InternationalAddressesForm.propTypes = {
  changeForm: PropTypes.func.isRequired,
  internationalAddresses: PropTypes.arrayOf(PropTypes.object),
  showCurrentAddressCheckbox: PropTypes.bool,
};

InternationalAddressesForm.defaultProps = {
  internationalAddresses: [{}],
  showCurrentAddressCheckbox: false,
};

export { InternationalAddressesForm as TestableInternationalAddressesForm };

export default connect(
  state => {
    const selector = formValueSelector('applyform');
    return {
      internationalAddresses: selector(state, 'internationalAddresses'),
      showCurrentAddressCheckbox:
        state.internationalAddressHistory.showCurrentAddressCheckbox,
    };
  },
  {
    changeForm: change,
  },
)(InternationalAddressesForm);
