import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { change, Field } from 'redux-form';
import { bindActionCreators } from 'redux';
import * as O from '../lib/options';
import * as V from '../lib/validations';
import { Select, Text, Textarea, TypeaheadInput } from './fields';
import { requestCities, requestCounties } from '../actions';

class CriminalSelfDisclosureEntry extends Component {
  static propTypes = {
    change: PropTypes.func.isRequired,
    entry: PropTypes.object,
    entryNum: PropTypes.number.isRequired,
    requiredFields: PropTypes.object,
    labels: PropTypes.object,
    placeholders: PropTypes.object,
    offenseLevelDropdown: PropTypes.bool,
  };

  state = {
    cities: {},
    counties: {},
  };

  componentDidMount() {
    const {
      entry: { state },
    } = this.props;
    if (state) {
      this.getCities(state);
      this.getCounties(state);
    }
  }

  getCities(state) {
    const { cities } = this.state;
    if (!cities[state]) {
      const newCities = {};
      // eslint-disable-next-line no-shadow
      requestCities(state).then(cities => {
        newCities[state.toUpperCase()] = cities.map(city => city.toUpperCase());
        this.setState({ cities: { ...cities, ...newCities } });
      });
    }
  }

  getCounties(state) {
    const { counties } = this.state;

    if (!counties[state]) {
      requestCounties([state]).then(response => {
        const newCounties = {};
        Object.keys(response).forEach(key => {
          const stateUpperCased = key.toUpperCase();
          newCounties[stateUpperCased] = (
            newCounties[stateUpperCased] || []
          ).concat(
            response[key].counties.map(county => county.name.toUpperCase()),
          );
        });
        this.setState({ counties: { ...counties, ...newCounties } });
        this.countiesFetched = true;
      });
    }
  }

  handleStateChange({ target: { value } }) {
    // eslint-disable-next-line no-shadow
    const { change, entryNum } = this.props;

    change(
      'applyform',
      `criminalSelfDisclosureEntries[${entryNum}].county`,
      null,
    );
    change(
      'applyform',
      `criminalSelfDisclosureEntries[${entryNum}].city`,
      null,
    );

    if (value) {
      this.getCities(value);
      this.getCounties(value);
    }
  }

  validateCounty(value) {
    // ensures county validation gets rerun once the AJAX request to get counties is completed
    // specifically for when user returns from later pages
    const { counties } = this.state;
    // eslint-disable-next-line no-shadow
    const { change, entry, entryNum } = this.props;
    if (this.countiesFetched) {
      return V.validCounty(counties[entry.state])(value);
    }
    return change(
      'applyform',
      `criminalSelfDisclosureEntries[${entryNum}].county`,
      entry.county,
    );
  }

  render() {
    const { entry, entryNum, offenseLevelDropdown } = this.props;
    let { requiredFields, labels, placeholders } = this.props;
    const { cities, counties } = this.state;
    const cityOptions = cities[entry.state] || [];
    const countyOptions = counties[entry.state] || [];

    requiredFields = {
      ...CriminalSelfDisclosureEntry.defaultProps.requiredFields,
      ...requiredFields,
    };
    labels = {
      ...CriminalSelfDisclosureEntry.defaultProps.labels,
      ...labels,
    };
    placeholders = {
      ...CriminalSelfDisclosureEntry.defaultProps.placeholders,
      ...placeholders,
    };

    return (
      <div
        className={`criminal-self-disclosure-form criminal-self-disclosure-form-${entryNum}`}
      >
        <div className='row'>
          <div className='col-lg-3'>
            <Field
              type='text'
              name={`criminalSelfDisclosureEntries[${entryNum}].state`}
              label={labels.stateOfConviction}
              component={Select}
              // eslint-disable-next-line react/jsx-no-bind
              onChange={this.handleStateChange.bind(this)}
              options={O.states(
                placeholders.state,
                placeholders.state ===
                  CriminalSelfDisclosureEntry.defaultProps.placeholders.state,
              )}
              validate={requiredFields.state ? [V.required] : []}
            />
          </div>
          <div className='col-lg-3'>
            {/* Not requiring state selected means we cannot use typeahead */}
            {requiredFields.state || entry.state ? (
              <Field
                type='text'
                name={`criminalSelfDisclosureEntries[${entryNum}].city`}
                aria-labelledby='labels.cityOfConviction'
                label={labels.cityOfConviction}
                clearOnChange
                component={TypeaheadInput}
                disabled={!entry.state}
                options={cityOptions}
                placeholder={I18n.t(
                  entry.state ? placeholders.city : placeholders.cityLocked,
                )}
                minLength={1}
                suggest
                typeaheadAsync
                validate={requiredFields.city ? [V.required] : []}
              />
            ) : (
              <Field
                type='text'
                name={`criminalSelfDisclosureEntries[${entryNum}].city`}
                label={labels.cityOfConviction}
                placeholder={I18n.t(placeholders.city)}
                validate={requiredFields.city ? [V.required] : []}
                component={Text}
              />
            )}
          </div>
          <div className='col-lg-3'>
            {/* Not requiring state selected means we cannot use typeahead */}
            {requiredFields.state || entry.state ? (
              <Field
                type='text'
                name={`criminalSelfDisclosureEntries[${entryNum}].county`}
                label={labels.countyOfConviction}
                component={TypeaheadInput}
                clearOnChange
                disabled={!entry.state}
                placeholder={I18n.t(
                  entry.state ? placeholders.county : placeholders.countyLocked,
                )}
                minLength={1}
                suggest
                typeaheadAsync
                options={countyOptions}
                validate={
                  requiredFields.county
                    ? [V.required, this.validateCounty.bind(this)]
                    : []
                }
              />
            ) : (
              <Field
                type='text'
                name={`criminalSelfDisclosureEntries[${entryNum}].county`}
                label={labels.countyOfConviction}
                placeholder={I18n.t(placeholders.county)}
                validate={requiredFields.county ? [V.required] : []}
                component={Text}
              />
            )}
          </div>
          <div className='col-lg-3'>
            <Field
              type='text'
              name={`criminalSelfDisclosureEntries[${entryNum}].date`}
              label={labels.dateOfConviction}
              component={Text}
              validate={
                requiredFields.date ? [V.required, V.validDate('MM/YYYY')] : []
              }
              placeholder={placeholders.dateOfConviction}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-3'>
            {offenseLevelDropdown ? (
              <Field
                type='text'
                name={`criminalSelfDisclosureEntries[${entryNum}].offenseLevel`}
                label={labels.offenseLevel}
                component={Select}
                options={O.selfDisclosureOffenseLevels}
                validate={requiredFields.level ? [V.required] : []}
              />
            ) : (
              <Field
                type='text'
                name={`criminalSelfDisclosureEntries[${entryNum}].offenseLevel`}
                label={labels.offenseLevel}
                component={Text}
                placeholder={placeholders.offenseLevel}
                validate={requiredFields.level ? [V.required] : []}
              />
            )}
          </div>
          <div className='col-lg-9'>
            <Field
              type='text'
              component={Text}
              name={`criminalSelfDisclosureEntries[${entryNum}].offenseCategory`}
              label={labels.offenseCategory}
              placeholder={placeholders.offenseCategory}
              validate={requiredFields.category ? [V.required] : []}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <Field
              type='text'
              name={`criminalSelfDisclosureEntries[${entryNum}].sentence`}
              label={labels.sentence}
              placeholder={placeholders.sentence}
              validate={requiredFields.sentence ? [V.required] : []}
              component={Text}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <Field
              type='text'
              name={`criminalSelfDisclosureEntries[${entryNum}].timeServed`}
              label={labels.timeServed}
              placeholder={placeholders.timeServed}
              validate={requiredFields.timeServed ? [V.required] : []}
              component={Text}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <Field
              component={Textarea}
              name={`criminalSelfDisclosureEntries[${entryNum}].applicantDescription`}
              label={labels.mitigatingFactors}
              placeholder={placeholders.mitigatingFactors}
              validate={requiredFields.mitigatingFactors ? [V.required] : []}
              rows={3}
            />
          </div>
        </div>
        <hr />
      </div>
    );
  }
}

CriminalSelfDisclosureEntry.defaultProps = {
  entry: {},
  requiredFields: {
    state: true,
    city: false,
    county: true,
    date: true,
    level: true,
    category: true,
    sentence: true,
    timeServed: false,
    mitigatingFactors: false,
  },
  labels: {
    stateOfConviction: 'labels.stateOfConviction',
    cityOfConviction: 'labels.cityOfConviction',
    countyOfConviction: 'labels.countyOfConviction',
    dateOfConviction: 'labels.dateOfConviction',
    offenseLevel: 'labels.offenseLevel',
    offenseCategory: 'labels.offenseCategory',
    sentence: 'labels.sentence',
    timeServed: 'labels.timeServed',
    mitigatingFactors: 'labels.mitigatingFactors',
  },
  placeholders: {
    state: 'options.selectAState',
    city: 'placeholders.city',
    cityLocked: 'placeholders.cityLocked',
    county: 'placeholders.county',
    countyLocked: 'placeholders.countyLocked',
    dateOfConviction: 'placeholders.mmYY',
    offenseLevel: 'placeholders.offenseLevel',
    offenseCategory: 'placeholders.offenseCategory',
    sentence: 'placeholders.sentence',
    timeServed: 'placeholders.timeServed',
    mitigatingFactors: 'placeholders.mitigatingFactors',
  },
  offenseLevelDropdown: false,
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      change,
    },
    dispatch,
  ),
});

const ConnectedCriminalSelfDisclosureEntry = connect(
  null,
  mapDispatchToProps,
)(CriminalSelfDisclosureEntry);

export default ConnectedCriminalSelfDisclosureEntry;
