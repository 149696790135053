import { M } from '@dashboard-experience/mastodon';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RenderableSchemaProperty } from 'international/Fields/FieldCreationLogic/Field.types';
import InternationalContext from '../../../InternationalContext';
import GCCRequirements from './GCCCountryRowFieldArray/GCCRequirements';
import { generateRows } from './GCCCountryRowFieldArray/CommonComponents';

import './CreditReportField.scss';

type Props = {
  id: string;
  properties: Record<string, RenderableSchemaProperty>;
  form?: any;
};

class CreditReportField extends PureComponent<Props> {
  static id = 'credit_report';

  static readonly properties: Record<string, string> = {};

  updateGCCPropertyKeys = (
    properties: Record<string, RenderableSchemaProperty>,
  ) => {
    Object.keys(properties).reduce((acc, key) => {
      CreditReportField.properties[key] = key;
      return acc;
    }, {});
  };

  render() {
    const { id = CreditReportField.id, properties, form } = this.props;
    this.updateGCCPropertyKeys(properties);

    return (
      <InternationalContext.Consumer>
        {({ fieldGenerator, getFieldGeneratorForProperties }) => {
          const gen = getFieldGeneratorForProperties(properties, id);
          const rows = generateRows(gen, fieldGenerator, form, properties, {
            statusComponent: GCCRequirements,
          });
          return <>{M.Container.buildSubContents(rows)}</>;
        }}
      </InternationalContext.Consumer>
    );
  }
}

const mapStateToProps = (state: any) => ({
  form: state?.form?.intlForm?.values,
});

export default connect(mapStateToProps)(CreditReportField);
