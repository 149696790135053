export const dbsIdentityDocumentsPrimaryGroup1 = [
  'Adoption_Certificate',
  'Biometric_Residence_Permit',
  'Birth_Certificate_At_Birth',
  'Current_Driving_Licence',
  'Current_Valid_Passport',
];

export const dbsIdentityDocumentsPrimaryGroup1a = [
  'AccessionResidence_Card',
  'ApplicationRegistration_Card',
  'BRP_With_IndefiniteOrCurrent_Leave',
  'CertificateOfApplication',
  'Current_Driving_Licence',
  'Current_Valid_Passport',
  'Immigration_Status_Document',
  'Immigration_Status_Document_Indefinite_Stay',
  'Passport_With_IndefiniteOrCurrent_Leave',
  'Permanent_Residence_Card',
];

export const dbsIdentityDocumentsSecondaryGroup2a = [
  'Birth_Certificate_After_Birth',
  'Current_Driving_Licence_NonUk',
  'Current_Driving_Licence_Old_Style',
  'Fire_Arms_Licence',
  'HM_Forces_ID_Card',
  'Immigration_Document_Visa_WorkPermit',
  'Marriage_Civil_Partnership_Certificate',
];

export const dbsIdentityDocumentsFinancialGroup2b = [
  'Bank_Opening_Letter',
  'Bank_Statement_NonEea',
  'Bank_Statement_UkOrEea',
  'Benefit_Statement',
  'Building_Society_Opening_Letter',
  'Building_Society_Statement_NonEea',
  'Building_Society_Statement_UkOrEea',
  'Central_Government_Document',
  'Council_Tax_Statement',
  'Credit_Card_Statement',
  'EU_National_ID_Card',
  'Financial_Statement',
  'Irish_Passport_Card',
  'Letter_From_Head_Teacher',
  'Letter_Of_Sponsorship',
  'Local_Government_Document',
  'Mortgage_Statement',
  'P45_Statement',
  'P60_Statement',
  'PASS_Card',
  'Utility_Bill',
];

export const dbsIdentityDocumentsPrimaryGroup1AndSecondaryGroup2 = [
  ...dbsIdentityDocumentsPrimaryGroup1,
  ...dbsIdentityDocumentsSecondaryGroup2a,
  ...dbsIdentityDocumentsFinancialGroup2b,
];

export const dsProofOfIdentityDocuments = [
  'Passport',
  'DrivingLicence',
  'UKVisa',
  'BirthCertificate',
  'EEAIDCard',
  'HMForcesIDCard',
  'UKAdoptionCertificate',
  'OfficeTravelDoc',
  'UKGovDepartCard',
  'EmployeeIDCard',
  'NUSCard',
  'FireArmsLicence',
  'PASSCard',
  'BiometricResidencePermit',
  'MarriageDocument',
  'ImmigrationDocument',
];

export const dsProofOfResidentialAddressDocuments = [
  'Electricity',
  'Gas',
  'Water',
  'Telephone',
  'MobilePhone',
  'TVLicence',
  'Bank',
  'BuildingSociety',
  'CreditCard',
  'StoreCard',
  'Pension',
  'ISA',
  'Endowment',
  'Mortgage',
  'Invoices',
  'CouncilTax',
  'UKBA',
  'HMRC',
  'JobCentrePlus',
  'OtherCentralGovernment',
  'OtherLocalGovernment',
  'NHS',
  'TenancyAgreement',
  'DrivingLicence',
  'UKCounterpartDrivingLicence',
  'UKMOTTaxDiskReminder',
  'UKV5C',
  'Payslip',
  'UKP45OrP60',
  'InsuranceDocs',
];

export const dbsEmploymentSectors = [
  'LOCAL GOVERNMENT',
  'CENTRAL GOVERNMENT',
  'PUBLIC SECTOR- OTHER',
  'NHS',
  'PRESCHOOL EDUCATION',
  'PRIMARY EDUCATION',
  'SECONDARY EDUCATION',
  'ACADEMY EDUCATION',
  'FURTHER EDUCATION',
  'HIGHER EDUCATION',
  'INDEPENDENT EDUCATION',
  'VOLUNTARY/CHARITY',
  'PRIVATE HEALTHCARE',
  'AGRICULTURE, FORESTRY AND FISHING',
  'MINING AND QUARRYING',
  'MANUFACTURING',
  'RETAIL',
  'ENERGY AND AIR CONDITIONING',
  'WATER AND WASTE MANAGEMENT',
  'CONSTRUCTION',
  'TRADE OR REPAIR OF VEHICLES',
  'TRANSPORTATION AND STORAGE',
  'ACCOMMODATION AND FOOD SERVICE',
  'INFORMATION AND COMMUNICATION',
  'FINANCIAL AND INSURANCE',
  'REAL ESTATE ACTIVITIES',
  'PROFESSIONAL, TECHNICAL',
  'ADMINISTRATIVE AND SUPPORT',
  'SOCIAL CARE',
  'ARTS AND ENTERTAINMENT',
  'LEISURE, SPORT AND TOURISM',
  'FOSTER/ADOPTION',
  'CHILD CARE',
  'DRIVERS',
  'LAW ENFORCEMENT AND SECURITY',
  'RECRUITMENT AND HR',
];

export const licenseTypes = ['Unknown', 'Paper', 'Photo'];

export const titles = [
  'Mr',
  'Ms',
  'Mrs',
  'Miss',
  'Dr',
  'Professor',
  'Baron',
  'Baroness',
  'Brigadier',
  'Canon',
  'Captain',
  'Duchess',
  'Duke',
  'Esq',
  'Father',
  'Hon',
  'Inspector',
  'Lady',
  'Lord',
  'LtCol',
  'Major',
  'MostRever',
  'Pastor',
  'Rabbi',
  'RevDr',
  'Reverend',
  'RtReveren',
  'Sir',
  'Sister',
  'SquadronL',
  'WgCdr',
];
