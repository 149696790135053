import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import ArgyleReviewEmploymentExpandable from './ArgyleReviewEmploymentExpandable';

const ArgyleReviewVerifiedEmployments = ({
  maximumAllowedEmployers,
  verifiedWithArgyleEmployments,
  selectedArgyleEmployments,
  numSelectedManualEmployments,
  isPreExpanded,
  companyName,
  handleArgyleEmploymentSelected,
  handleArgyleEmploymentUnselected,
}) => {
  const maxAllowedEmployersReached = () => {
    return !!(
      maximumAllowedEmployers &&
      selectedArgyleEmployments.length + numSelectedManualEmployments >=
        maximumAllowedEmployers
    );
  };

  const noArgyleEmploymentsSelected = () => {
    return selectedArgyleEmployments.length === 0;
  };

  return (
    <>
      <h2 className='found-heading'>
        <T value='components.EmploymentForm.ArgyleForm.reviewComponents.foundHeading' />
      </h2>

      {maximumAllowedEmployers &&
        verifiedWithArgyleEmployments.length > maximumAllowedEmployers && (
          <>
            {maximumAllowedEmployers === 1 && (
              <div className='select-up-to-limit'>
                {i18n.getStr(
                  'components.EmploymentForm.ArgyleForm.reviewComponents.selectOne',
                  [companyName],
                )}
              </div>
            )}
            {maximumAllowedEmployers > 1 && (
              <div className='select-up-to-limit'>
                {i18n.getStr(
                  'components.EmploymentForm.ArgyleForm.reviewComponents.selectUpToLimit',
                  [companyName, maximumAllowedEmployers],
                )}
              </div>
            )}
          </>
        )}

      <h3>
        <M.Icon icon='CheckmarkFilled' size='16' className='checkmark' />
        <span className='icon-inline-text'>
          <T value='components.EmploymentForm.ArgyleForm.reviewComponents.verifiedWithArgyle' />
        </span>
      </h3>

      {noArgyleEmploymentsSelected() && (
        <div className='select-verified-argyle'>
          {maximumAllowedEmployers === 1 ? (
            <T value='components.EmploymentForm.ArgyleForm.reviewComponents.selectVerifiedArgyleSingle' />
          ) : (
            <T value='components.EmploymentForm.ArgyleForm.reviewComponents.selectVerifiedArgyleMultiple' />
          )}
        </div>
      )}

      {verifiedWithArgyleEmployments.map((argyleEmployment, _) => {
        const isPreSelected = selectedArgyleEmployments.some(
          selectedArgyleEmployment =>
            selectedArgyleEmployment.argyleEmploymentId ===
            argyleEmployment.argyleEmploymentId,
        );

        return (
          <ArgyleReviewEmploymentExpandable
            key={`argyle-review-employment-${argyleEmployment.argyleEmploymentId}`}
            disabled={false}
            initialExpanded={isPreExpanded}
            initialSelected={isPreSelected}
            maxAllowedEmployers={maximumAllowedEmployers}
            maxAllowedEmployersReached={maxAllowedEmployersReached()}
            employment={argyleEmployment}
            onSelected={selectedArgyleEmployment => {
              handleArgyleEmploymentSelected(selectedArgyleEmployment);
            }}
            onUnselected={unselectedArgyleEmployment => {
              handleArgyleEmploymentUnselected(unselectedArgyleEmployment);
            }}
          />
        );
      })}
    </>
  );
};

ArgyleReviewVerifiedEmployments.propTypes = {
  maximumAllowedEmployers: PropTypes.number,
  verifiedWithArgyleEmployments: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedArgyleEmployments: PropTypes.arrayOf(PropTypes.object).isRequired,
  numSelectedManualEmployments: PropTypes.number.isRequired,
  isPreExpanded: PropTypes.bool.isRequired,
  companyName: PropTypes.string.isRequired,
  handleArgyleEmploymentSelected: PropTypes.func.isRequired,
  handleArgyleEmploymentUnselected: PropTypes.func.isRequired,
};

ArgyleReviewVerifiedEmployments.defaultProps = {
  maximumAllowedEmployers: null, // Unlimited max employers.
};

export default ArgyleReviewVerifiedEmployments;
