/* eslint-disable */
import { M } from '@dashboard-experience/mastodon';
import { RTW_PROPERTY_KEYS } from 'international/constants';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RenderableSchemaProperty } from 'international/Fields/FieldCreationLogic/Field.types';
import { clearFormValue } from '../../../../actions/intlForm';
import InternationalContext from '../../../InternationalContext';
import generatedGBRows from './RTWCountryRowFieldArray/GB-Rows';

type Props = {
  clearFormValue: any;
  id: string;
  properties: Record<string, RenderableSchemaProperty>;
  form?: any;
  accountId?: string;
};

class RightToWorkField extends PureComponent<Props> {
  static id = 'right_to_work';

  static readonly properties = RTW_PROPERTY_KEYS;

  generateCountryRows = (gen: any, form: any, accountId?: string) => {
    const workLocation = form?.data_residency_location;
    if (workLocation === 'GB') {
      return generatedGBRows(gen, form, accountId);
    }
    return [];
  };

  render() {
    const {
      id = RightToWorkField.id,
      properties,
      form,
      accountId,
      clearFormValue: clearFormValueAction,
    } = this.props;

    return (
      <InternationalContext.Consumer>
        {({ getFieldGeneratorForProperties }) => {
          const gen = getFieldGeneratorForProperties(properties, id);

          const rows = this.generateCountryRows(gen, form, accountId);
          return <>{M.Container.buildSubContents(rows)}</>;
        }}
      </InternationalContext.Consumer>
    );
  }
}

const mapStateToProps = (state: any) => ({
  form: state?.form?.intlForm?.values,
  accountId: state?.configuration?.configuration?.account?.id,
});

export default connect(mapStateToProps, {
  clearFormValue,
})(RightToWorkField);
