import React from 'react';
import PropTypes from 'prop-types';
import configuration from 'reducers/configuration';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import { Translate as T } from 'react-redux-i18n';
import { changePage, trackAnalyticsEvent } from 'actions';
import * as V from '../lib/validations';

import Address from './review/components/Address';
import Break from './review/components/Break';
import Disclosure from './review/components/Disclosure';
import { Document, DocumentLabel } from './review/components/Document';
import InputTranslate from './review/components/InputTranslate';
import InputNonTranslate from './review/components/InputNonTranslate';
import Subheader from './review/components/Subheader';
import TitlePanel from './review/components/TitlePanel';

import CreateReviewPage from './review/config';
import Panel from './Panel';
import '../css/review.scss';
import DOTAuthorization from './DOTAuthorization';
import SignatureAuthorization from './SignatureAuthorization';
import { ANALYTICS_EVENTS } from '../lib/analytics';
import ReCaptchaV2 from './fields/ReCaptchaV2';

const determineType = (
  aria,
  className,
  icon,
  label,
  labelOptions,
  link,
  name,
  type,
  value,
) => {
  switch (type) {
    case 'break':
      return <Break />;
    case 'mailing':
    case 'clinic':
      return (
        <Address aria={aria} label={label} className={name} value={value} />
      );
    case 'disclosure':
      return (
        <Disclosure
          aria={aria}
          label={label}
          labelOptions={labelOptions}
          name={name}
          value={value}
        />
      );
    case 'document':
      return (
        <Document
          aria={aria}
          label={label}
          link={link}
          icon={icon}
          value={value}
        />
      );
    case 'document-label':
      return (
        <DocumentLabel
          aria={aria}
          className={className}
          label={label}
          icon={icon}
        />
      );
    case 'DOTAuthorization':
      return <DOTAuthorization />;
    case 'SignatureAuthorization':
      return (
        <SignatureAuthorization
          name={name}
          company={labelOptions.company}
          readOnly
        />
      );
    case 'subheader':
      return <Subheader className={className} label={label} icon={icon} />;
    case 'translate':
      return (
        <InputTranslate
          aria={aria}
          className={className}
          label={label}
          icon={icon}
          value={value}
        />
      );
    default:
      return (
        <InputNonTranslate
          aria={aria}
          className={className}
          label={label}
          icon={icon}
          value={value}
        />
      );
  }
};

const ReviewPanel = ({
  title,
  fields,
  pageIndex,
  // eslint-disable-next-line no-shadow
  changePage,
  tabIndex,
  // eslint-disable-next-line no-shadow
  trackAnalyticsEvent,
}) => {
  return (
    <Panel className='review-panel'>
      <div className='review-panel-header'>
        <T value={title} className='review-panel-header-title' />
        <button
          type='button'
          className='review-panel-header-edit'
          onClick={() => {
            trackAnalyticsEvent(
              ANALYTICS_EVENTS.DOMESTIC_APPLY_CONFIRMATION_PAGE_EDIT_SELECTED,
            );
            changePage(pageIndex + 1, configuration);
          }}
          tabIndex={tabIndex + 1}
          aria-label={`edit button for ${title}`}
        >
          Edit
        </button>
      </div>
      {/* function mask SSN */}
      <div className='review-panel-inputs-container'>
        {fields.map(
          ({
            aria,
            className,
            icon,
            label,
            labelOptions,
            link,
            name,
            type,
            value,
          }) => {
            return determineType(
              aria,
              className,
              icon,
              label,
              labelOptions,
              link,
              name,
              type,
              value,
            );
          },
        )}
      </div>
    </Panel>
  );
};

const ReviewPage = ({
  applyform,
  config,
  // eslint-disable-next-line no-shadow
  changePage,
  navigation,
  // eslint-disable-next-line no-shadow
  trackAnalyticsEvent,
}) => {
  const { values: applyValues } = applyform;
  const {
    configuration: { pages },
  } = config;
  const displayPages = new CreateReviewPage(pages, applyValues).initalize();
  const finalPage = navigation.currentPage === navigation.totalPages;

  return (
    <div className='review-page'>
      <TitlePanel />
      {displayPages.map(({ index, title, fields }, tabIndex) => {
        return (
          <ReviewPanel
            key={`${index}${title}`}
            title={title}
            fields={fields}
            pageIndex={index}
            tabIndex={tabIndex}
            changePage={changePage}
            trackAnalyticsEvent={trackAnalyticsEvent}
          />
        );
      })}
      {finalPage && (
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Field
            key='recaptcha'
            name='recaptchaCode'
            component={ReCaptchaV2}
            validate={V.required}
          />
        </div>
      )}
    </div>
  );
};

ReviewPanel.propTypes = {
  title: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(Object).isRequired,
  pageIndex: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
  trackAnalyticsEvent: PropTypes.func.isRequired,
};

ReviewPage.propTypes = {
  applyform: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  changePage: PropTypes.func.isRequired,
  navigation: PropTypes.object.isRequired,
  trackAnalyticsEvent: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  const {
    // eslint-disable-next-line no-shadow
    configuration: config,
    navigation,
    form: { applyform },
  } = state;

  return {
    config,
    navigation,
    applyform,
  };
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      trackAnalyticsEvent,
      changePage,
    },
    dispatch,
  ),
});

const Review = connect(mapStateToProps, mapDispatchToProps)(ReviewPage);

export default Review;
