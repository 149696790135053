import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { Translate } from 'react-redux-i18n';

const LoadingContainerStyled = styled.div`
  padding-bottom: 16px;
`;

const LoadingContainer = ({ verificationCompleted }) => (
  <LoadingContainerStyled data-testid='loading-container'>
    <M.LoadingInline
      description={
        verificationCompleted ? (
          <Translate value='components.IdentityVerificationResults.verificationComplete' />
        ) : (
          <Translate value='components.IdentityVerificationResults.verifying' />
        )
      }
      status={verificationCompleted ? 'finished' : 'active'}
    />
  </LoadingContainerStyled>
);

LoadingContainer.propTypes = {
  verificationCompleted: PropTypes.bool.isRequired,
};

export default LoadingContainer;
