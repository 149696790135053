import { DATE_PLACEHOLDER } from '../international/constants';

const ONLY_NUMS = /[^\d]/g;

const smartPunctuation = value => {
  if (!value) return value;

  // eslint-disable-next-line
  return value
    .replaceAll('‘', "'")
    .replaceAll('’', "'")
    .replaceAll('`', "'")
    .replaceAll('–', '-')
    .replaceAll('—', '-');
};

const upper = value => value && value.toUpperCase();

const monthYear = value => {
  if (!value) return value;

  const onlyNums = value.replace(ONLY_NUMS, '');
  if (onlyNums.length <= 2) return onlyNums;

  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 6)}`;
};

const monthDayYear = value => {
  if (!value) return value;

  const onlyNums = value.replace(ONLY_NUMS, '');
  if (onlyNums.length <= 2) return onlyNums;

  if (onlyNums.length <= 4)
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2)}`;

  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(
    4,
    8,
  )}`;
};

const phone = value => value?.replace(/[^\d()+]/g, '');

const zipcode = value => value?.replace(ONLY_NUMS, '')?.slice(0, 5);

const numbersOnly = value => value?.replace(ONLY_NUMS, '');

const year = value => value?.replace(ONLY_NUMS, '')?.slice(0, 4);

const ssn = value => {
  if (!value) return value;

  const onlyNums = value.replace(ONLY_NUMS, '');
  if (onlyNums.length <= 3) return onlyNums;

  if (onlyNums.length <= 5)
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;

  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}-${onlyNums.slice(
    5,
    9,
  )}`;
};

const ccCVC = value => value?.replace(ONLY_NUMS, '')?.slice(0, 3);

const ccZipcode = value => value?.replace(ONLY_NUMS, '')?.slice(0, 5);

const ccMonthYear = value => {
  if (!value) return value;

  const onlyNums = value.replace(ONLY_NUMS, '');
  if (onlyNums.length <= 2) return onlyNums;

  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}`;
};

const ccNumber = value => {
  if (!value) return value;

  const onlyNums = value.replace(ONLY_NUMS, '');
  if (onlyNums.length <= 3) return onlyNums;

  if (onlyNums.length <= 7)
    return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4)}`;

  if (onlyNums.length <= 11)
    return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(
      8,
      12,
    )}`;

  return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(
    8,
    12,
  )} ${onlyNums.slice(12, 16)}`;
};

const getSeparator = placeholder => {
  if (placeholder.includes('/')) return '/';
  return placeholder.includes('-') ? '-' : '.';
};

const getFormatedDateStr = (value, placeholder) => {
  if (!value || !placeholder || !DATE_PLACEHOLDER.includes(placeholder))
    return value;
  const separator = getSeparator(placeholder);
  const datesFormat = placeholder.split(separator);
  const onlyNums = value.replace(ONLY_NUMS, '');
  // YYYY format
  if (datesFormat.length < 2) return onlyNums.slice(0, 4);
  const firstLength = datesFormat[0].length;
  if (onlyNums.length <= firstLength) return onlyNums;
  // YYYY/MM || MM/YYYY formats
  const secondLength = firstLength + datesFormat[1].length;
  if (datesFormat.length < 3 || onlyNums.length <= secondLength) {
    return `${onlyNums.slice(0, firstLength)}${separator}${onlyNums.slice(
      firstLength,
      secondLength,
    )}`;
  }
  // YYYY/MM/DD || DD/MM/YYYY formats
  const thirdLength = secondLength + datesFormat[2]?.length;
  return `${onlyNums.slice(0, firstLength)}${separator}${onlyNums.slice(
    firstLength,
    secondLength,
  )}${separator}${onlyNums.slice(secondLength, thirdLength)}`;
};

export {
  ccCVC,
  ccNumber,
  ccMonthYear,
  ccZipcode,
  monthYear,
  monthDayYear,
  numbersOnly,
  phone,
  ssn,
  upper,
  year,
  zipcode,
  getFormatedDateStr,
  smartPunctuation,
};
