import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';

function SchoolUnsupportedModal({ open, onClose, onError }) {
  return (
    <M.ComposedModal open={open} onClose={() => onClose()}>
      <M.ModalHeader closeModal={() => onClose()}>
        <h2>
          {i18n.getStr(
            'i18nInternational.educationField.measureOne.m1SchoolNotSupportedModal.headerTitle',
          )}
        </h2>
      </M.ModalHeader>
      <M.ModalBody>
        <div>
          <div className='row'>
            <div className='col-md-12'>
              <h2>
                {i18n.getStr(
                  'i18nInternational.educationField.measureOne.m1SchoolNotSupportedModal.schoolNotSupportedMessageTitle',
                )}
              </h2>
            </div>
            <div className='col-md-12 m1-waiting-results-modal-description-padding'>
              <span className='m1-learn-more-modal-span'>
                {i18n.getStr(
                  'i18nInternational.educationField.measureOne.m1SchoolNotSupportedModal.schoolNotSupportedMessageDescription',
                )}
              </span>
            </div>
          </div>
          <div className='row m1-cancel-and-enter-info-button'>
            <M.Button
              className='secondary'
              onClick={() => {
                onError();
                onClose();
              }}
            >
              {i18n.getStr(
                'i18nInternational.educationField.measureOne.m1SchoolNotSupportedModal.enterInformationButton',
              )}
            </M.Button>
          </div>
        </div>
      </M.ModalBody>
    </M.ComposedModal>
  );
}

SchoolUnsupportedModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default SchoolUnsupportedModal;
