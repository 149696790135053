/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { Translate as T } from 'react-redux-i18n';
import { isEqual } from 'lodash';

import { ButtonSelect } from './fields';
import Panel from './Panel';
import {
  setHasInternationalAddresses,
  requestHasInternationalAddressesConfigUpdate,
} from '../actions';
import getUniqNonNullableByField from '../lib/utils/getUniqNonNullableByField';
import InternationalAddressesForm from './InternationalAddressesForm';

const InternationalAddressHistoryForm = ({
  hasInternationalAddresses,
  internationalAddresses,
  requestConfigUpdate,
  setHasIntlAddresses,
}) => {
  const [countries, setCountries] = useState([]);
  /**
   * @name handleInternationalAddressesToggle
   * @function
   * @memberOf InternationalAddressHistoryForm
   * @description Handle state update for international addresses toggle
   * @param {'yes' | 'no'} workedOutsideUS - String option for whether we should show the addresses form or not
   */
  const handleInternationalAddressesToggle = workedOutsideUS =>
    setHasIntlAddresses(workedOutsideUS === 'yes');

  useEffect(() => {
    const codes = getUniqNonNullableByField(
      internationalAddresses,
      'countryCode',
    );
    if (!isEqual(codes, countries)) setCountries(codes);
  }, [internationalAddresses]);

  useEffect(() => {
    if (typeof hasInternationalAddresses !== 'boolean') return;
    requestConfigUpdate();
  }, [hasInternationalAddresses, countries]);

  /**
   * @name getLocationBooleanValue
   * @function
   * @memberOf InternationalAddressHistoryForm
   * @description Determine the current value of the ButtonSelect
   * @returns {string}
   */
  const getLocationBooleanValue = () => {
    let buttonValue = null;
    if (hasInternationalAddresses) {
      buttonValue = 'yes';
    } else if (hasInternationalAddresses === false) {
      buttonValue = 'no';
    }
    return buttonValue;
  };

  return (
    <>
      <Panel
        title='components.InternationalLocationBoolean.title'
        icon='fa-map-marker'
      >
        <p>
          <T
            value='components.InternationalLocationBoolean.label'
            dangerousHTML
          />
        </p>
        <div className='row'>
          <div className='col-md-4'>
            <Field
              type='button'
              name='hasInternationalAddresses'
              className='international-location-boolean'
              component={ButtonSelect}
              selectedValue={`${getLocationBooleanValue()}`}
              options={['yes', 'no']}
              onClick={handleInternationalAddressesToggle}
            />
          </div>
        </div>
      </Panel>
      {hasInternationalAddresses && (
        <Panel
          title='components.InternationalAddressHistoryForm.title'
          icon='fa-home'
          className='international-address-history-form'
        >
          <p>
            <T
              value='components.InternationalAddressHistoryForm.instructions'
              dangerousHTML
            />
          </p>
          <InternationalAddressesForm />
        </Panel>
      )}
    </>
  );
};

InternationalAddressHistoryForm.propTypes = {
  hasInternationalAddresses: PropTypes.bool,
  internationalAddresses: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.string),
  ).isRequired,
  requestConfigUpdate: PropTypes.func.isRequired,
  setHasIntlAddresses: PropTypes.func.isRequired,
};

InternationalAddressHistoryForm.defaultProps = {
  hasInternationalAddresses: null,
};

export { InternationalAddressHistoryForm as TestableInternationalAddressHistoryForm };

export default connect(
  state => {
    const selector = formValueSelector('applyform');
    return {
      hasInternationalAddresses:
        state.internationalAddressHistory.hasInternationalAddresses,
      internationalAddresses: selector(state, 'internationalAddresses') || [],
    };
  },
  {
    requestConfigUpdate: requestHasInternationalAddressesConfigUpdate,
    setHasIntlAddresses: setHasInternationalAddresses,
  },
)(InternationalAddressHistoryForm);
