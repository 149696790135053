import React from 'react';
import { filterByCountryValue } from 'lib/options/countries';

const RowHeaderTitleAndDescription = ({
  country,
}: { country: string }) => {
  return (
    <>
      <h2 key='screening-credit_report-title'
        className='region-requirements-header-text'>
        {country}
      </h2>
    </>
  );
};

export const generateRows = (
  gen: any,
  fieldGenerator: any,
  form: any,
  requiredFields: any, 
  options: {
    statusComponent: React.ComponentType<any>;
  },
) => {

  const country = filterByCountryValue(form?.data_residency_location)?.[0]?.name || '';

  return [
    <RowHeaderTitleAndDescription
      country={country}
      key='screening-credit_report-title-and-description'
    />,
    <options.statusComponent
      key='screening-credit_report-citizenship-status-options'
      gen={gen}
      fieldGenerator={fieldGenerator}
      form={form}
      requiredFields={requiredFields}
    />,
  ];
};
