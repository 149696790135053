import moment from 'moment/moment';
import insertArgyleEmployment from './insertArgyleEmployment';

const insertVerifiedArgyleEmploymentNoContractType = () => dispatch => {
  const argyleEmployment = {
    contractType: null,
    contractTypeVerified: false,
    name: 'Fake Verified - No Contract Type',
    nameVerified: true,
    position: 'SWE L5',
    positionVerified: true,
    startDate: moment()
      .subtract(3, 'years')
      .format('MM/YYYY'),
    startDateVerified: true,
    endDate: moment()
      .subtract(1, 'months')
      .format('MM/YYYY'),
    endDateVerified: true,
    currentPosition: false,
    argyleEmploymentId: 'verified_no_contract_type',
    isVerified: true,
  };

  dispatch(insertArgyleEmployment(argyleEmployment));
};

export default insertVerifiedArgyleEmploymentNoContractType;
