/* eslint-disable react/no-danger, react/require-default-props */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class HTMLText extends Component {
  handleClick = event => {
    if (event.target.tagName === 'A' && event.target.href) {
      if (event.target.id === 'refresh-page') {
        window.location.reload();
      } else {
        event.target.setAttribute('target', '_blank');
      }
    }
  };

  render() {
    const { content, customClassName } = this.props;
    return (
      <span
        className={`custom-text ${customClassName || ''}`}
        dangerouslySetInnerHTML={{ __html: content }}
        onClick={this.handleClick.bind(this)}
        role='button'
        tabIndex='0'
      />
    );
  }
}

HTMLText.propTypes = {
  content: PropTypes.string,
  customClassName: PropTypes.string,
};

export default HTMLText;
