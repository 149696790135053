import _ from 'lodash';
import { statesByCode } from 'lib/options/states';
import { OTHER_PROFESSION } from './plvProfessionUtils';
import { UNITED_STATES } from './plvStateUtils';

export const OTHER_CERT_ORG = 'checkr-other';

/**
 * Retrieves a cert org schema, if it exists, for a given cert org ID
 *
 * @param {Object} certOrgsById representing available PLV cert orgs, including the "Other" cert org
 * @param {String} certOrgId representing a cert org id as recognized by a PLV vendor
 * @returns {Object} the schema for the cert org if it exists, else {}
 */
export const getSchemaForCertOrgId = (certOrgsById, certOrgId) => {
  /*
    the keys of certOrgsById are modified (apply flow calls camelizeKeys on all API calls to monolith)
    whereas the incoming certOrgId is unmodified, representing the ID as recognized by our PLV vendors
    PLV vendor cert org IDs contain special characters like '-'

    e.g. Object.keys(certOrgsById) = ['000A1122b'], but certOrgId = '000-a11-22b'
  */
  const certOrg = _.find(certOrgsById, c => c.id === certOrgId);
  return certOrg ? _.get(certOrg, 'schema', {}) : {};
};

/**
 * Constructs the options provided to the cert org select dropdown, grouping cert orgs by locations
 *
 * @param {Object} certOrgsById available PLV cert orgs
 * @param {Array<String>} export array of PLV package configured locations
 *
 * @returns {Object} cert orgs grouped by locations
 */
export const groupCertOrgsByLocations = (certOrgsById, packageLocations) => {
  const certOrgsGroupedByLocation = {};

  _.forEach(certOrgsById, (certOrg, _certOrgId) => {
    const sharedLocations = _.intersection(packageLocations, certOrg.states);
    _.forEach(sharedLocations, locationCode => {
      // locationCode, e.g. 'CA', 'NY', 'United States'

      // Initialize this location's array of cert orgs, if not initialized by a previous cert org.
      if (!certOrgsGroupedByLocation[locationCode]) {
        certOrgsGroupedByLocation[locationCode] = [];
      }

      certOrgsGroupedByLocation[locationCode].push(certOrg);
    });
  });

  _.forEach(certOrgsGroupedByLocation, (_certOrgs, location) => {
    certOrgsGroupedByLocation[location] = _.sortBy(
      certOrgsGroupedByLocation[location],
      certOrg => certOrg.name,
    );
  });

  return certOrgsGroupedByLocation;
};

/**
 * Gets array of cert orgs to render in a dropdown list.
 *
 * @param {Array<Object>} certOrgsForState array of cert orgs (including "Other") for the selected location.
 * @param {String} userEnteredProfession User-entered profession, or null if not applicable.
 *
 * @returns {Array<Object>} Array of cert orgs, with only the name and value fields.
 */
export const getCertOrgsForDropdown = (
  certOrgsForState,
  userEnteredProfession,
) => {
  let certOrgsToRender;

  if (userEnteredProfession === OTHER_PROFESSION) {
    // "Other" profession implies "Other" cert org.
    // Keep only the "Other" cert org.
    certOrgsToRender = certOrgsForState.filter(
      certOrg => certOrg.id === OTHER_CERT_ORG,
    );
  } else if (userEnteredProfession) {
    // User has entered a profession.
    // Show only relevant cert orgs with a matching keyword, and the "Other" cert org.
    certOrgsToRender = certOrgsForState.filter(
      certOrg =>
        certOrg.keywords?.includes(userEnteredProfession) ||
        certOrg.id === OTHER_CERT_ORG,
    );
  } else {
    // User did not enter a profession.
    certOrgsToRender = certOrgsForState;
  }

  return certOrgsToDropdown(certOrgsToRender);
};

/**
 * The uiSchema object is very specific to the react-jsonschema-form library
 * See docs for more details (https://react-jsonschema-form.readthedocs.io/en/docs/)
 *
 * @param {Object} schema representing a cert org's schema
 *
 * @returns {Object} uiSchema object
 */
export const constructUiSchema = schema => {
  const hiddenStateWidget = {
    state: { 'ui:widget': 'hidden' },
  };

  const customizeFieldsTitle = {
    optionName: { 'ui:title': 'Name of issuing organization' },
    licenseNumber: { 'ui:title': 'Number—include letters and dashes' },
    licenseType: { 'ui:title': 'Type' },
    expirationDate: { 'ui:title': 'Expiration date' },
    birthYear: { 'ui:title': 'Birth year' },
    socialSec4: { 'ui:title': 'Last 4 digits of Social Security number' },
  };

  const uiSchema = {
    'ui:title': 'Your credential information',
    'ui:description':
      'Enter all information exactly as it appears on your license or certification.',
    'ui:order': ['optionName', 'licenseNumber', '*'], // ensures `optionName` field is always displayed first
    ...customizeFieldsTitle,
    ...(shouldDeDupeState(schema) && hiddenStateWidget), // conditionally hide `state` field
  };

  return uiSchema;
};

/**
 * Conditionally sets a default state on a cert org schema
 *
 * @param {Object} schema representing a cert org's schema
 * @param {String} selectedStateCode the candidate selected state code
 * @param {Array<String>} locations representing package-configured locations
 *
 * @returns the conditionally modified schema
 */
export const conditionallyModifySchema = (
  schema,
  selectedStateCode,
  locations,
) => {
  if (shouldDeDupeState(schema)) {
    // modifications to schema here may need to be unset in
    // ProLicenseVerificationCertification.handleStateOptionChange()
    if (selectedStateCode === UNITED_STATES) {
      const stateNames = _.reduce(
        locations,
        (acc, location) => {
          const stateName = statesByCode[location];
          if (stateName) acc.push(stateName);
          return acc;
        },
        [],
      );
      _.set(schema, 'properties.state.enum', stateNames);
    } else {
      const stateName = statesByCode[selectedStateCode];
      _.set(schema, 'properties.state.default', stateName);
    }
  }
  return schema;
};

/**
 * Checks if state is being duplicated in the provided cert org schema
 *
 * @param {Object} schema representing a cert org's schema
 *
 * @returns boolean representing if state should be deduped
 */
export const shouldDeDupeState = schema => {
  const schemaDuplicatesState = _.has(schema, 'properties.state');
  const stateSchemaNotEnum = !_.has(schema, 'properties.state.enum');
  return schemaDuplicatesState && stateSchemaNotEnum;
};

/**
 * Converts an array of cert orgs to an array of name+value objects for rendering in a dropdown list.
 *
 * @param {Array<Object>} certOrgs Array of cert orgs, with at least the id and name fields.
 *
 * @returns {Array<Object>} Array of cert orgs, with only the name and value fields.
 */
const certOrgsToDropdown = certOrgs => {
  return [{ name: '', value: '' }].concat(
    certOrgs.map(certOrg => {
      return {
        name: certOrg.name,
        value:
          certOrg.id /* IMPORTANT! tldr; certOrg.id != certOrgId
                        apply flow calls camelizeKeys on all API calls to monolith
                        `certOrgId` is modified/camelized, whereas `certOrg.id` is
                        unmodified and represents the proper cert org ID
                        as recognized by our PLV vendors

                        e.g.: certOrg.id = '000-a11-22b' vs certOrgId = '000A1122b'
                      */,
      };
    }),
  );
};
