import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import Panel from '../../Panel';

const TitlePanel = () => {
  return (
    <Panel id='review-page' className='title-panel'>
      <h4 className='title-panel-title'>
        <T value='components.Review.title' />
      </h4>
      <p className='title-panel-header'>
        <T value='components.Review.header' />
      </p>
    </Panel>
  );
};

export default TitlePanel;
