import insertArgyleEmployment from './insertArgyleEmployment';

const insertOldVerifiedArgyleEmployment = () => dispatch => {
  const argyleEmployment = {
    contractType: 'full_time',
    contractTypeVerified: true,
    name: 'Fake Old',
    nameVerified: true,
    position: 'SWE',
    positionVerified: true,
    startDate: '01/2000',
    startDateVerified: true,
    endDate: '03/2001',
    endDateVerified: true,
    currentPosition: false,
    argyleEmploymentId: 'old',
    isVerified: true,
  };

  dispatch(insertArgyleEmployment(argyleEmployment));
};

export default insertOldVerifiedArgyleEmployment;
