import React from 'react';
import { Field } from 'redux-form';
// TODO: This import is 1.2M, find way to reduce
import { Translate as T } from 'react-redux-i18n';
import Panel from './Panel';
import { Upload } from './fields';
import * as V from '../lib/validations';

const ConsentHeader = () => (
  <div className='row'>
    <div className='col-lg-12'>
      <p>
        <strong>
          <T value='components.ConsentForm.header' />
        </strong>
      </p>
    </div>
  </div>
);

const ConsentText = () => (
  <div className='row'>
    <div className='col-lg-12'>
      <p>
        <T value='components.ConsentForm.text' dangerousHTML />
      </p>
    </div>
  </div>
);

const ConsentUpload = () => (
  <div className='row'>
    <div className='col-md-4'>
      <Field
        type='upload'
        name='consent'
        component={Upload}
        options={{ maxFiles: 1, minFiles: 1 }}
        validate={V.required1Document}
      />
    </div>
  </div>
);

const ConsentFooter = () => (
  <div className='row'>
    <div className='col-lg-12'>
      <p>
        <strong>
          <T value='components.ConsentForm.footer' />
        </strong>
      </p>
    </div>
  </div>
);

const ConsentForm = () => (
  <Panel title='components.ConsentForm.title' icon='fa-file-text'>
    <ConsentHeader />
    <ConsentText />
    <ConsentUpload />
    <ConsentFooter />
  </Panel>
);

export default ConsentForm;
