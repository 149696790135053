/* eslint-disable camelcase */
import { includes, filter, first } from 'lodash';
import { i18n } from '@international/mastodon-i18n';

import { REQUIRED } from '../../lib/validations/messages';

export const jumpToError = () => {
  setTimeout(() => {
    const errors = document.getElementsByClassName('bx--form-requirement');
    if (errors.length) {
      const error = Array.from(errors).filter(
        elem => elem.innerHTML === i18n.getStr(REQUIRED),
      )[0];
      window.scrollTo({
        top: (error as any)?.offsetTop - 60,
        behavior: 'smooth',
      });
    }
  });
};

export default jumpToError;

const IDENTITY_VERIFICATION_COUNTRIES = ['GB-ENG', 'GB-WLS'];

export const showIdentityVerificationPage = (
  gbConstituentCountry: string,
): boolean => {
  return includes(IDENTITY_VERIFICATION_COUNTRIES, gbConstituentCountry);
};

const DEFAULT_ADDRESS_HISTORY_NUM_YEARS_REQUIRED = 5;

export const addressHistoryNumYearsRequired = (
  screeningSettings: any,
  addressHistoryYears: number,
): number => {
  const screeningSetting = first(
    filter(screeningSettings, function(ss) {
      return (
        ss.screening_type === 'international_criminal_search_v2' &&
        ss.name === 'address_history_required_years' &&
        ss.enabled === true
      );
    }),
  );
  if (addressHistoryYears > 0) {
    return addressHistoryYears;
  }
  return screeningSetting
    ? parseInt(screeningSetting.value, 10)
    : DEFAULT_ADDRESS_HISTORY_NUM_YEARS_REQUIRED;
};

// This function is used to determine the number of years of address history
// per screening and country.
export const getAddressHistoryYears = (schemaProperties: any): number => {
  const yearValue =
    schemaProperties?.address?.extended_configuration?.address_history_years;

  return yearValue ? parseInt(yearValue, 10) : 0;
};
