import React from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues } from 'redux-form';
import { M } from '@dashboard-experience/mastodon';
import { get } from 'lodash';
import * as V from '../lib/validations';
import Instructions from '../international/Components/Instructions';

import ReduxMRadio from '../international/Fields/FieldComponents/WrappedMastodonComps/Radio';

interface ChoiceCardOptionProps {
  id: string;
  index: number;
  option: any;
  choiceCardValue: any;
  onChange: Function | undefined;
  stretched?: Boolean;
  requiredField?: Boolean;
  disabled: Boolean | undefined;
}

const ChoiceCardOption: React.FC<ChoiceCardOptionProps> = ({
  id,
  index,
  option,
  choiceCardValue,
  onChange,
  stretched = false,
  requiredField = false,
  disabled,
}) => (
  <M.Card
    className={`${stretched ? 'choice-card-stretched' : 'choice-card'}
    ${
      choiceCardValue !== undefined && choiceCardValue === option?.id
        ? 'selected-choice-card'
        : ''
    }`}
    onClick={() => document.getElementById(`${id}_option_${index}`)?.click()}
    role='button'
  >
    <Field
      id={`${id}_option_${index}`}
      name={id}
      component={(field: any) => (
        <ReduxMRadio
          labelText={option?.label}
          value={option?.id !== undefined ? option?.id : true}
          onChange={onChange}
          {...field}
          input={{
            ...field.input,
            checked:
              choiceCardValue !== undefined && choiceCardValue === option?.id,
          }}
        />
      )}
      type='radio'
      disabled={disabled}
      onChange={onChange}
      validate={() => (requiredField ? V.required(choiceCardValue) : false)}
    />
  </M.Card>
);

interface ChoiceCardInstructionProps {
  id: string;
  index: number;
  option: any;
  choiceCardValue: any;
}

const ChoiceCardInstruction: React.FC<ChoiceCardInstructionProps> = ({
  id,
  index,
  option,
  choiceCardValue,
}) => (
  <>
    {option?.instruction &&
      choiceCardValue !== undefined &&
      choiceCardValue === option?.id && (
        <Instructions text={option?.instruction} isHtmlContent />
      )}
  </>
);

const ChoiceCardWithInstructions = ({
  id,
  disabled,
  stretched = false,
  requiredField = false,
  onChange,
  title,
  fieldValues,
  options,
}: {
  id: string;
  options?: any;
  // optionData needs to be an array of object of format -
  // [{ id: string | undefined, label: string | undefined, instruction: string | undefined }]
  stretched?: Boolean;
  requiredField?: Boolean;
  disabled: Boolean | undefined;
  onChange: Function | undefined;
  title: string;
  fieldValues: { string: string | number | boolean | undefined };
}) => {
  const fieldValue = get(fieldValues, id);

  const choiceCardValue = fieldValue === undefined ? undefined : fieldValue;

  return (
    <div>
      <div className='choice-card-container'>
        <div className='choice-card-with-instructions-question'>{title}</div>
        <div className='choice-card-button-container'>
          {(options || []).map((option: any, index: any) => (
            <ChoiceCardOption
              key={`${id}_options${index + 1}`}
              id={id}
              index={index}
              option={option}
              choiceCardValue={choiceCardValue}
              onChange={onChange}
              stretched={stretched}
              requiredField={requiredField}
              disabled={disabled}
            />
          ))}
        </div>

        {(options || []).map((option: any, index: any) => (
          <ChoiceCardInstruction
            key={`${id}_options${index + 1}`}
            id={id}
            index={index}
            option={option}
            choiceCardValue={choiceCardValue}
          />
        ))}
      </div>
    </div>
  );
};

export default connect((state: any) => ({
  fieldValues: getFormValues('intlForm')(state) as any,
}))(ChoiceCardWithInstructions);
