import React, { useContext } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import ResponsiveTextFieldGroupWrapper from 'international/Components/Layouts/ResponsiveTextFieldGroupWrapper';
import InternationalContext from '../../../InternationalContext';
import { createGrid } from './RegionOverrideUtils';

const BIRTH_CERTIFICATE_DOC_TYPES = [
  'BirthCertificate',
  'Birth_Certificate_At_Birth',
  'Birth_Certificate_After_Birth',
];

const DRIVERS_LICENSE_DOC_TYPES = [
  'Current_Driving_Licence',
  'Current_Driving_Licence_NonUk',
  'Current_Driving_Licence_Old_Style',
  'DrivingLicence',
];

const PASSPORT_DOC_TYPES = [
  'Current_Valid_Passport',
  'Passport',
  'Passport_With_IndefiniteOrCurrent_Leave',
];

export const UnitedKingdomAdditionalInfo = ({
  documentTypes,
}: {
  documentTypes: string[];
}) => {
  const intlContext = useContext(InternationalContext);
  const { data, getFieldGeneratorForProperties } = intlContext;
  const scopedRequirementProperties = get(
    data,
    'fieldMap.scoped_requirements.properties.GB.properties',
    {},
  );
  const genScoped = getFieldGeneratorForProperties(scopedRequirementProperties);

  const components = [];

  if (
    documentTypes.some(providedDocumentType =>
      DRIVERS_LICENSE_DOC_TYPES.includes(providedDocumentType),
    )
  ) {
    components.push(
      <ResponsiveTextFieldGroupWrapper>
        {createGrid(genScoped, [
          'driver_license_issuing_country',
          'drivers_license_number',
          'drivers_license_issue_date',
          'drivers_license_type',
        ])}
      </ResponsiveTextFieldGroupWrapper>,
    );
  }

  if (
    documentTypes.some(providedDocumentType =>
      PASSPORT_DOC_TYPES.includes(providedDocumentType),
    )
  ) {
    components.push(
      <ResponsiveTextFieldGroupWrapper>
        {
          createGrid(genScoped, [
            'passport_country',
            'passport_number',
            'passport_expiration_date',
            'passport_issue_date',
          ]) as any
        }
      </ResponsiveTextFieldGroupWrapper>,
      false,
    );
  }

  // NOTE: this is optional for DBS but required for DS searches
  if (
    documentTypes.some(providedDocumentType =>
      BIRTH_CERTIFICATE_DOC_TYPES.includes(providedDocumentType),
    )
  ) {
    components.push(
      <ResponsiveTextFieldGroupWrapper>
        {createGrid(genScoped, [
          'birth_certificate_country',
          'birth_certificate_number',
        ])}
      </ResponsiveTextFieldGroupWrapper>,
    );
  }

  if (documentTypes.includes('EU_National_ID_Card')) {
    components.push(
      <ResponsiveTextFieldGroupWrapper>
        {createGrid(genScoped, [
          'jurisdiction_specific_identifier',
          'national_id_number',
        ])}
      </ResponsiveTextFieldGroupWrapper>,
    );
  }

  // NOTE: this is required for DS
  if (documentTypes.includes('UKVisa')) {
    components.push(
      <ResponsiveTextFieldGroupWrapper>
        {createGrid(genScoped, ['nationality'])}
      </ResponsiveTextFieldGroupWrapper>,
    );
  }

  // NOTE: this is required for DS
  if (documentTypes.includes('Payslip')) {
    components.push(
      <ResponsiveTextFieldGroupWrapper>
        {createGrid(genScoped, ['payslip_date'])}
      </ResponsiveTextFieldGroupWrapper>,
    );
  }

  return <>{components}</>;
};

UnitedKingdomAdditionalInfo.propTypes = {
  documentTypes: PropTypes.array.isRequired,
};

export default UnitedKingdomAdditionalInfo;
