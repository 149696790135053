import React from 'react';
import Loading from './Loading';
import Panel from './Panel';

const LoadingComponent = () => {
  return (
    <Panel>
      <Loading />
    </Panel>
  );
};
export default LoadingComponent;
