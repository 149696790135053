/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as Sentry from '@sentry/react';

import AbstractDisclosure from './AbstractDisclosure';
import ApplicantInformationForm from './ApplicantInformationForm';
import ApplicantPayNotification from './ApplicantPayNotification';
import Article23aDisclosure from './Article23aDisclosure';
import AuthenticateInvitationForm from './AuthenticateInvitationForm';
import CaliforniaDisclosure from './CaliforniaDisclosure';
import CitizenshipInformation from './CitizenshipInformation';
import ConsentForm from './ConsentForm';
import ContactInformationForm from './ContactInformationForm';
import CreditReportDisclosure from './CreditReportDisclosure';
import CriminalSelfDisclosure from './CriminalSelfDisclosure';
import CriminalSelfDisclosureInstructions from './CriminalSelfDisclosureInstructions';
import CurrentAddressForm from './CurrentAddressForm';
import Disclosure from './Disclosure';
import DOTAuthorization from './DOTAuthorization';
import DriverLicenseForm from './DriverLicenseForm';
import DrugAlcoholClearinghouseDisclosure from './DrugAlcoholClearinghouseDisclosure';
import EducationInformationForm from './EducationInformationForm';
import EmploymentHistoryForm from './employment/EmploymentHistoryForm';
import ExpungementConsent from './ExpungementConsent';
import ExpungementHeader from './ExpungementHeader';
import ExtendedEducationInformationForm from './ExtendedEducationInformationForm';
import ExtendedEmploymentHistoryForm from './ExtendedEmploymentHistoryForm';
import FairChanceDisclosure from './FairChanceDisclosure';
import FCRA from './FCRA';
import HealthScreeningConfiguration from './HealthScreeningConfiguration';
import HealthScreeningConsent from './HealthScreeningConsent';
import HipaaPrivacyConsent from './HipaaPrivacyConsent';
import HTMLText from './HTMLText';
import IdentityVerificationProcessor from './identity_verification/IdentityVerificationProcessor';
import InlineConsent from './InlineConsent';
import InternationalAddressHistoryForm from './InternationalAddressHistoryForm';
import InternationalAuthorizationForm from './InternationalAuthorizationForm';
import InternationalConsent from './InternationalConsent';
import InternationalInformationForm from './InternationalInformationForm';
import InvestigativeDisclosure from './InvestigativeDisclosure';
import LastInternationalAddressForm from './LastInternationalAddressForm';
import LoadingComponent from './LoadingComponent';
import LocalDisclosure from './LocalDisclosure';
import Logo from './Logo';
import MailingAddressInfo from './MailingAddressInfo';
import ManualOrderCandidateCopy from './ManualOrderCandidateCopy';
import ManualOrderConsent from './ManualOrderConsent';
import MvrAdditionalDetailsComponent from './MvrAdditionalDetailsComponent';
import PassportForm from './PassportForm';
import PaymentForm from './PaymentForm';
import PersonalReferenceForm from './PersonalReferenceForm';
import ProfessionalReferenceForm from './ProfessionalReferenceForm';
import ProLicenseVerificationForm from './ProLicenseVerificationForm';
import RegionRequirements from '../international/Components/RegionRequirements';
import Review from './Review';
import SelectLocationForm from './SelectLocationForm';
import Signature from './Signature';
import SignatureAuthorization from './SignatureAuthorization';
import StandardConsent from './StandardConsent';
import Success from './Success';
import Text from './Text';
import TextPanel from './TextPanel';
import ConnectedParsEmployeeInformation from './pars/ParsEmployeeInformationForm';
import ConnectedOneEightHundredInstructions from './one_eight_hundred_flow/OneEightHundredInstructions';
import { ParsReporterInformationForm } from './pars/ParsReporterInformationForm';
import ParsWelcome from './pars/ParsWelcome';
import ParsSuccessPage from './pars/ParsSuccessPage';
import CustodyControlForm from './one_eight_hundred_flow/CustodyControlForm';

const Registry = {
  AbstractDisclosure,
  ApplicantInformationForm,
  ApplicantPayNotification,
  Article23aDisclosure,
  AuthenticateInvitationForm,
  CaliforniaDisclosure,
  CitizenshipInformation,
  ConsentForm,
  ContactInformationForm,
  CreditReportDisclosure,
  CriminalSelfDisclosure,
  CriminalSelfDisclosureInstructions,
  CurrentAddressForm,
  Disclosure,
  DOTAuthorization,
  DriverLicenseForm,
  DrugAlcoholClearinghouseDisclosure,
  EducationInformationForm,
  EmploymentHistoryForm,
  ExpungementConsent,
  ExpungementHeader,
  ExtendedEducationInformationForm,
  ExtendedEmploymentHistoryForm,
  FairChanceDisclosure,
  FCRA,
  HealthScreeningConfiguration,
  HealthScreeningConsent,
  HipaaPrivacyConsent,
  HTMLText,
  IdentityVerificationProcessor,
  InlineConsent,
  InternationalAddressHistoryForm,
  InternationalAuthorizationForm,
  InternationalConsent,
  InternationalInformationForm,
  InvestigativeDisclosure,
  LastInternationalAddressForm,
  LoadingComponent,
  LocalDisclosure,
  Logo,
  MailingAddressInfo,
  ManualOrderCandidateCopy,
  ManualOrderConsent,
  MvrAdditionalDetailsComponent,
  OneEightHundredInstructions: ConnectedOneEightHundredInstructions,
  ParsEmployeeInformationForm: ConnectedParsEmployeeInformation,
  ParsReporterInformationForm,
  ParsWelcome,
  ParsSuccessPage,
  PassportForm,
  PaymentForm,
  PersonalReferenceForm,
  ProfessionalReferenceForm,
  ProLicenseVerificationForm,
  RegionRequirements,
  Review,
  SelectLocationForm,
  Signature,
  SignatureAuthorization,
  StandardConsent,
  Success,
  Text,
  TextPanel,
  CustodyControlForm,
};

class CustomComponent extends Component {
  static propTypes = {
    component: PropTypes.string.isRequired,
    props: PropTypes.object,
  };

  render() {
    const { component, props } = this.props;
    const Comp = Registry[component];

    if (!Comp) {
      const errMsg = `Could not render ${component}`;
      Sentry.captureMessage(errMsg);
      return null;
    }

    return <Comp {...props} />;
  }
}

const CustomComponentList = ({ list }) => {
  return (
    <div className='custom-component-list'>
      {list.map((c, i) => (
        <CustomComponent key={`list-${i + 1}`} {...c} />
      ))}
    </div>
  );
};

CustomComponentList.propTypes = {
  list: PropTypes.array.isRequired,
};

export { Registry, CustomComponent, CustomComponentList };
