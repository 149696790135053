import React from 'react';
import PropTypes from 'prop-types';
import { M } from '@dashboard-experience/mastodon';
import { Translate as T } from 'react-redux-i18n';

const Subheader = ({ className = '', icon = false, label }) => {
  return (
    <h3 className={className} aria-label={label} key={label}>
      <T value={label} />
      {icon && <M.Icon className='icon' icon={icon} />}
    </h3>
  );
};

Subheader.propTypes = {
  className: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Subheader;
