/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { change } from 'redux-form';
import { connect } from 'react-redux';

import Panel from './Panel';

import CurrentAddressFields from './CurrentAddressFields';

export class CurrentAddressFormComponent extends Component {
  static propTypes = {
    protectedInvitationFlow: PropTypes.bool,
    protectedInvitationFields: PropTypes.array,
    currentAddress: PropTypes.object,
    baseKey: PropTypes.string,
    title: PropTypes.string,
    skipFrom: PropTypes.bool,
  };

  titleCopy() {
    const { title } = this.props;
    return `components.CurrentAddressForm.${title || 'default'}`;
  }

  render() {
    const {
      protectedInvitationFlow,
      protectedInvitationFields,
      currentAddress,
      skipFrom,
      baseKey,
    } = this.props;

    return (
      <Panel title={this.titleCopy()} icon='fa-home'>
        <CurrentAddressFields
          currentAddress={currentAddress}
          protectedInvitationFlow={protectedInvitationFlow}
          protectedInvitationFields={protectedInvitationFields}
          skipFrom={skipFrom}
          baseKey={baseKey}
          skipUnit
        />
      </Panel>
    );
  }
}

const CurrentAddressForm = connect(
  ({ configuration: { configuration } }, props) => {
    const { candidate } = configuration;
    const currentAddress = (candidate && candidate.currentAddress) || {};
    const {
      protectedInvitationFlow,
      protectedInvitationFields,
    } = configuration.package;
    return {
      protectedInvitationFlow,
      protectedInvitationFields,
      currentAddress,
    };
  },
  { change },
)(CurrentAddressFormComponent);

export default CurrentAddressForm;
