/* eslint-disable react/require-default-props */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Translate as T } from 'react-redux-i18n';
import { change, Field } from 'redux-form';

import { i18n } from '@international/mastodon-i18n';

import {
  EMPLOYMENT_ANALYTICS_ARGYLE_LEARN_MORE_SOURCES,
  EMPLOYMENT_ANALYTICS_EVENTS,
  EMPLOYMENT_ANALYTICS_PROPERTIES,
} from '../../lib/employment/analytics_events';
import { EMPLOYMENT_INPUT_MODE_MANUAL } from '../../lib/employment/constants';
import {
  employerTitleGenerator,
  getEmploymentKeyPart,
} from '../../lib/employment/utils/employmentUtils';
import * as O from '../../lib/options';
import * as V from '../../lib/validations';
import { RemoveFieldButton, Select } from '../fields';
import Panel from '../Panel';
import EmploymentInputTypeSelection from './EmploymentInputTypeSelection';
import EmploymentArgyleForm from './EmploymentArgyleForm';
import ManualEmployer from './ManualEmployer';
import ArgyleLearnMoreModal from './ArgyleLearnMoreModal';
import { trackAnalyticsEvent } from '../../actions';

class Employer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    fieldName: PropTypes.string.isRequired,
    employer: PropTypes.object.isRequired,
    fieldLength: PropTypes.number.isRequired,
    handleOnRemove: PropTypes.func.isRequired,
    i: PropTypes.number.isRequired,
    nth: PropTypes.number.isRequired,
    managerRequired: PropTypes.bool,
    salaryRequired: PropTypes.bool,
    contractTypeRequired: PropTypes.bool,
    lookbackYears: PropTypes.number.isRequired,
    isDot: PropTypes.bool,
    documentCollection: PropTypes.bool,
    argyleEnabled: PropTypes.bool,
    argyleEmployment: PropTypes.object,
    openArgyle: PropTypes.func.isRequired,
    accountUriName: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      showLearnMoreModal: false,
      everExplicitlyChoseManual: false,
      showManualEntryLink: true,
    };

    this.setShowLearnMoreModal = this.setShowLearnMoreModal.bind(this);
    this.setChoseManualExplicitly = this.setChoseManualExplicitly.bind(this);
    this.setShowManualEntryLink = this.setShowManualEntryLink.bind(this);
  }

  setManualInputMode = () => {
    const { dispatch, fieldName } = this.props;
    dispatch(change('applyform', `${fieldName}.inputMode`, 'manual'));
  };

  resetInputMode = () => {
    const { dispatch, fieldName } = this.props;
    dispatch(change('applyform', `${fieldName}.inputMode`, null));
  };

  setShowLearnMoreModal = showModal => {
    this.setState({ showLearnMoreModal: showModal });
  };

  setChoseManualExplicitly = () => {
    this.setState({ everExplicitlyChoseManual: true });
  };

  isArgyleAvailable = () => {
    const {
      employer: { country },
      argyleEnabled,
    } = this.props;

    return argyleEnabled && country === 'US';
  };

  hasArgyleEmploymentId = () => {
    const {
      employer: { argyleEmploymentId },
    } = this.props;
    return !_.isUndefined(argyleEmploymentId);
  };

  allowsRemoval = () => {
    const { fieldLength } = this.props;

    return (
      fieldLength > 1 ||
      // Allow removing of last remaining element if it's Argyle.
      // Parent will re-add a brand new employer, basically resetting it.
      this.hasArgyleEmploymentId()
    );
  };

  setShowManualEntryLink = (showLink = false) => {
    this.setState({ showManualEntryLink: showLink });
  };

  render() {
    const {
      dispatch,
      fieldName,
      employer,
      employer: { country, inputMode },
      handleOnRemove,
      i,
      nth,
      openArgyle,
      argyleEmployment,
      argyleEnabled,
      managerRequired,
      salaryRequired,
      contractTypeRequired,
      lookbackYears,
      isDot,
      documentCollection,
    } = this.props;

    const manualEmployerProps = {
      dispatch,
      fieldName,
      employer,
      managerRequired,
      salaryRequired,
      contractTypeRequired,
      lookbackYears,
      isDot,
      documentCollection,
    };

    const keyPart = getEmploymentKeyPart(employer);

    const isArgyleAvailable = this.isArgyleAvailable();
    const isArgyleEmployment = this.hasArgyleEmploymentId();
    // Do not offer Argyle employments for pre-filled employments e.g. from public API.
    const offerArgyleEmployment = isArgyleAvailable && !inputMode;
    const {
      showLearnMoreModal,
      everExplicitlyChoseManual,
      showManualEntryLink,
    } = this.state;

    const trackArgyleAnalyticsEvent = (eventTitle, eventProperties = {}) => {
      dispatch(trackAnalyticsEvent(eventTitle, eventProperties));
    };

    const toggleManualEntryLink = (
      <>
        {showManualEntryLink && (
          <a
            href='#manualEntry'
            className='manual-link'
            role='button'
            onClick={() => {
              trackArgyleAnalyticsEvent(
                EMPLOYMENT_ANALYTICS_EVENTS.MANUAL_SELECTED,
              );
              this.setManualInputMode();
              this.setShowManualEntryLink();
              this.setChoseManualExplicitly();
            }}
            style={{ textDecoration: 'none' }}
          >
            {i18n.getStr('components.EmploymentForm.toggleManualEntry')}
          </a>
        )}
        {!showManualEntryLink && (
          <a
            href='#quickVerifyEntry'
            className='quick-verify-link'
            role='button'
            onClick={() => {
              this.resetInputMode();
              this.setShowManualEntryLink(true);
            }}
            style={{ textDecoration: 'none' }}
          >
            {i18n.getStr('components.EmploymentForm.toggleQuickVerifyEntry')}
          </a>
        )}
      </>
    );

    return (
      <Panel className='verify-emp'>
        <div className='row'>
          <div className='col-md-12'>
            <h2 className='card-block-heading'>
              {employerTitleGenerator(nth)}
            </h2>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 verify-emp country-question'>
            <h3>{i18n.getStr('components.EmploymentForm.countryQuestion')}</h3>
          </div>
        </div>

        {isArgyleEmployment ? (
          <>
            <div className='row'>
              <div className='col-md-6'>
                <Field
                  type='text'
                  name={`${fieldName}.country`}
                  label='labels.country'
                  component={Select}
                  validate={[V.required]}
                  options={[
                    {
                      name: 'United States of America',
                      value: 'US',
                      alpha3: 'USA',
                      numeric: '840',
                    },
                  ]}
                  disabled
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 verify-emp'>
                <h3>
                  {i18n.getStr(
                    'components.EmploymentForm.verifyEmploymentText',
                  )}
                </h3>
              </div>
            </div>
            <EmploymentArgyleForm
              employment={employer}
              fieldName={fieldName}
              argyleEmployment={argyleEmployment}
            />
          </>
        ) : (
          <>
            <div className='row'>
              <div className='col-md-6'>
                <Field
                  type='text'
                  name={`${fieldName}.country`}
                  label='labels.country'
                  component={Select}
                  validate={[V.required]}
                  options={O.countries}
                  onChange={e => {
                    if (argyleEnabled) {
                      const countryCode = e.target.value;
                      if (countryCode && countryCode !== 'US') {
                        this.setManualInputMode();
                      } else if (
                        countryCode === 'US' &&
                        inputMode === EMPLOYMENT_INPUT_MODE_MANUAL &&
                        !everExplicitlyChoseManual
                      ) {
                        this.resetInputMode();
                      } else if (countryCode === 'US') {
                        trackArgyleAnalyticsEvent(
                          EMPLOYMENT_ANALYTICS_EVENTS.DOMESTIC_EMPLOYMENT_VERIFICATION_STARTED,
                          {
                            [EMPLOYMENT_ANALYTICS_PROPERTIES.EMPLOYMENT_ID]: keyPart,
                          },
                        );
                      }
                    }
                  }}
                />
              </div>
            </div>
            {country && (
              // user needs to have selected a country to see the rest of the form.
              <>
                <div className='row'>
                  <div className='col-md-12 verify-emp'>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <h3>
                        {i18n.getStr(
                          'components.EmploymentForm.verifyEmploymentText',
                        )}
                      </h3>
                      {isArgyleAvailable && toggleManualEntryLink}
                    </div>
                  </div>
                </div>
                {offerArgyleEmployment ? (
                  <div>
                    <EmploymentInputTypeSelection
                      onSelectArgyle={openArgyle}
                      trackArgyleAnalyticsEvent={trackArgyleAnalyticsEvent}
                    />
                    {
                      // This field prevents advancing from the Employment Page as long as the
                      // "Choose Argyle" selection card is presented
                      // TODO: adl - This happens pretty silently and could be confusing to users
                      // perhaps we should provide a visual indication that the employment is "invalid"?
                    }
                    <Field
                      name={`${keyPart}.argyle-selection-cards-blocker`}
                      component='input'
                      type='hidden'
                      validate={V.required}
                    />
                  </div>
                ) : (
                  <>
                    {isArgyleAvailable && (
                      <div className='detailed-text'>
                        <T
                          value='components.EmploymentForm.ArgyleForm.verifyEmploymentDetailedText.part1'
                          dangerousHTML
                        />
                        <span
                          role='button'
                          tabIndex={0}
                          className='link'
                          onClick={openArgyle}
                        >
                          {i18n.getStr(
                            'components.EmploymentForm.ArgyleForm.verifyEmploymentDetailedText.part2',
                          )}
                        </span>
                        <T
                          value='components.EmploymentForm.ArgyleForm.verifyEmploymentDetailedText.part3'
                          dangerousHTML
                        />
                        &nbsp;
                        <a
                          href='#learnMore'
                          data-toggle='modal'
                          onClick={() => this.setShowLearnMoreModal(true)}
                        >
                          {i18n.getStr(
                            'components.EmploymentForm.ArgyleForm.learnMoreBtn',
                          )}
                        </a>
                        <ArgyleLearnMoreModal
                          isOpen={showLearnMoreModal}
                          handleClose={() => this.setShowLearnMoreModal(false)}
                          trackArgyleAnalyticsEvent={trackArgyleAnalyticsEvent}
                          source={
                            EMPLOYMENT_ANALYTICS_ARGYLE_LEARN_MORE_SOURCES.MANUAL_ENTRY_FORM
                          }
                        />
                      </div>
                    )}
                    {!isArgyleAvailable && (
                      <p>
                        {i18n.getStr(
                          'components.EmploymentForm.verifyEmploymentDetailedText',
                        )}
                      </p>
                    )}
                    <p>
                      {i18n.getStr(
                        'components.EmploymentForm.employerInputBestPracticeSuggestion',
                      )}
                    </p>
                    <ManualEmployer {...manualEmployerProps} />
                  </>
                )}
              </>
            )}
          </>
        )}
        <br />
        {this.allowsRemoval() && (
          <RemoveFieldButton
            handleOnClick={() => handleOnRemove(i)}
            textValue={i18n.getStr('components.Employer.employmentRemovalText')}
            showIcon={false}
            className='btn btn-secondary'
          />
        )}
      </Panel>
    );
  }
}

export default Employer;
