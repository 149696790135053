/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate as T } from 'react-redux-i18n';

import { change, Field } from 'redux-form';

import { Text, Phone } from './fields';
import * as V from '../lib/validations';
import isDisabled from './helper';

import Panel from './Panel';

export class ContactInformationFormComponent extends Component {
  static propTypes = {
    identityVerificationCapture: PropTypes.bool,
    optionalEmail: PropTypes.bool,
    optionalPhone: PropTypes.bool,
    protectedInvitationFlow: PropTypes.bool,
    protectedInvitationFields: PropTypes.array,
    candidate: PropTypes.object,
  };

  phone() {
    const { identityVerificationCapture, optionalPhone } = this.props;
    if (identityVerificationCapture) {
      return { label: 'labels.mobilePhone', validate: [V.required] };
    }
    if (optionalPhone) {
      return { label: 'labels.phoneOptional', validate: [] };
    }
    return { label: 'labels.phone', validate: [V.required] };
  }

  renderMobilePhoneRequest = () => {
    return (
      <div
        className='row'
        style={{ fontWeight: 'lighter', paddingBottom: '20px' }}
      >
        <div className='col-md-12'>
          <T value='components.ContactInformationForm.mobilePhoneRequest' />
        </div>
      </div>
    );
  };

  render() {
    const {
      protectedInvitationFlow,
      protectedInvitationFields,
      candidate,
      optionalEmail,
      identityVerificationCapture,
    } = this.props;
    const phone = this.phone();

    return (
      <Panel title='components.ContactInformationForm.title' icon='fa-envelope'>
        {identityVerificationCapture && this.renderMobilePhoneRequest()}
        <div className='row'>
          <div className='col-md-6'>
            <Field
              type='tel'
              name='phone'
              label={phone.label}
              placeholder='placeholders.phone'
              component={Phone}
              validate={phone.validate}
              disabled={isDisabled({
                field: candidate.phone,
                protect:
                  protectedInvitationFlow &&
                  protectedInvitationFields.includes('phone'),
              })}
            />
          </div>

          <div className='col-md-6'>
            <Field
              type='email'
              name='email'
              label={optionalEmail ? 'labels.emailOptional' : 'labels.email'}
              placeholder='placeholders.email'
              component={Text}
              validate={
                optionalEmail ? [V.emailFormat] : [V.required, V.emailFormat]
              }
              disabled={isDisabled({
                field: candidate.email,
                protect:
                  protectedInvitationFlow &&
                  protectedInvitationFields.includes('email'),
              })}
            />
          </div>
        </div>
      </Panel>
    );
  }
}

const ConnectedContactInformationForm = connect(
  (state, props) => {
    return {
      candidate: state.configuration.configuration.candidate,
      protectedInvitationFlow:
        state.configuration.configuration.package.protectedInvitationFlow,
      protectedInvitationFields:
        state.configuration.configuration.package.protectedInvitationFields,
      identityVerificationCapture:
        state.configuration.configuration.package.identityVerificationCapture,
    };
  },
  { change },
)(ContactInformationFormComponent);

export default ConnectedContactInformationForm;
