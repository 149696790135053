/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React, { createElement } from 'react';

import { FieldArray } from 'redux-form';

import { appendField, removeField } from './helper';

const WrapInnerComponent = props => {
  const { fields, innerComponent } = props;
  const fieldLength = fields.length;
  const handleOnRemove = removeField(fields);
  const handleOnAppend = appendField(fields);

  return createElement(innerComponent, {
    ...props,
    fieldLength,
    handleOnAppend,
    handleOnRemove,
  });
};

WrapInnerComponent.propTypes = {
  fields: PropTypes.any,
  innerComponent: PropTypes.func,
};

const DecoratedFieldArray = ({ component, ...props }) => (
  <FieldArray
    {...props}
    innerComponent={component}
    component={WrapInnerComponent}
  />
);

DecoratedFieldArray.propTypes = {
  component: PropTypes.func,
};

export default DecoratedFieldArray;
