import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Translate } from 'react-redux-i18n';

const VerificationMessageStyled = styled.div`
  padding-bottom: 24px;
`;

const VerificationMessage = ({ verificationCompleted }) => (
  <VerificationMessageStyled data-testid='verification-message'>
    <p>
      <Translate
        value={
          verificationCompleted
            ? 'components.IdentityVerificationResults.verificationCompleteMessage'
            : 'components.IdentityVerificationResults.verifyingMessage'
        }
      />
    </p>
  </VerificationMessageStyled>
);

VerificationMessage.propTypes = {
  verificationCompleted: PropTypes.bool.isRequired,
};

export default VerificationMessage;
