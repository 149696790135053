import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import ArgyleReviewEmploymentExpandable from './ArgyleReviewEmploymentExpandable';

const ArgyleReviewOutsideLookbackEmployments = ({
  lookbackYears,
  outsideLookbackArgyleEmployments,
  isPreExpanded,
}) => {
  return (
    <>
      <p>
        {outsideLookbackArgyleEmployments.length === 1 ? (
          <>
            {i18n.getStr(
              'components.EmploymentForm.ArgyleForm.reviewComponents.outsideLookbackSingle',
              lookbackYears,
            )}
          </>
        ) : (
          <>
            {i18n.getStr(
              'components.EmploymentForm.ArgyleForm.reviewComponents.outsideLookbackMultiple',
              lookbackYears,
            )}
          </>
        )}
      </p>

      {outsideLookbackArgyleEmployments.map((employment, index) => {
        return (
          <ArgyleReviewEmploymentExpandable
            key={`argyle-review-employment-outside-lookback-${index + 1}`}
            disabled
            maxAllowedEmployersReached={false}
            initialExpanded={isPreExpanded}
            initialSelected={false}
            employment={employment}
            onSelected={() => {}}
            onUnselected={() => {}}
          />
        );
      })}
    </>
  );
};

ArgyleReviewOutsideLookbackEmployments.propTypes = {
  lookbackYears: PropTypes.number.isRequired,
  outsideLookbackArgyleEmployments: PropTypes.arrayOf(PropTypes.object)
    .isRequired,
  isPreExpanded: PropTypes.bool.isRequired,
};

export default ArgyleReviewOutsideLookbackEmployments;
