/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { Translate as T } from 'react-redux-i18n';
import isDisabled from './helper';

import { requestConfigurationUpdate } from '../actions';

import Panel from './Panel';

import { Select } from './fields';

import * as N from '../lib/normalizations';
import * as O from '../lib/options';
import * as V from '../lib/validations';
import SensitiveInput from './fields/SensitiveInput';

export class DriverLicenseFormComponent extends Component {
  static propTypes = {
    candidate: PropTypes.object,
    requestConfigurationUpdate: PropTypes.func,
    protectedInvitationFlow: PropTypes.bool,
    protectedInvitationFields: PropTypes.array,
  };

  render() {
    const {
      protectedInvitationFlow,
      protectedInvitationFields,
      candidate,
      // eslint-disable-next-line no-shadow
      requestConfigurationUpdate,
    } = this.props;

    const onDLStateUpdate = ({ target: { value: dlState } }) => {
      requestConfigurationUpdate({ params: { dlState } });
    };

    return (
      <>
        <Panel title='components.DriverLicenseForm.title' icon='fa-car'>
          <div className='row'>
            <div className='col-md-4'>
              <Field
                type='select'
                name='dlState'
                label='labels.dlState'
                component={Select}
                validate={[V.required]}
                onChange={onDLStateUpdate}
                options={O.states()}
                disabled={isDisabled({
                  field: candidate.dlState,
                  protect:
                    protectedInvitationFlow &&
                    protectedInvitationFields.includes('driver_license'),
                })}
              />
            </div>

            <div className='col-md-4'>
              <Field
                type='text'
                name='dlNumber'
                label='labels.dlNumber'
                component={SensitiveInput}
                mask={/./g}
                normalize={N.upper}
                validate={[V.required, V.dlFormat]}
                disabled={isDisabled({
                  field: candidate.dlNumber,
                  protect:
                    protectedInvitationFlow &&
                    protectedInvitationFields.includes('driver_license'),
                })}
              />
            </div>

            <div className='col-md-4'>
              <Field
                type='text'
                name='dlNumberConfirmation'
                label='labels.dlNumberConfirmation'
                component={SensitiveInput}
                mask={/./g}
                normalize={N.upper}
                validate={[
                  V.required,
                  V.dlFormat,
                  (_, model) => V.dlMatch(_, model),
                ]}
                disabled={isDisabled({
                  field: candidate.dlNumber,
                  protect:
                    protectedInvitationFlow &&
                    protectedInvitationFields.includes('driver_license'),
                })}
              />
            </div>
          </div>

          <label
            htmlFor='dlExpirationDate.month'
            key='dl-expiration-date-label'
          >
            <T value='labels.dlExpirationDate' />
          </label>
          <div className='row' key='dl-expiration-date-row'>
            <div className='col-md-4'>
              <Field
                type='select'
                name='dlExpirationDate.month'
                component={Select}
                validate={[
                  V.required,
                  (_, model) => V.validExpirationDate(model),
                ]}
                options={O.months()}
              />
            </div>

            <div className='col-md-4'>
              <Field
                type='select'
                name='dlExpirationDate.day'
                component={Select}
                validate={[
                  V.required,
                  (_, model) => V.validExpirationDate(model),
                ]}
                options={O.days()}
              />
            </div>

            <div className='col-md-4'>
              <Field
                type='select'
                name='dlExpirationDate.year'
                component={Select}
                validate={[
                  V.required,
                  (_, model) => V.validExpirationDate(model, true),
                ]}
                options={O.years(-60, 0)}
              />
            </div>
          </div>
        </Panel>
      </>
    );
  }
}

const ConnectedDriverLicenseForm = connect(
  (state, props) => {
    const {
      protectedInvitationFlow,
      protectedInvitationFields,
    } = state.configuration.configuration.package;
    const { candidate } = state.configuration.configuration;
    return {
      protectedInvitationFlow,
      protectedInvitationFields,
      candidate,
    };
  },
  { requestConfigurationUpdate },
)(DriverLicenseFormComponent);

export default ConnectedDriverLicenseForm;
