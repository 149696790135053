import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import Panel from './Panel';

const MailingAddressInfo = ({ translationCopyKey }) => (
  <Panel title='components.MailingAddressInfo.title' icon='fa-home'>
    <T value={`components.MailingAddressInfo.${translationCopyKey}`} />
  </Panel>
);

MailingAddressInfo.propTypes = {
  translationCopyKey: PropTypes.string,
};

MailingAddressInfo.defaultProps = {
  translationCopyKey: 'nonElectronicLocationCopy',
};

export default MailingAddressInfo;
