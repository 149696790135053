/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import HealthScreeningConfigurationInstructions from './HealthScreeningConfigurationInstructions';
import ExamCategory from './ExamCategory';
import Panel from './Panel';

import {
  initializeSelectedExams,
  selectExam,
  setExamWaivers,
} from '../actions';
import {
  EXAM_TYPES,
  EXAM_CATEGORY_TYPES,
  filterWaivableHealthExams,
  HealthScreeningContext,
} from '../lib/utils/healthScreeningUtils';

export class HealthScreeningConfigurationComponent extends Component {
  static propTypes = {
    enabledExams: PropTypes.arrayOf(PropTypes.object),
    initializeSelectedExams: PropTypes.func.isRequired,
    selectExam: PropTypes.func.isRequired,
    setExamWaivers: PropTypes.func.isRequired,
    waivedExamsRequireDocuments: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    props.initializeSelectedExams();
    this.handleToggle = this.handleToggle.bind(this);
    this.handleDocumentUpload = this.handleDocumentUpload.bind(this);
  }

  handleToggle(slug) {
    const { props } = this;
    return (e, newValue) => {
      props.selectExam(slug, newValue);
    };
  }

  handleDocumentUpload(slug) {
    const { props } = this;
    return e => {
      const documents = [];
      // convert object with numerical keys to array
      for (let i = 0; e[i]; i += 1) {
        const { mimetype, url, filename } = e[i];
        documents.push({ mimetype, url, filename });
      }
      props.setExamWaivers(slug, documents);
    };
  }

  render() {
    const { enabledExams, waivedExamsRequireDocuments } = this.props;

    const OccupationalHealthExamCategories = enabledExams.filter(
      exam => exam.screeningType === EXAM_CATEGORY_TYPES.occupationalHealth,
    );

    const { vaccine, physical } = EXAM_TYPES;

    const healthContextValue = {
      requireWaiverDocument: waivedExamsRequireDocuments,
      handleDocumentUpload: this.handleDocumentUpload,
      handleToggle: this.handleToggle,
    };

    return (
      <div>
        <HealthScreeningConfigurationInstructions />
        <div id='healthScreeningInfo'>
          <Panel
            title='components.Health.exams.header'
            icon='fa-medkit'
            className='examWrapper'
          >
            <Panel className='exam'>
              <HealthScreeningContext.Provider value={healthContextValue}>
                {OccupationalHealthExamCategories.map((examCategory, i) => {
                  const exams = filterWaivableHealthExams(
                    examCategory.services,
                  );
                  const examType =
                    examCategory.name === 'Immunizations' ? vaccine : physical;

                  return (
                    exams.length > 0 && (
                      <ExamCategory
                        key={`category-${i + 1}`}
                        examCategoryName={examCategory.name}
                        examType={examType}
                        exams={exams}
                      />
                    )
                  );
                })}
              </HealthScreeningContext.Provider>
            </Panel>
          </Panel>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    enabledExams: state.configuration.configuration.package.enabledExams,
  };
};

const mapDispatchToProps = {
  initializeSelectedExams,
  selectExam,
  setExamWaivers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HealthScreeningConfigurationComponent);
