/* eslint-disable no-shadow */
/* eslint-disable react/display-name */
import React, { Component, Fragment } from 'react';
import { M } from '@dashboard-experience/mastodon';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, change } from 'redux-form';
import { Translate as T } from 'react-redux-i18n';
import styled from 'styled-components';
import { isEmpty, unset } from 'lodash';
import Form from '@rjsf/core';
import {
  RemoveFieldButton,
  Select,
  Label,
  Upload,
  TypeaheadInput,
} from './fields';
import Panel from './Panel';
import {
  alwaysSuggestOtherProfessionFilter,
  OTHER_PROFESSION,
  PROFESSIONS,
} from '../lib/utils/plvProfessionUtils';
import {
  getStatesForDropdown,
  UNITED_STATES,
} from '../lib/utils/plvStateUtils';
import {
  conditionallyModifySchema,
  constructUiSchema,
  getCertOrgsForDropdown,
  getSchemaForCertOrgId,
  OTHER_CERT_ORG,
} from '../lib/utils/plvCertOrgUtils';
import setDocumentContextAttributes from '../lib/utils/documentUtils';
import * as V from '../lib/validations';
import Checkbox from './fields/Checkbox';

const ToolTipContainer = styled.div`
  .tooltip-wrapper-aligned {
    display: inline-block;
  }
  .bx--tooltip__label {
    vertical-align: text-bottom;
  }
`;

const JsonFormContainer = styled.div`
  .field-object {
    margin-bottom: 0px;
  }
`;

class ProLicenseVerificationCertification extends Component {
  static propTypes = {
    certification: PropTypes.object,
    change: PropTypes.func.isRequired,
    errors: PropTypes.array,
    index: PropTypes.number.isRequired,
    locations: PropTypes.array,
    optionsById: PropTypes.object,
    optionsByState: PropTypes.object.isRequired,
    removeCertification: PropTypes.func.isRequired,
    showRemoveButton: PropTypes.bool,
    licenseCategories: PropTypes.array,
  };

  static defaultProps = {
    certification: {},
    errors: [],
    locations: [],
    optionsById: [],
    showRemoveButton: false,
    licenseCategories: [],
  };

  constructor() {
    super();
    this.formRef = null;
    this.state = {
      scrollY: window.scrollY,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  hasCategory = () => {
    const { licenseCategories } = this.props;
    return licenseCategories.length > 0;
  };

  hasNoCategory = () => {
    return !this.hasCategory();
  };

  userHasEnteredProfession = () => {
    const { certification } = this.props;
    return !!certification.profession;
  };

  certOrgsToRender = () => {
    const { certification, optionsByState } = this.props;
    const stateCodeSelected = certification.state;
    const userEnteredProfession = certification.profession;

    return getCertOrgsForDropdown(
      optionsByState[stateCodeSelected],
      userEnteredProfession,
    );
  };

  // Returns whether the "Other" cert org is the only cert org for the state.
  isOtherOnlyCertOrg = stateCode => {
    const { certification, optionsByState } = this.props;

    const certOrgsForState = optionsByState[stateCode];
    const userEnteredProfession = certification.profession;
    const renderedRealCertOrgs = getCertOrgsForDropdown(
      certOrgsForState,
      userEnteredProfession,
    )?.filter(
      // Exclude the first item, the blank cert org.
      certOrg => certOrg.value !== '',
    );
    return (
      renderedRealCertOrgs?.length === 1 &&
      renderedRealCertOrgs[0].value === OTHER_CERT_ORG
    );
  };

  // Returns whether the current cert org is the "Other" cert org.
  isOtherCertOrg = () => {
    const {
      certification: { organizationId },
    } = this.props;

    return organizationId === OTHER_CERT_ORG;
  };

  isOtherProfession = profession => {
    return profession === OTHER_PROFESSION;
  };

  isNationwide = stateCode => {
    return stateCode === UNITED_STATES;
  };

  handleScroll = _event => {
    this.setState({ scrollY: window.scrollY });
  };

  handleClearInputDataAndDocuments = () => {
    const { change, index } = this.props;
    // Clear existing documents if selection is updated
    // to prevent stale document context attribute associations
    change(
      'applyform',
      `proLicenseVerification[certifications][${index}][documents]`,
      null,
    );

    change(
      'applyform',
      `proLicenseVerification[certifications][${index}][inputData]`,
      null,
    );
  };

  handleProfessionChange = (_event, _newProfession) => {
    const { change, index } = this.props;

    // Reset the nationwide selection.
    change(
      'applyform',
      `proLicenseVerification[certifications][${index}][nationwide]`,
      false,
    );

    // Reset the state selection.
    change(
      'applyform',
      `proLicenseVerification[certifications][${index}][state]`,
      null,
    );

    // Reset the cert org selection.
    change(
      'applyform',
      `proLicenseVerification[certifications][${index}][organizationId]`,
      null,
    );
  };

  handleNationwideChange = checked => {
    const { change, index } = this.props;
    const state = checked ? UNITED_STATES : null;
    change(
      'applyform',
      `proLicenseVerification[certifications][${index}][state]`,
      state,
    );
    this.handleStateOptionChange(state);
  };

  handleStateOptionChange = newStateCode => {
    const { certification, change, index, optionsById } = this.props;

    // Reset the nationwide selection.
    change(
      'applyform',
      `proLicenseVerification[certifications][${index}][nationwide]`,
      false,
    );

    // Reset the cert org selection.
    const certOrgToSelect =
      this.isOtherProfession(certification.profession) ||
      (newStateCode && this.isOtherOnlyCertOrg(newStateCode))
        ? OTHER_CERT_ORG
        : null;
    change(
      'applyform',
      `proLicenseVerification[certifications][${index}][organizationId]`,
      certOrgToSelect,
    );

    this.handleClearInputDataAndDocuments();

    const schema = getSchemaForCertOrgId(
      optionsById,
      certification.organizationId,
    );
    if (schema) {
      // unset what was done in proLicenseVerificationUtils.conditionallyModifySchema()
      unset(schema, 'properties.state.default');
      unset(schema, 'properties.state.enum');
    }
  };

  handleCertificationDocuments = (_, documents) => {
    const { certification } = this.props;
    setDocumentContextAttributes(documents, {
      certificationOrganizationId: certification.organizationId,
    });
  };

  removeDocuments = () => {
    const { change, index } = this.props;
    change(
      'applyform',
      `proLicenseVerification[certifications][${index}][documents]`,
      null,
    );
  };

  handleCertificationOrganizationSelection = (_, newCertification) => {
    const { change, index } = this.props;
    change(
      'applyform',
      `proLicenseVerification[certifications][${index}][organizationId]`,
      newCertification,
    );
    this.handleClearInputDataAndDocuments();
  };

  formattedFormData = () => {
    const { state } = this.formRef || {};
    const { formData } = state || {};
    return formData;
  };

  handleChange = inputData => {
    const { change, index } = this.props;

    // Blank out the License Number when the "no license number" checkbox is checked.
    // We can remove this special treatment if and when react-jsonschema-form supports
    // blanking out a previously-entered field based on another field's value.
    if (this.isOtherCertOrg() && inputData?.noLicenseNumber) {
      // eslint-disable-next-line no-param-reassign
      delete inputData.licenseNumber;
    }

    const inputDataJson = JSON.stringify(inputData);
    change(
      'applyform',
      `proLicenseVerification[certifications][${index}][inputData]`,
      inputDataJson,
    );
  };

  renderJsonSchemaForm() {
    const {
      optionsById,
      locations,
      certification: { organizationId, inputData, state },
    } = this.props;

    if (!organizationId) {
      return null;
    }

    const schema = getSchemaForCertOrgId(optionsById, organizationId);
    return (
      <Fragment>
        <JsonFormContainer>
          <Form
            schema={conditionallyModifySchema(schema, state, locations)}
            uiSchema={constructUiSchema(schema)}
            formData={inputData ? JSON.parse(inputData) : {}}
            onChange={() => this.handleChange(this.formattedFormData())}
            tagName='div'
            ref={form => {
              this.formRef = form;
            }}
            id='proLicenseVerificationOrderInput'
          >
            <div />
          </Form>
        </JsonFormContainer>
      </Fragment>
    );
  }

  render() {
    const {
      index,
      certification,
      locations,
      showRemoveButton,
      removeCertification,
      errors,
    } = this.props;
    const { scrollY } = this.state;

    return (
      <Panel>
        <div className='row'>
          <div className='col-md-12'>
            <h4 className='card-block-heading'>
              <T
                value={`labels.ordinalNumbers.number_${index + 1}`}
                count={index + 1}
              />{' '}
              <T value='components.ProLicenseVerificationForm.certification' />
            </h4>
          </div>
        </div>
        {this.hasNoCategory() && (
          <div className='row'>
            <div className='col-md-6'>
              <Field
                type='text'
                name={`proLicenseVerification[certifications][${index}][profession]`}
                id={`proLicenseVerification[certifications][${index}][profession]`}
                label='components.ProLicenseVerificationForm.profession'
                placeholder='components.ProLicenseVerificationForm.professionPlaceholder'
                component={TypeaheadInput}
                options={PROFESSIONS}
                suggest
                minLength={1}
                filterBy={alwaysSuggestOtherProfessionFilter}
                onChange={this.handleProfessionChange}
                validate={[V.required]}
              />
            </div>
          </div>
        )}
        {(this.hasCategory() || this.userHasEnteredProfession()) && (
          <>
            <div className='row'>
              <div className='col-md-6'>
                <Field
                  component={Select}
                  name={`proLicenseVerification[certifications][${index}][state]`}
                  id={`proLicenseVerification[certifications][${index}][state]`}
                  label='components.ProLicenseVerificationForm.state'
                  options={getStatesForDropdown(locations)}
                  onChange={event => {
                    this.handleStateOptionChange(event.target.value);
                  }}
                  validate={[V.required]}
                  disabled={this.isNationwide(certification.state)}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <Field
                  type='checkbox'
                  component={Checkbox}
                  name={`proLicenseVerification[certifications][${index}][nationwide]`}
                  id={`proLicenseVerification[certifications][${index}][nationwide]`}
                  label='components.ProLicenseVerificationForm.nationwide'
                  onChange={event => {
                    this.handleNationwideChange(event.target.checked);
                  }}
                />
              </div>
            </div>
          </>
        )}
        {certification.state && (
          <div
            className={`row
              ${
                this.isOtherProfession(certification.profession) ||
                this.isOtherOnlyCertOrg(certification.state)
                  ? 'hidden'
                  : ''
              }`}
          >
            <div className='col-md-6'>
              <ToolTipContainer>
                <Label label='components.ProLicenseVerificationForm.certifyingOrganization' />
                <M.Tooltip
                  direction='top'
                  menuOffset={{ left: 1, top: scrollY + 10 }}
                  renderIcon={React.forwardRef((props, ref) => (
                    <div ref={ref}>
                      <M.Icon icon='HelpFilled' />
                    </div>
                  ))}
                >
                  <p>
                    <T value='components.ProLicenseVerificationForm.certifyingOrganizationTooltip' />
                  </p>
                </M.Tooltip>
              </ToolTipContainer>
              <Field
                component={Select}
                name={`proLicenseVerification[certifications][${index}][organizationId]`}
                id={`proLicenseVerification[certifications][${index}][organizationId]`}
                options={this.certOrgsToRender()}
                onChange={this.handleCertificationOrganizationSelection}
                validate={[V.required]}
              />
            </div>
          </div>
        )}
        <br />
        <div className='row'>
          <div className='col-md-12'>
            {!isEmpty(errors) && (
              <p className='alert alert-danger' role='alert'>
                {errors.map(({ schema: { title }, message, property }) => (
                  <li key={property}>
                    {title} {message}
                  </li>
                ))}
              </p>
            )}
            {this.renderJsonSchemaForm()}
          </div>
        </div>
        {certification.organizationId && (
          <div className='row'>
            <div className='col-md-12'>
              <span>
                <strong>
                  <T value='components.ProLicenseVerificationForm.candidateUploadTitle' />
                </strong>
              </span>
              <p>
                <T value='components.ProLicenseVerificationForm.candidateUploadInfo' />
              </p>
            </div>
            <div className='col-sm-12  col-md-6' id={`filestack_${index}`}>
              <Field
                type='upload'
                name={`proLicenseVerification[certifications][${index}].documents`}
                component={Upload}
                onChange={this.handleCertificationDocuments}
                options={{
                  maxFiles: 2,
                  container: `#filestack_${index} #filestack`,
                }}
                validate={[V.required1Document]}
                boldLabel
                handleRemoveDocuments={this.removeDocuments}
              />
            </div>
          </div>
        )}
        {showRemoveButton && (
          <div className='row'>
            <div className='col-md-12'>
              <RemoveFieldButton handleOnClick={removeCertification} />
            </div>
          </div>
        )}
      </Panel>
    );
  }
}

const mapStateToProps = (
  {
    form: {
      applyform: {
        values: { proLicenseVerification },
      },
    },
  },
  { index },
) => {
  const { certifications } = proLicenseVerification || {};
  const certification = certifications ? certifications[index] : {};

  return {
    certification,
  };
};

export default connect(mapStateToProps, { change })(
  ProLicenseVerificationCertification,
);
