import PropTypes from 'prop-types';
import React from 'react';
import WELCOME_PAGE from '../identifiers/identifiers';

const Logo = ({ logoUri }) => (
  <div className='container text-center'>
    <img
      data-testid={WELCOME_PAGE.companyLogo}
      className='company-logo'
      src={logoUri}
      alt='Company Logo'
    />
  </div>
);

Logo.propTypes = {
  logoUri: PropTypes.string.isRequired,
};

export default Logo;
