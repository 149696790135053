/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { Checkbox } from './fields';

import * as V from '../lib/validations';

import Panel from './Panel';
import HTMLText from './HTMLText';

const InlineConsent = ({ content }) => (
  <Panel title='components.InlineConsent.title' icon='fa-file-text'>
    {content && (
      <div className='scrollable'>
        <HTMLText content={content} />
      </div>
    )}
    <div className='col-md-12'>
      <Field
        type='checkbox'
        name='disclosure'
        label='components.InlineConsent.label'
        component={Checkbox}
        validate={[V.requiredCheckbox]}
        boldLabel
      />
    </div>
  </Panel>
);

InlineConsent.propTypes = {
  content: PropTypes.string,
};

export default InlineConsent;
