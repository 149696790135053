/* eslint-disable react/prop-types */
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import { Dictionary } from 'lodash';
import React, { FC, useState, useContext } from 'react';

import { required } from '../../lib/validations';
import InternationalContext from '../InternationalContext';
import { UI_SPECIFIC_INFO } from '../constants';
import generate from '../Fields/FieldCreationLogic/FieldGenerators';
import ResponsiveTextFieldGroupWrapper from './Layouts/ResponsiveTextFieldGroupWrapper';

type Props = {
  reqs: string[][];
};

const OneOfDropdown: FC<Props> = ({ reqs }) => {
  const [id, setId] = useState('');
  const intlContext = useContext(InternationalContext);
  const {
    intlI18n,
    data: { oneOfKeys },
    fieldGenerator: { generateField },
  } = intlContext;

  const optionsMap: Dictionary<string[]> = {};

  reqs.forEach(reqArr => {
    optionsMap[reqArr.join('')] = reqArr;
  });

  const getDocumentLabel = (size: number) => {
    if (size === 3) return 'documentLabel3';
    if (size === 4) return 'documentLabel4';
    return 'documentLabel';
  };

  const fields =
    id && optionsMap[id]
      ? optionsMap[id].map(key =>
          generateField(oneOfKeys[key]).render(() => ({
            validate: required,
          })),
        )
      : [];

  const dropdown = generate
    .dropdownField({
      id: `${UI_SPECIFIC_INFO}.oneOfInformation`,
      title: i18n.getStr(
        'i18nInternational.screeningPage.selectDocumentToProvide',
      ),
      placeholder: i18n.getStr(
        'i18nInternational.screeningPage.selectDocument',
      ),
      className: 'text-field',
      items: Object.keys(optionsMap).map(key => {
        const optionLabel = optionsMap[key].map(str => intlI18n(str, 'labels'));
        const value =
          optionLabel?.length > 1
            ? i18n.getStr(
                `i18nInternational.screeningPage.${getDocumentLabel(
                  optionLabel.length,
                )}`,
                optionLabel,
              )
            : optionLabel[0];
        return {
          id: key,
          label: value || '',
        };
      }),
    })
    .render({
      validate: required,
      valueOnInit: val => setId(val),
      onChange: (_, newId) => {
        setId(newId);
      },
    });

  return (
    <>
      {M.Container.buildSubContents([
        <ResponsiveTextFieldGroupWrapper key='one-of-dropdown-wrapper'>
          <div className='text-field' key={id}>
            {dropdown}
          </div>
        </ResponsiveTextFieldGroupWrapper>,
        fields,
      ])}
    </>
  );
};

export default OneOfDropdown;
