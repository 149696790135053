import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import HTMLText from './HTMLText';
import Panel from './Panel';
import VideoNotice from './VideoNotice';
import { SUCCESS_STATUS_NAME } from '../constants';

const Success = ({ content }) => {
  useEffect(() => {
    if (window.location !== window.parent.location) {
      window.parent.postMessage({ status: SUCCESS_STATUS_NAME }, '*');
    }
  }, []);

  return (
    <Panel>
      <HTMLText content={content} />
      <VideoNotice />
    </Panel>
  );
};

Success.propTypes = {
  content: PropTypes.string.isRequired,
};

export default Success;
