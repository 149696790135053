import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { get } from 'lodash';
import { i18n } from '@international/mastodon-i18n';

import ResponsiveTextFieldGroupWrapper from 'international/Components/Layouts/ResponsiveTextFieldGroupWrapper';
import * as V from '../../../../lib/validations';
import {
  dsProofOfIdentityDocuments,
  dsProofOfResidentialAddressDocuments,
} from '../../../Fields/FieldCreationLogic/SelectOptions';
import generate from '../../../Fields/FieldCreationLogic/FieldGenerators';
import InternationalContext from '../../../InternationalContext';
import { createGrid, getPastNames } from './RegionOverrideUtils';
import UnitedKingdomAdditionalInfo from './UnitedKingdomAdditionalInfo';
import PastNamesWrapper from './PastNamesWrapper';

export const UnitedKingdomDS = ({
  constituentCountryEnabled,
  dsProofOfIdentityDocument1Type,
  dsProofOfIdentityDocument2Type,
  dsProofOfResidentialAddressDocumentType,
}: {
  constituentCountryEnabled: boolean;
  dsProofOfIdentityDocument1Type?: string;
  dsProofOfIdentityDocument2Type?: string;
  dsProofOfResidentialAddressDocumentType?: string;
}) => {
  const intlContext = useContext(InternationalContext);
  const { data, getFieldGeneratorForProperties } = intlContext;
  const scopedRequirementProperties = get(
    data,
    'fieldMap.scoped_requirements.properties.GB.properties',
    {},
  );
  const genScoped = getFieldGeneratorForProperties(scopedRequirementProperties);

  const proofOfIdentityDocument1Types = dsProofOfIdentityDocuments.filter(
    type => type !== dsProofOfIdentityDocument2Type,
  );
  const proofOfIdentityDocument2Types = dsProofOfIdentityDocuments.filter(
    type => type !== dsProofOfIdentityDocument1Type,
  );

  const { pastNamesContent, hasPastNames } = getPastNames(
    scopedRequirementProperties,
    intlContext,
  );

  return (
    <div className='uk-region-container'>
      {createGrid(
        genScoped,
        [
          {
            sectionHeader:
              'international.regionOverrides.sectionHeaders.proofOfIdentity',
          },
          {
            label: 'international.regionOverrides.descriptions.proofOfIdentity',
          },
        ],
        false,
        constituentCountryEnabled,
      )}

      <div className='text-field'>
        {generate
          .dropdownField({
            id: 'dsProofOfIdentityDocument1Type',
            title: i18n.getStr(
              'international.regionOverrides.titles.documentType',
            ),
            items: proofOfIdentityDocument1Types.map(doc => ({
              id: doc,
              label: i18n.getConstStr(
                'international.dropdownLabel.identityDocuments',
                doc.replaceAll('_', ' '),
              ),
            })),
            placeholder: i18n.getStr(
              'international.regionOverrides.placeholders.selectADocument',
            ),
          })
          .render({
            validate: [V.required],
          })}
      </div>
      <ResponsiveTextFieldGroupWrapper>
        {dsProofOfIdentityDocument1Type &&
          createGrid(genScoped, [
            'ds_proof_of_identity_document_1',
            <UnitedKingdomAdditionalInfo
              key='proofOfIdentityDocument1AdditionalInfo'
              documentTypes={[dsProofOfIdentityDocument1Type || '']}
            />,
          ])}
      </ResponsiveTextFieldGroupWrapper>

      <div className='text-field'>
        {generate
          .dropdownField({
            id: 'dsProofOfIdentityDocument2Type',
            title: i18n.getStr(
              'international.regionOverrides.titles.documentType',
            ),
            items: proofOfIdentityDocument2Types.map(doc => ({
              id: doc,
              label: i18n.getConstStr(
                'international.dropdownLabel.identityDocuments',
                doc.replaceAll('_', ' '),
              ),
            })),
            placeholder: i18n.getStr(
              'international.regionOverrides.placeholders.selectADocument',
            ),
            disabled: true,
          })
          .render({
            validate: [V.required],
          })}
      </div>
      <ResponsiveTextFieldGroupWrapper>
        {dsProofOfIdentityDocument2Type &&
          createGrid(genScoped, [
            'ds_proof_of_identity_document_2',
            <UnitedKingdomAdditionalInfo
              key='proofOfIdentityDocument2AdditionalInfo'
              documentTypes={[dsProofOfIdentityDocument2Type || '']}
            />,
          ])}
      </ResponsiveTextFieldGroupWrapper>

      {createGrid(
        genScoped,
        [
          {
            sectionHeader:
              'international.regionOverrides.sectionHeaders.proofOfResidence',
          },
          {
            label:
              'international.regionOverrides.descriptions.proofOfResidence',
          },
        ],
        false,
      )}

      <div className='text-field'>
        {generate
          .dropdownField({
            id: 'dsProofOfResidentialAddressDocumentType',
            title: i18n.getStr(
              'international.regionOverrides.titles.documentType',
            ),
            items: dsProofOfResidentialAddressDocuments.map(doc => ({
              id: doc,
              label: i18n.getConstStr(
                'international.dropdownLabel.residentialAddressDocuments',
                doc,
              ),
            })),
            placeholder: i18n.getStr(
              'international.regionOverrides.placeholders.selectADocument',
            ),
          })
          .render({
            validate: [V.required],
          })}
      </div>
      <ResponsiveTextFieldGroupWrapper>
        {dsProofOfResidentialAddressDocumentType &&
          createGrid(genScoped, [
            'ds_proof_of_residential_address_document',
            <UnitedKingdomAdditionalInfo
              key='proofOfResidentialAddressDocumentAdditionalInfo'
              documentTypes={[dsProofOfResidentialAddressDocumentType || '']}
            />,
          ])}
      </ResponsiveTextFieldGroupWrapper>

      {createGrid(
        genScoped,
        [
          {
            sectionHeader:
              'international.regionOverrides.sectionHeaders.additionalDetails',
          },
          {
            label:
              'international.regionOverrides.descriptions.additionalDetails',
          },
        ],
        false,
      )}

      {
        <div>
          <ResponsiveTextFieldGroupWrapper>
            {createGrid(genScoped, [
              'title',
              'mother_maiden_name',
              'national_insurance_number',
              'national_insurance_document',
            ])}
          </ResponsiveTextFieldGroupWrapper>
          {hasPastNames && (
            <PastNamesWrapper>{pastNamesContent}</PastNamesWrapper>
          )}
        </div>
      }
    </div>
  );
};

const ConnectedUnitedKingdomDS = connect((state: any) => {
  const isInternational =
    state.configuration.configuration.format === 'international';
  const formSelector = formValueSelector(
    isInternational ? 'intlForm' : 'applyform',
  );
  return formSelector(
    state,
    'dsProofOfIdentityDocument1Type',
    'dsProofOfIdentityDocument2Type',
    'dsProofOfResidentialAddressDocumentType',
  );
})(UnitedKingdomDS);

export default ConnectedUnitedKingdomDS;
