/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { Checkbox, Select, Text, TypeaheadInput } from './fields';
import * as O from '../lib/options';
import * as V from '../lib/validations';
import { requestCities } from '../actions';

class ExtendedHistoryEntry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cities: [],
    };
  }

  handleStateChange({ target: { value } }) {
    const { change, formKey, entryNum } = this.props;

    change('applyform', `${formKey(entryNum, 'city')}`, null);

    if (value) {
      requestCities(value).then(cities => this.setState({ cities }));
    } else {
      this.setState({ cities: [] });
    }
  }

  render() {
    const { cities } = this.state;
    const {
      entryNum,
      entities,
      entityInputProps,
      entry,
      change,
      formKey,
      translationKey,
    } = this.props;

    return (
      <div>
        <div className='row'>
          <div className='col-lg-6'>
            <Field
              type='text'
              name={formKey(entryNum, 'country')}
              label='labels.country'
              component={Text}
              disabled
              input={{ value: I18n.t('countries.unitedStatesOfAmerica') }}
            />
          </div>
          <div className='col-lg-3'>
            <Field
              type='text'
              name={formKey(entryNum, 'state')}
              label='labels.state'
              component={Select}
              // eslint-disable-next-line react/jsx-no-bind
              onChange={this.handleStateChange.bind(this)}
              validate={entryNum === 0 ? [V.required] : []}
              options={O.states()}
            />
          </div>
          <div className='col-lg-3'>
            <Field
              type='text'
              name={formKey(entryNum, 'city')}
              label='labels.city'
              component={TypeaheadInput}
              disabled={!entry.state}
              options={cities}
              placeholder={I18n.t(
                `placeholders.${entry.state ? 'city' : 'cityLocked'}`,
              )}
              validate={entryNum === 0 ? [V.required] : []}
              minLength={1}
              suggest
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6'>
            <Field
              type='text'
              name={formKey(entryNum, 'entity')}
              label={`${translationKey}.entity`}
              component={TypeaheadInput}
              options={entities}
              suggest
              validate={entryNum === 0 ? [V.required] : []}
              translationKe={translationKey}
              {...entityInputProps}
            />
          </div>
          <div className='col-lg-3'>
            <Field
              type='number'
              name={formKey(entryNum, 'startDate')}
              label='labels.startDateOptional'
              component={Text}
              validate={[V.dateValidIfAvailable('YYYY')]}
              placeholder='placeholders.YYYY'
            />
          </div>
          <div className='col-lg-3'>
            <Field
              type='number'
              name={formKey(entryNum, 'endDate')}
              label='labels.endDateOptional'
              disabled={entry.currentPlacement}
              component={Text}
              validate={
                entry.currentPlacement ? [] : [V.dateValidIfAvailable('YYYY')]
              }
              placeholder='placeholders.YYYY'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-3 offset-lg-9'>
            <Field
              type='checkbox'
              name={formKey(entryNum, 'currentPlacement')}
              label={`${translationKey}.currentPlacement`}
              component={Checkbox}
              onChange={() => {
                change('applyform', formKey(entryNum, 'endDate'), null);
              }}
            />
          </div>
        </div>
        <hr />
      </div>
    );
  }
}

ExtendedHistoryEntry.propTypes = {
  change: PropTypes.func,
  cities: PropTypes.array,
  entities: PropTypes.array,
  entityInputProps: PropTypes.object,
  entry: PropTypes.object,
  entryNum: PropTypes.number.isRequired,
  formKey: PropTypes.func,
  translationKey: PropTypes.string,
  type: PropTypes.oneOf(['education', 'employment']),
};

ExtendedHistoryEntry.defaultProps = {
  cities: [],
  entities: [],
  entry: {},
};

export default ExtendedHistoryEntry;
