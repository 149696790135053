/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';
import OverridableTranslate from './translate/OverrideableTranslate';

const Panel = ({
  children,
  className,
  icon,
  noBlock,
  title,
  additionalText,
  i18nOverrides,
}) => (
  <div className={`card clearfix mb-3 ${className || ''}`}>
    {title && (
      <div className='card-header'>
        <div className='d-md-flex justify-content-md-end'>
          <div className='mr-auto'>
            {icon && <i className={`fa fa-fw ${icon}`} />} {icon && ' '}
            <OverridableTranslate value={title} overrides={i18nOverrides} />
          </div>
          {additionalText && (
            <div className='card-additional-text'>
              <OverridableTranslate
                value={additionalText}
                overrides={i18nOverrides}
              />
            </div>
          )}
        </div>
      </div>
    )}
    {noBlock ? children : <div className='card-block'>{children}</div>}
  </div>
);

Panel.propTypes = {
  additionalText: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  noBlock: PropTypes.bool,
  i18nOverrides: PropTypes.object,
};

export default Panel;
