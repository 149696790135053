/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { I18n, Translate as T } from 'react-redux-i18n';

import { requestConfigurationUpdate } from '../actions';

import Panel from './Panel';
import SsnFields from './SsnFields';

import { Upload } from './fields';

import * as V from '../lib/validations';
import OverridableTranslate from './translate/OverrideableTranslate';
import MotherMaidenNameField from './MotherMaidenNameField';
import CurrentAddressFields from './CurrentAddressFields';
import '../css/DriverLicenseForm.scss';

const MvrAdditionalDetailsComponent = ({
  requiredDetails,
  protectedInvitationFlow,
  protectedInvitationFields,
  candidate,
  currentAddress,
}) => {
  return (
    <>
      {requiredDetails.includes('notification') && (
        <Panel title='components.MvrAdditionalDetailsComponent.title'>
          <OverridableTranslate value='components.MvrAdditionalDetailsComponent.notification' />
          {(requiredDetails.includes('mmn') ||
            requiredDetails.includes('ssn')) && (
            <>
              <hr />
              <strong className='piiTitle'>
                <T value='components.MvrAdditionalDetailsComponent.piiTitle' />
              </strong>
              {requiredDetails.includes('mmn') && (
                <MotherMaidenNameField
                  candidate={candidate}
                  protectedInvitationFlow={protectedInvitationFlow}
                  protectedInvitationFields={protectedInvitationFields}
                />
              )}
              {requiredDetails.includes('ssn') && (
                <SsnFields
                  noSsn={false}
                  candidatesCanDeclareNoSsn={false}
                  protectedInvitationFlow={protectedInvitationFlow}
                  protectedInvitationFields={protectedInvitationFields}
                  candidate={candidate}
                  onNoSsnUpdate={null}
                />
              )}
            </>
          )}
          <hr />
          <strong className='addressTitle'>
            <T value='components.MvrAdditionalDetailsComponent.addressTitle' />
          </strong>
          <CurrentAddressFields
            protectedInvitationFlow={protectedInvitationFlow}
            protectedInvitationFields={protectedInvitationFields}
            skipUnit={false}
            currentAddress={currentAddress}
            skipFrom
          />
          <hr />
          {requiredDetails.includes('upload_dl') && (
            <DriverLicenseImageUpload />
          )}
        </Panel>
      )}
    </>
  );
};

MvrAdditionalDetailsComponent.propTypes = {
  requiredDetails: PropTypes.array,
  protectedInvitationFlow: PropTypes.bool,
  protectedInvitationFields: PropTypes.array,
  candidate: PropTypes.object,
  currentAddress: PropTypes.object,
};

const DriverLicenseImageUpload = () => {
  return (
    <div className='row'>
      <div className='col-md-12'>
        <span>
          <strong>
            <T value='components.MvrAdditionalDetailsComponent.uploadDriverLicense.title' />
          </strong>
        </span>
        <p className='prompt'>
          <T value='components.MvrAdditionalDetailsComponent.uploadDriverLicense.prompt' />
        </p>
        <span className='subheader'>
          <strong>
            <T value='components.MvrAdditionalDetailsComponent.uploadDriverLicense.subheader' />
          </strong>
          <br />
          <ul>
            <T
              value='components.MvrAdditionalDetailsComponent.uploadDriverLicense.instructions'
              dangerousHTML
            />
          </ul>
        </span>
      </div>
      <div
        className='col-sm-12  col-md-6'
        id='driver_license_image'
        data-testid='driver_license_image'
      >
        <Field
          type='upload'
          name='driver_license_image'
          component={Upload}
          options={{
            maxFiles: 1,
            container: `#driver_license_image #filestack`,
          }}
          validate={[V.required1Document]}
          boldLabel
          uploadButtonText={I18n.t(
            'components.MvrAdditionalDetailsComponent.uploadDriverLicense.uploadButtonText',
          )}
          mobileUploadButtonText={I18n.t(
            'components.MvrAdditionalDetailsComponent.uploadDriverLicense.uploadButtonText',
          )}
        />
      </div>
    </div>
  );
};

const ConnectedMvrAdditionalDetailsComponent = connect(
  (state, props) => {
    const {
      protectedInvitationFlow,
      protectedInvitationFields,
    } = state.configuration.configuration.package;
    const { candidate } = state.configuration.configuration;
    const currentAddress = (candidate && candidate.currentAddress) || {};
    return {
      protectedInvitationFlow,
      protectedInvitationFields,
      candidate,
      currentAddress,
    };
  },
  { requestConfigurationUpdate },
)(MvrAdditionalDetailsComponent);

export default ConnectedMvrAdditionalDetailsComponent;
