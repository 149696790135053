import * as Sentry from '@sentry/react';

const parseGooglePlacesResult = (addressComponents: any) => {
  let address1 = '';
  let postcode = '';
  let city = '';
  let state = '';

  try {
    for (const component of addressComponents as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          address1 = `${component.long_name} ${address1}`;
          break;
        }

        case 'route': {
          address1 += component.short_name;
          break;
        }

        case 'postal_code': {
          postcode = `${component.long_name}${postcode}`;
          break;
        }

        case 'postal_code_suffix': {
          postcode = `${postcode}-${component.long_name}`;
          break;
        }

        case 'locality':
          city = component.long_name;
          break;

        // For London, the city comes in the postal_town field unlike other countries
        case 'postal_town':
          city = component.long_name;
          break;

        case 'administrative_area_level_1': {
          state = component.short_name;
          break;
        }
      }
    }
  } catch (e) {
    Sentry.captureException(e);
  }

  return [address1, city, state, postcode];
};

export default parseGooglePlacesResult;
