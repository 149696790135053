import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';

import '../css/DocumentList.scss';

type Document = {
  [key: string]: any;
  filename: string;
  url: string;
};

type Props = {
  documents: Document[];
  removeDocument: (doc: Document) => void;
};

const DocumentList: React.FC<Props> = ({ documents, removeDocument }) => {
  const handleDocumentRevove = useCallback(doc => () => removeDocument(doc), [
    removeDocument,
  ]);
  return (
    <div className='uploaded-docs col-md-6'>
      {documents.map((value: any, index: number) => {
        if (value) {
          return (
            <div className='row' key={`doc-${index + 1}`}>
              <div className='uploaded-doc-info'>
                <M.Icon
                  icon='CheckmarkFilled'
                  className='uploaded-doc-icon'
                  data-testid='uploaded-doc-icon'
                />
                {value.filename}
              </div>
              <M.Icon
                icon='TrashCan'
                size='16'
                onClick={handleDocumentRevove(value)}
                className='remove-doc-danger'
                data-testid='remove-document'
              />
            </div>
          );
        }
        return null;
      }, documents)}
    </div>
  );
};

export default DocumentList;
