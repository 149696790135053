const MEDIA_QUERY_SIZES = {
  SMALL: '(min-width: 320px) and (max-width: 671px)',
  MEDIUM: '(min-width: 672px) and (max-width: 1095px)',
  LARGE: '(min-width: 1096px) and (max-width: 1311px)',
  XL: '(min-width: 1312px)',
};

const constructMediaQuery = (viewportSize: string) =>
  window.matchMedia(viewportSize);

export default { MEDIA_QUERY_SIZES, constructMediaQuery };
