/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import React from 'react';

import { groupClass } from './helper';
import FeedbackLabel from './FeedbackLabel';
import OverridableTranslate from '../translate/OverrideableTranslate';

const Checkbox = ({
  boldLabel,
  className,
  disabled,
  input,
  i18nOverrides,
  label,
  labelOptions,
  meta: { touched, error, warning },
}) => {
  return (
    <div className={`${groupClass(touched, error)} ${className}`}>
      <label>
        <input {...input} id={input.name} disabled={disabled} type='checkbox' />{' '}
        {boldLabel ? (
          <strong>
            <OverridableTranslate
              value={label}
              overrides={i18nOverrides}
              labelOptions={labelOptions}
              dangerousHTML
            />
          </strong>
        ) : (
          <OverridableTranslate
            value={label}
            overrides={i18nOverrides}
            labelOptions={labelOptions}
            dangerousHTML
          />
        )}
      </label>
      <FeedbackLabel {...{ touched, error, warning }} />
    </div>
  );
};

Checkbox.propTypes = {
  boldLabel: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.object,
  i18nOverrides: PropTypes.bool,
  label: PropTypes.string,
  labelOptions: PropTypes.object,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
};

export default Checkbox;
