/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable consistent-return */
import { get } from 'lodash';
import {
  applicantInfoFormat,
  clinicFormat,
  contactInfoFormat,
  currentAddress,
  dlFormat,
  employmentFormat,
  extendedEducationFormat,
  internationalAddressesFormat,
  mailingAddress,
  referenceFormat,
  mvrAdditionalDetailsFormat,
  parsEmployeeInfoFormat,
  parsReporterInfoFormat,
  educationFormat,
  custodyControlFormFormat,
} from './constants';

class CreateReviewPage {
  /**
   * Creates a review page for the apply flow
   * @param {array} pages - Array of objects that is build for each candidates
   * apply flow.
   * @param {object} applyValues - Object of values that contains all the value
   * the candidate has supplied during their apply flow.
   */
  constructor(pages, applyValues) {
    this.pages = pages;
    this.applyValues = applyValues;
    this.displayedReviewSections = [];

    // Get all defined class methods
    const methods = Object.getOwnPropertyNames(Object.getPrototypeOf(this));

    // Bind all methods
    methods
      .filter(method => method !== 'constructor')
      .forEach(method => {
        this[method] = this[method].bind(this);
      });
  }

  /**
   * Creates text for sections that the user doesn't provide any information
   * @param {string} primaryKey - The primary key of the applyValues that is missing
   * information. Example applyValues['employment'] or applyValues['domesticSchool']
   * @returns {array} - Returns a single element in an array that has the label
   * and className of the text to be displayed in the section missing information
   */
  noInformationTitle(key) {
    switch (key) {
      case 'domesticSchool':
      case 'extendedEducationEntries':
        key = 'education';
        break;
      default:
        break;
    }

    return [
      {
        type: 'subheader',
        label: `No ${key} provided`,
        className: 'missing-info',
      },
    ];
  }

  /**
   * Converts a number of bytes of a file to readable value of KB, MB, etc
   * @param {number} bytes - Number of bytes
   * @param {number} decimals - (optional, defaults to 0) number of decimal
   * places to return
   * @returns {string} - A string that is the bytes converted to KB, MB, etc
   */
  convertFileSize(bytes, decimals = 0) {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`;
  }

  /**
   * Creates an array of documents to be displayed
   * @param {array} arrayOfDocuments - Array of documents
   * @returns {array} - An array of fields created from the documents to be
   * displayed
   */
  documents(arrayOfDocuments) {
    const documents = [];

    for (const document of arrayOfDocuments) {
      documents.push({
        type: 'document',
        label: `${document.filename}`,
        value: `${document.mimetype} ${this.convertFileSize(document.size)}`,
        link: `${document.url}`,
      });
    }

    return documents;
  }

  /**
   * Creates an array of components from objects or nested objects to be
   * displayed for the review page. If primary key is array, skip primary loop
   * and call method to handle array.
   * @param {object} formatter - Array of objects that has the required key to find
   *  the data in the applyValues state object. See fieldFormat variables for
   * reference
   * @param {string} primaryKey - String that defines the primary key being
   * accessed. Example applyValues['domesticSchool'] or applyValues['employment']
   * defaults to false boolean
   * @returns Array of objects that has the index for the component for use on
   * the edit button, title of the component and an array of objects that is the
   * fields and values the user had provided
   */
  getFieldsFromObjects(formatter, obj, index) {
    if (!obj) return;
    let fields = [];
    // eslint-disable-next-line prefer-const
    for (let { type, label, key, className, format, icon, aria } of formatter) {
      if (typeof label === 'function') label = label(index);

      // handle documents
      if (type === 'document-label') {
        fields.push({ type, label, className, icon, aria });
        const documents = get(obj, 'documents');

        if (documents) {
          fields = fields.concat(this.documents(documents));
        } else {
          const licenseImage = get(obj, 'driver_license_image');
          if (licenseImage) {
            fields = fields.concat(this.documents(licenseImage));
          } else {
            // if no documents, remove the 'document-label'
            fields = fields.slice(0, -1);
          }
        }
      } else if (!key) {
        fields.push({ type, label, className, icon, aria });
      } else {
        let value = get(obj, key, '--');
        if (format) value = format(value);

        fields.push({
          type,
          label,
          value,
          className,
          icon,
          aria,
        });
      }
    }

    return fields;
  }

  /**
   * Creates an array of components to be displayed for the review page when value
   * in applyValues key is an array of object. Example applyValues['employment']
   * @param {array} format - Array of objects that has the required key to find
   *  the data in the applyValues state object.
   * @param {arr} arr - Array of elements to map over and use the format upon
   * @returns Array of objects that has the index for the component for use on
   * the edit button, title of the component and an array of objects that is the
   * fields and values the user had provided
   */
  getFieldsFromArray(format, arr) {
    if (!arr) return;
    let fields = [];

    arr.forEach((elem, index) => {
      fields = fields.concat(this.getFieldsFromObjects(format, elem, index));
      if (index < arr.length - 1 && arr.length > 1) {
        fields.push({ type: 'break' });
      }
    });

    return fields;
  }

  /**
   * Adds an object to the displayedReviewSections array.
   * @param {number} index - Number that is used to create the sections Edit
   * button link.
   * @param {string} title - String used to create the section title
   * @param {array} fields - Array of objects that has necessary keys to create
   *  the displayed fields and values.
   */
  addFieldsForDisplay(index, title, fields) {
    if (!fields) return;

    this.displayedReviewSections.push({
      index,
      title,
      fields,
    });
  }

  /**
   * Creates the ContactInformationForm section.
   * @param {number} index - Number that is used to create the sections Edit
   * button link.
   */
  ContactInformationForm(index) {
    const title = 'Contact details';
    const fields = this.getFieldsFromObjects(
      contactInfoFormat,
      this.applyValues,
    );

    return this.addFieldsForDisplay(index, title, fields);
  }

  /**
   * Creates the DriversLicenseForm section.
   * @param {number} index - Number that is used to create the sections Edit
   * button link.
   */
  DriverLicenseForm(index) {
    const title = 'Drivers license';

    const fields = this.getFieldsFromObjects(dlFormat, this.applyValues);

    return this.addFieldsForDisplay(index, title, fields);
  }

  MvrAdditionalDetailsComponent(index) {
    const title = 'Puerto Rico information';

    const fields = this.getFieldsFromObjects(
      mvrAdditionalDetailsFormat,
      this.applyValues,
    );

    return this.addFieldsForDisplay(index, title, fields);
  }

  /**
   * Creates the CurrentAddressForm section.
   * @param {number} index - Number that is used to create the sections Edit
   * button link.
   */
  CurrentAddressForm(index) {
    const title = 'Current address';

    const fields = this.getFieldsFromObjects(currentAddress, this.applyValues);

    return this.addFieldsForDisplay(index, title, fields);
  }

  /**
   * Creates the CurrentAddressForm section.
   * @param {number} index - Number that is used to create the sections Edit
   * button link.
   */
  MailingAddressForm(index) {
    const title = 'Health screening (non-electronic)';

    const fields = this.getFieldsFromObjects(mailingAddress, this.applyValues);

    return this.addFieldsForDisplay(index, title, fields);
  }

  /**
   * Creates the SelectLocationForm section.
   * @param {number} index - Number that is used to create the sections Edit
   * button link.
   */
  SelectLocationForm(index) {
    const title = 'Health screening';
    const location = get(this.applyValues, 'location');
    if (!location) return;

    const fields = this.getFieldsFromObjects(clinicFormat, location);

    return this.addFieldsForDisplay(index, title, fields);
  }

  /**
   * Creates the ApplicantInformationForm section.
   * @param {number} index - Number that is used to create the sections Edit
   * button link.
   */
  ApplicantInformationForm(index) {
    const title = 'Personal information';
    const fields = this.getFieldsFromObjects(
      applicantInfoFormat,
      this.applyValues,
    );

    return this.addFieldsForDisplay(index, title, fields);
  }

  /**
   * Creates the CustodyControlForm section.
   * @param {number} index - Number that is used to create the sections Edit
   * button link.
   */
  CustodyControlForm(index) {
    const title = 'Custody & control number';
    const fields = this.getFieldsFromObjects(
      custodyControlFormFormat,
      this.applyValues,
    );

    return this.addFieldsForDisplay(index, title, fields);
  }

  /**
   * Creates the ParsEmployeeInformationForm section.
   * @param {number} index - Number that is used to create the sections Edit
   * button link.
   */
  ParsEmployeeInformationForm(index) {
    const title = 'Employee information';
    const fields = this.getFieldsFromObjects(
      parsEmployeeInfoFormat,
      this.applyValues,
    );

    return this.addFieldsForDisplay(index, title, fields);
  }

  /**
   * Creates the ParsEmployeeInformationForm section.
   * @param {number} index - Number that is used to create the sections Edit
   * button link.
   */
  ParsReporterInformationForm(index) {
    const title = 'Reporter information';
    const fields = this.getFieldsFromObjects(
      parsReporterInfoFormat,
      this.applyValues,
    );

    return this.addFieldsForDisplay(index, title, fields);
  }

  /**
   * Creates the EducationInformationForm section.
   * @param {number} index - Number that is used to create the sections Edit
   * button link.
   */
  EducationInformationForm(index) {
    /**
     * TODO tech debt - if you look thru the git history, this section was only ever created to
     * display _domestic_ education, not OCH (out of country) education. in EVER-6897 MDV project we
     * had to refactor the EducationInformationForm redux data structure, and made edits to
     * this part of the code. we considered displaying OCH education out of scope for this project,
     * but should revisit this later.
     */
    const educationKey = 'education';
    const title = 'Education history';

    const noEducation = get(this.applyValues, 'noEducationHistory');

    if (noEducation) {
      return this.addFieldsForDisplay(
        index,
        title,
        this.noInformationTitle(educationKey),
      );
    }

    const schools = get(this.applyValues, educationKey);
    const fields = this.getFieldsFromArray(educationFormat, schools);
    return this.addFieldsForDisplay(index, title, fields);
  }

  /**
   * Creates the ExtendedEducationInformationForm section.
   * @param {number} index - Number that is used to create the sections Edit
   * button link.
   */
  ExtendedEducationInformationForm(index) {
    const educationKey = 'extendedEducationEntries';
    const title = 'Education history';

    const schools = get(this.applyValues, educationKey);

    if (!schools) {
      return this.addFieldsForDisplay(
        index,
        title,
        this.noInformationTitle(educationKey),
      );
    }

    const fields = this.getFieldsFromArray(extendedEducationFormat, schools);
    return this.addFieldsForDisplay(index, title, fields);
  }

  /**
   * Creates the InternationalAddressHistoryForm section.
   * @param {number} index - Number that is used to create the sections Edit
   * button link.
   */
  InternationalAddressHistoryForm(index) {
    const internationalAddressesKey = 'internationalAddresses';
    const title = 'International addresses';

    const internationalAddressesArr = get(
      this.applyValues,
      internationalAddressesKey,
    );
    if (!internationalAddressesArr) return;

    const fields = this.getFieldsFromArray(
      internationalAddressesFormat,
      internationalAddressesArr,
    );

    return this.addFieldsForDisplay(index, title, fields);
  }

  /**
   * Creates the PersonalReferenceForm section.
   * @param {number} index - Number that is used to create the sections Edit
   * button link.
   */
  PersonalReferenceForm(index) {
    const referenceKey = 'personalReferences';
    const title = 'Personal references';

    const referenceArr = get(this.applyValues, referenceKey);
    if (!referenceArr) return;

    const fields = this.getFieldsFromArray(referenceFormat, referenceArr);

    return this.addFieldsForDisplay(index, title, fields);
  }

  /**
   * Creates the PersonalReferenceForm section.
   * @param {number} index - Number that is used to create the sections Edit
   * button link.
   */
  ProfessionalReferenceForm(index) {
    const referenceKey = 'professionalReferences';
    const title = 'Professional references';

    const referenceArr = get(this.applyValues, referenceKey);
    if (!referenceArr) return;

    const fields = this.getFieldsFromArray(referenceFormat, referenceArr);

    return this.addFieldsForDisplay(index, title, fields);
  }

  /**
   * Creates the EmploymentHistoryForm section.
   * @param {number} index - Number that is used to create the sections Edit
   * button link.
   */
  EmploymentHistoryForm(index) {
    const employmentKey = 'employment';
    const title = 'Employment history';

    const noEmployment = get(this.applyValues, 'noEmploymentHistory');

    if (noEmployment) {
      return this.addFieldsForDisplay(
        index,
        title,
        this.noInformationTitle(employmentKey),
      );
    }

    const employers = get(this.applyValues, employmentKey);
    const fields = this.getFieldsFromArray(employmentFormat, employers);

    return this.addFieldsForDisplay(index, title, fields);
  }

  /**
   * Initializes and creates all sections to be displayed on the review and submit page.
   * New sections must be added to the reviewSections object with their creation function.
   * @returns {array} - Returns and array of objects {index, title, fields}
   */
  initalize() {
    // listed in order of desired display on the review page
    const reviewSections = {
      ApplicantInformationForm: this.ApplicantInformationForm,
      ContactInformationForm: this.ContactInformationForm,
      DriverLicenseForm: this.DriverLicenseForm,
      MvrAdditionalDetailsComponent: this.MvrAdditionalDetailsComponent,
      InternationalAddressHistoryForm: this.InternationalAddressHistoryForm,
      ParsReporterInformationForm: this.ParsReporterInformationForm,
      ParsEmployeeInformationForm: this.ParsEmployeeInformationForm,
      PersonalReferenceForm: this.PersonalReferenceForm,
      ProfessionalReferenceForm: this.ProfessionalReferenceForm,
      CurrentAddressForm: this.CurrentAddressForm,
      MailingAddressForm: this.MailingAddressForm,
      SelectLocationForm: this.SelectLocationForm,
      EducationInformationForm: this.EducationInformationForm,
      ExtendedEducationInformationForm: this.ExtendedEducationInformationForm,
      EmploymentHistoryForm: this.EmploymentHistoryForm,
      CustodyControlForm: this.CustodyControlForm,
    };

    // handles the order of the sections on the review page
    for (const reviewSection in reviewSections) {
      if (reviewSection) {
        this.pages.forEach((page, index) => {
          page.components.forEach(({ component }) => {
            // add logic to handle if pages is 'welcome' or 'Mailing Address'
            if (page.name === 'welcome' && component === 'CurrentAddressForm') {
              component = 'CurrentAddressForm';
            }
            if (
              page.name === 'Mailing Address' &&
              component === 'CurrentAddressForm'
            ) {
              component = 'MailingAddressForm';
            }
            // create the review section
            if (component === reviewSection) {
              reviewSections[component](index);
            }
          });
        });
      }
    }

    return this.displayedReviewSections;
  }
}

export default CreateReviewPage;
