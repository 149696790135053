import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import Panel from './Panel';

const HealthScreeningConfigurationInstructions = () => (
  <Panel>
    <h3>
      <T value='components.Health.instructions.header' />
    </h3>
    <br />
    <div>
      <p>
        <T value='components.Health.instructions.body' />
      </p>
    </div>
  </Panel>
);

export default HealthScreeningConfigurationInstructions;
