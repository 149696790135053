import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import React, { LegacyRef, PureComponent } from 'react';

import { connect } from 'react-redux';
import { omit, isObject } from 'lodash';
import { getFormValues } from 'redux-form';

import { WrappedMInputProps } from './WrappedComp.types';
import { getFormatedDateStr } from '../../../../lib/normalizations';
import { DATE_PLACEHOLDER } from '../../../constants';
import responsiveHelpers from '../../../../lib/utils/responsiveHelpers';

const { MEDIA_QUERY_SIZES, constructMediaQuery } = responsiveHelpers;

type Props = {
  valueOnInit?: (val?: boolean) => void;
  intlFormValues: {
    [key: string]: any;
  };
};

const mediaQuery = constructMediaQuery(MEDIA_QUERY_SIZES.SMALL);

class ReduxMInput extends PureComponent<
  WrappedMInputProps & Props,
  { tooltipDirection: string }
> {
  state = {
    tooltipDirection: 'top',
  };

  componentDidMount() {
    const { input, valueOnInit } = this.props;
    valueOnInit && valueOnInit(input?.value);
    mediaQuery.addEventListener('change', this.handleChange);
  }

  componentDidUpdate(prevProps: any) {
    const { input, value } = this.props;
    // excluding these to fix ENGSUPPORT-9180
    if (
      input &&
      input.name &&
      !input.name.endsWith('start_date') &&
      !input.name.endsWith('end_date')
    ) {
      if (value !== prevProps.value) {
        input.onChange(value as any);
      }
    }
  }

  componentWillUnmount(): void {
    mediaQuery.removeEventListener('change', this.handleChange);
  }

  normalizeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { placeholder, input } = this.props;
    if (!input) return;
    if (DATE_PLACEHOLDER.includes(placeholder)) {
      const formatedStr = getFormatedDateStr(e.target.value, placeholder);
      input.onChange(formatedStr as any);
    } else {
      input.onChange(e);
    }
    // hide date picker popper while typing
    const datePicker = document.querySelector('.react-datepicker-popper');
    if (datePicker) {
      datePicker.setAttribute('style', 'display:none;');
    }
  };

  // eslint-disable-next-line react/sort-comp
  handleChange = (e: { matches: boolean }) => {
    this.setState({ tooltipDirection: e.matches ? 'right' : 'top' });
  };

  render() {
    const {
      id,
      labelText,
      className,
      placeholder,
      disabled,
      invalid,
      input,
      meta,
      onClick,
      intlFormValues,
    } = this.props;
    const { touched, error } = meta;
    const { tooltipDirection } = this.state;

    let errorMsg = '';
    let errorId = { ...error };
    if (error) {
      const { message, dateCompared, format } = error as any;
      errorMsg =
        typeof error === 'string'
          ? i18n.getStr(error)
          : i18n.getStr(
              `i18nInternational.${message}` as string,
              Object.values(omit(error, 'message')),
            );
      if (
        isObject(error) &&
        (id.includes('start_date') || id.includes('end_date'))
      ) {
        if (format) {
          errorId = {
            ...error,
            format: i18n.getMonthYearPattern(),
          };
        } else if (dateCompared) {
          errorId = {
            ...error,
            dateCompared: i18n.getLocalizedMonthYear(dateCompared),
          };
        }
        errorMsg = i18n.getStr(
          `i18nInternational.${message}` as string,
          Object.values(omit(errorId, 'message')),
        );
      }
      if (
        isObject(error) &&
        (id.includes('date_of_sentence') ||
          id.includes('date_of_entry') ||
          id.includes('driver_license_expiration_date') ||
          id.includes('drivers_license_issue_date') ||
          id.includes('dob') ||
          id.includes('passport_expiration_date') ||
          id.includes('passport_issue_date') ||
          id.includes('payslip_date'))
      ) {
        if (format) {
          errorId = {
            ...error,
            format: i18n.getLocalizedDateFormat(),
          };
        } else if (dateCompared) {
          errorId = {
            ...error,
            dateCompared: i18n.getLocalizedDate(dateCompared),
          };
        }
        errorMsg = i18n.getStr(
          `i18nInternational.${message}` as string,
          Object.values(omit(errorId, 'message')),
        );
      }
    }
    const CANADIAN_DATE_OF_ENTRY_ID = 'scoped_requirements.CA.date_of_entry';
    const isCanadianDateOfEntry = id === CANADIAN_DATE_OF_ENTRY_ID;
    // eslint-disable-next-line react/display-name
    const TooltipIcon = React.forwardRef((_, ref) => (
      <div ref={ref as LegacyRef<HTMLDivElement>}>
        <M.Icon icon='HelpFilled' />
      </div>
    ));

    return (
      <>
        <M.Input
          {...input}
          id={id}
          labelText={
            (
              <>
                {labelText}
                {id.includes('end_date') && disabled && (
                  <>
                    <M.Tooltip
                      className='tooltip'
                      renderIcon={TooltipIcon}
                      tooltipDirection={tooltipDirection}
                      direction={
                        constructMediaQuery(MEDIA_QUERY_SIZES.SMALL)
                          ? 'right'
                          : 'top'
                      }
                    >
                      {i18n.getStr(
                        'i18nInternational.addressField.tooltips.datePickerAddressHistoryGaps',
                      )}
                    </M.Tooltip>
                  </>
                )}
              </>
            ) || <span>&nbsp;</span>
          }
          className={className}
          placeholder={placeholder}
          disabled={
            disabled ||
            (isCanadianDateOfEntry &&
              // eslint-disable-next-line camelcase
              intlFormValues?.scoped_requirements?.CA?.country_of_birth ===
                'CA')
          }
          invalid={(touched && !!error) || invalid}
          invalidText={touched && errorMsg}
          onChange={this.normalizeInput}
          onClick={onClick}
        />
      </>
    );
  }
}

export default connect(state => ({
  intlFormValues: getFormValues('intlForm')(state),
}))(ReduxMInput);
