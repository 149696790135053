import React from 'react';
import PropTypes from 'prop-types';
import { M } from '@dashboard-experience/mastodon';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';

const EmploymentTurnaroundTime = ({ cardType }) => {
  return (
    <M.TooltipDefinition
      align='center'
      direction='top'
      className='tat-tooltip'
      id='tat-tooltip-body'
      tooltipText={i18n.getStr(
        'components.EmploymentForm.ArgyleForm.selectionTatTooltipText',
      )}
    >
      <div className='employment-tat'>
        <M.Icon icon={cardType === 'quick' ? 'Time' : 'Calendar'} id='icon' />

        <p className='text'>
          <T
            value={`components.EmploymentForm.ArgyleForm.selectionComponents.${cardType}.timeToCompletion`}
          />
        </p>
      </div>
    </M.TooltipDefinition>
  );
};

EmploymentTurnaroundTime.propTypes = {
  cardType: PropTypes.string,
};
EmploymentTurnaroundTime.defaultProps = {
  cardType: PropTypes.string,
};

export default EmploymentTurnaroundTime;
