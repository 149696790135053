import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from '@international/mastodon-i18n';

function ModalTitle({ timerExpired }) {
  return (
    <h2>
      {!timerExpired &&
        i18n.getStr(
          'i18nInternational.educationField.measureOne.m1ReviewResultsWaitingModal.headerTitle',
        )}
      {timerExpired &&
        i18n.getStr(
          'i18nInternational.educationField.measureOne.m1TimerExpiredModal.headerTitle',
        )}
    </h2>
  );
}

ModalTitle.propTypes = {
  timerExpired: PropTypes.bool.isRequired,
};

export default ModalTitle;
