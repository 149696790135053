import PropTypes from 'prop-types';
import React from 'react';

import ReferenceForm from './ReferenceForm';

const PersonalReferenceForm = ({ quantity }) => (
  <ReferenceForm
    icon='fa-user'
    model='personalReferences'
    title='components.PersonalReferenceForm.title'
    quantity={quantity}
  />
);

PersonalReferenceForm.propTypes = {
  quantity: PropTypes.number.isRequired,
};

export default PersonalReferenceForm;
