/* eslint-disable react/require-default-props */
import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import { camelizeKeys } from 'humps';
import { i18n } from '@international/mastodon-i18n';
import { updateIntlFormValue } from '../../actions/intlForm';
import '../Fields/FieldCreationLogic/CustomFlexRow.scss';
import ResponsiveTextFieldGroupWrapper from './Layouts/ResponsiveTextFieldGroupWrapper';

const IdentityDocument = ({
  arrayPathModifier,
  generateField,
  identityDocument = {},
  passport = {},
  properties,
  updateIntlFormValue: updateIntlFormValueAction,
}) => {
  const { idDocumentCountry, idDocumentNumber, idDocumentType } = camelizeKeys(
    identityDocument,
  );
  const hasTypeAndCountry = !!idDocumentType && !!idDocumentCountry;
  const requiresSecondImage = [
    'national_id',
    'driver_license',
    'residence_permit',
  ].includes(idDocumentType);
  const type = idDocumentType
    ? i18n.getConstStr(
        'i18nInternational.identityDocument.plural',
        idDocumentType,
      )
    : i18n.getStr('i18nInternational.identityDocument.documents');
  const secondImage = requiresSecondImage
    ? i18n.getStr(
        'i18nInternational.identityDocument.mustUploadSecondImage',
        type,
      )
    : i18n.getStr('i18nInternational.identityDocument.mayUploadSecondImage');
  const defaultDocumentType = 'passport';
  if (!idDocumentType) {
    updateIntlFormValueAction({
      key: 'id_document_type',
      value: defaultDocumentType,
    });
  }
  const rows = [
    <p key='identity-document-label'>
      {i18n.renderHTML('i18nInternational.identityDocument.mustBeValid', [
        type,
        secondImage,
      ])}
    </p>,
    <>
      <div className='text-field' key='id_document_type'>
        {generateField(properties.id_document_type, arrayPathModifier).render(
          () => ({
            input: {
              onChange: idDocumentTypeValue => {
                updateIntlFormValueAction({
                  key: 'id_document_type',
                  value: idDocumentTypeValue,
                });
              },
              value: idDocumentType || defaultDocumentType,
            },
          }),
        )}
      </div>
      <div className='text-field' key='id_document_number'>
        {generateField(properties.id_document_number, arrayPathModifier).render(
          () => ({
            input: {
              defaultValue: idDocumentNumber || passport.number,
              onChange: ({ target: { value: idDocumentNumberValue } }) => {
                updateIntlFormValueAction({
                  key: 'id_document_number',
                  value: idDocumentNumberValue,
                });
              },
            },
          }),
        )}
      </div>
      <div className='text-field' key='id_document_country'>
        {generateField(
          properties.id_document_country,
          arrayPathModifier,
        ).render(() => ({
          input: {
            onChange: idDocumentCountryValue => {
              updateIntlFormValueAction({
                key: 'id_document_country',
                value: idDocumentCountryValue,
              });
            },
            value: idDocumentCountry || passport.country,
          },
        }))}
      </div>
    </>,
  ];
  const backKey =
    idDocumentType === 'passport'
      ? i18n.getStr('i18nInternational.identityDocument.signaturePage')
      : i18n.getStr('i18nInternational.identityDocument.backOfId');

  if (hasTypeAndCountry) {
    rows.push([
      <div
        key='front-id'
        className='custom-flex-row text-field identity-document-custom-flex-row'
      >
        <div>
          <p>{i18n.getStr('i18nInternational.identityDocument.frontOfId')}</p>
        </div>
        {generateField(
          properties.id_document_front,
          arrayPathModifier,
        ).render()}
      </div>,
      <div
        key='back-id'
        className='custom-flex-row text-field identity-document-custom-flex-row'
      >
        <div>
          <p>{backKey}</p>
        </div>
        {generateField(properties.id_document_back, arrayPathModifier).render()}
      </div>,
    ]);
  }
  return (
    <ResponsiveTextFieldGroupWrapper>{rows}</ResponsiveTextFieldGroupWrapper>
  );
};

IdentityDocument.propTypes = {
  arrayPathModifier: PropTypes.string,
  generateField: PropTypes.any.isRequired,
  identityDocument: PropTypes.any,
  passport: PropTypes.any,
  properties: PropTypes.any.isRequired,
  updateIntlFormValue: PropTypes.func,
};

const ConnectedIdentityDocument = connect(
  state => ({
    identityDocument: formValueSelector('intlForm')(state, 'identity_document'),
    passport: formValueSelector('intlForm')(state, 'passport'),
  }),
  {
    updateIntlFormValue,
  },
)(IdentityDocument);

export default ConnectedIdentityDocument;
