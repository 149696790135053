import React from 'react';
import { Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import Panel from './Panel';
import { Checkbox } from './fields';
import * as V from '../lib/validations';

const DOTAuthorization = ({ company }) => (
  <Panel title='components.DotDisclosure.title' icon='fa-truck'>
    <div className='scrollable'>
      {I18n.t('components.DotDisclosure.text', { company })}
    </div>

    <hr />

    <div className='col-md-12'>
      <Field
        type='checkbox'
        name='dotAuthorization'
        label='components.DotDisclosure.label'
        component={Checkbox}
        validate={[V.requiredCheckbox]}
        boldLabel
      />
    </div>
  </Panel>
);

DOTAuthorization.propTypes = {
  company: PropTypes.string,
};

DOTAuthorization.defaultProps = {
  company: '',
};

export default DOTAuthorization;
