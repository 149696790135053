import professions from '../options/professions';

export const OTHER_PROFESSION = 'Other';
export const PROFESSIONS = [...professions, OTHER_PROFESSION];

/**
 * Returns whether a typeahead suggested profession should surface to the user.
 * It always surfaces the "Other" profession, regardless of what the user has entered.
 * Meant to be plugged into the TypeaheadInput's filterBy prop.
 *
 * @param {String} suggestedProfession The profession suggestion to be tested for surfacing
 * @param {Object} props The Typeahead component's props
 *
 * @returns {Boolean} True if suggestedProfession should surface, false if it should not.
 */
export const alwaysSuggestOtherProfessionFilter = (
  suggestedProfession,
  props,
) => {
  const enteredProfession = props.text;
  return (
    suggestedProfession === OTHER_PROFESSION ||
    suggestedProfession
      ?.toLowerCase()
      .indexOf(enteredProfession?.toLowerCase()) !== -1
  );
};
