import React from 'react';

import ExtendedHistoryForm from './ExtendedHistoryForm';
import { requestSchools } from '../actions';

const ExtendedEducationInformationForm = props => {
  return (
    <ExtendedHistoryForm
      type='education'
      icon='fa-graduation-cap'
      translationKey='components.ExtendedEducationInformationForm'
      fieldPrefix='extendedEducationEntries'
      handleEntityPreload={(...args) => {
        return requestSchools(...args).then(
          ({ higherEducationSchools, highSchools }) => {
            return [
              ...new Set([...higherEducationSchools, ...highSchools]),
            ].sort();
          },
        );
      }}
      {...props}
    />
  );
};

export default ExtendedEducationInformationForm;
