import React, { ElementType } from 'react';

import responsiveHelpers from '../../../lib/utils/responsiveHelpers';

const { MEDIA_QUERY_SIZES, constructMediaQuery } = responsiveHelpers;

const isXL = constructMediaQuery(MEDIA_QUERY_SIZES.XL).matches;
const responsiveClassForXL = (numItems: number) => {
  switch (numItems % 3) {
    case 1:
      return 'xl-text-field-group-after-single-item';
    case 2:
      return 'xl-text-field-group-after-two-items';
    default:
      return '';
  }
};

const ResponsiveTextFieldGroupWrapper = ({
  children,
}: {
  children: ElementType | Array<ElementType> | any;
}) => (
  <>
    {(Array.isArray(children) && children.length > 0) ||
    (!Array.isArray(children) && !!children) ? (
      <div
        className={`text-field-group ${isXL &&
          responsiveClassForXL(children?.length)}`}
      >
        {children}
      </div>
    ) : (
      <></>
    )}
  </>
);

export default ResponsiveTextFieldGroupWrapper;
