/* eslint-disable react/require-default-props */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { AddFieldButton } from 'components/fields';
import School from './School';

class Schools extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    documentCollection: PropTypes.bool.isRequired,
    fieldLength: PropTypes.number.isRequired,
    fields: PropTypes.object.isRequired,
    handleOnRemove: PropTypes.func.isRequired,
    maximumAllowedSchools: PropTypes.number,
    requiresCompletedDegrees: PropTypes.bool.isRequired,
    schools: PropTypes.arrayOf(PropTypes.object),
    typeaheadSchools: PropTypes.object,
  };

  componentDidMount() {
    const { fieldLength } = this.props;
    if (fieldLength === 0) {
      this.addEmptySchool();
    }
  }

  addEmptySchool = () => {
    const { fields } = this.props;
    fields.push({});
  };

  showAddSchoolButton = () => {
    const { fieldLength, maximumAllowedSchools } = this.props;
    if (maximumAllowedSchools && fieldLength >= maximumAllowedSchools) {
      return false;
    }
    return true;
  };

  render() {
    const {
      dispatch,
      documentCollection,
      fieldLength,
      fields,
      handleOnRemove,
      requiresCompletedDegrees,
      schools,
      typeaheadSchools,
    } = this.props;

    return (
      <>
        {fields.map((fieldName, i) => {
          const school = schools[i];
          return (
            <Fragment key={`school-${i + 1}`}>
              <School
                {...{
                  dispatch,
                  documentCollection,
                  fieldLength,
                  fieldName,
                  handleOnRemove,
                  i,
                  requiresCompletedDegrees,
                  school,
                  typeaheadSchools,
                }}
              />
            </Fragment>
          );
        })}
        {this.showAddSchoolButton() && (
          <AddFieldButton
            handleOnClick={() => this.addEmptySchool()}
            buttonText='components.Schools.addMoreEducation'
            count={fieldLength}
          />
        )}
      </>
    );
  }
}

export default Schools;
