/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import { Button } from 'reactstrap';
import { Translate as T, I18n } from 'react-redux-i18n';
import { replace } from 'lodash';

import { groupClass } from './helper';
import FeedbackLabel from './FeedbackLabel';
import Label from './Label';

function caseInsensitiveIncludes(strings, value) {
  return strings.map(str => str.toLowerCase()).includes(value.toLowerCase());
}

function caseInsensitiveSubstr(str1, str2) {
  return str1.toLowerCase().includes(str2.toLowerCase());
}

function findSuggestion(strings, value) {
  if (caseInsensitiveIncludes(strings, value)) {
    return null;
  }

  return strings.find(
    str =>
      caseInsensitiveSubstr(str, value) || caseInsensitiveSubstr(value, str),
  );
}

class TypeaheadInput extends Component {
  constructor(props) {
    super(props);
    const { options } = this.props;

    this.typeaheadRef = React.createRef();
    this.state = {
      isLoading: false,
      options: options || [],
    };
  }

  componentDidMount() {
    const {
      input: { value },
    } = this.props;

    // Restore display value.
    // Needed when the TypeaheadInput reappears after page navigation.
    this.typeaheadRef.current.setState({ text: value });
  }

  componentWillReceiveProps() {
    const { options } = this.props;
    if (options) {
      this.setState({
        options,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      input: { value },
    } = this.props;

    // Apply display value when prop value is changed from upstream.
    // Needed when used inside a DecoratedFieldArray and a preceding TypeaheadInput has just been removed.
    if (
      value !== this.typeaheadRef.current.state.text &&
      !this.typeaheadRef.current.state.isFocused
    ) {
      this.typeaheadRef.current.clear();
      this.typeaheadRef.current.setState({ text: value });
    }
  }

  handleSearch(query) {
    const { onSearch, options } = this.props;

    if (onSearch) {
      this.setState({ isLoading: true });
      onSearch(query).then(results => {
        this.setState({
          options: results,
          isLoading: false,
        });
      });
    } else {
      this.setState({ options });
    }
  }

  render() {
    const {
      autoFocus,
      disabled,
      suggest,
      id,
      input,
      input: { value },
      label,
      meta: { touched, error, warning },
      minLength,
      maxResults,
      placeholder,
      type,
      noResultsText,
      typeaheadAsync,
      filterBy,
    } = this.props;

    const { options, isLoading } = this.state;

    const suggestMinLength = minLength || 2;
    const minLengthMet = value.length >= suggestMinLength;

    const handleChange = values => {
      input.onChange(values[0]);
    };

    const valueWithinOptions = caseInsensitiveIncludes(options, value);

    let suggestion;
    if (suggest && minLengthMet && !valueWithinOptions) {
      suggestion = findSuggestion(options, value);
    }
    const { typeaheadRef } = this;
    const menuElementId = `${id || input.name}.menu`;

    const TypeaheadType = typeaheadAsync ? AsyncTypeahead : Typeahead;
    return (
      <div className={groupClass(touched, error)}>
        <Label label={label} htmlFor={input.name} />
        <TypeaheadType
          {...input}
          id={menuElementId}
          inputProps={{
            id: replace(input.name, '.', '-'),
            name: input.name,
          }}
          type={type}
          placeholder={placeholder && I18n.t(placeholder)}
          options={options}
          filterBy={filterBy}
          onChange={handleChange}
          autoFocus={autoFocus}
          disabled={disabled}
          minLength={suggestMinLength}
          maxResults={maxResults}
          isLoading={isLoading}
          // eslint-disable-next-line react/jsx-no-bind
          onSearch={this.handleSearch.bind(this)}
          ref={typeaheadRef}
          emptyLabel=''
        />
        {suggest && suggestion && (
          <span className='d-inline-block pt-2 pl-2'>
            <T value='components.TypeAheadInput.didyoumean' />{' '}
            <Button
              color='link'
              className='p-0'
              onClick={() => {
                // Change Typeahead component's value.
                // https://github.com/ericgio/react-bootstrap-typeahead/issues/266#issuecomment-414987723
                typeaheadRef.current.setState({ text: suggestion });
                input.onChange(suggestion);
              }}
            >
              {suggestion}
            </Button>
            ?
          </span>
        )}
        {noResultsText && minLengthMet && !valueWithinOptions && (
          <div>{noResultsText}</div>
        )}
        <FeedbackLabel {...{ touched, error, warning }} />
      </div>
    );
  }
}

TypeaheadInput.propTypes = {
  id: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  suggest: PropTypes.bool,
  typeaheadAsync: PropTypes.bool,
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  minLength: PropTypes.number,
  maxResults: PropTypes.number,
  onSearch: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  noResultsText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  filterBy: PropTypes.func,
};

export default TypeaheadInput;
