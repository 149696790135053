import { M, Add16, colors } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';

import React, { PureComponent } from 'react';
import {
  FieldsProps,
  FieldArrayMetaProps,
  getFormValues,
  change,
} from 'redux-form';
import { get, isObject } from 'lodash';

import {
  RenderableElementCreator,
  renderRFCtoArrayList,
} from 'international/Fields/FieldCreationLogic/RelevantFieldCreator.class';

import { required } from 'lib/validations';
import { connect } from 'react-redux';
import ResponsiveWrapper from 'international/HigherOrderComponents/ResponsiveWrapper';
import generate from '../../FieldCreationLogic/FieldGenerators';
import { setAddressHistoryRequirementSatisfied } from '../../../../actions';

const noEntriesFields: { [id: string]: string } = Object.freeze({
  employers: 'no_employment_history',
  education: 'no_education_history',
  'scoped_requirements.CA.violations':
    'scoped_requirements.CA.no_canada_violations',
  'scoped_requirements.CA.aliases': 'scoped_requirements.CA.no_aliases',
  'aliases': 'no_aliases',
});

export const validateNoEntriesField = (
  id: string,
  val: boolean,
  allFields: any,
) => {
  const noEntryNotSelected: boolean = !val;
  const noValueProvided: boolean = !get(allFields, id, []).length;
  return noEntryNotSelected && noValueProvided ? required() : undefined;
};

type FieldArrayContentProps<T> = {
  fields: FieldsProps<T>;
  // passThroughProps: PassThroughTypes;
};

type FieldArrayWrapperProps<T> = {
  id: string;
  fieldCreator?: RenderableElementCreator;
  generator: (id: string) => RenderableElementCreator;
  meta?: FieldArrayMetaProps;
  showErrorNotification: boolean;
  internationalAddressHistory: { addressHistoryRequirementSatisfied: boolean };
  fieldData: any;
  dispatch: Function;
  setAddressHistoryRequirementSatisfied: Function;
} & FieldArrayContentProps<T>;

export class FieldArrayContentWrapper<T> extends PureComponent<
  FieldArrayWrapperProps<T>,
  { hideArray: boolean }
> {
  state = {
    hideArray: false,
  };

  componentDidMount() {
    const { id, fields } = this.props;

    if (!fields.length && !noEntriesFields[id!]) {
      this.addElem();
    }
  }

  addElem = () => {
    const { fields } = this.props;
    fields.push(undefined as any);
  };

  render() {
    const {
      id,
      fields,
      generator,
      meta,
      showErrorNotification,
      internationalAddressHistory,
      fieldData,
      dispatch,
      setAddressHistoryRequirementSatisfied: setAddressHistoryRequirementSatisfiedAction,
    } = this.props;

    const { addressHistoryRequirementSatisfied } =
      internationalAddressHistory || {};
    const { dirty, error } = meta || {};

    const noEntriesText: { [id: string]: string } = Object.freeze({
      employers: i18n.getStr(
        'i18nInternational.fieldArrayWrapper.noEmploymentHistory',
      ),
      education: i18n.getStr(
        'i18nInternational.fieldArrayWrapper.noEducationHistory',
      ),
      'scoped_requirements.CA.violations': '',
      'scoped_requirements.CA.aliases': i18n.getStr(
        'i18nInternational.fieldArrayWrapper.noOtherNames',
      ),
      'aliases': i18n.getStr(
        'i18nInternational.fieldArrayWrapper.noOtherNames',
      ),
    });

    const noEntriesLabels: { [id: string]: string } = Object.freeze({
      'scoped_requirements.CA.violations':
        'international.headers.scopedRequirements.CA.violations',
      'scoped_requirements.CA.aliases':
        'international.headers.scopedRequirements.CA.aliases',
      'aliases':
        'international.headers.scopedRequirements.GB.aliases',
    });

    const prefixedFields = fields.map(arrayIndex => {
      return generator(arrayIndex);
    });

    const removeButton = (i: number) => (
      <M.Button
        className='remove-button'
        kind='secondary'
        onClick={() => {
          fields.remove(i);
          const { address } = fieldData || {};
          if (id === 'address' && i + 1 < address.length && i >= 0) {
            dispatch(
              change(
                'intlForm',
                `address[${i}].end_date`,
                address[i - 1].start_date,
              ),
            );
          }
          if (addressHistoryRequirementSatisfied) {
            setAddressHistoryRequirementSatisfiedAction(false)(dispatch);
          }
        }}
      >
        {i18n.getStr('i18nInternational.fieldArrayWrapper.remove')}
      </M.Button>
    );
    const addMoreButton = (
      <M.Button
        kind='secondary'
        key='add-element'
        onClick={this.addElem}
        disabled={id === 'address' && addressHistoryRequirementSatisfied}
      >
        <>
          <Add16 />
          &nbsp;
          {i18n.getStr('i18nInternational.fieldArrayWrapper.addAnother')}
        </>
      </M.Button>
    );

    const fieldsToDisplay = renderRFCtoArrayList(
      prefixedFields,
      /* passThroughProps, */
      () => ({}),
      {
        afterEach: (i: number) => (
          <>
            {id === 'address' || id.includes('scoped_requirements.CA') || id === 'aliases' ? (
              <>
                {i > 0 ? (
                  <div
                    className={`add-remove-buttons ${
                      id === 'address' ? 'address-add-remove-buttons' : ''
                    }`}
                  >
                    {i === prefixedFields.length - 1 && addMoreButton}
                    {i !== 0 && removeButton(i)}
                  </div>
                ) : (
                  <>
                    {i === prefixedFields.length - 1 && (
                      <div
                        className={`add-remove-buttons ${
                          id === 'address' ? 'address-add-remove-buttons' : ''
                        }`}
                      >
                        {addMoreButton}
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <>{removeButton(i)}</>
            )}
          </>
        ),
        separator: i =>
          // eslint-disable-next-line no-nested-ternary
          id !== 'address' && !id.includes('scoped_requirements.CA') && id !== 'aliases' ? (
            <hr key='divider' />
          ) : id !== 'address' ? (
            <div
              style={{
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 700,
                width: '100%',
              }}
            >
              {`${i18n.getStr(noEntriesLabels[id])} #${i + 2}`}
            </div>
          ) : (
            <></>
          ),
      },
    );

    const { hideArray } = this.state;
    let hideComponent: JSX.Element | undefined;

    if (noEntriesFields[id]) {
      const renderFunction = (_: any) => ({
        validate: (val: boolean, allFields: any) =>
          validateNoEntriesField(id, val, allFields),
        valueOnInit: (val: boolean) => {
          this.setState({ hideArray: !!val });
          if (!fields.length && !val) {
            this.addElem();
          }
        },
        onClick: (e: any, val: any, prev: any) => {
          // for some reason this handler gets called twice on every click
          // check if val is a boolean to restrict to the actual event
          if (typeof val === 'boolean') {
            this.setState({ hideArray: val });
            // fields is an object from redux-form not a normal array
            // both removeAll and push are redux actions binded to dispatch
            // https://github.com/redux-form/redux-form/blob/master/src/createFieldArrayProps.js
            // https://github.com/redux-form/redux-form/blob/master/src/createReduxForm.js
            // https://redux.js.org/api/bindactioncreators
            // @ts-ignore
            fields?.removeAll();
            if (!val) {
              // @ts-ignore
              fields?.push(null);
            }
          }
        },
      });
      const isChoiceCard = [
        'scoped_requirements.CA.violations',
        'scoped_requirements.CA.aliases',
        'aliases',
      ].includes(id);
      hideComponent = (
        <M.Container
          type='info'
          className={isChoiceCard ? 'choice-card-container-outer' : ''}
        >
          {isChoiceCard
            ? generate
                .choiceCardField({
                  id: noEntriesFields[id],
                  title: noEntriesText[id],
                  initialValueKey: id,
                })
                .render(renderFunction)
            : generate
                .checkboxField({
                  id: noEntriesFields[id],
                  title: noEntriesText[id],
                  placeholder: '',
                })
                .render(renderFunction)}
        </M.Container>
      );
    }
    const componentsToRender = [];
    if (dirty && error) {
      let formattedError = i18n.getStr(error);
      if (isObject(error)) {
        const { key, format = [] } = error;
        formattedError = i18n.getStr(
          key,
          format?.map(d =>
            i18n.getLocalizedMonthYear(d, i18n.getMonthYearPattern()),
          ),
        );
      }
      componentsToRender.push(
        <>
          <span
            className='bx--form-requirement'
            style={{
              display: 'block',
              overflow: 'visible',
              maxHeight: '12.5rem',
              fontWeight: 'normal',
              color: colors.dangerRed100,
            }}
          >
            {formattedError}
          </span>
          {showErrorNotification && (
            <div className='notificationContainerFixed'>
              <M.ToastNotification
                kind='error'
                title={i18n.getStr(
                  'i18nInternational.fieldArrayWrapper.formValidationError',
                )}
                notificationType='toast'
                className='formValidationErrorNotification'
                timeout={5000}
                caption={formattedError}
                iconDescription={i18n.getStr(
                  'i18nInternational.closeNotification',
                )}
              />
            </div>
          )}
        </>,
      );
    }
    if (hideComponent) {
      componentsToRender.push(hideComponent);
    }

    let addAnotherButton: any[] = [];
    if (id === 'driver_licenses' || id === 'right_to_works' || id === 'credit_report') {
      // TODO: remove this logic during Abstract IMVR development
      // driver_licenses should come in monolith as array structure
      // but Apply Flow should show form only for one DL
      componentsToRender.push(
        renderRFCtoArrayList(prefixedFields, () => ({}), {}),
      );
    } else {
      const [header, ...rest] = fieldsToDisplay || {};
      if (id === 'address' || id.includes('scoped_requirements')) {
        componentsToRender.push(
          ...[
            header,
            <>
              {rest.length > 0 ? (
                <ResponsiveWrapper
                  passThroughComponent={
                    <div
                      className='text-field-group'
                      key='components-to-render'
                    >
                      {rest.map(field => {
                        if (
                          field?.props?.children &&
                          !field?.props?.children[0]?.key?.includes(
                            'scoped_requirements.CA',
                          ) && !field?.props?.children[0]?.key?.includes(
                            'aliases',
                          )
                        ) {
                          return field;
                        }
                        return Object.keys(field.props || {}).length > 0 ? (
                          <div
                            className='text-field'
                            key='components-to-render'
                          >
                            {Object.keys(field.props).length > 0 && field}
                          </div>
                        ) : (
                          <></>
                        );
                      })}
                    </div>
                  }
                />
              ) : (
                <></>
              )}
            </>,
          ],
        );
      } else componentsToRender.push(...fieldsToDisplay);

      addAnotherButton = [
        id !== 'address' && !id.includes('scoped_requirements.CA') && id !== 'aliases' ? (
          <M.Button kind='secondary' key='add-element' onClick={this.addElem}>
            <Add16 />
            &nbsp;
            {i18n.getStr('i18nInternational.fieldArrayWrapper.addAnother')}
          </M.Button>
        ) : (
          <></>
        ),
      ];
    }

    return (
      <ResponsiveWrapper
        passThroughComponent={
          <div className='field-array-container'>
            {hideArray
              ? M.Container.buildContents([hideComponent])
              : M.Container.buildContents(
                  componentsToRender.filter(Boolean).concat(addAnotherButton),
                )}
          </div>
        }
      />
    );
  }
}

const WrappedFieldArrayContentWrapper = connect(
  (state: any): { internationalAddressHistory: any; fieldData: any } => {
    const { internationalAddressHistory } = state || {};
    return {
      internationalAddressHistory,
      fieldData: getFormValues('intlForm')(state),
    };
  },
  dispatch => ({
    dispatch,
    setAddressHistoryRequirementSatisfied,
  }),
)(FieldArrayContentWrapper);

export default function fieldArrayContentFactory<T>(
  id: string,
  generator: (arrayIndex: string) => RenderableElementCreator,
  showErrorNotification: boolean,
) {
  const contentWrapper = (props: {
    fields: FieldsProps<T>;
    // passThroughProps: any;
  }) => (
    <WrappedFieldArrayContentWrapper
      id={id}
      {...props}
      generator={generator}
      showErrorNotification={showErrorNotification}
    />
  );
  return contentWrapper;
}
