/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import styled from 'styled-components';

import { connect } from 'react-redux';
import { CustomComponentList } from './CustomComponent';
import LanguageToggle from './LanguageToggle';
import { IS_PRODUCTION } from '../constants';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_PROPERTIES,
  getAnalyticsPageName,
} from '../lib/analytics';
import { trackAnalyticsEvent } from '../actions';

const FixedFooterPadding = styled.div`
  height: 64px;
`;

function getEventPropsForButton(currentPageName) {
  const eventProps = {};

  if (currentPageName) {
    eventProps[ANALYTICS_PROPERTIES.PAGE_NAME] = getAnalyticsPageName(
      currentPageName,
    );
  }

  return eventProps;
}

const Footer = ({
  components,
  trackAnalyticsEventDispatch,
  currentPageName,
}) => {
  return (
    <footer className='footer text-center'>
      <CustomComponentList list={components} />
      <div className='checkr-logo'>
        <img
          src='https://assets.checkr.com/logo-aqua-2022.svg'
          style={{ height: '34px' }}
          alt='Checkr logo'
        />
      </div>
      <div>
        <T value='address' />
      </div>
      <div className='footerLinksContainer'>
        <div className='footerLink'>
          <a
            href='https://help.checkr.com/hc/en-us/categories/360002478633-Candidate'
            target='_blank'
            rel='noopener noreferrer'
            onClick={() =>
              trackAnalyticsEventDispatch(
                ANALYTICS_EVENTS.DOMESTIC_FAQ_VIEWED,
                getEventPropsForButton(currentPageName),
              )
            }
          >
            <T value='components.footer.helpCenter' />
          </a>
        </div>
        &nbsp;
        <div className='footerLink'>
          <a
            href='//checkr.com/privacy-policy'
            target='_blank'
            rel='noopener noreferrer'
          >
            <T value='components.footer.privacyPolicy' />
          </a>
        </div>
      </div>
      <LanguageToggle dropup configIsLive={IS_PRODUCTION} />
      <FixedFooterPadding />
    </footer>
  );
};

Footer.propTypes = {
  components: PropTypes.array,
  trackAnalyticsEventDispatch: PropTypes.func,
  currentPageName: PropTypes.string,
};

const mapDispatchAnalyticsToProps = dispatch => ({
  trackAnalyticsEventDispatch: (eventName, eventParams = {}) =>
    dispatch(trackAnalyticsEvent(eventName, eventParams)),
});

export default connect(null, mapDispatchAnalyticsToProps)(Footer);
