import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { i18n } from '@international/mastodon-i18n';

import { trackAnalyticsEvent } from '../../../../actions/analytics';
import { ANALYTICS_EVENTS } from '../../../../lib/analytics';
import * as V from '../../../../lib/validations';
import generate from '../../../Fields/FieldCreationLogic/FieldGenerators';

import UnitedKingdomDBS from './UnitedKingdomDBS';
import UnitedKingdomDS from './UnitedKingdomDS';

const CountryComponentMap = {
  'GB-ENG': UnitedKingdomDBS,
  'GB-NIR': UnitedKingdomDS,
  'GB-SCT': UnitedKingdomDS,
  'GB-WLS': UnitedKingdomDBS,
  Other: UnitedKingdomDS,
};

export const WorkLocationGB = ({
  constituentCountry,
  trackAnalyticsEvent: trackAnalyticsEventAction,
}: {
  constituentCountry: string;
  trackAnalyticsEvent: Function;
}) => {
  const CountryComp: any =
    CountryComponentMap[constituentCountry as keyof typeof CountryComponentMap];
  const constituentCountries = [
    {
      id: 'GB-ENG',
      label: i18n.getStr('international.regionOverrides.countries.england'),
    },
    {
      id: 'GB-SCT',
      label: i18n.getStr('international.regionOverrides.countries.scotland'),
    },
    {
      id: 'GB-WLS',
      label: i18n.getStr('international.regionOverrides.countries.wales'),
    },
    {
      id: 'GB-NIR',
      label: i18n.getStr(
        'international.regionOverrides.countries.northernIreland',
      ),
    },
    {
      id: 'Other',
      label: i18n.getStr('international.regionOverrides.countries.other'),
    },
  ];

  useEffect(() => {
    if (!constituentCountry) return;
    trackAnalyticsEventAction(
      ANALYTICS_EVENTS.UK_IDENTITY_VERIFICATION_CONSTITUENT_COUNTRY_SELECTED,
    );
  }, [constituentCountry, trackAnalyticsEventAction]);

  return (
    <div className='uk-region-container'>
      <b>
        {i18n.getStr(
          'international.regionOverrides.sectionHeaders.workLocation',
        )}
      </b>

      {i18n.getStr(
        'international.regionOverrides.descriptions.gbConstituentCountry',
      )}
      <div className='text-field'>
        {generate
          .dropdownField({
            id: 'scoped_requirements.GB.constituent_country',
            title: i18n.getStr(
              'international.regionOverrides.titles.constituentCountry',
            ),
            items: constituentCountries,
            placeholder: i18n.getStr(
              'international.regionOverrides.placeholders.selectACountry',
            ),
          })
          .render({
            validate: [V.required],
          })}
      </div>
      {CountryComp && <CountryComp />}
    </div>
  );
};

const intlFormSelector = formValueSelector('intlForm');

const isConstituentCountryEnabled = (
  addresses: any[],
  workLocation: string,
) => {
  const hasUnitedKingdomAddress = addresses.some(
    address => address.country_code === 'GB',
  );
  return workLocation === 'GB' && hasUnitedKingdomAddress;
};

const ConnectedWorkLocationGB = connect(
  state => ({
    constituentCountry: intlFormSelector(
      state,
      'scoped_requirements.GB.constituent_country',
    ),
  }),
  { trackAnalyticsEvent },
)(WorkLocationGB);

export const UnitedKingdomRegion = ({
  addresses,
  workLocation,
}: {
  addresses: any[];
  workLocation: string;
}) =>
  isConstituentCountryEnabled(addresses, workLocation) ? (
    <ConnectedWorkLocationGB />
  ) : (
    <UnitedKingdomDS constituentCountryEnabled={false} />
  );

const ConnectedUnitedKingdomRegion = connect(
  (state: { configuration: any }) => ({
    addresses: intlFormSelector(state, 'address') || [],
    workLocation:
      state.configuration.configuration.candidate.data_residency_location,
  }),
)(UnitedKingdomRegion);

export default ConnectedUnitedKingdomRegion;
