import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from '@international/mastodon-i18n';
import './UploadHeader.scss';

export const UploadHeader = ({ screening }) => {
  const header = i18n.getStr(`international.uploadText.${screening}.header`);
  const body = i18n.getStr(`international.uploadText.${screening}.body`);
  return (
    <div className='upload-header-text'>
      <div className='upload-header-title'>{header}</div>
      {body}
    </div>
  );
};

UploadHeader.propTypes = {
  screening: PropTypes.string.isRequired,
};

export default UploadHeader;
