import { uniq, upperFirst, words } from 'lodash';
import { genericJSONRequest } from '../../../actions/helper';

const asciiRegex = /^[\u0020-\u007f]*$/;

const keepAsciiOnly = results => results.filter(str => asciiRegex.test(str));

const requestEmployers = query => {
  const clearbitUrl = `https://autocomplete.clearbit.com/v1/companies/suggest?query=${query}`;

  return genericJSONRequest(clearbitUrl)
    .then(results =>
      results.map(r =>
        words(r.name)
          .map(upperFirst)
          .join(' '),
      ),
    )
    .then(keepAsciiOnly)
    .then(uniq);
};

export { requestEmployers as default };
