import * as KeyCode from 'keycode-js';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { Translate as T } from 'react-redux-i18n';

import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';

import * as V from '../../lib/validations';
import { overridePosition as overridePositionAction } from '../../actions/employment/employmentActions';

const ArgyleAddPositionContainer = ({
  employment,
  fieldName,
  overridePosition,
}) => {
  const [textInputAutoFocus, setTextInputAutoFocus] = useState(false);
  const [isEditing, setIsEditing] = useState(_.isEmpty(employment.position));
  const [newPosition, setNewPosition] = useState(employment.position || '');

  const handleOnSave = clickEvent => {
    if (!canSave()) {
      return;
    }

    setIsEditing(false);

    const sanitizedNewPosition = newPosition.trim();
    overridePosition(`${fieldName}.position`, sanitizedNewPosition);
    setNewPosition(sanitizedNewPosition);
  };

  const canSave = () => {
    return !_.isEmpty(newPosition.trim());
  };

  const handleOnCancel = () => {
    if (!canCancel()) {
      return;
    }

    setIsEditing(false);
  };

  const canCancel = () => {
    return !_.isEmpty(employment.position?.trim());
  };

  const handleOnEditLinkClick = () => {
    setTextInputAutoFocus(true);
    setIsEditing(true);
  };

  const handleOnKeyDown = event => {
    switch (event.keyCode) {
      case KeyCode.KEY_RETURN:
        handleOnSave();
        break;
      case KeyCode.KEY_ESCAPE:
        handleOnCancel();
        break;
      default:
    }
  };

  const shouldPreventNavigation = () => {
    return isEditing && _.isEmpty(employment.position);
  };

  const renderEditing = () => {
    return (
      <div id='editingArgylePosition'>
        <input
          type='text'
          className='form-control has-success new-position'
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={textInputAutoFocus}
          placeholder={i18n.getStr(
            'components.EmploymentForm.ArgyleForm.addPosition.lastPositionHeldPlaceholder',
          )}
          value={newPosition}
          onChange={changeEvent => setNewPosition(changeEvent.target.value)}
          onKeyDown={handleOnKeyDown}
        />
        {shouldPreventNavigation() && (
          <Field
            name={`add-argyle-position-blocker-${employment.argyleEmploymentId}`}
            component='input'
            type='hidden'
            validate={V.required}
          />
        )}
        <M.Button
          kind='tertiary'
          size='default'
          className='cancel editing'
          onClick={handleOnCancel}
          disabled={!canCancel()}
        >
          <M.Icon
            icon='Close'
            className={`cancel icon ${canCancel() ? '' : 'disabled'}`}
            size={20}
          />
        </M.Button>
        <M.Button
          kind='tertiary'
          size='default'
          className='save editing'
          onClick={clickEvent => handleOnSave(clickEvent)}
          disabled={!canSave()}
        >
          <M.Icon
            icon='Checkmark'
            className={`save icon ${canSave() ? '' : 'disabled'}`}
            size={20}
          />
        </M.Button>
      </div>
    );
  };

  const renderNonEditing = () => {
    return (
      <div
        className='last-position-link'
        role='button'
        tabIndex={0}
        onClick={handleOnEditLinkClick}
      >
        <span className='position-link'>{employment.position}</span>{' '}
        <M.Icon icon='Edit' className='icon' />
      </div>
    );
  };

  return (
    <div id='mastodon' className='add-position'>
      <M.Container type='alert'>
        <div className='heading'>
          {i18n.getStr(
            'components.EmploymentForm.ArgyleForm.addPosition.addYourPositionHeading',
            [employment.name],
          )}
        </div>

        <div className='subheading'>
          <T value='components.EmploymentForm.ArgyleForm.addPosition.addYourPositionSubheading' />
        </div>

        <div className='last-position-held-label'>
          <T value='components.EmploymentForm.ArgyleForm.addPosition.lastPositionHeld' />
        </div>

        {isEditing ? renderEditing() : renderNonEditing()}
      </M.Container>
    </div>
  );
};

ArgyleAddPositionContainer.propTypes = {
  employment: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  overridePosition: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  overridePosition: overridePositionAction,
};

export default connect(null, mapDispatchToProps)(ArgyleAddPositionContainer);
