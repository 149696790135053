import React from 'react';
import PropTypes from 'prop-types';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import acceptImage from '../../Assets/symbol-accept@3x.png';
import loginImage from '../../Assets/symbol-login@3x.png';

function LearnMoreModal({ open, onClose }) {
  return (
    <M.ComposedModal open={open} onClose={onClose}>
      <M.ModalHeader closeModal={onClose}>
        <h2>
          {i18n.getStr(
            'i18nInternational.educationField.measureOne.m1LearnMoreModal.title',
          )}
        </h2>
      </M.ModalHeader>
      <M.ModalBody>
        <div className='m1-learn-more-modal'>
          <div className='row'>
            <div className='col-md-12'>
              <h2>
                {i18n.getStr(
                  'i18nInternational.educationField.measureOne.m1LearnMoreModal.howItWorks',
                )}
              </h2>
            </div>
            <div className='col-md-12 m1-waiting-results-modal-description-padding'>
              <span className='m1-learn-more-modal-span'>
                {i18n.getStr(
                  'i18nInternational.educationField.measureOne.m1LearnMoreModal.description',
                )}
              </span>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <img
                src={loginImage}
                alt={i18n.getStr(
                  'i18nInternational.educationField.measureOne.m1LearnMoreModal.leftTitle',
                )}
                className='intl-learn-more-m1-modal-left-Image'
              />
              <h3>
                {i18n.getStr(
                  'i18nInternational.educationField.measureOne.m1LearnMoreModal.leftTitle',
                )}
              </h3>
              <span className='m1-learn-more-modal-span'>
                {i18n.getStr(
                  'i18nInternational.educationField.measureOne.m1LearnMoreModal.leftDescription',
                )}
              </span>
            </div>
            <div className='col-md-6'>
              <img
                src={acceptImage}
                alt={i18n.getStr(
                  'i18nInternational.educationField.measureOne.m1LearnMoreModal.rightTitle',
                )}
                className='intl-learn-more-m1-modal-right-Image'
              />
              <h3>
                {i18n.getStr(
                  'i18nInternational.educationField.measureOne.m1LearnMoreModal.rightTitle',
                )}
              </h3>
              <span className='m1-learn-more-modal-span'>
                {i18n.getStr(
                  'i18nInternational.educationField.measureOne.m1LearnMoreModal.rightDescription',
                )}
              </span>
            </div>
          </div>
        </div>
      </M.ModalBody>
    </M.ComposedModal>
  );
}

LearnMoreModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default LearnMoreModal;
