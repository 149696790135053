/* eslint-disable react/require-default-props */
import React from 'react';
import { Field } from 'redux-form';

import { Checkbox } from './fields';

import * as V from '../lib/validations';

import HTMLText from './HTMLText';
import Panel from './Panel';

type AbstractDisclosureProps = {
  title: string;
  content: string;
  checkboxRequired: boolean;
  checkboxLabel?: string;
  checkboxName?: string;
  noScroll?: boolean;
}

const AbstractDisclosure = ({
  checkboxRequired,
  checkboxName,
  checkboxLabel,
  content,
  title,
  noScroll,
}: AbstractDisclosureProps) => {
  const renderCheckbox = () => {
    return (
      <div>
        <hr />
        <div className='col-md-12'>
          <Field
            type='checkbox'
            name={checkboxName}
            label={checkboxLabel}
            component={Checkbox}
            validate={[V.requiredCheckbox]}
            labelFeedback
            boldLabel
          />
        </div>
      </div>
    )
  }

  return (
    <Panel title={title} icon='fa-file-text'>
      <div className={noScroll ? '' : 'scrollable'}>
        <HTMLText content={content} />
      </div>
      {checkboxRequired && renderCheckbox()}
    </Panel>
  );
}

export default AbstractDisclosure;
