import React from 'react';
import { extractRadioFields } from './Utils';
import generate from '../../../FieldCreationLogic/FieldGenerators';

const IsolatedComponent = ({ requirement, gen, fieldGenerator }: any) => {
  let content: JSX.Element | null = null;
  const key = requirement?.path?.key;

  const isFieldArray = requirement?.type === 'array' && requirement?.items;
  // @ts-ignore
  content = isFieldArray ? (
    generate.arrayField(
      `${key}`,
      fieldGenerator.generateFieldLater(requirement.items),
      true,
    )
  ) : (
    <div className='form-container' key={`${key}`}>
      {gen(key).render()}
    </div>
  );

  return content;
};

const RadioComponent = ({ gen, identificationStatus, content }: any) => (
  <>
    {Object.entries(content).map(([key, value]: any) => {
      const rendered = gen(key).render();
      const statusCheck = Object.keys(value).find(
        (val: any) =>
          identificationStatus === val.toUpperCase() ||
          identificationStatus === val,
      );
      const requirement = statusCheck ? value[statusCheck] : null;

      return (
        <div key={key}>
          {rendered}
          {requirement && (
            <IsolatedComponent requirement={requirement} gen={gen} />
          )}
        </div>
      );
    })}
  </>
);

const GCCRequirements = ({
  gen,
  fieldGenerator,
  form,
  requiredFields,
}: any) => {
  const { isolatedRequirements, radioRequirements } = extractRadioFields(
    requiredFields,
  );
  const identificationStatusField = Object.keys(radioRequirements)[0];
  const identificationStatus =
    identificationStatusField &&
    form?.credit_report?.at(0)?.[identificationStatusField];

  return (
    <>
      {Object.entries(isolatedRequirements).map(([_, requirement]) => (
        <>
          <IsolatedComponent
            gen={gen}
            fieldGenerator={fieldGenerator}
            requirement={requirement}
          />
        </>
      ))}
      <RadioComponent
        gen={gen}
        identificationStatus={identificationStatus}
        content={radioRequirements}
      />
    </>
  );
};

export default GCCRequirements;
