import { i18n } from '@international/mastodon-i18n';

export const getSpecializedDateFieldTranslation = (
  key: string,
  fromParent: string = '',
) => {
  let label: string | undefined;
  switch (key) {
    case 'dob':
      label = 'international.labels.dob';
      break;
    case 'date_of_sentence':
      if (fromParent.includes('scoped_requirements.CA.violations')) {
        label = 'international.labels.canadaViolationDate';
      }
      break;
    case 'date_of_entry':
      if (fromParent.includes('scoped_requirements.CA'))
        label = 'international.labels.scopedRequirements.CA.dateOfEntry';
      else label = 'international.labels.dateOfEntry';
      break;
  }

  if (label)
    return i18n.getStr(
      label,
      i18n.getLocalizedDateFormat(i18n.DateFormats.DATE_SLASH),
    );
  return undefined;
};

export default {
  getSpecializedDateFieldTranslation,
};
