/* eslint-disable lines-between-class-members */
/* eslint-disable camelcase */
import { get } from 'lodash';

import { RenderableSchemaProperty } from 'international/Fields/FieldCreationLogic/Field.types';
import { required } from 'lib/validations';
import { JsonSchema } from '../types/schema.types';

import { DropdownListItem } from '../Fields/FieldComponents/WrappedMastodonComps/Dropdown';
import ApplyFormGenerator from './FormGenerator.class';
import FieldDeterminer from '../Fields/FieldCreationLogic/FieldDeterminer.class';
import { ApplyForm } from './Form.types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
class Payload {
  phone_number: string = '';
  first_name: string = '';
  middle_name: string = '';
  last_name: string = '';
  email_address: string = '';
  birth_date: object = {};
  id_number: string = '';
  passport_number: string = '';
  address: Address[] = [];
  [otherProperties: string]: string | boolean | object | object[];
}

export type intlI18nFunc = (
  key: string,
  base?: string,
  undefinedIfNotFound?: boolean,
) => string | undefined;

class Address {
  'street-one': string;
  'street-two': string;
  'city': string;
  'state': string;
  'postal-code': string;
  'country': string;
  'residency-date-start': Date;
  'residency-date-end': Date;
}

class ApplyFormManager {
  applyFormGenerator: ApplyFormGenerator;
  fieldGenerator: FieldDeterminer;
  data: ApplyForm;

  constructor(
    schema: JsonSchema,
    public alpha2CodeToName: DropdownListItem[] = [],
    public lang: string,
    public intlI18n: intlI18nFunc,
  ) {
    this.applyFormGenerator = new ApplyFormGenerator(schema);
    this.data = this.applyFormGenerator.getApplyFormData();
    this.fieldGenerator = new FieldDeterminer(alpha2CodeToName, intlI18n);
  }

  getFieldGeneratorForProperties = (
    props: {
      [prop: string]: RenderableSchemaProperty;
    },
    arrayPathModifier?: string,
  ) => (prop: string, overrideTitleFromInstructions?: boolean) =>
    this.fieldGenerator
      .generateField(get(props, prop), arrayPathModifier, {
        overrideTitleFromInstructions,
      })
      .extendProps(_ => {
        const path = props[prop]?.path;
        return {
          validate: path?.isRequired ? required : [],
          disabled: path?.isDisabled,
        };
      });
}

export default ApplyFormManager;
