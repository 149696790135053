import React, { useCallback, useEffect, useState } from 'react';

import responsiveHelpers from 'lib/utils/responsiveHelpers';

const { MEDIA_QUERY_SIZES, constructMediaQuery } = responsiveHelpers;

const mediaQuery = constructMediaQuery(MEDIA_QUERY_SIZES.XL);

interface ContainerProps {
  content: Array<any> | null;
  breakpoint?: number;
}

const ResponsiveColumnLayout: React.FC<ContainerProps> = ({
  content,
}: ContainerProps) => {
  const [firstColumn, setFirstColumn] = useState<Array<any> | undefined>(
    undefined,
  );
  const [secondColumn, setSecondColumn] = useState<Array<any> | undefined>(
    undefined,
  );
  const [thirdColumn, setThirdColumn] = useState<Array<any> | undefined>(
    undefined,
  );
  const computeTwoColumnState = useCallback(() => {
    const firstColumnContent = content?.slice(0, content.length / 2);
    const secondColumnContent = content?.slice(content.length / 2);
    setFirstColumn(firstColumnContent);
    setSecondColumn(secondColumnContent);
    setThirdColumn(undefined);
  }, [content]);

  const computeThreeColumnState = useCallback(() => {
    const firstColumnContent = content?.slice(0, content.length / 3);
    const secondColumnContent = content?.slice(
      content.length / 3,
      Math.imul(2, content.length / 3),
    );
    const thirdColumnContent = content?.slice(
      Math.imul(2, content.length / 3),
      Math.imul(3, content.length / 3),
    );
    setFirstColumn(firstColumnContent);
    setSecondColumn(secondColumnContent);
    setThirdColumn(thirdColumnContent);
  }, [content]);
  useEffect(() => {
    if (mediaQuery.matches) computeThreeColumnState();
    else computeTwoColumnState();

    mediaQuery.addEventListener('change', e => {
      if (mediaQuery.matches) {
        computeThreeColumnState();
      } else {
        computeTwoColumnState();
      }
    });
  }, [computeThreeColumnState, computeTwoColumnState, content]);
  return (
    <div className='responsive-column-container'>
      <span className='responsive-column'>{firstColumn}</span>
      <span className='responsive-column'>{secondColumn}</span>
      {thirdColumn && thirdColumn.length > 0 && (
        <span className='responsive-column'>{thirdColumn}</span>
      )}
    </div>
  );
};

export default ResponsiveColumnLayout;
