import React from 'react';
import { Field } from 'redux-form';

import Panel from './Panel';

import { Select, Text } from './fields';

import * as O from '../lib/options';
import * as V from '../lib/validations';

const InternationalInformationForm = () => (
  <Panel
    title='international.InternationalInformationForm.title'
    icon='fa-globe'
  >
    <div className='row'>
      <div className='col-md-6'>
        <Field
          type='text'
          name='nationalIdNumber'
          label='labels.nationalIdNumber'
          component={Text}
          validate={[V.required, V.nameFormat]}
        />
      </div>

      <div className='col-md-6'>
        <Field
          type='select'
          name='citizenship'
          label='labels.citizenship'
          component={Select}
          validate={[V.required]}
          options={O.countries}
        />
      </div>
    </div>

    <div className='row'>
      <div className='col-md-6'>
        <Field
          type='text'
          name='motherMaidenName'
          label='labels.motherMaidenName'
          component={Text}
          validate={[V.required, V.nameFormat]}
        />
      </div>

      <div className='col-md-6'>
        <Field
          type='text'
          name='fatherName'
          label='labels.fatherName'
          component={Text}
          validate={[V.required, V.nameFormat]}
        />
      </div>
    </div>
  </Panel>
);

export default InternationalInformationForm;
