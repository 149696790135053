import _ from 'lodash';
import { statesByCode } from '../options/states';

export const UNITED_STATES = 'United States';

/**
 * Constructs the options provided to the state select dropdown
 *
 * @param {Array<String>} packageLocations array of supported locations defined on the PLV package
 *
 * @returns {Array<Object>} array of options for the state select dropdown
 */
export const getStatesForDropdown = packageLocations => {
  const supportedStatesForDropdown = packageLocations
    .filter(stateCode => stateCode !== UNITED_STATES)
    .map(stateCode => ({
      name: statesByCode[stateCode],
      value: stateCode,
    }))
    .filter(statesForDropdown => !!statesForDropdown.name);

  const sortedStatesForDropdown = _.sortBy(
    supportedStatesForDropdown,
    state => state.name,
  );

  return [{ name: '', value: '' }, ...sortedStatesForDropdown];
};
