/* eslint-disable react/prop-types */
import React, { FC } from 'react';

import RegionContainer from '../../Pages/RegionContainer';
import { FormRegionsToRender } from '../../FormLogic/Form.types';
import { Screening } from '../../types/schema.types';

type Props = {
  regionsInOrder: string[];
  regionsToRender: FormRegionsToRender;
  screening: Screening;
};

const RegionContainers: FC<Props> = ({
  regionsInOrder,
  regionsToRender,
  screening,
}) => (
  <>
    {regionsInOrder
      .filter(region => regionsToRender[region])
      .map(region => {
        return (
          <RegionContainer
            key={`region-${region}`}
            screening={screening}
            region={region}
            fieldsToRender={regionsToRender && regionsToRender[region]?.fields}
          />
        );
      })}
  </>
);

export default RegionContainers;
