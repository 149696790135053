import moment from 'moment/moment';
import insertArgyleEmployment from './insertArgyleEmployment';

const insertVerifiedArgyleEmploymentNoPosition = () => dispatch => {
  const argyleEmployment = {
    contractType: 'seasonal',
    contractTypeVerified: true,
    name: 'Fake Verified - No Position',
    nameVerified: true,
    position: null,
    positionVerified: false,
    startDate: moment()
      .subtract(3, 'years')
      .format('MM/YYYY'),
    startDateVerified: true,
    endDate: moment()
      .subtract(1, 'months')
      .format('MM/YYYY'),
    endDateVerified: true,
    currentPosition: false,
    argyleEmploymentId: 'verified_no_pos',
    isVerified: true,
  };

  dispatch(insertArgyleEmployment(argyleEmployment));
};

export default insertVerifiedArgyleEmploymentNoPosition;
