import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { get } from 'lodash';
import { i18n } from '@international/mastodon-i18n';

import ResponsiveTextFieldGroupWrapper from 'international/Components/Layouts/ResponsiveTextFieldGroupWrapper';
import * as V from '../../../../lib/validations';
import {
  dbsIdentityDocumentsPrimaryGroup1,
  dbsIdentityDocumentsPrimaryGroup1AndSecondaryGroup2,
} from '../../../Fields/FieldCreationLogic/SelectOptions';
import generate from '../../../Fields/FieldCreationLogic/FieldGenerators';
import InternationalContext from '../../../InternationalContext';
import { createGrid, getPastNames } from './RegionOverrideUtils';
import UnitedKingdomAdditionalInfo from './UnitedKingdomAdditionalInfo';
import PastNamesWrapper from './PastNamesWrapper';

// TODO: Need To Determine If UK National

export const UnitedKingdomDBS = ({
  dbsProofOfIdentityDocument1Type,
  dbsProofOfIdentityDocument2Type,
}: {
  dbsProofOfIdentityDocument1Type?: string;
  dbsProofOfIdentityDocument2Type?: string;
}) => {
  const intlContext = useContext(InternationalContext);
  const { data, getFieldGeneratorForProperties } = intlContext;
  const scopedRequirementProperties = get(
    data,
    'fieldMap.scoped_requirements.properties.GB.properties',
    {},
  );
  const genScoped = getFieldGeneratorForProperties(scopedRequirementProperties);

  const proofOfIdentityDocument1Types = dbsIdentityDocumentsPrimaryGroup1.filter(
    type => type !== dbsProofOfIdentityDocument2Type,
  );

  const proofOfIdentityDocument2Types = dbsProofOfIdentityDocument1Type
    ? dbsIdentityDocumentsPrimaryGroup1AndSecondaryGroup2.filter(
        type => type !== dbsProofOfIdentityDocument1Type,
      )
    : [];

  const { pastNamesContent, hasPastNames } = getPastNames(
    scopedRequirementProperties,
    intlContext,
  );

  return (
    <>
      {createGrid(
        genScoped,
        [
          {
            sectionHeader:
              'international.regionOverrides.sectionHeaders.proofOfIdentity',
          },
          {
            label: 'international.regionOverrides.descriptions.proofOfIdentity',
          },
        ],
        false,
      )}
      <div className='text-field'>
        {generate
          .dropdownField({
            id: 'dbsProofOfIdentityDocument1Type',
            title: i18n.getStr(
              'international.regionOverrides.titles.documentType',
            ),
            items: proofOfIdentityDocument1Types.map(doc => ({
              id: doc,
              label: i18n.getConstStr(
                'international.dropdownLabel.identityDocuments',
                doc.replaceAll('_', ' '),
              ),
            })),
            placeholder: i18n.getStr(
              'international.regionOverrides.placeholders.selectADocument',
            ),
          })
          .render({
            validate: [V.required],
          })}
      </div>
      <ResponsiveTextFieldGroupWrapper>
        {dbsProofOfIdentityDocument1Type &&
          createGrid(genScoped, [
            'dbs_primary_group_1_document',
            <UnitedKingdomAdditionalInfo
              key='proofOfIdentityDocument1AdditionalInfo'
              documentTypes={[dbsProofOfIdentityDocument1Type || '']}
            />,
          ])}
      </ResponsiveTextFieldGroupWrapper>
      <div className='text-field'>
        {generate
          .dropdownField({
            id: 'dbsProofOfIdentityDocument2Type',
            title: i18n.getStr(
              'international.regionOverrides.titles.documentType',
            ),
            items: proofOfIdentityDocument2Types.map(doc => ({
              id: doc,
              label: i18n.getConstStr(
                'international.dropdownLabel.identityDocuments',
                doc.replaceAll('_', ' '),
              ),
            })),
            placeholder: i18n.getStr(
              'international.regionOverrides.placeholders.selectADocument',
            ),
            disabled: true,
          })
          .render({
            validate: [V.required],
          })}
      </div>
      <ResponsiveTextFieldGroupWrapper>
        {dbsProofOfIdentityDocument2Type &&
          createGrid(genScoped, [
            'dbs_primary_group_1_and_secondary_group_2_document',
            <UnitedKingdomAdditionalInfo
              key='proofOfIdentityDocument2AdditionalInfo'
              documentTypes={[dbsProofOfIdentityDocument2Type || '']}
            />,
          ])}
      </ResponsiveTextFieldGroupWrapper>
      {createGrid(
        genScoped,
        [
          {
            sectionHeader:
              'international.regionOverrides.sectionHeaders.employmentDetails',
          },
          {
            label:
              'international.regionOverrides.descriptions.employmentDetails',
          },
        ],
        false,
      )}
      <ResponsiveTextFieldGroupWrapper>
        {createGrid(genScoped, [
          ['employer_name', 'dbs_employment_sector', 'position_applied_for'],
        ])}
      </ResponsiveTextFieldGroupWrapper>
      {createGrid(
        genScoped,
        [
          {
            sectionHeader:
              'international.regionOverrides.sectionHeaders.additionalDetails',
          },
          {
            label:
              'international.regionOverrides.descriptions.additionalDetails',
          },
        ],
        false,
      )}
      <div>
        <ResponsiveTextFieldGroupWrapper>
          {createGrid(genScoped, [
            'title',
            'sex',
            'city_of_birth',
            'country_of_birth',
            'national_insurance_number',
            'national_insurance_document',
          ])}
        </ResponsiveTextFieldGroupWrapper>
        {hasPastNames && (
          <PastNamesWrapper>{pastNamesContent}</PastNamesWrapper>
        )}
      </div>
    </>
  );
};

const intlFormSelector = formValueSelector('intlForm');

const ConnectedUnitedKingdomDBS = connect(state =>
  intlFormSelector(
    state,
    'dbsProofOfIdentityDocument1Type',
    'dbsProofOfIdentityDocument2Type',
  ),
)(UnitedKingdomDBS);

export default ConnectedUnitedKingdomDBS;
