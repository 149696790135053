import { useState, useEffect } from 'react';
import { fetchFlag } from '@dashboard-experience/react-flagr';
import { i18n, moment } from '@international/mastodon-i18n';
import * as Sentry from '@sentry/react';
import {
  ARGYLE_DEBUG_LOGS_ENABLED,
  SHORT_DATE_FORMAT,
  ARGYLE_FLOW_ID_SINGLE,
  ARGYLE_FLOW_ID_MULTIPLE,
  ARGYLE_FLOW_ID_CONTROLLER_FLAGR_KEY,
  FLAGR_URL,
  ENV,
} from '../../../constants';

const JOB_OR_GAP_DATE_FORMAT = SHORT_DATE_FORMAT;

const hasAllJobsOutOfDateRange = (lookbackYears, jobs) => {
  return jobs.every(job => jobIsOutOfLookback(lookbackYears, job));
};

const jobIsOutOfLookback = (lookbackYears, job) => {
  const lookbackYear = moment().subtract(lookbackYears, 'years');
  return moment(job.endDate, SHORT_DATE_FORMAT) < lookbackYear;
};

const hasUnallocatedPeriods = (lookbackYears, jobsOrGaps) => {
  const lookbackStartMonth = moment()
    .subtract(lookbackYears, 'years')
    .startOf('month');
  const lookbackEndMonth = moment().startOf('month');

  const allocationHash = initAllocationHash(
    lookbackStartMonth,
    lookbackEndMonth,
  );
  markAllocated(allocationHash, lookbackEndMonth, jobsOrGaps);

  return Object.keys(allocationHash).some(key => !allocationHash[key]);
};

const initAllocationHash = (lookbackStartMonth, lookbackEndMonth) => {
  const allocationHash = {};

  forEachMonth(lookbackStartMonth, lookbackEndMonth, key => {
    allocationHash[key] = false;
  });

  return allocationHash;
};

const markAllocated = (allocationHash, lookbackEndMonth, jobsOrGaps) => {
  if (!jobsOrGaps) return;

  jobsOrGaps.forEach(jobOrGap => {
    const startMonth = moment(
      jobOrGap.startDate,
      JOB_OR_GAP_DATE_FORMAT,
    ).startOf('month');
    if (!startMonth.isValid()) return;

    const endMonth = jobOrGap.currentPosition
      ? lookbackEndMonth
      : moment(jobOrGap.endDate, JOB_OR_GAP_DATE_FORMAT).startOf('month');
    if (!endMonth.isValid()) return;

    forEachMonth(startMonth, endMonth, key => {
      // eslint-disable-next-line no-param-reassign
      allocationHash[key] = true;
    });
  });
};

const forEachMonth = (startMonth, endMonth, callback) => {
  for (
    let currentMonth = startMonth;
    currentMonth <= endMonth;
    currentMonth.add(1, 'month')
  ) {
    callback(currentMonth.format('YYYY-MM'));
  }
};

const employerTitleGenerator = index => {
  return i18n.getStr(
    `components.Employer.ordinal_${+index < 10 ? +index : 'other'}`,
    {
      count: +index + 1,
      ordinal: true,
    },
  );
};

// Returns a stable React key part unique to an employment object.
const getEmploymentKeyPart = employment => {
  // argyleEmploymentId will be present if the employment was fetched from Argyle.
  // id will be present if the employment was pre-seeded by customer through API (Invite flow only).
  // addedAt will be present if the employment was added manually by the user during Apply flow.
  return employment.argyleEmploymentId ?? employment.id ?? employment.addedAt;
};

const argyleDebugLog = (message, ...optionalParams) => {
  if (ARGYLE_DEBUG_LOGS_ENABLED === true) {
    /* eslint-disable-next-line no-console */
    console.log(message, ...optionalParams);
  }
};

const SENTRY_CONTEXT = {
  tags: {
    team: 'koalas',
    screening: 'employment_verification',
    vendor: 'argyle',
  },
};

const argyleErrorLog = (
  message,
  { error = null, captureSentryException = false } = {},
) => {
  if (ARGYLE_DEBUG_LOGS_ENABLED === true) {
    if (error != null) {
      /* eslint-disable-next-line no-console */
      console.error(message, error);
    } else {
      /* eslint-disable-next-line no-console */
      console.error(message);
    }
  }

  if (captureSentryException) {
    if (error instanceof Error) {
      Sentry.captureException(error, SENTRY_CONTEXT);
    } else if (message != null) {
      Sentry.captureMessage(message, SENTRY_CONTEXT);
    }
  }
};

const useArgyleUpdatedFlowFlag = () => {
  const [flagState, setFlagState] = useState({ flag: {}, loading: true });

  useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      setFlagState(prevState => ({
        ...prevState,
        loading: true,
      }));

      let response;
      try {
        response = await fetchFlag(
          ARGYLE_FLOW_ID_CONTROLLER_FLAGR_KEY,
          {
            context: {
              env: ENV,
            },
          },
          {
            url: FLAGR_URL,
          },
        );
      } catch (e) {
        response = {};
      }

      if (mounted) {
        setFlagState({
          flag: response,
          loading: false,
        });
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, []);

  return flagState;
};

const useArgyleFlowId = maximumAllowedEmployers => {
  const { flag, loading } = useArgyleUpdatedFlowFlag();
  const [flowId, setFlowId] = useState();

  useEffect(() => {
    if (!loading && flag) {
      const newFlowId =
        maximumAllowedEmployers === 1
          ? // eslint-disable-next-line camelcase
            flag.variantAttachment?.argyle_flow_id_single ||
            ARGYLE_FLOW_ID_SINGLE
          : // eslint-disable-next-line camelcase
            flag.variantAttachment?.argyle_flow_id_multiple ||
            ARGYLE_FLOW_ID_MULTIPLE;

      setFlowId(newFlowId);
    }
  }, [flag, loading, maximumAllowedEmployers]);

  return flowId;
};

export {
  hasUnallocatedPeriods,
  hasAllJobsOutOfDateRange,
  jobIsOutOfLookback,
  employerTitleGenerator,
  getEmploymentKeyPart,
  argyleDebugLog,
  argyleErrorLog,
  useArgyleFlowId,
};
