import { I18n } from 'react-redux-i18n';

const groupClass = (touched, error) => {
  let classStr = '';

  if (touched) {
    classStr = 'has-success';
  }

  if (touched && error) {
    classStr = 'has-danger';
  }

  return `form-group ${classStr}`;
};

const removeField = fields => {
  return i => {
    fields.remove(i);
  };
};

const appendField = fields => {
  return () => fields.push({});
};

// custom i18n text for upload popup
const initUploadI18n = ({ isMobileClient }) => {
  return {
    'Select Files to Upload': isMobileClient.any()
      ? I18n.t('components.upload.tapToSelectFiles')
      : I18n.t('components.upload.clickToSelectFiles'),
    'or Drag and Drop, Copy and Paste Files': I18n.t(
      'components.upload.dragAndDrop',
    ),
    'Selected Files': I18n.t('components.upload.selectedFiles'),
    Upload: I18n.t('components.upload.upload'),
    'Deselect All': I18n.t('components.upload.deselectAll'),
    Images: I18n.t('components.upload.images'),
    Filter: I18n.t('components.upload.filter'),
    Files: I18n.t('components.upload.files'),
    'Upload more': I18n.t('components.upload.uploadMore'),
    'View/Edit Selected': I18n.t('components.upload.viewEditSelected'),
    Uploading: I18n.t('components.upload.uploading'),
    Uploaded: I18n.t('components.upload.uploaded'),
    Completed: I18n.t('components.upload.completed'),
    'File {displayName} is not an accepted file type. The accepted file types are {types}': I18n.t(
      'components.upload.fileError',
    ),
    'File {displayName} is too big. The accepted file size is less than {roundFileSize}': I18n.t(
      'components.upload.fileTooBig',
    ),
    'Search images': I18n.t('components.upload.searchImages'),
    'Select From': I18n.t('components.upload.selectFrom'),
    'more file': I18n.t('components.upload.moreFile'),
    'more files': I18n.t('components.upload.moreFiles'),
    'This folder is empty.': I18n.t('components.upload.thisFolderIsEmpty'),
    'This image cannot be edited': I18n.t(
      'components.upload.thisImageCannotBeEdited',
    ),
    'Our file upload limit is {maxFiles} {filesText}': I18n.t(
      'components.upload.ourFileUploadLimit',
    ),
    'No search results found for "{search}"': I18n.t(
      'components.upload.noSearchResults',
    ),
    Next: I18n.t('components.upload.next'),
    'Drop your files anywhere': I18n.t(
      'components.upload.dropYourFilesAnywhere',
    ),
    Done: I18n.t('components.upload.done'),
    'Failed While Uploading': I18n.t('components.upload.failedWhileUploading'),
    Add: I18n.t('components.upload.add'),
    'An error occurred. Please try again.': I18n.t(
      'components.upload.AnErrorOccurred',
    ),
    Reset: I18n.t('components.upload.reset'),
    'Go back': I18n.t('components.upload.goBack'),
  };
};

export { appendField, removeField, groupClass, initUploadI18n };
