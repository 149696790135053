/* eslint-disable react/require-default-props */
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import {
  generateCandidateAndDataRequest,
  addMeasureOneData,
  storeBufferMeasureOneData,
} from '../../../actions/measureOne';
import openMeasureOne, {
  getLocalizedLongMonthYearEducation,
} from '../../../lib/measureOne';
import LearnMoreModal from './LearnMoreModal';
import ResultsModal from './ResultsModal';
import SchoolUnsupportedModal from './SchoolUnsupportedModal';
import AcceptedResultsTable from './AcceptedResultsTable';
import MeasureOneEntryPoint from './MeasureOneEntryPoint';
import MeasureOneReviewPollingBody from './MeasureOneReviewPollingBody';
import TimerExpired from './TimerExpired';
import ModalTitle from './ModalTitle';

const InstantEducation = ({
  generateCandidateAndDataRequestAction,
  addMeasureOneDataAction,
  bufferMeasureOneData,
  storeBufferMeasureOneDataAction,
  invitationToken,
  selectedCountry,
  currentSchool = {},
  errorOccurredFunc,
}) => {
  const [showLearnMoreModal, setShowLearnMoreModal] = useState(false);
  const [showResultsModal, setShowResultsModal] = useState(false);
  const [showSchoolUnsupportedModal, setShowSchoolUnsupportedModal] = useState(
    false,
  );

  const [isWaiting, setIsWaiting] = useState(true);
  const [timerExpired, setTimerExpired] = useState(false);
  const [isResultAccepted, setIsResultAccepted] = useState(false);

  const [measureOneMetadata, setMeasureOneMetadata] = useState({
    accessToken: null,
    requestId: null,
  });

  const [candidateSchool, setCandidateSchool] = useState({});

  useEffect(() => {
    if (bufferMeasureOneData) {
      setIsWaiting(false);

      const cs = bufferMeasureOneData;
      setCandidateSchool({
        name: cs.name,
        address: cs.address,
        city: cs.city,
        state: cs.state,
        zipcode: cs.zipcode,
        phone: '1231231234',
        country: selectedCountry,
        start_date: getLocalizedLongMonthYearEducation(cs.startDate),
        end_date: getLocalizedLongMonthYearEducation(cs.endDate),
        year_awarded: cs.yearAwarded,
        degree: cs.degree,
        major: cs.major,
        provider: cs.provider,
        provider_id: cs.providerId,
      });
    }
  }, [bufferMeasureOneData, selectedCountry]);

  const countdowntimerExpiredFunction = useCallback(() => {
    setTimerExpired(true);
    // cancel listenning to checkr realtime and close connection
  }, [setTimerExpired]);

  const acceptAndSaveM1ScreeningResults = () => {
    setIsResultAccepted(true);
    addMeasureOneDataAction(candidateSchool);
    storeBufferMeasureOneDataAction(null);
  };

  useEffect(() => {
    if (currentSchool?.provider === 'MeasureOne') {
      setIsResultAccepted(true);
      setCandidateSchool(currentSchool);
    }
  }, [currentSchool]);

  useEffect(() => {
    async function generateData() {
      const [
        error,
        accessTkn,
        measureOneDataReqID,
      ] = await generateCandidateAndDataRequestAction();
      if (error) {
        errorOccurredFunc(
          i18n.getStr(
            'i18nInternational.educationField.measureOne.errorMessageSwitchToManual',
          ),
        );
      }

      if (accessTkn && measureOneDataReqID) {
        setMeasureOneMetadata({
          accessToken: accessTkn,
          requestId: measureOneDataReqID,
        });
      }
    }
    if (!(measureOneMetadata.accessToken && measureOneMetadata.requestId)) {
      generateData();
    }
  }, [
    measureOneMetadata.accessToken,
    errorOccurredFunc,
    generateCandidateAndDataRequestAction,
    measureOneMetadata.requestId,
  ]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const m1Widget = document.querySelector('m1-link');

    function timeout(delay) {
      return new Promise(res => setTimeout(res, delay));
    }

    const handleM1DatasourceConnected = async event => {
      setShowResultsModal(true);
      await timeout(5000);

      m1Widget.parentNode.removeChild(m1Widget);
    };

    const handleM1DatasourceNotSupported = async event => {
      setShowSchoolUnsupportedModal(true);
      m1Widget.parentNode.removeChild(m1Widget);
    };

    const handleM1CredentialsNotObtained = async event => {
      await timeout(5000);
      m1Widget.parentNode.removeChild(m1Widget);
      errorOccurredFunc(
        i18n.getStr(
          'i18nInternational.educationField.measureOne.m1CredsNotSupportedMessageSwitchToManual',
        ),
      );
    };

    const handleM1ExitRequested = async event => {
      await timeout(5000);
      m1Widget.parentNode.removeChild(m1Widget);
      errorOccurredFunc(
        i18n.getStr(
          'i18nInternational.educationField.measureOne.m1ExitMessageSwitchToManual',
        ),
      );
    };

    const handleTokenExpired = async event => {
      m1Widget.parentNode.removeChild(m1Widget);
    };

    const eventHandlerMapping = {
      datasourceConnected: handleM1DatasourceConnected,
      datasourceNotSupported: handleM1DatasourceNotSupported,
      credentialsNotObtained: handleM1CredentialsNotObtained,
      exitRequested: handleM1ExitRequested,
      tokenExpired: handleTokenExpired,
    };

    if (m1Widget) {
      Object.entries(eventHandlerMapping).forEach(([event, handler]) =>
        m1Widget.addEventListener(event, handler),
      );
    }
    return () => {
      if (m1Widget) {
        Object.entries(eventHandlerMapping).forEach(([event, handler]) =>
          m1Widget.removeEventListener(event, handler),
        );
      }
    };
  }, [errorOccurredFunc, invitationToken]);

  return (
    <>
      <div className='InstantEducation education-item-border'>
        {!isResultAccepted && (
          <MeasureOneEntryPoint
            setShowLearnMoreModal={() => setShowLearnMoreModal(true)}
            openMeasureOne={() =>
              openMeasureOne(
                measureOneMetadata.accessToken,
                measureOneMetadata.requestId,
              )
            }
          />
        )}
        {isResultAccepted && (
          <AcceptedResultsTable candidateSchool={candidateSchool} />
        )}

        <LearnMoreModal
          open={showLearnMoreModal}
          onClose={() => setShowLearnMoreModal(false)}
        />

        <SchoolUnsupportedModal
          open={showSchoolUnsupportedModal}
          onClose={() => setShowSchoolUnsupportedModal(false)}
          onError={() => errorOccurredFunc('')}
        />
      </div>

      <M.ComposedModal
        open={showResultsModal}
        onClose={() => setShowResultsModal(false)}
      >
        <M.ModalHeader closeModal={() => setShowResultsModal(false)}>
          <ModalTitle timerExpired={timerExpired} />
        </M.ModalHeader>
        <M.ModalBody>
          {isWaiting && !timerExpired && (
            <MeasureOneReviewPollingBody
              storeBufferMeasureOneDataAction={storeBufferMeasureOneDataAction}
              invitationToken={invitationToken}
              measureOneDataRequestID={measureOneMetadata.requestId}
              onCountdownExpired={countdowntimerExpiredFunction}
              onError={() => errorOccurredFunc('')}
            />
          )}

          {!isWaiting && !timerExpired && (
            <ResultsModal
              candidateSchool={candidateSchool}
              open={showResultsModal}
              onClose={() => setShowResultsModal(false)}
              onAccept={acceptAndSaveM1ScreeningResults}
              onError={() => errorOccurredFunc('')}
            />
          )}

          {timerExpired && (
            <TimerExpired onError={() => errorOccurredFunc('')} />
          )}
        </M.ModalBody>
      </M.ComposedModal>
    </>
  );
};

InstantEducation.propTypes = {
  generateCandidateAndDataRequestAction: PropTypes.func,
  addMeasureOneDataAction: PropTypes.func,
  bufferMeasureOneData: PropTypes.shape({}),
  storeBufferMeasureOneDataAction: PropTypes.func,
  invitationToken: PropTypes.string,
  selectedCountry: PropTypes.string.isRequired,
  currentSchool: PropTypes.object,
  errorOccurredFunc: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    invitationToken: state?.form?.applyform?.values?.token,
    bufferMeasureOneData: state?.form?.intlForm?.bufferMeasureOneData,
  };
};

export default connect(mapStateToProps, {
  generateCandidateAndDataRequestAction: generateCandidateAndDataRequest,
  addMeasureOneDataAction: addMeasureOneData,
  storeBufferMeasureOneDataAction: storeBufferMeasureOneData,
})(InstantEducation);
