import React from 'react';

const Break = () => {
  return (
    <div className='break'>
      <hr className='break-line' />
    </div>
  );
};

export default Break;
