export const extractRadioFields = (
  requiredFields: Record<string, any>
): {
  isolatedRequirements: any,
  radioRequirements: any
} => {
  const radioRequirements = Object.entries(requiredFields).reduce((acc, [key, value]) => {
    if (isRadioFormat(value)) {
      return { ...acc, ...extractRadioCases(requiredFields, key) };
    }
    return acc;
  }, {});

  const radioKeys = Object.values(radioRequirements)?.[0] || {};
  const isolatedRequirements = subtractFields(requiredFields, radioKeys);

  return { isolatedRequirements, radioRequirements };
};

const subtractFields = (requiredFields: any, radioFields: any): any => {
  return Object.keys(requiredFields)
    .filter(key => !(key in radioFields))
    .reduce((acc, key) => {
      acc[key] = requiredFields[key];
      return acc;
    }, {} as any);
};

const isRadioFormat = (value: any): boolean => {
  return value && value.format === 'radio';
};

const extractRadioCases = (
  requiredFields: any,
  key: any
): any => {
  const extendedArrayValues = requiredFields[key]?.extended_configuration?.array_values || [];
  const content = { [key]: requiredFields[key] };

  extendedArrayValues.forEach((item: any) => {
    const radioCaseId = item?.id?.toLowerCase();
    if (radioCaseId && radioCaseId in requiredFields) {
      content[radioCaseId] = requiredFields[radioCaseId];
    }
  });
  
  return { [key]: content };
};
