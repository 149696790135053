import React, { Component } from 'react';
import { Translate } from 'react-redux-i18n';
import PropTypes from 'prop-types';

class OverridableTranslate extends Component {
  static propTypes = {
    labelOptions: PropTypes.object,
    value: PropTypes.string.isRequired,
    overrides: PropTypes.object,
    dangerousHTML: PropTypes.bool,
  };

  static defaultProps = {
    labelOptions: {},
    overrides: {},
    dangerousHTML: false,
  };

  render() {
    const { value, overrides, labelOptions, dangerousHTML } = this.props;

    if (overrides[value] != null) {
      return overrides[value];
    }

    if (dangerousHTML) {
      return <Translate value={value} {...labelOptions} dangerousHTML />;
    }

    return <Translate value={value} {...labelOptions} />;
  }
}

export default OverridableTranslate;
