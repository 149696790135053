import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { Checkbox } from '../fields';

const NoHistoryPanel = ({ onChange }) => (
  <div className='alert alert-info' role='alert'>
    <Field
      type='checkbox'
      name='noEducationHistory'
      label='labels.noEducationHistory'
      className='no-bottom-margin'
      component={Checkbox}
      onChange={onChange}
      boldLabel
    />
  </div>
);

NoHistoryPanel.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default NoHistoryPanel;
