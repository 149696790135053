/* eslint-disable react/require-default-props */
import { replace } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-redux-i18n';

const Label = ({ label, htmlFor, boldLabel, labelAsRequired }) => {
  if (!label) {
    return null;
  }

  return (
    // eslint-disable-next-line jsx-a11y/label-has-for
    <label
      htmlFor={replace(htmlFor, '.', '-')}
      className={`form-control-label ${boldLabel ? 'font-weight-bold' : ''}`}
    >
      <Translate value={label} dangerousHTML />{' '}
      {labelAsRequired && <Translate value='labels.required' />}
    </label>
  );
};

Label.propTypes = {
  label: PropTypes.string,
  labelAsRequired: PropTypes.bool,
  htmlFor: PropTypes.string,
  boldLabel: PropTypes.bool,
};

export default Label;
