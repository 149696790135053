import React from 'react';
import { Field } from 'redux-form';
import { Translate as T } from 'react-redux-i18n';

import Panel from './Panel';

import { Select, SensitiveInput, Upload } from './fields';

import * as N from '../lib/normalizations';
import * as O from '../lib/options';
import * as V from '../lib/validations';

const PassportForm = () => (
  <Panel title='components.PassportForm.title' icon='fa-plane'>
    <p className='alert-full-name' role='alert'>
      <i className='fa fa-exclamation-circle' />{' '}
      <T value='components.PassportForm.alert' dangerousHTML />
    </p>
    <div className='row'>
      <div className='col-md-4'>
        <Field
          type='select'
          name='passportCountry'
          label='labels.passportCountry'
          component={Select}
          validate={[V.required]}
          options={O.countries}
        />
      </div>

      <div className='col-md-4'>
        <Field
          type='text'
          name='passportNumber'
          label='labels.passportNumber'
          component={SensitiveInput}
          normalize={N.upper}
          validate={[V.required]}
        />
      </div>

      <div className='col-md-4'>
        <Field
          type='upload'
          name='passport'
          label='labels.passportImage'
          component={Upload}
          options={{ maxFiles: 1, minFiles: 1 }}
          validate={V.required1Document}
        />
      </div>
    </div>
  </Panel>
);

export default PassportForm;
