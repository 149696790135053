import { M } from '@dashboard-experience/mastodon';

import React from 'react';

import { merge } from 'lodash';

import {
  PassThroughProps,
  PassThroughPropsGenerator,
  FieldGenerator,
  FieldArrayGenerator,
  PassThroughPropsMap,
} from './Field.types';

export type RenderableElementOrArray = JSX.Element | JSX.Element[];
export type RenderableElementCreator =
  | RelevantFieldCreator
  | RelevantComplexFieldCreator;
export type PropsOrGen = PassThroughProps | PassThroughPropsGenerator;
export type PropsMapOrGen = PassThroughPropsMap | PassThroughPropsGenerator;

export interface Renderable<T> {
  render(v: T | PassThroughPropsGenerator): RenderableElementOrArray;
  extendProps(v: T | PassThroughPropsGenerator): Renderable<T>;
}

function makeArray<T>(elem: T | T[] = []) {
  if (Array.isArray(elem)) {
    return elem;
  }
  return [elem];
}

type editors = {
  afterEach?: (i: number) => JSX.Element;
  separator?: (i: number) => JSX.Element;
};

export function renderRFCtoArrayList<T>(
  fields: Renderable<T>[],
  propsGenerator: PassThroughPropsGenerator,
  editors: editors,
): JSX.Element[] {
  return fields.flatMap((field, i, col) => {
    const toRender = makeArray(field.render(propsGenerator));
    if (editors.afterEach) {
      toRender.push(editors.afterEach(i));
    }
    if (editors.separator && i < col.length - 1) {
      toRender.push(editors.separator(i));
    }

    return toRender;
  });
}

export function containerizeRFC<T>(
  fields: Renderable<T>[],
  propsGenerator: PassThroughPropsGenerator,
  editors: editors,
): JSX.Element {
  return M.Container.buildSubContents(
    renderRFCtoArrayList(fields, propsGenerator, editors),
  );
}

function extendProps(
  currentProps: PassThroughProps,
  propsToAdd?: PassThroughProps,
) {
  let finalProps: PassThroughProps | undefined;

  if (propsToAdd) {
    // Special logic for validate, as merge will not concat validation functions
    const newValidate = makeArray(currentProps.validate).concat(
      makeArray(propsToAdd.validate),
    );

    finalProps = merge({ ...currentProps }, { ...propsToAdd });
    finalProps.validate = newValidate;
  }

  return finalProps || currentProps;
}

export class RelevantFieldCreator implements Renderable<PassThroughProps> {
  static fromGenerator(id: string, fieldGenerator: FieldGenerator) {
    return new RelevantFieldCreator(id, fieldGenerator);
  }

  constructor(
    public id: string,
    private fieldGenerator: FieldGenerator,
    private props: PassThroughProps = {}, // eslint-disable-next-line no-empty-function
  ) {}

  extendProps(propOrGen: PropsOrGen) {
    const propsToAdd =
      typeof propOrGen === 'function' ? propOrGen(this.id) : propOrGen;

    const finalProps = extendProps(this.props, propsToAdd);

    return new RelevantFieldCreator(this.id, this.fieldGenerator, finalProps);
  }

  render(propOrGen?: PropsOrGen): JSX.Element {
    if (propOrGen) {
      return this.extendProps(propOrGen).render();
    }
    return (
      <>
        {this.fieldGenerator(this.props)}
        {/* Somehow, removing the line below makes
        the Canada container on the Information page render as a blank div */}
        {this.id !== 'address' && (
          <span>{/* id: {this.id} props: {JSON.stringify(this.props)} */}</span>
        )}
      </>
    );
  }
}

export class RelevantComplexFieldCreator
  implements Renderable<PassThroughPropsMap> {
  static fromComplexGenerator(
    id: string,
    fieldIds: string[],
    fieldGenerator: FieldArrayGenerator,
    description?: string,
  ) {
    return new RelevantComplexFieldCreator(
      id,
      fieldIds,
      fieldGenerator,
      description,
    );
  }

  propsByField: PassThroughPropsMap = new Map();

  constructor(
    public id: string,
    fieldIdsOrProps: string[] | PassThroughPropsMap,
    private fieldGenerator: FieldArrayGenerator,
    public description?: string,
  ) {
    if (Array.isArray(fieldIdsOrProps)) {
      // Every field we're managing in the ComplexFieldCreator should be initialized
      fieldIdsOrProps.forEach(fieldId => {
        this.propsByField.set(fieldId, {});
      });
    } else {
      // Or copied over from the previous iteration
      this.propsByField = new Map(fieldIdsOrProps);
    }
  }

  extendProps(propsOrGen: PropsMapOrGen) {
    if (typeof propsOrGen === 'function') {
      // If we have a PropGenerator we want to extend the properties for each fieldId by the generator
      this.propsByField.forEach((currentProps, fieldId) => {
        this.propsByField.set(
          fieldId,
          extendProps(currentProps, propsOrGen(fieldId)),
        );
      });
    } else {
      // Otherwise we extend the props passed through the map
      this.propsByField.forEach((currentProps, fieldId) => {
        if (!(propsOrGen instanceof Map)) {
          // eslint-disable-next-line no-console
          console.error("This prop isn't getting added correctly", propsOrGen);
        } else {
          const propsToAdd = propsOrGen.get(fieldId);
          this.propsByField.set(fieldId, extendProps(currentProps, propsToAdd));
        }
      });
    }

    return new RelevantComplexFieldCreator(
      this.id,
      this.propsByField,
      this.fieldGenerator,
      this.description,
    );
  }

  render(propsOrGen?: PropsMapOrGen): JSX.Element[] {
    if (propsOrGen) {
      return this.extendProps(propsOrGen).render();
    }

    const elemsToRender: JSX.Element[] = [];
    if (this.id || this.description) {
      elemsToRender.push(
        <>
          {this.id && !['Previous names', 'Conviction'].includes(this.id) && (
            <span>{this.id}</span>
          )}
          {this.description && <p>{this.description}</p>}
        </>,
      );
    }
    return elemsToRender.concat(this.fieldGenerator(this.propsByField));
  }
}
