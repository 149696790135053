import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';

function TimerExpired({ onError }) {
  return (
    <div>
      <div className='row'>
        <div className='col-md-12'>
          <h2>
            {i18n.getStr(
              'i18nInternational.educationField.measureOne.m1TimerExpiredModal.timerExpiredMessageTitle',
            )}
          </h2>
        </div>
        <div className='col-md-12 m1-waiting-results-modal-description-padding'>
          <span className='m1-learn-more-modal-span'>
            {i18n.getStr(
              'i18nInternational.educationField.measureOne.m1TimerExpiredModal.timerExpiredDescription',
            )}
          </span>
        </div>
      </div>
      <div className='row m1-cancel-and-enter-info-button'>
        <M.Button
          className='secondary'
          onClick={() => {
            onError();
          }}
        >
          {i18n.getStr(
            'i18nInternational.educationField.measureOne.m1TimerExpiredModal.enterInformationButton',
          )}
        </M.Button>
      </div>
    </div>
  );
}

TimerExpired.propTypes = {
  onError: PropTypes.func.isRequired,
};

export default TimerExpired;
