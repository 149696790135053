import { forEach, uniq, Dictionary } from 'lodash';
import { concat, merge, isEmpty, reduce } from 'lodash/fp';

import {
  PathManager,
  FirstFieldManager,
  extractDocuments,
  removeSortAndReplaceAtFront,
  setSchemaFieldObjects,
  buildComplexRequirements,
  extractAddressRegionalProperties,
} from './Generator.utils';

import {
  actualForEachFieldCB,
  ApplyForm,
  DocTreesToRender,
  FieldsByRegionalScreening,
  forEachFieldCB,
  FormRegionsToRender,
  FormToRender,
} from './Form.types';

import {
  JsonSchema,
  JsonSchemaProperty,
  SCOPED_REQUIREMENTS,
  JsonSchemaDefinition,
  DOC_SCREENING,
  GLOBAL_REGION,
  GLOBAL_SCREENING,
  ManyOneOfs,
  INFORMATION,
} from '../types/schema.types';

import { RenderableSchemaProperty } from '../Fields/FieldCreationLogic/Field.types';

class ApplyFormGenerator {
  /*
    General Order:
    - Landing Page
    - Information
        - Documents, Global Requirements, Regional Requirements (alphabetical)
    - Disclosures
    - Authorization
  */

  /*
    If first appearance
      -> Render field
      if value in store
        -> populate field with value
    else
      -> do not

    global
    screenings alphabetically
      general
      regions alphabetically
  */

  constructor(public schema: JsonSchema) {
    // Comment so lint stops complaining
  }

  getApplyFormData = (firstPageIds?: string[]): ApplyForm => {
    const advRequirements = this.schema.allOf || [];

    const oneOfKeysToOptions: {
      [fieldId: string]: string[];
    } = {};

    const oneOfKeys: Dictionary<RenderableSchemaProperty> = {};

    forEach(advRequirements, requirement => {
      if (requirement.oneOf) {
        const oneOfs = reduce(
          (collection, { required }) => concat(collection, required),
          [] as string[],
          requirement.oneOf,
        );

        forEach(oneOfs, (fieldId, _, collection) => {
          oneOfKeysToOptions[fieldId] = collection;
        });
      }
    });

    const complexRequirements = buildComplexRequirements(advRequirements);

    const schemaAsRenderableFields: RenderableSchemaProperty = {
      properties: {},
    } as any;
    const setSchemaFieldObjectsIterator = setSchemaFieldObjects(
      schemaAsRenderableFields,
    );

    const fieldFirstAppears = new FirstFieldManager();

    // this region is artificial and needs to be added to add global screenings to "global region"
    const regionsInOrder = removeSortAndReplaceAtFront(
      this.getRegions(this.schema),
      GLOBAL_REGION,
    );

    const docTreesToRender: DocTreesToRender = {};
    const esignDocs: Dictionary<RenderableSchemaProperty> = {};
    const extractDocumentsIterator = extractDocuments(
      schemaAsRenderableFields,
      docTreesToRender,
      oneOfKeys,
      esignDocs,
    );

    // Iterate over every field and run logic:
    // - Map all documents to one dictionary for later compilation
    // - Convert all fields to UI-renderable objects
    this.forEachField(this.schema, path => {
      setSchemaFieldObjectsIterator(path);
    });

    this.actualForEach(schemaAsRenderableFields, prop => {
      const { path } = prop;
      const oneOfs =
        oneOfKeysToOptions[path.key] ||
        oneOfKeysToOptions[path.formPathToParent];
      if (oneOfs) {
        path.setOneOfs(oneOfs);
        fieldFirstAppears.set(prop, 'documents', 'oneOfs');
      }
      extractDocumentsIterator(prop);
    });

    Object.keys(esignDocs).forEach(key => {
      const doc = esignDocs[key];
      fieldFirstAppears.set(doc, 'consent_forms', '');
    });

    const scopedCountries =
      // eslint-disable-next-line camelcase
      schemaAsRenderableFields?.properties?.scoped_requirements;

    const screenings = [DOC_SCREENING, GLOBAL_SCREENING];
    let screeningsInOrder = uniq(
      removeSortAndReplaceAtFront(
        this.getScreenings(this.schema),
        scopedCountries ? screenings.concat(SCOPED_REQUIREMENTS) : screenings,
      ),
    );

    const fields = this.getFields(schemaAsRenderableFields);

    const fieldsByRegionalScreening = this.mapFieldsToRegionalScreenings(
      screeningsInOrder,
      regionsInOrder,
      fields,
      schemaAsRenderableFields,
    );

    // Generate fieldFirstAppears
    // Also add documents to proper location
    screeningsInOrder.forEach(screening => {
      regionsInOrder.forEach(region => {
        fields.forEach(field => {
          // If field has not yet appeared
          if (!fieldFirstAppears.has(field)) {
            // And it is defined at this step
            const newField =
              fieldsByRegionalScreening[screening][region][
                field.path.getCombinedPath.schema()
              ];
            if (newField?.path?.isField) {
              if (docTreesToRender[newField.path.getCombinedPath.schema()]) {
                fieldsByRegionalScreening[DOC_SCREENING][region][
                  field.path.getCombinedPath.schema()
                ] = newField;
                fieldFirstAppears.set(field, DOC_SCREENING, region);
              } else {
                fieldFirstAppears.set(field, screening, region);
              }
            }
          }
        });
      });
    });

    const applyForm: FormToRender = { screenings: {} };

    screeningsInOrder.forEach(screening => {
      const regionsForScreening: FormRegionsToRender = {};

      // If a region has fields to render for a screening, add only those to an intermediary object
      regionsInOrder.forEach(region => {
        const fieldsForRegion: {
          [field: string]: RenderableSchemaProperty;
        } = {};

        fields.forEach(fieldInfo => {
          if (
            fieldFirstAppears.shouldRenderField(fieldInfo, screening, region)
          ) {
            fieldsForRegion[fieldInfo.path.getCombinedPath.schema()] =
              fieldsByRegionalScreening[screening][region][
                fieldInfo.path.getCombinedPath.schema()
              ];
          }
        });

        if (Object.keys(fieldsForRegion).length) {
          regionsForScreening[region] = { fields: fieldsForRegion };
        }
      });

      if (Object.keys(regionsForScreening).length) {
        applyForm.screenings[screening] = {
          regions: regionsForScreening,
        };
      }
    });

    // start: consolidate multiple screening steps into one
    screeningsInOrder.unshift(INFORMATION);

    ['documents', 'criminal', 'global', 'scoped_requirements'].forEach(
      screening => {
        applyForm.screenings.information = merge(
          applyForm.screenings.information,
          applyForm.screenings[screening],
        );
        delete applyForm.screenings[screening];

        screeningsInOrder = screeningsInOrder.filter(
          name => name !== screening,
        );
      },
    );

    if (isEmpty(applyForm.screenings.information)) {
      delete applyForm.screenings.information;
    }
    // end: consolidate multiple screening steps into one

    return {
      // Global screenings are always first
      screeningsInOrder,
      regionsInOrder,
      fields,
      fieldFirstAppears,
      fieldsByRegionalScreening,
      applyForm,
      fieldMap: schemaAsRenderableFields.properties || {},
      required: this.getRequirementsSet(this.schema),
      schemaAsRenderableFields,
      oneOfKeys,
      esignDocs,
      complexRequirements,
    };
  };

  mapFieldsToRegionalScreenings = (
    screenings: string[],
    regions: string[],
    fields: RenderableSchemaProperty[],
    schemaAsRenderableFields: RenderableSchemaProperty,
  ) => {
    const fieldsByRegionalScreening: FieldsByRegionalScreening = {} as any;

    screenings.forEach(screening => {
      fieldsByRegionalScreening[screening] = {
        // Each screening has a 'general' region to map fields that multiple regions request
        [GLOBAL_REGION]: {},
      };
      regions.forEach(region => {
        fieldsByRegionalScreening[screening][region] = {};
      });
    });

    // populate fieldsByRegionalScreening
    fields
      .map(
        field => (schemaAsRenderableFields?.properties || {})[field.path.key],
      )
      .filter(Boolean)
      .forEach(renderableProp => {
        const relevantRegions: { [screening: string]: string[] } = {};
        // Iterate over the source for each field
        renderableProp?.sources?.forEach(source => {
          if (!Array.isArray(relevantRegions[source.screening_type])) {
            // Create empty data set if the region doesn't have a screening type mapping
            relevantRegions[source.screening_type] = [];
          }
          // Add country or name to Region's screening info
          relevantRegions[source.screening_type].push(
            // This will generally be a 2-Alpha code, unless it's 'Global'
            source.region.country || source.region.name,
          );
        });

        renderableProp?.sources?.forEach(source => {
          const screening = source.screening_type;
          // If there are more than 1 relevant regions for a given screening we want to put
          // it in the general section. Country-specific requirements are listed under that country.
          const region =
            relevantRegions[screening].length > 1
              ? GLOBAL_REGION
              : relevantRegions[screening][0];

          // Map the field to the data structure by its name
          fieldsByRegionalScreening[screening][region][
            renderableProp.path.getCombinedPath.schema()
          ] = renderableProp;
        });

        if (renderableProp?.sources == null) {
          // TODO: How should we handle fields without sources?
          // They should probably be "additional"
          fieldsByRegionalScreening[GLOBAL_SCREENING][GLOBAL_REGION][
            renderableProp.path.getCombinedPath.schema()
          ] = renderableProp;
        }
      });

    this.iterateScopedRequirements(
      schemaAsRenderableFields,
      (alpha2Code, key, prop) => {
        fieldsByRegionalScreening[SCOPED_REQUIREMENTS][alpha2Code][
          prop.path.getCombinedPath.schema()
        ] = prop;
      },
    );
    return fieldsByRegionalScreening;
  };

  getFields = (
    schema: RenderableSchemaProperty,
  ): RenderableSchemaProperty[] => {
    let fields: RenderableSchemaProperty[] = [];
    if (schema.properties) {
      const props = schema.properties;
      fields = Object.keys(props)
        .filter(
          // Scoped_requirements do not follow normal property rules and are handled separately
          key => key !== SCOPED_REQUIREMENTS,
        )
        .map(key => props[key]);

      this.iterateScopedRequirements(schema, (_, key, prop) => {
        fields.push(prop);
      });
    }
    return fields;
  };

  actualForEach = (
    prop: RenderableSchemaProperty,
    cb: actualForEachFieldCB,
  ) => {
    const recurOnProperty = (
      field: RenderableSchemaProperty,
      lastProp?: RenderableSchemaProperty,
    ) => {
      // iterate to next level and build next set of paths
      if (field?.items) {
        // Add items to the path so any future CBs know the path
        cb(field.items);
        recurOnProperty(field.items);
      }

      if (field?.properties) {
        // Every time we iterate on properties we add properties the layer
        const props = field.properties;
        Object.keys(props).forEach(subFieldId => {
          const nextProp = props[subFieldId];
          cb(nextProp);
          recurOnProperty(nextProp, lastProp);
        });
      }
    };

    recurOnProperty(prop);
  };

  forEachField = (schema: JsonSchema, cb: forEachFieldCB) => {
    const recurOnProperty = (
      field: JsonSchemaProperty | JsonSchemaDefinition | JsonSchema,
      path = new PathManager(),
      lastProp?: JsonSchemaProperty,
    ) => {
      // iterate to next level and build next set of paths
      const jsonProp = field as JsonSchemaProperty;
      if (jsonProp?.items) {
        // Add items to the path so any future CBs know the path
        const itemsProp = jsonProp.items;

        const pathToUse = PathManager.addItems(path, itemsProp);
        cb(pathToUse);
        const childPath = PathManager.makeChildPath(
          PathManager.addProperties(pathToUse, itemsProp.properties),
        );
        recurOnProperty(itemsProp, childPath);
      }

      if (field?.properties) {
        // Every time we iterate on properties we add properties the layer
        const pathToUse = PathManager.addProperties(path, field.properties);

        const requiredSet = new Set(field?.required);
        if (field?.allOf?.length) {
          let requirements: string[] = [];
          if ((field?.allOf as ManyOneOfs)[0].oneOf) {
            requirements = (field.allOf as ManyOneOfs).reduce(
              (allReq, req) =>
                allReq.concat(
                  ...req.oneOf.reduce(
                    (allSubReqs, subReq) => allSubReqs.concat(subReq.required),
                    [] as string[],
                  ),
                ),
              [] as string[],
            );
            requirements.forEach(req => requiredSet.add(req));
          }
          if ((field?.allOf[0] as JsonSchemaDefinition)?.required?.length) {
            requirements = (field.allOf as JsonSchemaDefinition[]).reduce(
              (allReq, req) => allReq.concat(req.required),
              [] as string[],
            );
            requirements.forEach(req => requiredSet.add(req));
          }
        }

        const props = field.properties || {};
        Object.keys(props).forEach(subFieldId => {
          const nextProp = props[subFieldId];
          const nextPath = PathManager.addField(
            pathToUse,
            subFieldId,
            nextProp,
          );
          if (requiredSet.has(nextPath.key)) {
            nextPath.isRequired = true;
          }

          cb(nextPath);
          recurOnProperty(nextProp, nextPath, lastProp);
        });
      }
    };

    recurOnProperty(schema);
  };

  getScreenings = (jsonSchema: JsonSchema): string[] =>
    jsonSchema.request.screening_types;

  getRegions = (jsonSchema: JsonSchema): string[] =>
    jsonSchema.request.regions.map(r => r.country);

  getRequirementsSet = (jsonSchema: JsonSchema): Set<string> => {
    const requirements = new Set<string>();
    (jsonSchema.required || []).forEach(requirement =>
      requirements.add(requirement),
    );
    return requirements;
  };

  iterateScopedRequirements = (
    schema: RenderableSchemaProperty,
    cb: (
      alpha2Code: string,
      key: string,
      prop: RenderableSchemaProperty,
    ) => void,
  ) => {
    const scopedCountries =
      // eslint-disable-next-line camelcase
      schema?.properties?.scoped_requirements;

    if (scopedCountries) {
      // generate key for redux store:
      const scopedCountryProps = scopedCountries?.properties;
      if (scopedCountryProps) {
        Object.keys(scopedCountryProps).forEach(alpha2Code => {
          const propsForCountry = scopedCountryProps[alpha2Code]?.properties;
          if (propsForCountry) {
            Object.keys(propsForCountry).forEach(key => {
              const prop = propsForCountry[key];
              if (prop.path.isField) {
                cb(alpha2Code, key, prop);
              }
            });
          }
        });
      }
    }
  };

  getAdditionalInformation = () => {};

  getRights = () => {};

  getDisclosures = () => {};

  getAuthorizations = () => {};

  getAdditionalDocumentation = () => {};
}

export default ApplyFormGenerator;
