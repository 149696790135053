import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Translate as T } from 'react-redux-i18n';

import { M } from '@dashboard-experience/mastodon';
import { i18n, moment } from '@international/mastodon-i18n';

import { acceptArgyleEmployments } from '../../actions/employment/employmentActions';
import { ARGYLE_EMPLOYMENT_DATE_FORMAT } from '../../constants';
import { EMPLOYMENT_INPUT_MODE_MANUAL } from '../../lib/employment/constants';
import {
  argyleDebugLog,
  jobIsOutOfLookback,
} from '../../lib/employment/utils/employmentUtils';
import HTMLText from '../HTMLText';
import {
  EMPLOYMENT_ANALYTICS_EVENTS,
  EMPLOYMENT_ANALYTICS_PROPERTIES,
} from '../../lib/employment/analytics_events';

import ArgyleReviewManualEmployments from './ArgyleReviewManualEmployments';
import ArgyleReviewOutsideLookbackEmployments from './ArgyleReviewOutsideLookbackEmployments';
import ArgyleReviewPollingBody from './ArgyleReviewPollingBody';
import ArgyleReviewUnverifiedEmployments from './ArgyleReviewUnverifiedEmployments';
import ArgyleReviewVerifiedEmployments from './ArgyleReviewVerifiedEmployments';

const AddManuallyButton = onClick => {
  return (
    <M.Button onClick={onClick} kind='secondary' className='add-manually-btn'>
      {i18n.getStr(`components.EmploymentForm.ArgyleForm.addManuallyBtn`)}
    </M.Button>
  );
};

const ArgyleReviewModal = ({
  dispatch,
  argyleCurrentState,
  argyleConnectedAccountIds,
  argyleAccountEmployments,
  maximumAllowedEmployers,
  lookbackYears,
  employmentsOnForm,
  companyName,
  onClose,
  trackArgyleAnalyticsEvent,
}) => {
  const closeModalCancel = () => {
    trackArgyleAnalyticsEventOnCancel();
    onClose();
  };

  const closeModalAccept = () => {
    trackArgyleAnalyticsEventOnAccept();
    dispatch(
      acceptArgyleEmployments(
        selectedArgyleEmployments,
        selectedManualEmployments,
      ),
    );
    onClose();
  };

  const trackArgyleAnalyticsEventOnCancel = () => {
    trackArgyleAnalyticsEvent(
      EMPLOYMENT_ANALYTICS_EVENTS.ARGYLE_REVIEW_CANCELED,
      {
        [EMPLOYMENT_ANALYTICS_PROPERTIES.ARGYLE_VERIFIED_EMPLOYMENT_COUNT]:
          verifiedWithArgyleEmployments.length,
        [EMPLOYMENT_ANALYTICS_PROPERTIES.ARGYLE_UNVERIFIED_AND_OUTSIDE_LOOKBACK_EMPLOYMENT_COUNT]:
          unableToVerifyArgyleEmployments.length +
          outsideLookbackPeriodArgyleEmployments.length,
        [EMPLOYMENT_ANALYTICS_PROPERTIES.MAX_ALLOWED_EMPLOYER_COUNT]: maximumAllowedEmployers,
      },
    );
  };

  const trackArgyleAnalyticsEventOnAccept = () => {
    trackArgyleAnalyticsEvent(
      EMPLOYMENT_ANALYTICS_EVENTS.ARGYLE_REVIEW_ACCEPTED,
      {
        [EMPLOYMENT_ANALYTICS_PROPERTIES.ARGYLE_SELECTED_ARGYLE_EMPLOYMENT_COUNT]:
          selectedArgyleEmployments.length,
        [EMPLOYMENT_ANALYTICS_PROPERTIES.ARGYLE_SELECTED_MANUAL_EMPLOYMENT_COUNT]:
          selectedManualEmployments.length,
        [EMPLOYMENT_ANALYTICS_PROPERTIES.ARGYLE_UNVERIFIED_EMPLOYMENT_COUNT]:
          unableToVerifyArgyleEmployments.length,
        [EMPLOYMENT_ANALYTICS_PROPERTIES.ARGYLE_OUTSIDE_LOOKBACK_EMPLOYMENT_COUNT]:
          outsideLookbackPeriodArgyleEmployments.length,
        [EMPLOYMENT_ANALYTICS_PROPERTIES.MAX_ALLOWED_EMPLOYER_COUNT]: maximumAllowedEmployers,
        [EMPLOYMENT_ANALYTICS_PROPERTIES.ARGYLE_SELECTED_ARGYLE_EMPLOYER_NAMES]: selectedArgyleEmployments.map(
          emp => emp.name,
        ),
      },
    );
  };

  const allArgyleEmployments = _.orderBy(
    argyleAccountEmployments.map(account => account.argyleEmployments).flat(),
    [
      employment =>
        employment.endDate
          ? moment(employment.endDate, ARGYLE_EMPLOYMENT_DATE_FORMAT)
          : null,
      employment =>
        employment.startDate
          ? moment(employment.startDate, ARGYLE_EMPLOYMENT_DATE_FORMAT)
          : null,
    ],
    ['desc', 'desc'],
  );

  const unableToVerifyArgyleEmployments = allArgyleEmployments.filter(
    employment => employment.isVerified === false,
  );

  const outsideLookbackPeriodArgyleEmployments = allArgyleEmployments.filter(
    employment =>
      employment.isVerified === true &&
      jobIsOutOfLookback(lookbackYears, employment),
  );

  const verifiedWithArgyleEmployments = allArgyleEmployments.filter(
    employment =>
      employment.isVerified === true &&
      !jobIsOutOfLookback(lookbackYears, employment),
  );

  const manualEmployments = _.orderBy(
    employmentsOnForm.filter(
      employment => employment.inputMode === EMPLOYMENT_INPUT_MODE_MANUAL,
    ),
    [
      employment =>
        employment.endDate
          ? moment(employment.endDate, ARGYLE_EMPLOYMENT_DATE_FORMAT)
          : null,
      employment =>
        employment.startDate
          ? moment(employment.startDate, ARGYLE_EMPLOYMENT_DATE_FORMAT)
          : null,
      employment => employment.addedAt,
    ],
    ['desc', 'desc', 'desc'],
  );

  // Handles when the candidate selects an Argyle-discovered employment.
  // Is NOT invoked for pre-selected employments defined in Component State selectedArgyleEmploymentIds below.
  const handleArgyleEmploymentSelected = argyleEmployment => {
    setSelectedArgyleEmployments(
      Array.from(new Set([...selectedArgyleEmployments, argyleEmployment])),
    );
  };

  // Handles when the candidate unselects an Argyle-discovered employment.
  const handleArgyleEmploymentUnselected = argyleEmployment => {
    setSelectedArgyleEmployments(
      selectedArgyleEmployments.filter(
        selectedArgyleEmployment =>
          selectedArgyleEmployment.argyleEmploymentId !==
          argyleEmployment.argyleEmploymentId,
      ),
    );
  };

  // Handles when the candidate selects a manual employment.
  // Is NOT invoked for pre-selected employments defined in Component State selectedManualEmploymentIds below.
  const handleManualEmploymentSelected = manualEmployment => {
    setSelectedManualEmployments(
      Array.from(new Set([...selectedManualEmployments, manualEmployment])),
    );
  };

  // Handles when the candidate unselects a manual employment.
  const handleManualEmploymentUnselected = manualEmployment => {
    setSelectedManualEmployments(
      selectedManualEmployments.filter(
        selectedManualEmployment =>
          selectedManualEmployment.addedAt !== manualEmployment.addedAt,
      ),
    );
  };

  const computeArgylePreSelections = () => {
    // If any Argyle employments already exist on the form, select those.
    const selectedVerifiedArgyleEmployments = verifiedWithArgyleEmployments.filter(
      verifiedArgyleEmp =>
        employmentsOnForm.some(
          empOnForm =>
            empOnForm.argyleEmploymentId ===
            verifiedArgyleEmp.argyleEmploymentId,
        ),
    );

    // if the user has ever already chosen to select anything, we should remember those selections
    // (and for now, don't preselect anything new)
    if (selectedVerifiedArgyleEmployments.length > 0) {
      return selectedVerifiedArgyleEmployments;
    }

    // Otherwise pre-select the first N Verified Argyle employments, where N is maximumAllowedEmployers.
    // (or if maximumAllowedEmployers is null, preselect all verifiedWithArgyleEmployments)
    const maxNumToPreselect = maximumAllowedEmployers
      ? maximumAllowedEmployers - manualEmployments.length
      : verifiedWithArgyleEmployments.length;
    const numToPreselect = Math.min(
      maxNumToPreselect,
      verifiedWithArgyleEmployments.length,
    );

    const preselectedArgyleEmployments = _.take(
      verifiedWithArgyleEmployments,
      numToPreselect,
    );
    argyleDebugLog(
      `Preselecting ${numToPreselect} Argyle employments`,
      preselectedArgyleEmployments,
    );
    return preselectedArgyleEmployments;
  };

  const computeManualPreSelections = () => {
    // All manual employments are preselected because they're already included
    return manualEmployments;
  };

  const [selectedArgyleEmployments, setSelectedArgyleEmployments] = useState(
    [],
  );

  const [selectedManualEmployments, setSelectedManualEmployments] = useState(
    [],
  );

  // fired when argyleCurrentState changes to 'reviewing'
  useEffect(() => {
    if (argyleCurrentState === 'reviewing') {
      setSelectedManualEmployments(computeManualPreSelections());
      setSelectedArgyleEmployments(computeArgylePreSelections());
    }

    // Linter wants to include computeArgylePreSelections + setSelectedManualEmployments as a
    // dependency. Actually we only want to trigger this code when argyleCurrentState is changed
    // to "reviewing".
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [argyleCurrentState]);

  const noVerifiedArgyleSelected = () => {
    return selectedArgyleEmployments.length === 0;
  };

  const preExpandSomething =
    verifiedWithArgyleEmployments.length +
      manualEmployments.length +
      unableToVerifyArgyleEmployments.length +
      outsideLookbackPeriodArgyleEmployments.length ===
    1;

  return (
    <M.ComposedModal
      open
      onClose={closeModalCancel}
      preventCloseOnClickOutside
      size='sm'
      id='mastodon'
      className='argyle-review'
    >
      <M.ModalHeader className='modal-header' closeModal={closeModalCancel}>
        <h2>Review results</h2>
      </M.ModalHeader>

      {argyleCurrentState === 'polling' && (
        <React.Fragment>
          <ArgyleReviewPollingBody
            argyleConnectedAccountIds={argyleConnectedAccountIds}
            argyleAccountEmployments={argyleAccountEmployments}
            trackArgyleAnalyticsEvent={trackArgyleAnalyticsEvent}
          />
          <M.ModalFooter className='polling-modal-footer'>
            {AddManuallyButton(closeModalCancel)}
          </M.ModalFooter>
        </React.Fragment>
      )}

      {argyleCurrentState === 'polling-timeout' && (
        <>
          <M.ModalBody className='timeout-modal-body'>
            <h2>
              {i18n.getStr(
                `components.EmploymentForm.ArgyleForm.timeoutModal.headerText`,
              )}
            </h2>
            <HTMLText
              content={i18n.getStr(
                `components.EmploymentForm.ArgyleForm.timeoutModal.bodyText`,
              )}
            />
          </M.ModalBody>
          <M.ModalFooter className='polling-modal-footer'>
            {AddManuallyButton(closeModalCancel)}
          </M.ModalFooter>
        </>
      )}

      {argyleCurrentState === 'reviewing' && (
        <React.Fragment>
          <M.ModalBody>
            {verifiedWithArgyleEmployments.length > 0 && (
              <ArgyleReviewVerifiedEmployments
                maximumAllowedEmployers={maximumAllowedEmployers}
                verifiedWithArgyleEmployments={verifiedWithArgyleEmployments}
                selectedArgyleEmployments={selectedArgyleEmployments}
                numSelectedManualEmployments={selectedManualEmployments.length}
                isPreExpanded={preExpandSomething}
                companyName={companyName}
                handleArgyleEmploymentSelected={handleArgyleEmploymentSelected}
                handleArgyleEmploymentUnselected={
                  handleArgyleEmploymentUnselected
                }
              />
            )}

            {verifiedWithArgyleEmployments.length > 0 &&
              manualEmployments.length > 0 && (
                <ArgyleReviewManualEmployments
                  maximumAllowedEmployers={maximumAllowedEmployers}
                  manualEmployments={manualEmployments}
                  selectedManualEmployments={selectedManualEmployments}
                  numSelectedArgyleEmployments={
                    selectedArgyleEmployments.length
                  }
                  isPreExpanded={preExpandSomething}
                  handleManualEmploymentSelected={
                    handleManualEmploymentSelected
                  }
                  handleManualEmploymentUnselected={
                    handleManualEmploymentUnselected
                  }
                />
              )}

            {(unableToVerifyArgyleEmployments.length > 0 ||
              outsideLookbackPeriodArgyleEmployments.length > 0) && (
              <div className='review-unable-to-add'>
                <h3>
                  <T value='components.EmploymentForm.ArgyleForm.reviewComponents.unableToAddHeading' />
                </h3>

                {unableToVerifyArgyleEmployments.length > 0 && (
                  <ArgyleReviewUnverifiedEmployments
                    unverifiedArgyleEmployments={
                      unableToVerifyArgyleEmployments
                    }
                    isPreExpanded={preExpandSomething}
                  />
                )}

                {outsideLookbackPeriodArgyleEmployments.length > 0 && (
                  <ArgyleReviewOutsideLookbackEmployments
                    lookbackYears={lookbackYears}
                    outsideLookbackArgyleEmployments={
                      outsideLookbackPeriodArgyleEmployments
                    }
                    isPreExpanded={preExpandSomething}
                  />
                )}
              </div>
            )}
          </M.ModalBody>

          {verifiedWithArgyleEmployments.length > 0 && (
            <M.ModalFooter className='modal-footer'>
              <div className='row cancel-button'>
                <M.Button onClick={closeModalCancel} kind='secondary'>
                  <T value='components.EmploymentForm.ArgyleForm.reviewComponents.cancel' />
                </M.Button>
              </div>
              <div className='row accept-button'>
                <M.Button
                  onClick={closeModalAccept}
                  kind='primary'
                  disabled={noVerifiedArgyleSelected()}
                >
                  <T value='components.EmploymentForm.ArgyleForm.reviewComponents.accept' />
                </M.Button>
              </div>
            </M.ModalFooter>
          )}

          {verifiedWithArgyleEmployments.length === 0 && (
            <M.ModalFooter className='polling-modal-footer'>
              {AddManuallyButton(closeModalCancel)}
            </M.ModalFooter>
          )}
        </React.Fragment>
      )}
    </M.ComposedModal>
  );
};

ArgyleReviewModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  argyleCurrentState: PropTypes.oneOf([
    'polling',
    'polling-timeout',
    'reviewing',
  ]).isRequired,
  argyleConnectedAccountIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  argyleAccountEmployments: PropTypes.arrayOf(
    PropTypes.shape({
      argyleAccountId: PropTypes.string,
      argyleEmployments: PropTypes.arrayOf(PropTypes.object),
    }),
  ).isRequired,
  maximumAllowedEmployers: PropTypes.number,
  lookbackYears: PropTypes.number.isRequired,
  employmentsOnForm: PropTypes.arrayOf(PropTypes.object).isRequired,
  companyName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  trackArgyleAnalyticsEvent: PropTypes.func.isRequired,
};

ArgyleReviewModal.defaultProps = {
  maximumAllowedEmployers: null, // Unlimited max employers.
};

export default connect()(ArgyleReviewModal);
