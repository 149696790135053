const FULL_TIME = 'full_time';
const PART_TIME = 'part_time';
const CONTRACT = 'contract';
const INTERNSHIP = 'internship';
const SEASONAL = 'seasonal';
const SELF_EMPLOYED = 'self_employed';
const TEMPORARY = 'temporary';
const OTHER = 'other';

export default [
  { name: 'options.selectAContract', value: '', i18n: true },
  { name: 'options.contracts.fullTime', value: FULL_TIME, i18n: true },
  { name: 'options.contracts.partTime', value: PART_TIME, i18n: true },
  { name: 'options.contracts.contract', value: CONTRACT, i18n: true },
  { name: 'options.contracts.internship', value: INTERNSHIP, i18n: true },
  { name: 'options.contracts.seasonal', value: SEASONAL, i18n: true },
  { name: 'options.contracts.selfEmployed', value: SELF_EMPLOYED, i18n: true },
  { name: 'options.contracts.temporary', value: TEMPORARY, i18n: true },
  { name: 'options.contracts.other', value: OTHER, i18n: true },
];
export {
  FULL_TIME,
  PART_TIME,
  CONTRACT,
  INTERNSHIP,
  SEASONAL,
  SELF_EMPLOYED,
  TEMPORARY,
  OTHER,
};
