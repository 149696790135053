import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import { WrappedMCheckboxProps } from './WrappedComp.types';

type Props = {
  valueOnInit?: (val?: boolean) => void;
};
const ReduxMRadio: React.FunctionComponent<WrappedMCheckboxProps &
  Props> = props => {
  const {
    id,
    labelText,
    disabled,
    invalid,
    input,
    meta,
    defaultChecked,
    value,
    ...rest
  } = props as WrappedMCheckboxProps & {
    defaultChecked: boolean | undefined;
    value: boolean | undefined;
  };
  const { touched, error } = meta || {};
  const showInvalid = (touched && !!error) || invalid;

  return (
    <>
      <M.RadioButton
        {...rest}
        {...input}
        id={id}
        labelText={labelText || <span>&nbsp;</span>}
        disabled={disabled}
        className='radio-button'
        value={value}
        defaultChecked={defaultChecked}
      />
      {showInvalid && (
        <span
          style={{
            paddingLeft: '1.625rem',
            display: 'block',
            overflow: 'visible',
            maxHeight: '12.5rem',
            fontWeight: 'normal',
            color: colors.dangerRed100,
          }}
          className='bx--form-requirement'
        >
          {touched && error && i18n.getStr(error)}
        </span>
      )}
    </>
  );
};

export default ReduxMRadio;
