/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate as T } from 'react-redux-i18n';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { colors } from '@dashboard-experience/mastodon';
import BaseLayout from 'layouts/BaseLayout';

const StyledSuccessMessage = styled.h1`
  color: ${colors.aqua04};
  margin-bottom: 35px;
  font-family: 'Helvetica Neue';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
`;
class ParsSuccessPage extends Component {
  static propTypes = {
    reporterEmail: PropTypes.string.isRequired,
    employeeEmail: PropTypes.string,
  };

  render() {
    const { reporterEmail, employeeEmail } = this.props;
    const providedEmails = employeeEmail
      ? `${reporterEmail} & ${employeeEmail}`
      : `${reporterEmail}`;
    return (
      <BaseLayout header footer={[]} intro={null}>
        <StyledSuccessMessage id='thank-you'>
          <T value='parsSuccessPage.successFullySubmitted.header' />
        </StyledSuccessMessage>
        <T
          providedEmails={providedEmails}
          value='parsSuccessPage.successFullySubmitted.body'
          dangerousHTML
        />
      </BaseLayout>
    );
  }
}

const ConnectedParsSuccessPage = connect((state, props) => {
  const applyValues = state.form.applyform.values || {};
  return {
    reporterEmail: applyValues.reporterEmail,
    employeeEmail: applyValues.email,
    ...props,
  };
})(ParsSuccessPage);

export default ConnectedParsSuccessPage;
