import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import CountdownTimer from '../../../components/CountdownTimer';
import { MEASURE_ONE_WAIT_PERIOD } from '../../../constants';
import {
  loadMeasureOneCandidateSchools,
  measureOneDebugLog,
} from './instantEducationUtils';

const SECONDS = 1_000;
const POLL_INTERVAL = 5 * SECONDS;

const MeasureOneReviewPollingBody = ({
  storeBufferMeasureOneDataAction,
  invitationToken,
  measureOneDataRequestID,
  onCountdownExpired,
  onError,
}) => {
  const [pollCount, setPollCount] = useState(1);

  // This effect polls our backend for MeasureOne CandidateSchools.
  useEffect(() => {
    // for cleaning up an in-flight poll later if needed
    const abortController = new AbortController();

    const bumpPollCount = () => {
      measureOneDebugLog(
        `Request to increase pollCount ${pollCount} -> ${pollCount + 1}`,
      );

      setPollCount(p => p + 1);
    };

    const timeoutId = setTimeout(
      () => {
        measureOneDebugLog(`Poll #${pollCount} (timeoutId ${timeoutId})`);
        measureOneDebugLog(
          'Polling for MeasureOne Data Request',
          measureOneDataRequestID,
        );
        loadMeasureOneCandidateSchools(invitationToken, measureOneDataRequestID)
          .then(candidateSchools => {
            if (abortController.signal.aborted) {
              // abort!
              measureOneDebugLog(
                'Fetch was aborted. Discarding',
                candidateSchools,
              );
              return;
            }

            measureOneDebugLog(
              `Found ${candidateSchools.length} new MeasureOne CandidateSchools:`,
              candidateSchools,
            );
            if (candidateSchools.length > 0) {
              storeBufferMeasureOneDataAction(candidateSchools[0]);
            } else {
              measureOneDebugLog(
                'No new MeasureOne CandidateSchools found this poll',
              );
              bumpPollCount();
            }
          })
          .catch(error => {
            // This could be network errors or server errors.
            measureOneDebugLog(
              `Error getting MeasureOne CandidateSchools on poll #${pollCount}`,
            );
            // Continue to poll until we timeout
            bumpPollCount([]);
          });

        // wait POLL_INTERVAL and then poll (10 seconds unless this is the first poll or we've exceeded MAX_POLLS...?)
      },
      pollCount === 1 ? 0 : POLL_INTERVAL,
    );

    // return cleanup function:
    // clear the timeout on cancel/unmount
    // If this component unmounts (say because the user clicks "cancel"/"verify manually instead"
    // to abort), the last iteration of polling will probably still be in flight. Cleanup could
    // look like sending a cancellation to the fetch API, or we could just let the request resolve.
    // If that happens, we may end up adding MeasureOne Employments into state but the user won't
    // see them on the Review Modal unless they come back later.
    return () => {
      measureOneDebugLog(`Cleaning up (timeoutId ${timeoutId})`);
      clearTimeout(timeoutId);
      abortController.abort();
    };

    // this useEffect hook runs once on component mount, and again every time pollCount changes.
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [pollCount]);
  // note: these are the dependencies required by the React linter rules, but we believe the
  // only dependency we _really_ want here is `pollCount`. React warns about the missing dependencies in
  // react-hooks/exhaustive-deps, and we don't want to risk obscure stale closure bugs if we disable the
  // warning and remove any of these, so we'll suffer the additional re-renders instead. It doesn't appear to cause
  // any negative functional behavior other than additional cleanups in our logs.
  // more info: https://github.com/facebook/react/issues/22879
  // update: after further review of React dev guide (https://react.dev/learn/separating-events-from-effects), the
  // "right" solution to this problem is to update MeasureOneAccountEmployments and MeasureOneConnectedAccoundIds in an
  // "Effect Event" such that they don't need to be included in this useEffect's dependency array. This feature is
  // not yet available in React, and in the meantime, we determine that we are safe to explicitly omit these
  // dependencies and disable the react-hooks/exhaustive-deps lint warning.
  // }, [
  //   pollCount,
  //   MeasureOneAccountEmployments,
  //   MeasureOneConnectedAccountIds,
  //   dispatch,
  // ]);

  return (
    <div className='m1-learn-more-modal'>
      <table className='table'>
        <tbody>
          <tr>
            <td className='td-border-top-none'>
              <CountdownTimer
                duration={MEASURE_ONE_WAIT_PERIOD}
                onComplete={onCountdownExpired}
              />
            </td>
            <td className='td-border-top-none'>
              <div className='row'>
                <div className='col-sm-12'>
                  <h2>
                    {i18n.getStr(
                      'i18nInternational.educationField.measureOne.m1ReviewResultsWaitingModal.waitingMessageTitle',
                    )}
                  </h2>
                </div>
                <div className='col-sm-12 m1-waiting-results-modal-description-padding'>
                  <span className='m1-learn-more-modal-span'>
                    {i18n.getStr(
                      'i18nInternational.educationField.measureOne.m1ReviewResultsWaitingModal.waitingMessageDescription',
                    )}
                  </span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div className='row m1-cancel-and-enter-info-button'>
        <M.Button className='secondary' onClick={onError}>
          {i18n.getStr(
            'i18nInternational.educationField.measureOne.m1ReviewResultsWaitingModal.cancelAndEnterInformationButton',
          )}
        </M.Button>
      </div>
    </div>
  );
};

MeasureOneReviewPollingBody.propTypes = {
  storeBufferMeasureOneDataAction: PropTypes.func.isRequired,
  invitationToken: PropTypes.string.isRequired,
  measureOneDataRequestID: PropTypes.string.isRequired,
  onCountdownExpired: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default MeasureOneReviewPollingBody;
