import {
  ARGYLE_DEBUG_LOGS_ENABLED,
  ARGYLE_JS_URL,
  ARGYLE_LINK_KEY,
  ARGYLE_LINK_VERBOSE,
  ARGYLE_SANDBOX,
} from '../../constants';
import { apiRequestAsIs, buildSearchQuery } from '../../actions/helper';
import {
  EMPLOYMENT_ANALYTICS_EVENTS,
  EMPLOYMENT_ANALYTICS_PROPERTIES,
} from './analytics_events';
import { argyleDebugLog, argyleErrorLog } from './utils/employmentUtils';

/**
 * After making sure argyle.js is loaded into the DOM and procuring an Argyle User Token from Argyle, this method
 * will call Argyle.create to open Argyle Link UI in a modal window.
 *
 * @function
 * @param {Object} callbacks - A set of callbacks to be used by this method.
 * @param {string} callbacks.accountName The name of the account for the current package.
 * @param {function([argyleAccountId:string]) : void} callbacks.addArgyleConnectedAccountId Add Argyle Account ID to
 * Redux state
 * @param {function() : string} callbacks.onArgyleLinkClose Determine which Argyle State (Redux/app/component state)
 * to move to after closing Argyle Link. This determination depends on whether any Argyle Accounts were connected
 * and whether there are any that still require polling.
 * @param {function([newArgyleState:string]) : void} callbacks.setArgyleState Set a new Argyle State (Redux/app/
 * component state)
 * @param {function([trackArgyleAnalyticsEvent:string]) : void} callbacks.trackArgyleAnalyticsEvent Captures an
 * Amplitude Event with given map
 * @param {String} maximumAllowedEmployers The maximum selectable/submittable employers for a given package
 */
const openArgyle = async ({
  accountName,
  addArgyleConnectedAccountId,
  onArgyleLinkClose,
  setArgyleState,
  trackArgyleAnalyticsEvent,
  flowId,
}) => {
  if (ARGYLE_LINK_VERBOSE) {
    logArgyleSettings(flowId);
  }

  const wrappedSetArgyleState = newArgyleState => {
    argyleDebugLog(`setArgyleState: [${newArgyleState}]`);
    setArgyleState(newArgyleState);
  };

  argyleDebugLog('Opening Argyle Link UI');
  loadExternalScript(
    ARGYLE_JS_URL,
    async () => {
      try {
        await createArgyle(
          accountName,
          addArgyleConnectedAccountId,
          onArgyleLinkClose,
          wrappedSetArgyleState,
          flowId,
          trackArgyleAnalyticsEvent,
        );
      } catch (error) {
        argyleErrorLog('Error initializing Argyle Link', {
          error,
          captureSentryException: true,
        });
        wrappedSetArgyleState('link-error');
      }
    },
    error => {
      argyleErrorLog(`Error loading Argyle JS script: ${ARGYLE_JS_URL}`, {
        error,
        captureSentryException: true,
      });
      wrappedSetArgyleState('link-error');
    },
  );
};

const createArgyle = async (
  accountName,
  addArgyleConnectedAccountId,
  onArgyleLinkClose,
  setArgyleState,
  flowId,
  trackArgyleAnalyticsEvent,
) => {
  const argyleUserToken = await getUserToken(
    accountName,
    'opening-argyle',
  ).catch(error => {
    argyleErrorLog('Error getting Argyle User Token when opening Argyle Link', {
      error,
      captureSentryException: true,
    });
    setArgyleState('link-error');
  });

  if (!argyleUserToken) {
    // error state already captured -- just need to early return here
    return;
  }

  if (window.Argyle == null) {
    argyleErrorLog('Unable to invoke Argyle because window.Argyle is null', {
      captureSentryException: true,
    });
    setArgyleState('link-error');
    return;
  }

  const argyle = window.Argyle.create({
    linkKey: ARGYLE_LINK_KEY,
    flowId,
    sandbox: ARGYLE_SANDBOX,
    userToken: argyleUserToken,
    onAccountConnected: payload => {
      argyleDebugLog(`Argyle onAccountConnected => ${JSON.stringify(payload)}`);

      const { accountId } = payload;
      addArgyleConnectedAccountId(accountId);
      trackArgyleAnalyticsEvent(
        EMPLOYMENT_ANALYTICS_EVENTS.ARGYLE_PAYROLL_PROVIDER_ACCOUNT_CONNECTED,
        {
          [EMPLOYMENT_ANALYTICS_PROPERTIES.ARGYLE_ACCOUNT_ID]: accountId,
        },
      );

      argyleDebugLog(`addArgyleConnectedAccountId: ${accountId}`);
    },
    onAccountError: payload => {
      argyleErrorLog(`Argyle onAccountError => ${JSON.stringify(payload)}`, {
        captureSentryException: true,
      });
    },
    onCantFindItemClicked: query => {
      // onClose will still be called after this
      argyleDebugLog(
        `Argyle onCantFindItemClicked => ${JSON.stringify(query)}`,
      );
    },
    onClose: () => {
      argyleDebugLog('Argyle onClose');
      onArgyleLinkClose();
    },
    onError: async payload => {
      trackArgyleAnalyticsEvent(
        EMPLOYMENT_ANALYTICS_EVENTS.ARGYLE_PAYROLL_PROVIDER_ACCOUNT_CONNECTION_ERROR,
        {
          [EMPLOYMENT_ANALYTICS_PROPERTIES.ARGYLE_USER_ID]: payload.userId,
        },
      );

      argyleErrorLog(`Argyle onError => ${JSON.stringify(payload)}`, {
        captureSentryException: true,
      });

      // const { errorType } = payload;
      // if (errorType === 'expired_user_token') {
      //   // note: Argyle says we'll get an onTokenExpired along with this so don't need to handle this callback.
      //   // leaving here to demonstrate how to read specific error types for future reference
      // }
    },
    onExitIntroClicked: () => {
      // onClose will still be called after this
      argyleDebugLog('Argyle onExitIntroClicked');
    },
    onTokenExpired: async updateToken => {
      // Invoked 5 minutes before the user token expires.
      argyleDebugLog('Argyle onTokenExpired');

      // Get new token from Argyle and continue current Link session
      const newUserToken = await getUserToken(
        accountName,
        'token-expired-callback',
      ).catch(error => {
        argyleErrorLog(
          'Error getting Argyle User Token during onTokenExpired callback',
          {
            error,
            captureSentryException: true,
          },
        );
      });

      if (newUserToken) {
        updateToken(newUserToken);
      }
    },
    onUIEvent: payload => {
      if (ARGYLE_LINK_VERBOSE) {
        argyleDebugLog(`Argyle onUIEvent => ${JSON.stringify(payload)}`);
      }
    },
  });
  argyle.open();
  setArgyleState('linking');
};

const loadExternalScript = (url, onLoad, onError) => {
  if (!url) {
    onError(new Error('No URL configured'));
  } else {
    let script = document.querySelector(`script[src="${url}"]`);
    if (!script) {
      argyleDebugLog(`Adding Argyle JS script to DOM: ${url}`);
      script = document.createElement('script');
      script.type = 'application/javascript';
      script.src = url;
      script.async = true;
      document.body.appendChild(script);
      script.addEventListener('load', onLoad);
      script.addEventListener('error', onError);
    } else {
      onLoad();
    }
  }
};

const getUserToken = async (accountName, operationContext) => {
  const iframeType = getIframeType();
  // Send iframe type and account name to backend for troubleshooting session cookie issues.
  const userTokenQueryString = buildSearchQuery({
    iframeType,
    accountName,
    operationContext,
  });

  const getUserTokenPath = `apply/argyle/user-token${userTokenQueryString}`;
  return apiRequestAsIs(getUserTokenPath).then(json => {
    return json.data.argyle_user_token;
  });
};

const getIframeType = () => {
  const isInIframe = window.top !== window.self;
  const queryStringParams = new Proxy(
    new URLSearchParams(window.location.search),
    {
      get: (searchParams, prop) => searchParams.get(prop),
    },
  );

  // Dashboard "Manual Order" iframe would have an "order" query string param.
  // Reading window.top.location is not allowed in an iframe, so we have to rely on the query string param.
  const isInApprovedIframe = isInIframe && !!queryStringParams.order;
  const isInThirdPartyIframe = isInIframe && !queryStringParams.order;

  if (isInApprovedIframe) {
    return 'approved';
  }

  if (isInThirdPartyIframe) {
    return 'third_party';
  }

  return 'none';
};

const logArgyleSettings = flowId => {
  argyleDebugLog('ARGYLE_DEBUG_LOGS_ENABLED', ARGYLE_DEBUG_LOGS_ENABLED);
  argyleDebugLog('ARGYLE_FLOW_ID', flowId);
  argyleDebugLog('ARGYLE_JS_URL', ARGYLE_JS_URL);
  argyleDebugLog('ARGYLE_LINK_VERBOSE', ARGYLE_LINK_VERBOSE);
  argyleDebugLog('ARGYLE_SANDBOX', ARGYLE_SANDBOX);
};

export default openArgyle;
