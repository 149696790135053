/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { Translate as T } from 'react-redux-i18n';
import { Text } from './fields';

import * as V from '../lib/validations';

import Panel from './Panel';

const SignatureAuthorization = ({ name, company, readOnly }) => {
  if (readOnly)
    return (
      <div>
        {Authorization(name, company)}
        <hr />
        {Signature(name, readOnly)}
      </div>
    );
  return (
    <Panel title={`components.${name}.title`} icon='fa-file-text'>
      {Authorization(name, company)}
      <hr />
      {Signature(name, readOnly)}
    </Panel>
  );
};

const Authorization = (name, company) => (
  <div className='scrollable'>
    <span className='custom-text' role='button' tabIndex='0'>
      <h2>
        <T value={`components.${name}.contentHeader`} />
      </h2>
      <p className='content-spacing'>
        <T value={`components.${name}.content`} company={company} />
      </p>
    </span>
  </div>
);

const Signature = (name, readOnly) => {
  if (readOnly)
    return <Field name='signature' label='' component={Text} disabled />;
  return (
    <div className='row'>
      <div className='col-md-12'>
        <h6 className='cool-black'>
          <T value={`components.${name}.signatureHeader`} />
        </h6>

        <Field
          name='signature'
          label='labels.signHere'
          component={Text}
          placeholder='placeholders.fullLegalName'
          validate={[V.required, V.signatureFormat]}
        />
      </div>
    </div>
  );
};

SignatureAuthorization.propTypes = {
  name: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

SignatureAuthorization.defaultProps = {
  readOnly: false,
};

export default SignatureAuthorization;
