/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-redux-i18n';

const AddFieldButton = ({ handleOnClick, buttonText, count }) => (
  <button className='btn btn-secondary' type='button' onClick={handleOnClick}>
    <i className='fa fa-plus' />
    &nbsp;
    <Translate value={buttonText} count={count} />
  </button>
);

AddFieldButton.propTypes = {
  count: PropTypes.number,
  handleOnClick: PropTypes.func,
  buttonText: PropTypes.string,
};

export default AddFieldButton;
