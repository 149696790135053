import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';

const RemoveFieldButton = ({
  handleOnClick,
  className,
  textValue,
  id,
  showIcon,
}) => {
  return (
    <button type='button' className={className} onClick={handleOnClick} id={id}>
      {showIcon && <i className='fa fa-trash' />} <T value={textValue} />
    </button>
  );
};

RemoveFieldButton.propTypes = {
  handleOnClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  textValue: PropTypes.string,
  id: PropTypes.string,
  showIcon: PropTypes.bool,
};

RemoveFieldButton.defaultProps = {
  className: 'btn btn-sm btn-remove',
  textValue: 'buttons.remove',
  id: null,
  showIcon: true,
};

export default RemoveFieldButton;
