import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { Checkbox } from './fields';

import * as V from '../lib/validations';

import HTMLText from './HTMLText';
import Panel from './Panel';

const FCRA = ({ content, noScroll }) => (
  <Panel title='components.FCRA.title' icon='fa-file-text'>
    <div className={noScroll ? '' : 'scrollable'}>
      <HTMLText content={content} />
    </div>

    <hr />

    <div className='col-md-12'>
      <Field
        type='checkbox'
        name='fcra'
        label='components.FCRA.label'
        component={Checkbox}
        validate={[V.requiredCheckbox]}
        boldLabel
      />
    </div>
  </Panel>
);

FCRA.propTypes = {
  content: PropTypes.string.isRequired,
  noScroll: PropTypes.bool,
};

FCRA.defaultProps = {
  noScroll: false,
};

export default FCRA;
