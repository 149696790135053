import { camelCase } from 'lodash';

export const contactInfoFormat = [
  { label: 'labels.phone', key: 'phone', aria: 'phone number' },
  {
    label: 'labels.email',
    key: 'email',
    aria: 'email address',
  },
];

const formatDate = ({ year, month, day }) => {
  return `${month} / ${day} / ${year}`;
};

export const dlFormat = [
  { label: 'labels.dlState', key: 'dlState', aria: 'drivers license state' },
  { label: 'labels.dlNumber', key: 'dlNumber', aria: 'drivers license number' },
  {
    label: 'labels.dlNumberConfirmation',
    key: 'dlNumberConfirmation',
    aria: "confirm driver's license number",
  },
  {
    label: 'labels.dlExpirationDate',
    key: 'dlExpirationDate',
    format: formatDate,
    aria: 'drivers license expiration date',
  },
];

export const referenceFormat = [
  { label: 'labels.name', key: 'name', aria: 'reference name' },
  {
    label: 'labels.referenceRelationship',
    key: 'relationship',
    aria: 'reference relationship',
  },
  {
    label: 'labels.email',
    key: 'email',
    aria: 'reference email',
  },
  { label: 'labels.phone', key: 'phone', aria: 'reference phone number' },
];

const internationalAddresses = jobNum => {
  const title = `components.InternationalAddressHistoryForm.ordinal_${jobNum}`;
  return title;
};

export const internationalAddressesFormat = [
  {
    type: 'subheader',
    label: addressNum => internationalAddresses(addressNum),
    className: 'subheader',
  },
  { label: 'labels.address', key: 'street', aria: 'international address' },
  { label: 'labels.city', key: 'city', aria: 'international city' },
  {
    label: 'labels.country',
    key: 'countryCode',
    aria: 'international country code',
  },
  { label: 'labels.zipcode', key: 'zipcode', aria: 'international zipcode' },
  {
    label: 'labels.startDate',
    key: 'startDate',
    aria: 'international start date',
  },
  { label: 'labels.endDate', key: 'endDate', aria: 'international end date' },
];

const formatPhoneNumber = value => {
  if (!value) return '--';
  return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6)}`;
};

const formatClinic = ({ siteName, address, phoneNumber }) => {
  return [siteName, address, formatPhoneNumber(phoneNumber)];
};

export const clinicFormat = [
  {
    type: 'clinic',
    label: 'labels.clinicAddress',
    key: 'siteAddress',
    format: formatClinic,
    aria: 'clinic address',
  },
];

const formatMailingAddress = ({ street, city, state, zipcode }) => {
  return [street, `${city}, ${state} ${zipcode}`];
};

export const mailingAddress = [
  {
    type: 'mailing',
    label: 'labels.mailingAddress',
    key: 'postalAddress',
    format: formatMailingAddress,
    aria: 'mailing address',
  },
];

export const currentAddress = [
  {
    type: 'mailing',
    label: 'labels.currentAddress',
    key: 'currentAddress',
    format: formatMailingAddress,
    aria: 'current address',
  },
];

export const mvrAdditionalDetailsFormat = [
  {
    label: 'labels.motherMaidenName',
    key: 'motherMaidenName',
    aria: 'mothers maiden name',
  },
  {
    label: 'labels.ssn',
    key: 'ssn',
    icon: 'Locked',
    aria: 'social security number',
  },
  {
    type: 'current',
    label: 'components.MvrAdditionalDetailsComponent.addressTitle',
    key: 'currentAddress',
    format: formatMailingAddress,
    aria: 'current address',
  },
  {
    type: 'document-label',
    label: 'labels.attachedDocuments',
    className: 'documents-label',
    aria: 'attached documents',
  },
];

const formatMiddleName = name => {
  if (!name) return '--';
  return name;
};

export const custodyControlFormFormat = [
  {
    label: 'components.CustodyControlForm.label',
    key: 'custodyControlFormNumber',
    className: 'third',
    aria: 'custody and control form',
  },
];

export const parsEmployeeInfoFormat = [
  {
    label: 'labels.firstName',
    key: 'firstName',
    className: 'third',
    aria: 'first name',
  },
  {
    label: 'labels.middleName',
    key: 'middleName',
    className: 'third',
    format: formatMiddleName,
    aria: 'middle name',
  },
  {
    label: 'labels.lastName',
    key: 'lastName',
    className: 'third',
    aria: 'last name',
  },
  {
    label: 'labels.dob',
    key: 'dob',
    format: formatDate,
    aria: 'date of birth',
  },
  {
    label: 'labels.email',
    key: 'email',
    aria: 'email',
  },
  {
    label: 'labels.phone',
    key: 'phone',
    aria: 'phone',
  },
];

export const parsReporterInfoFormat = [
  {
    label: 'labels.reporterFullName',
    key: 'reporterFullName',
    aria: 'full name',
  },
  {
    label: 'labels.reporterEmail',
    key: 'reporterEmail',
    aria: 'email',
  },
];

export const applicantInfoFormat = [
  {
    label: 'labels.firstName',
    key: 'firstName',
    className: 'third',
    aria: 'first name',
  },
  {
    label: 'labels.middleName',
    key: 'middleName',
    className: 'third',
    format: formatMiddleName,
    aria: 'middle name',
  },
  {
    label: 'labels.lastName',
    key: 'lastName',
    className: 'third',
    aria: 'last name',
  },
  {
    label: 'labels.dob',
    key: 'dob',
    format: formatDate,
    aria: 'date of birth',
  },
  {
    label: 'labels.ssn',
    key: 'ssn',
    icon: 'Locked',
    aria: 'social security number',
  },
  { label: 'labels.zipcode', key: 'zipcode', aria: 'zip code' },
];

const formatEducationLevel = education => {
  const educationLevel = {
    HighSchool: 'highSchool',
    AA: 'associateOfArts',
    AS: 'associateOfScience',
    AAS: 'associateOfAppliedScience',
    BA: 'bachelorOfArts',
    BS: 'bachelorOfScience',
    MA: 'masterOfArts',
    MS: 'masterOfScience',
    Ph: 'doctorOfPhilosophy',
    MD: 'medicalDoctor',
    JD: 'jurisDoctorate',
    DDS: 'doctorOfDentalSurgery',
    Pharm: 'doctorOfPharmacy',
    degree: 'other',
  };

  const degree = educationLevel[education] || education.toLowerCase();
  return `options.degrees.${degree}`;
};

const formatEnrolled = enrolled => {
  if (enrolled === 'false') return 'buttons.no';
  if (enrolled === 'true') return 'buttons.yes';

  return '--';
};

export const educationFormat = [
  {
    type: 'subheader',
    label: 'components.EducationInformationForm.title',
    className: 'subheader',
  },
  {
    label: 'labels.schoolName',
    key: 'name',
    aria: 'school name',
  },
  {
    type: 'translate',
    label: 'labels.schoolDegree',
    key: 'degree',
    format: formatEducationLevel,
    aria: 'education level',
  },
  {
    label: 'labels.schoolMajor',
    key: 'major',
    aria: 'major',
  },
  {
    label: 'labels.schoolMinor',
    key: 'minor',
    aria: 'minor',
  },
  {
    type: 'translate',
    label: 'labels.schoolCurrent',
    key: 'current',
    format: formatEnrolled,
    aria: 'currently enrolled',
  },
  {
    label: 'labels.city',
    key: 'city',
    aria: 'city college or university was in',
  },
  {
    label: 'labels.state',
    key: 'state',
    aria: 'state college or university was in',
  },
  {
    type: 'document-label',
    label: 'labels.attachedDocuments',
    className: 'documents-label',
    aria: 'attached documents',
  },
];

const educationHeader = schoolNum => {
  const title = `components.ExtendedEmploymentHistoryForm.ordinal_${schoolNum}`;
  return title;
};

const formatEndDate = date => {
  if (!date) return 'labels.schoolCurrent';
  return date;
};

export const extendedEducationFormat = [
  {
    type: 'subheader',
    label: schoolNum => educationHeader(schoolNum),
    className: 'subheader',
  },
  {
    label: 'labels.schoolName',
    key: 'entity',
    aria: 'school name',
  },
  {
    label: 'labels.startDate',
    key: 'startDate',
    aria: 'start date',
  },
  {
    type: 'translate',
    label: 'labels.endDate',
    key: 'endDate',
    format: formatEndDate,
    aria: 'end date',
  },
  {
    label: 'labels.city',
    key: 'city',
    aria: 'city',
  },
  {
    label: 'labels.state',
    key: 'state',
    aria: 'state',
  },
  {
    type: 'document-label',
    label: 'labels.attachedDocuments',
    className: 'documents-label',
    aria: 'attached documents',
  },
];

const employmentHeader = jobNum => {
  const title = `components.Employer.ordinal_${jobNum}`;
  return title;
};

const formatEmploymentType = type => {
  return `options.contracts.${camelCase(type)}`;
};

export const employmentFormat = [
  {
    type: 'subheader',
    label: jobNum => employmentHeader(jobNum),
    className: 'subheader',
  },
  {
    label: 'labels.employerName',
    key: 'name',
    aria: "employer's name",
  },
  {
    label: 'labels.country',
    key: 'country',
    aria: 'country of employment',
  },
  {
    label: 'labels.employerState',
    key: 'state',
    aria: "employer's state",
  },
  {
    type: 'translate',
    label: 'labels.contractType',
    key: 'contractType',
    format: formatEmploymentType,
    aria: 'employment type',
  },
  {
    label: 'labels.position',
    key: 'position',
    aria: 'employment position',
  },
  {
    label: 'labels.startDate',
    key: 'startDate',
    aria: 'employment start date',
  },
  {
    label: 'labels.endDate',
    key: 'endDate',
    aria: 'employment end date',
  },
  {
    label: 'labels.managerName',
    key: 'manager.name',
    aria: "manager's name",
  },
  {
    label: 'labels.managerTitle',
    key: 'manager.title',
    aria: "manager's title",
  },
  {
    label: 'labels.managerEmail',
    key: 'manager.email',
    aria: "manager's email",
  },
  {
    label: 'labels.managerPhone',
    key: 'manager.phone',
    aria: "manager's phone",
  },
  {
    type: 'document-label',
    label: 'labels.attachedDocuments',
    className: 'documents-label',
    aria: 'employment documents',
  },
  // documents is an array of objects
];
