/* eslint-disable camelcase */
import React from 'react';
import { RTW_PASSPORT_STATUS } from 'international/constants';
import { i18n } from '@international/mastodon-i18n';
import HTMLText from 'components/HTMLText';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import RightToWorkField from '../RightToWorkField';
import * as V from '../../../../../lib/validations';

const GBRowHeaderTitleAndDescription = () => {
  return (
    <>
      <h2
        key='screening-right_to_work-title'
        className='region-requirements-header-text'
      >
        {i18n.getStr(`pages.rightToWork`)}
      </h2>
      <HTMLText
        key='screening-right_to_work-header-text'
        content={i18n.getStr(`international.headerText.rightToWork`)}
        customClassName='html-text'
      />
    </>
  );
};

const ShareCodeToolTip = () => {
  return (
    <M.TooltipDefinition
      data-testid='share-code-tooltip'
      align='end'
      direction='top'
      highlighted={false}
      id='share-code-tooltip'
      tooltipText={i18n.getStr(`international.helperText.getShareCodeTooltip`)}
    >
      <StyledInfoIcon icon='InformationFilled' size={20} />
    </M.TooltipDefinition>
  );
};

const StyledShareCodeFormat = styled.span`
  color: ${colors.darkGray100};
  font-size: 0.75rem;
  margin-bottom: 1rem;
  display: block;
  margin-top: -0.25rem;
`;

let noShareCode = false;

const StyledNoShareCodeWarningNotification = styled.div`
  background-color: ${colors.cautionYellowBg};
  color: ${colors.coolBlack100};
  padding: 1em;
  display: inline-flex;
  width: 100%;

  p {
    margin-left: 2em;
  }
`;

const NoShareCodeWarningNotificationBlock = () => {
  return (
    <StyledNoShareCodeWarningNotification data-testid='no-share-code-warning-notification'>
      <M.Icon icon='Warning' size={20} />
      <p>
        <HTMLText
          key='no-share-code-notification'
          content={i18n.getStr(
            `international.helperText.noShareCodeWarningNotification`,
          )}
        />
      </p>
    </StyledNoShareCodeWarningNotification>
  );
};

const StyledInfoIcon = styled(M.Icon)`
  color: #043f93;
  margin-left: 0.4em;
  vertical-align: text-bottom;
`;

const GetShareCodeInfoBlock = () => {
  return (
    <>
      <M.Divider />
      <M.Expandable
        key='share-code-container-expandable'
        title={
          <>
            <strong data-testid='get-share-code-info-block-title'>
              {i18n.getStr('international.labels.getShareCode')}
              <StyledInfoIcon icon='Information' size={16} />
            </strong>
          </>
        }
        initialExpanded={false}
      >
        <p>{i18n.getStr('international.helperText.getShareCode1')}</p>
        <p>{i18n.getStr('international.helperText.getShareCode2')}</p>
        <p>
          <a
            href='https://www.gov.uk/view-prove-immigration-status'
            target='_blank'
            rel='noopener noreferrer'
          >
            {i18n.getStr('international.labels.getShareCodeNow')}
          </a>
        </p>
      </M.Expandable>
    </>
  );
};

const CitizenshipStatusOptions = ({
  gen,
  accountId,
  rightToWorkFormValues,
}: any) => {
  const overrideTitleFromInstructionsTrue = true;
  const citizenshipStatus = rightToWorkFormValues?.citizenship_status;

  const validateShareCode = (
    value: string,
    { right_to_works }: { right_to_works: { no_share_code: boolean }[] },
  ) => !right_to_works.at(0)?.no_share_code && V.required(value);

  const noShareCodeOnClick = (_: Event, value: boolean) => {
    noShareCode = value;
    if (value) {
      rightToWorkFormValues.share_code = '';
    }
  };

  return (
    <>
      {citizenshipStatus === RTW_PASSPORT_STATUS.NATIONAL &&
        gen(RightToWorkField.properties.has_valid_passport).render()}

      {citizenshipStatus === RTW_PASSPORT_STATUS.FOREIGN && (
        <>
          <div>
            <h1 style={{ display: 'inline-block' }}>
              {i18n.getStr(
                `international.headers.scopedRequirements.GB.shareCode`,
              )}
            </h1>
            <ShareCodeToolTip />
          </div>
          {gen(RightToWorkField.properties.share_code).render({
            validate: validateShareCode,
            disabled: rightToWorkFormValues?.no_share_code,
            title: 'international.labels.shareCode',
            placeholder: i18n.getStr('international.placeholders.shareCode'),
          })}
          <StyledShareCodeFormat>
            {i18n.getStr('international.labels.shareCodeFormat')}
          </StyledShareCodeFormat>
          <GetShareCodeInfoBlock />
          <M.Divider />
          {gen(
            RightToWorkField.properties.no_share_code,
            overrideTitleFromInstructionsTrue,
          ).render({
            onClick: noShareCodeOnClick,
          })}
          {noShareCode && <NoShareCodeWarningNotificationBlock />}
        </>
      )}
    </>
  );
};

const generatedGBRows = (gen: any, form: any, accountId?: string) => {
  return [
    <GBRowHeaderTitleAndDescription key='screening-right_to_work-title-and-description' />,
    gen(RightToWorkField.properties.citizenship_status).render(),
    <CitizenshipStatusOptions
      key='screening-right_to_work-citizenship-status-options'
      gen={gen}
      accountId={accountId}
      rightToWorkFormValues={form?.right_to_works?.at(0)}
    />,
  ];
};

export default generatedGBRows;
