const months = () => {
  return [
    { value: '', name: 'options.selectAMonth', i18n: true },
    { value: '1', name: 'options.months.january', i18n: true },
    { value: '2', name: 'options.months.february', i18n: true },
    { value: '3', name: 'options.months.march', i18n: true },
    { value: '4', name: 'options.months.april', i18n: true },
    { value: '5', name: 'options.months.may', i18n: true },
    { value: '6', name: 'options.months.june', i18n: true },
    { value: '7', name: 'options.months.july', i18n: true },
    { value: '8', name: 'options.months.august', i18n: true },
    { value: '9', name: 'options.months.september', i18n: true },
    { value: '10', name: 'options.months.october', i18n: true },
    { value: '11', name: 'options.months.november', i18n: true },
    { value: '12', name: 'options.months.december', i18n: true },
  ];
};

const days = () => {
  const daysArr = [{ value: '', name: 'options.selectADay', i18n: true }];
  for (let d = 1; d <= 31; d += 1) {
    daysArr.push({ value: `${d}`, name: `${d}` });
  }

  return daysArr;
};

const years = (min, max) => {
  const today = new Date();
  const startYear = today.getFullYear() - max;
  const endYear = today.getFullYear() - min;

  const yearComponents = [
    { value: '', name: 'options.selectAYear', i18n: true },
  ];

  for (let y = endYear; y >= startYear; y -= 1) {
    yearComponents.push({ value: `${y}`, name: `${y}` });
  }

  return yearComponents;
};

export { years, months, days };
