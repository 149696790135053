import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import moment from 'moment';
import { Translate } from 'react-redux-i18n';

const IdvExtractedPiiContainer = styled.div`
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #e1e6eb;
  width: 100%;
  div {
    display: flex;
    width: 100%;
    align-items: flex-start;
  }
`;

const PiiComponent = styled.div`
  display: flex;
  padding: 12px 8px 12px 2px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  justify-content: flex-start;
`;

const CheckmarkIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center !important;
  width: 20px !important;
  height: 20px;
`;

const CheckmarkIcon = styled(M.Icon)`
  fill: #0f8a15 !important;
  flex-shrink: 0;
`;

const PiiLabel = styled.strong`
  font-weight: 500;
  color: rgba(26, 32, 38, 0.65);
`;

const renderPiiComponent = (label, value, key) => {
  return (
    <div key={key}>
      <PiiComponent>
        <CheckmarkIconWrapper>
          <CheckmarkIcon icon='CheckmarkFilled' className='icon checkmark' />
        </CheckmarkIconWrapper>
        <p>
          <PiiLabel>{label}</PiiLabel>{' '}
        </p>
      </PiiComponent>
      <PiiComponent>
        <p>{value}</p>
      </PiiComponent>
    </div>
  );
};

const PiiContainer = ({ extractedPii }) => (
  <IdvExtractedPiiContainer
    className='pii-container'
    data-testid='idv-pii-container'
  >
    {Object.entries(extractedPii).map(([key, originalValue]) => {
      const labelPath = `components.IdentityVerificationResults.${key}`;
      let value = originalValue;
      const excludedKeys = ['address', 'zipcode'];

      if (key === 'dob') {
        value = moment.utc(originalValue).format('MM/DD/YYYY'); // format the date
      }

      if (value && !excludedKeys.includes(key)) {
        return renderPiiComponent(<Translate value={labelPath} />, value, key);
      }

      return null;
    })}
  </IdvExtractedPiiContainer>
);

PiiContainer.propTypes = {
  extractedPii: PropTypes.object.isRequired,
};

export default PiiContainer;
