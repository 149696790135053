/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate as T } from 'react-redux-i18n';
import moment from 'moment';
import LocationSelector from './LocationSelector';
import Panel from './Panel';
import {
  getRequiredExams,
  getSelectedExams,
  isParsPackagePurpose,
} from '../actions';
import CustodyControlForm from './one_eight_hundred_flow/CustodyControlForm';

class SelectLocationForm extends Component {
  static propTypes = {
    company: PropTypes.string,
    exams: PropTypes.string,
    examSlugs: PropTypes.array,
    expiresAt: PropTypes.string,
    hideCovidNotice: PropTypes.bool,
    hideInstructions: PropTypes.bool,
    oneEightHundredFlowSelected: PropTypes.bool,
  };

  render() {
    const {
      company,
      exams,
      expiresAt,
      hideCovidNotice,
      hideInstructions,
      oneEightHundredFlowSelected,
    } = this.props;

    if (oneEightHundredFlowSelected) {
      return <CustodyControlForm />;
    }

    return (
      <div>
        {!hideCovidNotice && (
          <Panel
            className='alert-panel'
            title='components.ScheduleComponent.covidPanel.title'
            icon='fa-exclamation-triangle'
          >
            <p className='text-left'>
              <T value='components.ScheduleComponent.covidPanel.body' />
            </p>
          </Panel>
        )}
        {!hideInstructions && (
          <Panel>
            <p className='text-center'>
              <T
                value='components.ScheduleComponent.instructions.part1'
                company={company}
                exams={exams}
                dangerousHTML
              />
            </p>
            <p className='text-center'>
              <T
                value='components.ScheduleComponent.instructions.part2'
                date={moment(expiresAt).format('MMMM DD, YYYY')}
                dangerousHTML
              />
            </p>
          </Panel>
        )}
        <Panel className='card-plain'>
          <div className='checkr-blue'>
            <h4 className='panel-description'>
              <T value='components.ScheduleComponent.locations.title' />
            </h4>
          </div>
          <p>
            <T value='components.ScheduleComponent.locations.details' />
          </p>
          <LocationSelector {...this.props} />
        </Panel>
      </div>
    );
  }
}

const ConnectedSelectLocationForm = connect((state, props) => {
  const company = state.configuration.configuration.account.name;
  const exams = getRequiredExams(state).concat(getSelectedExams(state));
  const examLabels = [...new Set([...exams.map(exam => exam.label)])].join(
    ', ',
  );
  const examSlugs = [...new Set([...exams.map(exam => exam.name)])].sort();
  const isParsProgram = isParsPackagePurpose(state);
  return {
    company,
    oneEightHundredFlowSelected:
      state.healthScreening.oneEightHundredFlowSelected,
    exams: examLabels,
    examSlugs,
    isParsProgram,
    ...props,
  };
})(SelectLocationForm);

export default ConnectedSelectLocationForm;
