import React from 'react';

import ApplyFormManager from './FormLogic/ApplyFormManager.class';

export interface InternationalContextProps extends ApplyFormManager {
  company?: string;
  clearFormValue?: Function | undefined;
}

const InternationalContext: React.Context<InternationalContextProps> = React.createContext(
  {} as ApplyFormManager,
);

export default InternationalContext;
