/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { change, formValueSelector, Field } from 'redux-form';

import { Checkbox } from './fields';

import Panel from './Panel';
import HTMLText from './HTMLText';

const ManualOrderCandidateCopy = ({ content }) => {
  const onCopyRequestedUpdate = ({ target: { checked } }) => {
    if (checked) {
      change('applyform', 'copyRequested', null);
    }
  };

  return (
    <Panel
      title='components.ManualOrderCandidateCopy.title'
      icon='fa-file-text'
    >
      {content && (
        <div className='scrollable'>
          <HTMLText content={content} />
        </div>
      )}
      <div className='col-md-12'>
        <Field
          type='checkbox'
          name='copyRequested'
          label='components.ManualOrderCandidateCopy.label'
          component={Checkbox}
          boldLabel
          onChange={onCopyRequestedUpdate}
        />
      </div>
    </Panel>
  );
};

ManualOrderCandidateCopy.propTypes = {
  content: PropTypes.string,
};

const selector = formValueSelector('applyform');
const ConnectedManualOrderCandidateCopy = connect(
  state => {
    return {
      copyRequested: selector(state, 'copyRequested'),
    };
  },
  { change },
)(ManualOrderCandidateCopy);

export default ConnectedManualOrderCandidateCopy;
