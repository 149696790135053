import React from 'react';
import ReactPlayer from 'react-player';
import { Translate as T } from 'react-redux-i18n';

import '../css/VideoNotice.scss';

const VIDEO_URL = 'https://www.youtube.com/watch?v=-GusAPxwNaU';

const VideoNotice = () => (
  <div className='mt-5 d-flex flex-column'>
    <strong>
      <T value='components.VideoNotice.text' />
    </strong>
    <br />
    <div className='video-wrapper'>
      <ReactPlayer
        className='video-player'
        url={VIDEO_URL}
        controls
        width='100%'
        height='100%'
      />
    </div>
  </div>
);

export default VideoNotice;
