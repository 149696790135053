import { i18n, moment } from '@international/mastodon-i18n';
import React, { PureComponent } from 'react';
import CustomDatePicker, { registerLocale } from 'react-datepicker';

import { WrappedMDatePickerProps } from './WrappedComp.types';
import ReduxMInput from './Input';

import 'react-datepicker/dist/react-datepicker.css';

/**
 * To get locale object for the requested locale
 * @param {string} locale
 * @returns {object} locale object for localized month
 */
const getLocaleObj = (locale: string) => {
  let localeObj = {};
  try {
    // eslint-disable-next-line global-require, import/no-dynamic-require
    localeObj = require(`date-fns/locale/${locale}`)?.default;
  } catch (e) {
    const language = locale.length === 5 ? locale.slice(0, 2) : locale;
    try {
      // eslint-disable-next-line global-require, import/no-dynamic-require
      localeObj = require(`date-fns/locale/${language}`)?.default;
    } catch (er) {
      // eslint-disable-next-line global-require
      localeObj = require(`date-fns/locale/en-GB`)?.default;
    }
  }
  return localeObj;
};

// parse date to iso format
const parseDate = (date: string, dateFormat: string) =>
  new Date(moment(date, dateFormat).toString());

// check if it is valid date
const isValidDate = (date: string, dateFormat: any) =>
  moment(date, dateFormat, true).isValid();

class ReduxDatePicker extends PureComponent<WrappedMDatePickerProps, {}> {
  componentDidMount() {
    const formattedLocale = i18n.normalizeToI18nNextFormat(
      i18n.getCookieLocale(),
    );
    registerLocale(formattedLocale, getLocaleObj(formattedLocale));
  }

  normalizeInput = (date: any) => {
    const { input, dateFormat } = this.props;
    if (!input) return;
    if (date && isValidDate(date, dateFormat))
      input.onChange(i18n.getLocalizedMonthYear(date, dateFormat));
  };

  render() {
    const {
      id,
      placeholder,
      dateFormat,
      input,
      isMonthYear,
      disabled,
    } = this.props;
    const formattedLocale = i18n.normalizeToI18nNextFormat(
      i18n.getCookieLocale(),
    );
    const formattedValue =
      dateFormat &&
      isValidDate(input?.value, dateFormat) &&
      parseDate(input?.value, dateFormat);

    return (
      <CustomDatePicker
        id={id}
        selected={formattedValue}
        locale={formattedLocale}
        onChange={this.normalizeInput}
        dateFormat={dateFormat?.toLowerCase().replace('mm', 'MM')}
        placeholderText={placeholder}
        showMonthYearPicker={isMonthYear}
        customInput={<ReduxMInput {...this.props} />}
        disabled={disabled}
        closeOnScroll
      />
    );
  }
}

export default ReduxDatePicker;
