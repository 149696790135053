import * as Sentry from '@sentry/react';

import { apiRequest, patchOptions, parseErrorResponse } from './helper';
import { submitFormSuccess } from './submission';
import { CANDIDATE_TASK_FAILURE } from './intlForm';

const CANCEL_REQUEST = 'CANCEL_REQUEST';
const candidateTaskFailure = errors => ({
  type: CANDIDATE_TASK_FAILURE,
  candidateTaskErrors: errors,
});

const handleIdentityVerificationCancel = identityVerificationData => dispatch => {
  const finalPayload = {
    task_status: identityVerificationData.taskStatus,
  };

  dispatch({ type: CANCEL_REQUEST });
  return apiRequest(
    `candidate_tasks/${identityVerificationData.resourceId}`,
    patchOptions(finalPayload),
  )
    .then(json => {
      dispatch(submitFormSuccess(json));
    })
    .catch(error => {
      parseErrorResponse(error).then(({ errors }) => {
        dispatch(candidateTaskFailure(errors));
        Sentry.captureException(errors);
      });
    });
};

const handleVendorSuccess = () => dispatch => {
  dispatch(submitFormSuccess());
};

const fetchIdentityVerificationPii = (candidateId, contextId) => {
  return apiRequest(
    `candidates/${candidateId}/identity_verification_results/${contextId}`,
  );
};

export {
  handleIdentityVerificationCancel,
  handleVendorSuccess,
  candidateTaskFailure,
  fetchIdentityVerificationPii,
};
