/* eslint-disable camelcase */
import { Dictionary, uniq } from 'lodash';
import {
  concat,
  flatten,
  get,
  getOr,
  head,
  intersection,
  map,
  tail,
  orderBy,
} from 'lodash/fp';

import {
  JsonSchemaProperty,
  JsonSchemaDefinition,
  ManyOneOfs,
} from '../types/schema.types';

import {
  initialValueTree,
  RenderableSchemaProperty,
} from '../Fields/FieldCreationLogic/Field.types';

import { DocTreesToRender, forEachFieldCB } from './Form.types';

export class PathManager {
  static readonly properties = 'properties';

  static buildPath = (pathToParent = '', key = '') =>
    pathToParent && key ? `${pathToParent}.${key}` : pathToParent || key;

  static addPropsToPath(parentPath: string) {
    return this.buildPath(parentPath, this.properties);
  }

  static addKey(
    path: PathManager,
    newKey: string,
    newProp: any,
    isField = true,
  ) {
    let {
      schemaPathToLastField,
      schemaPathFromLastFieldToParent,
      formPathToParent,
    } = path;
    const { key } = path;

    if (path.isField) {
      schemaPathToLastField = this.buildPath(
        path.schemaPathToParentNode(),
        key,
      );
      schemaPathFromLastFieldToParent = '';
      formPathToParent = this.buildPath(formPathToParent, key);
    } else {
      schemaPathFromLastFieldToParent = this.buildPath(
        schemaPathFromLastFieldToParent,
        path.key,
      );
    }
    return new PathManager(
      schemaPathToLastField,
      schemaPathFromLastFieldToParent,
      formPathToParent,
      newKey,
      newProp,
      isField,
    );
  }

  static addItems(path: PathManager, prop: any) {
    return this.retainParent(this.addKey(path, 'items', prop, false), path);
  }

  static addProperties(path: PathManager, prop: any) {
    return this.retainParent(
      this.addKey(path, 'properties', prop, false),
      path,
    );
  }

  static addField(
    path: PathManager,
    newKey: string,
    newProp: any,
    isField = true,
  ) {
    return this.retainParent(this.addKey(path, newKey, newProp, isField), path);
  }

  static retainParent(newPath: PathManager, oldPath: PathManager) {
    // eslint-disable-next-line no-param-reassign
    newPath.parent = oldPath.parent;
    return newPath;
  }

  static makeChildPath(parent: PathManager, child = new PathManager()) {
    const childPath = new PathManager();
    childPath.parent = parent;
    return childPath;
  }

  parent?: PathManager;

  isDisabled = false;

  isRequired = false;

  oneOfs: string[] = [];

  setOneOfs(oneOfs: string[]) {
    this.oneOfs = oneOfs;
  }

  constructor(
    public schemaPathToLastField: string = '',
    public schemaPathFromLastFieldToParent: string = '',
    public formPathToParent: string = '',
    public key: string = '',
    public prop: JsonSchemaProperty | JsonSchemaDefinition = {} as any,
    public isField = true,
  ) {
    // Make that lint error go away by adding a comment
  }

  schemaPathToParentNode = () =>
    PathManager.buildPath(
      this.schemaPathToLastField,
      this.schemaPathFromLastFieldToParent,
    );

  getCombinedPath = {
    schema: () =>
      PathManager.buildPath(this.schemaPathToParentNode(), this.key),
    form: (arrayPathModifier = '') =>
      PathManager.buildPath(
        `${this.formPathToParent}${arrayPathModifier}`,
        this.isField ? this.key : '',
      ),
  };

  getDefaultValueForForm = (
    childValue?: initialValueTree | initialValueTree[],
  ): undefined | initialValueTree => {
    const valueToAppend = childValue || this.getDefaultValue();
    if (valueToAppend == null) {
      return undefined;
    }

    const formStructureToChild: initialValueTree = {};
    // search and default to this^ object
    const searchByPath = (path: string) =>
      getOr(formStructureToChild, path, formStructureToChild);

    const strPath = this.formPathToParent;
    const splitPath = strPath.split('.');

    const lastKey = this.isField ? this.key : splitPath.pop();

    let pathThusfar = '';
    if (strPath) {
      splitPath.forEach(pathSegment => {
        // We know this is a tree itself because we're parsing a path to arrive here:
        const toAssignTo = searchByPath(pathThusfar) as initialValueTree;
        toAssignTo[pathSegment] = {};
        pathThusfar = PathManager.buildPath(pathThusfar, pathSegment);
      });
    }

    if (lastKey) {
      // We can coerce here because we're following a known-path to get to the last leaf
      (searchByPath(pathThusfar) as initialValueTree)[lastKey] = valueToAppend;
    }

    if (this.parent) {
      // we only do a "parent" action if it's an items structure
      return this.parent.getDefaultValueForForm([formStructureToChild]);
    }

    return formStructureToChild;
  };

  private getDefaultValue = () => {
    if (this.isField) {
      if (this.prop.constant) {
        this.isDisabled = true;
        return this.prop.constant;
      }

      switch (this.prop.type) {
        case 'boolean':
          return false;
      }
    }
    return undefined;
  };
}

export class FirstFieldManager {
  landingPage = {
    screening: 'landingPage',
    region: 'landingPage',
  };

  public fieldFirstAppears: {
    [fieldName: string]: {
      screening: string;
      region: string;
    };
  } = {
    'properties.phone_number': this.landingPage,
    'properties.email': this.landingPage,
    'properties.citizenship': this.landingPage,
    'properties.citizenship_identity': this.landingPage,
    'properties.first_name': this.landingPage,
    'properties.middle_name': this.landingPage,
    'properties.no_middle_name': this.landingPage,
    'properties.last_name': this.landingPage,
    'properties.dob': this.landingPage,
    'properties.address': this.landingPage,
  };

  has = (field: RenderableSchemaProperty) =>
    !!this.fieldFirstAppears[field.path.getCombinedPath.schema()];

  get = (field: RenderableSchemaProperty) =>
    this.fieldFirstAppears[field.path.getCombinedPath.schema()] || {};

  set = (
    field: RenderableSchemaProperty,
    screening: string,
    region: string,
  ) => {
    this.fieldFirstAppears[field.path.getCombinedPath.schema()] = {
      screening,
      region,
    };
  };

  shouldRenderField = (
    field: RenderableSchemaProperty,
    screening: string,
    region: string,
  ) => {
    const firstAppearance = this.get(field);
    return (
      firstAppearance?.region === region &&
      firstAppearance?.screening === screening
    );
  };
}

// Documents are retrieved from schema first and added to fieldFirstAppears first
function isDocument(field: JsonSchemaDefinition | RenderableSchemaProperty) {
  return !!field?.properties?.document;
}

function isUpload(field: JsonSchemaDefinition | RenderableSchemaProperty) {
  const remoteDoc = 'http://region-compliance.checkr.com/field_types/document';
  const localDoc = '#/definitions/document';
  return field?.$id === remoteDoc || field?.$id === localDoc;
}

function isESign(field: JsonSchemaDefinition | RenderableSchemaProperty) {
  const remoteESignDoc =
    'http://region-compliance.checkr.com/field_types/electronic_signature';
  const localESignDoc = '#/definitions/electronic-signature';
  return field?.$id === remoteESignDoc || field?.$id === localESignDoc;
}

function isIdentityDocument(field: RenderableSchemaProperty) {
  return field?.path?.formPathToParent === 'identity_document';
}

export function extractDocuments(
  schema: RenderableSchemaProperty,
  docTreesToRender: DocTreesToRender,
  oneOfKeys: Dictionary<RenderableSchemaProperty>,
  esignDocs: Dictionary<RenderableSchemaProperty>,
) {
  return (prop: RenderableSchemaProperty) => {
    const { path } = prop;
    const isObjectType: boolean = prop.type === 'object';

    if (isObjectType && !isIdentityDocument(prop)) {
      if (isESign(prop)) {
        // eslint-disable-next-line no-param-reassign
        esignDocs[prop.path.key] = prop;
      }
      if (isUpload(prop) || isDocument(prop)) {
        if (path.oneOfs.length === 0) {
          if (prop.document_uri) {
            // if it has a document_uri we are meant to download & upload this
            // No other context is needed for the upload
            // eslint-disable-next-line no-param-reassign
            docTreesToRender[path.getCombinedPath.schema()] = {
              path,
              fieldToRender: prop,
            };
          }
          const uploadContext = get(path.schemaPathToLastField, schema);
          const oneOfs = uploadContext?.path?.oneOfs;
          if (oneOfs?.length === 0) {
            // otherwise it is only an upload, in which case we need to render the context of this request
            // eslint-disable-next-line no-param-reassign
            docTreesToRender[path.schemaPathToLastField] = {
              path,
              fieldToRender: get(path.schemaPathToParentNode(), schema),
            };
          } else if (oneOfs?.length > 0) {
            if (!oneOfKeys[uploadContext.path.key]) {
              // eslint-disable-next-line no-param-reassign
              oneOfKeys[uploadContext.path.key] = prop;
            }
          }
        } else if (path.oneOfs.length > 0) {
          if (!oneOfKeys[path.key]) {
            // eslint-disable-next-line no-param-reassign
            oneOfKeys[path.key] = prop;
          }
        }
      }
    }
  };
}

export function setSchemaFieldObjects(
  schemaAsRenderableFields: RenderableSchemaProperty,
): forEachFieldCB {
  return path => {
    const { properties, ...restOfField } = path.prop;
    const toPlace = {
      ...(restOfField as any),
      path,
    };

    let { parent: parentPath } = path;

    let targetParent = path.schemaPathToParentNode();
    if (parentPath) {
      let parentPathString = targetParent;

      while (parentPath) {
        parentPathString = PathManager.buildPath(
          parentPath.schemaPathToParentNode(),
          parentPathString,
        );
        parentPath = parentPath.parent;
      }

      targetParent = parentPathString;
    }
    const propToAssignTo = parsePathToNode(
      targetParent,
      schemaAsRenderableFields,
    );

    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    propToAssignTo[path.key] = toPlace;
  };
}

function parsePathToNode(
  strPath: string,
  schemaToWriteTo: RenderableSchemaProperty,
) {
  return (
    get(strPath, schemaToWriteTo) ||
    strPath.split('.').reduce((newSchema, accessor) => {
      // @ts-ignore
      const next = newSchema[accessor];
      if (!next) {
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        newSchema[accessor] = {};
      }

      // @ts-ignore
      return newSchema[accessor];
    }, schemaToWriteTo)
  );
}

export function removeSortAndReplaceAtFront(
  arr: string[],
  elem: string | string[],
) {
  const i = arr.findIndex(item => item === elem);
  if (i >= 0) {
    arr.splice(i, 1);
  }

  return Array.isArray(elem)
    ? elem.concat(arr.sort())
    : [elem].concat(arr.sort());
}

export const buildComplexRequirements = (
  advRequirements: ManyOneOfs,
): string[][] | null => {
  // sort all of the requirements and collect just the string field values
  const oneOfs = orderBy<string[][]>(
    req => req.length,
    'desc',
    map(
      requirement =>
        map((r: { required: string[] }) => r.required, requirement?.oneOf),
      advRequirements,
    ),
  );

  const startingReq = head(oneOfs);
  const restOneOfs = tail(oneOfs);
  if (!startingReq) return null;
  if (!restOneOfs.length)
    return orderBy(
      x => x.length,
      'asc',
      map(reqArr => reqArr.sort(), startingReq),
    );

  const finalRequirements: Record<number, any> = {};
  startingReq.forEach((requirements, i) => {
    finalRequirements[i] = requirements;
    restOneOfs.forEach(restArr => {
      const likeValues = intersection(flatten(startingReq), flatten(restArr));
      restArr.length > 1
        ? restArr.forEach(column => {
            if (
              !intersection(requirements, likeValues).length &&
              !intersection(column, likeValues).length
            )
              finalRequirements[i] = uniq(
                concat(finalRequirements[i], column),
              ).sort();
            if (
              intersection(requirements, likeValues).length &&
              intersection(requirements, column).length
            )
              finalRequirements[i] = uniq(
                concat(finalRequirements[i], column),
              ).sort();
          })
        : (finalRequirements[i] = uniq(
            concat(finalRequirements[i], restArr[0]),
          ).sort());
    });
  });

  return orderBy(x => x.length, 'asc', Object.values(finalRequirements).sort());
};

// This function will extract highest the number of
// address history years required by all the screenings
// in the request
export const extractAddressRegionalProperties = (
  schemaAsRenderableFields: any,
  screenings: Array<string>,
): any => {
  // screenings that don't not collect address
  if (!schemaAsRenderableFields?.properties?.address) {
    return schemaAsRenderableFields;
  }
  const yearsOfAddressHistory: Array<number> = [0];
  const updatedSchema = { ...schemaAsRenderableFields };
  for (const screening of screenings) {
    if (
      updatedSchema?.properties?.[screening]?.items?.properties?.address
        ?.extended_configuration?.address_history_years
    ) {
      yearsOfAddressHistory.push(
        updatedSchema.properties[screening].items.properties.address
          .extended_configuration.address_history_years,
      );
      delete updatedSchema.properties[screening].items.properties.address;
    }
  }
  return {
    ...updatedSchema,
    properties: {
      ...updatedSchema.properties,
      address: {
        ...updatedSchema.properties.address,
        extended_configuration: {
          address_history_years: Math.max(...yearsOfAddressHistory),
        },
      },
    },
  };
};
