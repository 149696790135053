/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';
import Exam from './Exam';

const ExamCategory = ({ examCategoryName, examType, exams }) => {
  return (
    <div>
      <h2>{examCategoryName}</h2>
      <hr />
      {exams.map((exam, i) => (
        <Exam
          key={`exam-${i + 1}`}
          exam={exam}
          examType={examType}
          includeHorizontalRule={i < exams.length - 1}
        />
      ))}
    </div>
  );
};

ExamCategory.propTypes = {
  examCategoryName: PropTypes.string.isRequired,
  examType: PropTypes.string.isRequired,
  exams: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ExamCategory;
