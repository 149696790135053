/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import isDisabled from './helper';

import { Checkbox } from './fields';

import * as N from '../lib/normalizations';
import * as V from '../lib/validations';
import SensitiveInput from './fields/SensitiveInput';

const SsnFields = ({
  noSsn,
  candidatesCanDeclareNoSsn,
  protectedInvitationFlow,
  protectedInvitationFields,
  candidate,
  onNoSsnUpdate,
}) => {
  return (
    <div className='row'>
      <div className='col-md-6'>
        <Field
          type='tel'
          name='ssn'
          placeholder='555-55-5555'
          label='labels.ssn'
          component={SensitiveInput}
          normalize={N.ssn}
          mask={/\w/g}
          validate={(checked, model) =>
            V.ssnValidations(model, false, protectedInvitationFlow, candidate)
          }
          disabled={
            isDisabled({
              field: candidate.ssn,
              protect:
                protectedInvitationFlow &&
                protectedInvitationFields.includes('ssn'),
            }) || noSsn === true
          }
          onPaste={e => e.preventDefault()}
        />

        {candidatesCanDeclareNoSsn && (
          <Field
            type='checkbox'
            name='noSsn'
            label='labels.noSsn'
            className='inline-checkbox top-inline bottom-inline'
            onChange={onNoSsnUpdate}
            component={Checkbox}
          />
        )}
      </div>
      <div className='col-md-6'>
        <Field
          type='tel'
          name='ssnConfirmation'
          placeholder='555-55-5555'
          label='labels.ssnConfirmation'
          component={SensitiveInput}
          normalize={N.ssn}
          mask={/\w/g}
          validate={(checked, model) =>
            V.ssnValidations(model, true, protectedInvitationFlow, candidate)
          }
          disabled={
            isDisabled({
              field: candidate.ssn,
              protect:
                protectedInvitationFlow &&
                protectedInvitationFields.includes('ssn'),
            }) || noSsn === true
          }
          onPaste={e => e.preventDefault()}
        />
      </div>
    </div>
  );
};

SsnFields.propTypes = {
  noSsn: PropTypes.bool,
  candidatesCanDeclareNoSsn: PropTypes.bool,
  protectedInvitationFlow: PropTypes.bool,
  protectedInvitationFields: PropTypes.array,
  candidate: PropTypes.object,
  onNoSsnUpdate: PropTypes.func,
};

export default SsnFields;
