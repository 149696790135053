import PropTypes from 'prop-types';
import React from 'react';

import ReferenceForm from './ReferenceForm';

const ProfessionalReferenceForm = ({ quantity }) => (
  <ReferenceForm
    icon='fa-briefcase'
    model='professionalReferences'
    title='components.ProfessionalReferenceForm.title'
    quantity={quantity}
  />
);

ProfessionalReferenceForm.propTypes = {
  quantity: PropTypes.number.isRequired,
};

export default ProfessionalReferenceForm;
