/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';

const Toggle = ({ text, translationArguments, expanded, toggle }) => {
  const toggleButton = e => {
    e.preventDefault();
    toggle();
  };

  return (
    <div className='row'>
      <div className='col-lg-8 col-sm-12'>
        <div style={{ marginTop: '7px' }}>
          <strong>
            <T value={text} {...translationArguments} />
          </strong>
        </div>
      </div>
      <div className='flex col-lg-4 col-sm-12'>
        <div className='btn-group' role='group' style={{ margin: 'auto' }}>
          <button
            type='button'
            className={`btn btn-${expanded ? 'primary' : 'secondary'}`}
            onClick={toggleButton}
          >
            <T value='buttons.yesIHave' />
          </button>
          <button
            type='button'
            className={`btn btn-${!expanded ? 'primary' : 'secondary'}`}
            onClick={toggleButton}
          >
            <T value='buttons.noIHavent' />
          </button>
        </div>
      </div>
    </div>
  );
};

Toggle.propTypes = {
  text: PropTypes.string,
  translationArguments: PropTypes.object,
  expanded: PropTypes.bool,
  toggle: PropTypes.func,
};

export default Toggle;
