import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import { Translate as T, I18n } from 'react-redux-i18n';

import { Text } from './fields';

import * as V from '../lib/validations';

import Panel from './Panel';

const ReferenceForm = ({ icon, model, title, quantity }) => (
  <Panel title={title} icon={icon}>
    <p className='alert-full-name' role='alert'>
      <i className='fa fa-exclamation-circle' />{' '}
      <T value='components.ReferenceForm.alert' />
    </p>

    {[...Array(quantity).keys()].map(i => {
      return (
        <React.Fragment key={`${model}-${i + 1}`}>
          {quantity === 1 || (
            <p>
              <strong>
                <T
                  value={`labels.ordinalNumbers.number_${
                    i + 1 < 10 ? i + 1 : 'other'
                  }`}
                  count={i + 1}
                />{' '}
                <span>{I18n.t(title).toLowerCase()}</span>
              </strong>
            </p>
          )}
          <ReferenceFormRow model={`${model}[${i}]`} />
          {i + 1 < quantity ? <hr /> : ''}
        </React.Fragment>
      );
    })}
  </Panel>
);

const ReferenceFormRow = ({ model }) => (
  <React.Fragment>
    <div className='row'>
      <div className='col-md-6'>
        <Field
          type='text'
          name={`${model}.name`}
          label='labels.name'
          component={Text}
          validate={[V.required]}
        />
      </div>

      <div className='col-md-6'>
        <Field
          type='text'
          name={`${model}.relationship`}
          label='labels.referenceRelationship'
          component={Text}
          validate={[V.required]}
        />
      </div>
    </div>
    <div className='row'>
      <div className='col-md-6'>
        <Field
          type='tel'
          name={`${model}.phone`}
          label='labels.phone'
          component={Text}
          validate={[V.required]}
        />
      </div>

      <div className='col-md-6'>
        <Field
          type='email'
          name={`${model}.email`}
          label='labels.email'
          component={Text}
          validate={[V.required, V.emailFormat]}
        />
      </div>
    </div>
  </React.Fragment>
);

ReferenceForm.propTypes = {
  icon: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
};

ReferenceFormRow.propTypes = {
  model: PropTypes.string.isRequired,
};

export default ReferenceForm;
