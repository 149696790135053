import React from 'react';
import PropTypes from 'prop-types';
import { M } from '@dashboard-experience/mastodon';
import { Translate as T } from 'react-redux-i18n';

export const Document = ({ aria = '', label, link, icon = false, value }) => {
  return (
    <div className='document-container' key={label}>
      <a
        className='document-link'
        rel='noopener noreferrer'
        key={label}
        href={link}
        target='_blank'
        aria-label={`Link to ${aria || value}`}
      >
        <div className='document' key={label}>
          <h3 className='document-name' aria-label={label}>
            <T value={label} />
            {icon && <M.Icon className='icon' icon={icon} />}
          </h3>
          <div className='document-type-size' aria-label={value}>
            <T value={value} />
          </div>
        </div>
      </a>
    </div>
  );
};

export const DocumentLabel = ({ className = '', icon = false, label }) => {
  return (
    <h3 className={className} aria-label={label} key={label}>
      <T value={label} />
      {icon && <M.Icon className='icon' icon={icon} />}
    </h3>
  );
};

Document.propTypes = {
  aria: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

DocumentLabel.propTypes = {
  className: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
