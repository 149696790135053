import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-redux-i18n';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const IdvButton = styled(M.Button)`
  border-radius: 4px !important;
  background: #0a57a4 !important;
  box-shadow: 0px 12px 32px -12px rgba(10, 87, 164, 0.25),
    0px 4px 8px -4px rgba(10, 87, 164, 0.5) !important;
  gap: 8px;
  justify-content: center;
  height: 48px !important;
  padding: 16px !important;
  @media (max-width: 768px) {
    align-self: stretch;
  }
`;

const StartIdvComponent = ({ handleClick }) => {
  return (
    <>
      <p>
        <Translate value='components.IdentityVerificationProcessor.bodyContent1' />
      </p>
      <IdvButton
        type='primary'
        onClick={handleClick}
        data-testid='idv-start-button'
      >
        <Translate value='components.IdentityVerificationProcessor.startVerificationButton' />
        <M.Icon icon='ArrowRight' />
      </IdvButton>
    </>
  );
};

StartIdvComponent.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default StartIdvComponent;
