import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from '@international/mastodon-i18n';
import {
  CheckmarkFilled16,
  HelpFilled16,
} from '@dashboard-experience/mastodon';

function AcceptedResultsTable({ candidateSchool }) {
  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='accepted-results'>
          <div className='m1-learn-more-modal'>
            <div className='container m1-results-modal-container'>
              <table className='table'>
                <tbody>
                  <tr>
                    <td className='td-border-top-none'>
                      {candidateSchool.name === undefined ? (
                        <HelpFilled16 />
                      ) : (
                        <CheckmarkFilled16 className='m1-green-icon' />
                      )}
                      &nbsp;
                      {i18n.getStr(
                        'i18nInternational.educationField.measureOne.m1ReviewResultsReceivedModal.schoolOrUniversity',
                      )}
                    </td>
                    <td className='td-border-top-none'>
                      {candidateSchool.name === undefined
                        ? 'N/A'
                        : candidateSchool.name}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {candidateSchool.start_date === undefined ? (
                        <HelpFilled16 />
                      ) : (
                        <CheckmarkFilled16 className='m1-green-icon' />
                      )}
                      &nbsp;
                      {i18n.getStr(
                        'i18nInternational.educationField.measureOne.m1ReviewResultsReceivedModal.startDate',
                      )}
                    </td>
                    <td>
                      {candidateSchool.start_date === undefined
                        ? 'N/A'
                        : candidateSchool.start_date}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {candidateSchool.end_date === undefined ? (
                        <HelpFilled16 />
                      ) : (
                        <CheckmarkFilled16 className='m1-green-icon' />
                      )}
                      &nbsp;
                      {i18n.getStr(
                        'i18nInternational.educationField.measureOne.m1ReviewResultsReceivedModal.endDate',
                      )}
                    </td>
                    <td>
                      {candidateSchool.end_date === undefined
                        ? 'N/A'
                        : candidateSchool.end_date}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {candidateSchool.degree === undefined ? (
                        <HelpFilled16 />
                      ) : (
                        <CheckmarkFilled16 className='m1-green-icon' />
                      )}
                      &nbsp;
                      {i18n.getStr(
                        'i18nInternational.educationField.measureOne.m1ReviewResultsReceivedModal.degree',
                      )}
                    </td>
                    <td>
                      {candidateSchool.degree === undefined
                        ? 'N/A'
                        : candidateSchool.degree}
                    </td>
                  </tr>
                  <tr>
                    <td className='m1-results-table-left-without-border'>
                      {candidateSchool.major === undefined ? (
                        <HelpFilled16 />
                      ) : (
                        <CheckmarkFilled16 className='m1-green-icon' />
                      )}
                      &nbsp;
                      {i18n.getStr(
                        'i18nInternational.educationField.measureOne.m1ReviewResultsReceivedModal.major',
                      )}
                    </td>
                    <td className='m1-results-table-right-without-border'>
                      {candidateSchool.major === undefined
                        ? 'N/A'
                        : candidateSchool.major}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

AcceptedResultsTable.propTypes = {
  candidateSchool: PropTypes.object.isRequired,
};

export default AcceptedResultsTable;
