import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import VerificationMessage from './VerificationMessage';
import LoadingContainer from './LoadingContainer';
import PiiContainer from './PiiContainer';

const VerificationResultsContainer = styled.div`
  width: 100%;
  .pii-container > div:first-child {
    border-top: 1px solid #ffffff;
  }
  .pii-container > div:not(:first-child) {
    border-top: 1px solid #e1e6eb;
    padding-top: 10px;
  }
`;

const IdentityVerificationResults = ({
  verificationCompleted,
  extractedPii,
}) => (
  <VerificationResultsContainer data-testid='verification-results-container'>
    <VerificationMessage verificationCompleted={verificationCompleted} />
    <LoadingContainer verificationCompleted={verificationCompleted} />
    {verificationCompleted && <PiiContainer extractedPii={extractedPii} />}
  </VerificationResultsContainer>
);

IdentityVerificationResults.propTypes = {
  verificationCompleted: PropTypes.bool.isRequired,
  extractedPii: PropTypes.object.isRequired,
};

export default IdentityVerificationResults;
