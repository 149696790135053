import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { change, formValueSelector, Field } from 'redux-form';

import { Checkbox } from './fields';

import * as V from '../lib/validations';

import HTMLText from './HTMLText';
import Panel from './Panel';

export const CaliforniaDisclosureComponent = ({
  content,
  hideBgcCopy,
  i18nOverrides,
  noScroll,
}) => {
  const onCopyRequestedUpdate = ({ target: { checked } }) => {
    if (checked) {
      change('applyform', 'copyRequested', null);
    }
  };

  return (
    <Panel title='components.CaliforniaDisclosure.title' icon='fa-file-text'>
      <div
        data-testid='scroll-wrapper'
        className={noScroll ? '' : 'scrollable'}
      >
        <HTMLText content={content} />
      </div>
      <hr />
      <div className='col-md-12'>
        <Field
          type='checkbox'
          name='caDisclosure'
          label='components.CaliforniaDisclosure.label'
          i18nOverrides={i18nOverrides}
          component={Checkbox}
          validate={[V.requiredCheckbox]}
          labelFeedback
          boldLabel
        />
      </div>

      {!hideBgcCopy && (
        <div className='col-md-12'>
          <Field
            type='checkbox'
            name='copyRequested'
            label='components.StandardConsent.label'
            component={Checkbox}
            boldLabel
            onChange={onCopyRequestedUpdate}
          />
        </div>
      )}
    </Panel>
  );
};

CaliforniaDisclosureComponent.propTypes = {
  content: PropTypes.string.isRequired,
  hideBgcCopy: PropTypes.bool,
  i18nOverrides: PropTypes.object,
  noScroll: PropTypes.bool,
};

CaliforniaDisclosureComponent.defaultProps = {
  hideBgcCopy: false,
  i18nOverrides: {},
  noScroll: false,
};

const selector = formValueSelector('applyform');
const ConnectedCaliforniaDisclosure = connect(
  state => {
    return {
      copyRequested: selector(state, 'copyRequested'),
    };
  },
  { change },
)(CaliforniaDisclosureComponent);

export default ConnectedCaliforniaDisclosure;
