/* eslint-disable react/require-default-props */
/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-redux-i18n';

import { groupClass } from './helper';
import FeedbackLabel from './FeedbackLabel';
import Label from './Label';

const ButtonSelect = ({
  boldLabel,
  className,
  label,
  onClick,
  options,
  selectedValue,
  type,
  meta: { touched, error, warning },
}) => {
  return (
    <div className={`${groupClass(touched, error)} ${className}`}>
      <p>
        {boldLabel ? (
          <strong>
            <Label label={label} />
          </strong>
        ) : (
          <Label label={label} />
        )}
      </p>
      <div className='btn-group d-flex' role='group'>
        {options.map(option => {
          const selectedClass = selectedValue === option ? 'selected' : '';
          return (
            <button
              key={option}
              id={`${className}-${option}`}
              className={`btn btn-secondary w-100 ${selectedClass}`}
              onClick={() => onClick(option)}
              type={type}
            >
              <Translate value={`buttons.${option}`} />
            </button>
          );
        })}
      </div>
      <br />
      <FeedbackLabel {...{ touched, error, warning }} />
    </div>
  );
};

ButtonSelect.propTypes = {
  boldLabel: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string),
  meta: PropTypes.object,
  selectedValue: PropTypes.string,
  type: PropTypes.string,
};

export default ButtonSelect;
