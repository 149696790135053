import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import PropTypes from 'prop-types';
import HTMLText from '../HTMLText';

const ArgyleLinkErrorModal = ({ onClose }) => {
  return (
    <M.ComposedModal
      open
      size='sm'
      onClose={onClose}
      className='argyle-link-error'
    >
      <M.ModalBody id='body-content'>
        <div className='row'>
          <h2>
            {i18n.getStr(
              `components.EmploymentForm.ArgyleForm.linkErrorModal.headerText`,
            )}
          </h2>
        </div>
        <div className='row'>
          <HTMLText
            content={i18n.getStr(
              `components.EmploymentForm.ArgyleForm.linkErrorModal.bodyText`,
            )}
          />
        </div>
        <M.Button onClick={onClose} kind='btn-primary'>
          {i18n.getStr(
            `components.EmploymentForm.ArgyleForm.linkErrorModal.buttonText`,
          )}
        </M.Button>
      </M.ModalBody>
    </M.ComposedModal>
  );
};

ArgyleLinkErrorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ArgyleLinkErrorModal;
