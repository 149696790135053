export const employmentDocuments = [
  { value: '', name: 'options.selectADocument', i18n: true },
  {
    value: 'tax_form_w2',
    name: 'options.employmentDocuments.taxFormW2',
    i18n: true,
  },
  {
    value: 'tax_form_1099',
    name: 'options.employmentDocuments.taxForm1099',
    i18n: true,
  },
  { value: 'paystub', name: 'options.employmentDocuments.paystub', i18n: true },
  {
    value: 'tax_form_schedule_c',
    name: 'options.employmentDocuments.taxFormScheduleC',
    i18n: true,
  },
];

export const higherEducationDocuments = [
  { value: '', name: 'options.selectADocument', i18n: true },
  {
    value: 'degree',
    name: 'options.educationDocuments.degree',
    i18n: true,
  },
  {
    value: 'education_certificate',
    name: 'options.educationDocuments.educationCertificate',
    i18n: true,
  },
  {
    value: 'transcript',
    name: 'options.educationDocuments.transcript',
    i18n: true,
  },
];

export const educationDocuments = [
  { value: '', name: 'options.selectADocument', i18n: true },
  {
    value: 'degree',
    name: 'options.educationDocuments.degree',
    i18n: true,
  },
  {
    value: 'diploma',
    name: 'options.educationDocuments.diploma',
    i18n: true,
  },
  {
    value: 'education_certificate',
    name: 'options.educationDocuments.educationCertificate',
    i18n: true,
  },
  {
    value: 'transcript',
    name: 'options.educationDocuments.transcript',
    i18n: true,
  },
];

export const gedDocuments = [
  { value: '', name: 'options.selectADocument', i18n: true },
  {
    value: 'diploma',
    name: 'options.educationDocuments.diploma',
    i18n: true,
  },
  {
    value: 'education_certificate',
    name: 'options.educationDocuments.educationCertificate',
    i18n: true,
  },
  {
    value: 'transcript',
    name: 'options.educationDocuments.transcript',
    i18n: true,
  },
];
