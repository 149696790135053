import React, { useEffect } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import PropTypes from 'prop-types';
import HTMLText from '../HTMLText';
import {
  EMPLOYMENT_ANALYTICS_EVENTS,
  EMPLOYMENT_ANALYTICS_PROPERTIES,
} from '../../lib/employment/analytics_events';

const ArgyleLearnMoreModal = ({
  isOpen,
  handleClose,
  trackArgyleAnalyticsEvent,
  source,
}) => {
  useEffect(() => {
    // Track analytics event when modal is first opened, and not on every render.
    const trackArgyleAnalyticsEventOnOpen = () => {
      trackArgyleAnalyticsEvent(
        EMPLOYMENT_ANALYTICS_EVENTS.ARGYLE_LEARN_MORE_VIEWED,
        {
          [EMPLOYMENT_ANALYTICS_PROPERTIES.ARGYLE_LEARN_MORE_SOURCE]: source,
        },
      );
    };

    if (isOpen) {
      trackArgyleAnalyticsEventOnOpen();
    }
  }, [isOpen, source, trackArgyleAnalyticsEvent]);

  const yourDataText = i18n.getStr(
    'components.EmploymentForm.ArgyleForm.learnMoreModal.body.yourData.subtext',
    [
      i18n.getStr(
        'components.EmploymentForm.ArgyleForm.learnMoreModal.body.yourData.howItWorksUrl',
      ),
      i18n.getStr(
        'components.EmploymentForm.ArgyleForm.learnMoreModal.body.yourData.endUserTermsUrl',
      ),
    ],
  );

  return (
    <M.ComposedModal
      open={isOpen}
      size='sm'
      className='argyle-learn-more'
      onClose={handleClose}
      id='learnMore'
    >
      <M.ModalHeader closeModal={handleClose}>
        <h2>
          {i18n.getStr(
            `components.EmploymentForm.ArgyleForm.learnMoreModal.header`,
          )}
        </h2>
      </M.ModalHeader>
      <M.ModalBody className='body-content'>
        <h2>
          {i18n.getStr(
            `components.EmploymentForm.ArgyleForm.learnMoreModal.body.howItWorks`,
          )}
        </h2>
        <div className='info-bullets'>
          <div className='section'>
            <div className='row'>
              <M.Icon icon='DirectLink' size='20' />
              <h3>
                {i18n.getStr(
                  `components.EmploymentForm.ArgyleForm.learnMoreModal.body.connect.header`,
                )}
              </h3>
            </div>
            <div className='row'>
              <HTMLText
                className='subtext'
                content={i18n.getStr(
                  `components.EmploymentForm.ArgyleForm.learnMoreModal.body.connect.subtext`,
                )}
              />
            </div>
          </div>
          <div className='section'>
            <div className='row'>
              <M.Icon icon='DataCheck' size='20' />
              <h3>
                {i18n.getStr(
                  `components.EmploymentForm.ArgyleForm.learnMoreModal.body.reviewAndVerify.header`,
                )}
              </h3>
            </div>
            <div className='row'>
              <HTMLText
                className='subtext'
                content={i18n.getStr(
                  `components.EmploymentForm.ArgyleForm.learnMoreModal.body.reviewAndVerify.subtext`,
                )}
              />
            </div>
          </div>
          <div className='section'>
            <hr className='ruled' />
          </div>
          <div className='section'>
            <div className='row'>
              <M.Icon icon='DataCollection' size='20' />
              <h3>
                {i18n.getStr(
                  `components.EmploymentForm.ArgyleForm.learnMoreModal.body.whatIsArgyle.header`,
                )}
              </h3>
            </div>
            <div className='row'>
              <HTMLText
                className='subtext'
                content={i18n.getStr(
                  `components.EmploymentForm.ArgyleForm.learnMoreModal.body.whatIsArgyle.subtext`,
                )}
              />
            </div>
          </div>
          <div className='section'>
            <div className='row'>
              <M.Icon icon='Security' size='20' />
              <h3>
                {i18n.getStr(
                  `components.EmploymentForm.ArgyleForm.learnMoreModal.body.yourData.header`,
                )}
              </h3>
            </div>
            <div className='row'>
              <HTMLText className='subtext' content={yourDataText} />
            </div>
          </div>
        </div>
      </M.ModalBody>
      <M.ModalFooter>
        <M.Button
          onClick={handleClose}
          kind='btn-primary'
          className='dismiss-learn-more'
        >
          {i18n.getStr(
            `components.EmploymentForm.ArgyleForm.learnMoreModal.buttonText`,
          )}
        </M.Button>
      </M.ModalFooter>
    </M.ComposedModal>
  );
};
ArgyleLearnMoreModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  trackArgyleAnalyticsEvent: PropTypes.func.isRequired,
  source: PropTypes.string.isRequired,
};

export default ArgyleLearnMoreModal;
