/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { i18n } from '@international/mastodon-i18n';
import { Field } from 'redux-form';
import Panel from '../Panel';
import EmploymentPeriod from './EmploymentPeriod';
import { RemoveFieldButton, Textarea } from '../fields';

class Gap extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    fieldName: PropTypes.string,
    employer: PropTypes.object,
    fieldLength: PropTypes.number,
    handleOnRemove: PropTypes.func,
    i: PropTypes.number,
    nth: PropTypes.number,
  };

  gapTitle = () => {
    const { nth } = this.props;
    return i18n.getStr(
      `components.EmploymentGap.ordinal_${+nth < 10 ? +nth : 'other'}`,
      {
        count: +nth + 1,
        ordinal: true,
      },
    );
  };

  render() {
    const { fieldLength, fieldName, handleOnRemove, i } = this.props;

    return (
      <Panel>
        <div className='row'>
          <div className='col-md-12'>
            <h4 className='card-block-heading'>{this.gapTitle()}</h4>
          </div>
        </div>

        <EmploymentPeriod {...this.props} presentLabel='labels.present' />

        <div className='row'>
          <div className='col-md-12'>
            <Field
              type='text'
              name={`${fieldName}.note`}
              label='labels.employmentGapNote'
              component={Textarea}
              rows={3}
              // needs to be an empty string or else the placeholder will default to label
              placeholder=' '
            />
          </div>
        </div>

        {fieldLength > 1 && (
          <RemoveFieldButton
            handleOnClick={() => handleOnRemove(i)}
            textValue={i18n.getStr(
              'components.EmploymentGap.employmentGapRemovalText',
            )}
            showIcon={false}
            className='btn btn-secondary'
          />
        )}
      </Panel>
    );
  }
}

export default Gap;
