import { PureComponent } from 'react';
import { i18n } from '@international/mastodon-i18n';
import { camelize } from 'humps';
import $RefParser, { JSONSchema } from 'json-schema-ref-parser';
import { capitalize, Dictionary, get, omitBy, isNil, map } from 'lodash';
import { merge } from 'lodash/fp';
import countries, { LocaleData } from 'i18n-iso-countries';

import RegionRequirements from './Components/RegionRequirements';
import ApplyFormManager, {
  intlI18nFunc,
} from './FormLogic/ApplyFormManager.class';
import FieldDeterminer from './Fields/FieldCreationLogic/FieldDeterminer.class';
import { intlDefaultValues } from '../reducers/defaultValues';
import { initialValueTree } from './Fields/FieldCreationLogic/Field.types';
import { INFORMATION, JsonSchema } from './types/schema.types';
import {
  Props as InternationalProps,
  State as InternationalState,
} from './International';
import filterOutCountries from '../lib/utils/intlCountryFilter';

type Context = PureComponent<InternationalProps, InternationalState>;
interface Locale extends LocaleData {
  default: LocaleData;
}

function prettyify(str: string) {
  return str.replace(/_|-/gi, ' ');
}

const getAlpha2CodeToName = (
  locale: Locale,
  lang: string,
): InternationalState['alpha2CodeToName'] => {
  countries.registerLocale(locale.default);
  return map(
    filterOutCountries(Object.keys(countries.getAlpha2Codes())),
    code => ({
      id: code,
      label: countries.getName(code, lang),
    }),
  );
};

class InternationalHelper {
  static lang = i18n.defaultLocale;

  static translations: Dictionary<Dictionary<string>> = {};

  static handleIntlTranslations: intlI18nFunc = (
    key: string,
    base?: string,
    undefinedIfNotFound = false,
  ) => {
    const international = 'international';
    const currentTranslation =
      InternationalHelper.translations[InternationalHelper.lang];
    const keyToUse = camelize(key);

    const intlPath = base
      ? `${international}.${base}.${keyToUse}`
      : `${international}.${keyToUse}`;
    const path = base ? `${base}.${keyToUse}` : keyToUse;

    const intlTranslation = get(currentTranslation, intlPath);
    if (intlTranslation) {
      return intlTranslation;
    }
    const domTranslation = get(currentTranslation, path);
    if (domTranslation) {
      return domTranslation;
    }

    // In some cases we only want to display a translation if that text has a translation
    // for that text (e.g., not everything has a description), make that configurable
    if (undefinedIfNotFound) {
      return undefined;
    }
    return capitalize(prettyify(key));
  };

  static countryList(context: Context) {
    const { lang = i18n.defaultLocale } = context.props;
    const formattedLang = lang.slice(0, 2);
    import(`i18n-iso-countries/langs/${formattedLang}.json`).then(
      (locale: Locale) => {
        const alpha2CodeToName = getAlpha2CodeToName(locale, formattedLang);
        InternationalHelper.parseSchema(context, alpha2CodeToName);
        context.setState({
          alpha2CodeToName,
        });
      },
    );
  }

  static parseSchema(
    context: Context,
    alpha2CodeToName: InternationalState['alpha2CodeToName'],
  ) {
    const {
      lang = i18n.defaultLocale,
      schema = {},
      translations,
      company,
    } = context.props;
    InternationalHelper.lang = lang;
    InternationalHelper.translations = translations;
    $RefParser
      .dereference(schema, {
        dereference: { circular: 'ignore' },
        resolve: { external: false } as any,
      })
      .then(parsedSchema => {
        const applyFormManager = new ApplyFormManager(
          parsedSchema as any,
          alpha2CodeToName,
          InternationalHelper.lang,
          InternationalHelper.handleIntlTranslations,
        );
        const initialCandidate = omitBy(context.props.candidate, isNil);
        const initialValues = [
          ...FieldDeterminer.getInitialValues(
            Object.values(
              applyFormManager.data.schemaAsRenderableFields.properties || {},
            ),
          ),
          intlDefaultValues,
          initialCandidate,
        ].reduce(merge, {}) as initialValueTree;
        context.setState({
          intlContext: { ...applyFormManager, company },
          pages: applyFormManager.data.screeningsInOrder.filter(
            (screeningName: string) =>
              applyFormManager.data.applyForm.screenings[screeningName] ||
              (screeningName === INFORMATION &&
                Object.keys(applyFormManager.data.oneOfKeys).length),
          ),
          initialValues,
        });
      });
  }

  static countryListDomestic(
    schema: JsonSchema,
    context: typeof RegionRequirements,
  ) {
    const locale = i18n.getLocale();
    const lang = locale.slice(0, 2);
    InternationalHelper.lang = lang;
    import(`i18n-iso-countries/langs/${lang}.json`)
      .then((data: Locale) => {
        const alpha2CodeToName = getAlpha2CodeToName(data, lang);
        InternationalHelper.parseSchemaDomestic(
          schema,
          alpha2CodeToName,
          context,
        );
      })
      .finally(() => context.setState({ locale }));
  }

  static parseSchemaDomestic(
    schema: JsonSchema,
    alpha2CodeToName: InternationalState['alpha2CodeToName'],
    context: typeof RegionRequirements,
  ) {
    const { translations } = context.props;
    InternationalHelper.translations = translations;
    $RefParser
      .dereference(schema as JSONSchema, {
        dereference: { circular: 'ignore' },
        resolve: { external: false } as any,
      })
      .then(parsedSchema => {
        const intlContextForDomestic = new ApplyFormManager(
          parsedSchema as JsonSchema,
          alpha2CodeToName,
          InternationalHelper.lang,
          InternationalHelper.handleIntlTranslations,
        );
        context.setState({ intlContextForDomestic });
      });
  }
}

export default InternationalHelper;
