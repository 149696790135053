import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { M } from '@dashboard-experience/mastodon';

import {
  connectFakeAccount,
  devResetArgyleData,
  devSetArgyleState,
  insertCurrentVerifiedArgyleEmployment,
  insertOldVerifiedArgyleEmployment,
  insertRecentVerifiedArgyleEmployment,
  insertUnverifiedArgyleEmployment,
  insertVerifiedArgyleEmploymentNoPosition,
  insertVerifiedArgyleEmploymentNoContractType,
} from './actions';

const ArgyleDevToolsMenu = ({ dispatch }) => {
  return (
    <div className='argyle-dev-tools-menu'>
      <M.OverflowMenu
        direction='top'
        renderIcon={() => <div className='menu-button'>Argyle Dev Tools</div>}
      >
        <M.OverflowMenuItem
          itemText='Argyle State: Uninitialized'
          onClick={() => dispatch(devSetArgyleState('uninitialized'))}
          className='argyle-dev-tools-menu-item'
        />
        <M.OverflowMenuItem
          itemText='Argyle State: Link Init (5 seconds)'
          onClick={() => {
            dispatch(devSetArgyleState('link-init'));
            setTimeout(() => {
              dispatch(devSetArgyleState('uninitialized'));
            }, 5000);
          }}
          className='argyle-dev-tools-menu-item'
        />
        <M.OverflowMenuItem
          itemText='Argyle State: Link Error'
          onClick={() => dispatch(devSetArgyleState('link-error'))}
          className='argyle-dev-tools-menu-item'
        />
        <M.OverflowMenuItem
          itemText='Argyle State: Polling'
          onClick={() => dispatch(devSetArgyleState('polling'))}
          className='argyle-dev-tools-menu-item'
        />
        <M.OverflowMenuItem
          itemText='Argyle State: Polling Timeout'
          onClick={() => dispatch(devSetArgyleState('polling-timeout'))}
          className='argyle-dev-tools-menu-item'
        />
        <M.OverflowMenuItem
          itemText='Argyle State: Reviewing'
          onClick={() => dispatch(devSetArgyleState('reviewing'))}
          className='argyle-dev-tools-menu-item'
        />
        <M.OverflowMenuItem
          itemText='Connect Fake Argyle Account (for Polling Timeout)'
          onClick={() => dispatch(connectFakeAccount())}
          className='argyle-dev-tools-menu-item'
        />
        <M.OverflowMenuItem
          itemText='Insert Current Verified Argyle Employment'
          onClick={() => dispatch(insertCurrentVerifiedArgyleEmployment())}
          className='argyle-dev-tools-menu-item'
        />
        <M.OverflowMenuItem
          itemText='Insert Recent Verified Argyle Employment'
          onClick={() => dispatch(insertRecentVerifiedArgyleEmployment())}
          className='argyle-dev-tools-menu-item'
        />
        <M.OverflowMenuItem
          itemText='Insert Verified Argyle Employment (No Position)'
          onClick={() => dispatch(insertVerifiedArgyleEmploymentNoPosition())}
          className='argyle-dev-tools-menu-item'
        />
        <M.OverflowMenuItem
          itemText='Insert Verified Argyle Employment (No Contract Type)'
          onClick={() =>
            dispatch(insertVerifiedArgyleEmploymentNoContractType())
          }
          className='argyle-dev-tools-menu-item'
        />
        <M.OverflowMenuItem
          itemText='Insert Old Verified Argyle Employment'
          onClick={() => dispatch(insertOldVerifiedArgyleEmployment())}
          className='argyle-dev-tools-menu-item'
        />
        <M.OverflowMenuItem
          itemText='Insert Unverified Argyle Employment'
          onClick={() => dispatch(insertUnverifiedArgyleEmployment())}
          className='argyle-dev-tools-menu-item'
        />
        <M.OverflowMenuItem
          itemText='Reset Argyle Data'
          onClick={() => dispatch(devResetArgyleData())}
          className='argyle-dev-tools-menu-item'
        />
      </M.OverflowMenu>
    </div>
  );
};

ArgyleDevToolsMenu.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(ArgyleDevToolsMenu);
