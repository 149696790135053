/* eslint-disable react/require-default-props */
import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'lodash';

import { I18n } from 'react-redux-i18n';
import { groupClass } from './helper';
import FeedbackLabel from './FeedbackLabel';
import Label from './Label';

const TextInputGroup = ({
  autoFocus,
  disabled,
  input,
  label,
  meta: { touched, error, warning },
  placeholder,
  type,
  onPaste,
  onChange,
  onKeyDown,
  buttonText,
  buttonClass,
  buttonIconClass,
  buttonOnClick,
  value,
}) => {
  return (
    <div className={groupClass(touched, error)}>
      <Label label={label} htmlFor={input.name} />
      <div className='input-group'>
        <input
          {...input}
          id={replace(input.name, '.', '-')}
          type={type}
          className='form-control'
          placeholder={I18n.t(placeholder || label)}
          onChange={onChange}
          onKeyDown={onKeyDown}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          disabled={disabled}
          onPaste={onPaste}
          value={value || undefined}
        />
        <div className='input-group-append'>
          <button
            className={buttonClass}
            onClick={buttonOnClick}
            disabled={disabled}
            type='button'
          >
            <i className={buttonIconClass} aria-hidden='true' />{' '}
            {I18n.t(buttonText)}
          </button>
        </div>
      </div>
      <FeedbackLabel {...{ touched, error, warning }} />
    </div>
  );
};

TextInputGroup.propTypes = {
  autoFocus: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonClass: PropTypes.string,
  buttonIconClass: PropTypes.string,
  buttonOnClick: PropTypes.func,
  disabled: PropTypes.bool,
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onPaste: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
};
const mapStateToProps = ({ i18n }) => ({ i18n });

export default connect(mapStateToProps)(TextInputGroup);
