import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';

import { Navbar, NavbarBrand } from 'reactstrap';

const ExpungementHeader = ({ partner }) => (
  <Navbar className='expungement-header'>
    <div className='container'>
      <NavbarBrand className='d-flex justify-content-start'>
        <img
          className='logo'
          src='https://assets.checkr.com/logo-white-2022.svg'
          alt='Checkr logo'
        />
        <div className='circle' />
        {!!partner && partner === 'timedone' ? (
          <img
            className='externalLogo'
            src='https://images.squarespace-cdn.com/content/v1/61927a31a5673e66c896b1b7/dc1d69ca-bcfd-42fc-a53a-dbe6b77402c1/timedone-logo.png'
            alt='TimeDone logo'
          />
        ) : (
          <div className='align-self-end title'>
            <T value='components.ExpungementHeader.title' />
          </div>
        )}
      </NavbarBrand>
    </div>
  </Navbar>
);

ExpungementHeader.propTypes = {
  partner: PropTypes.string,
};

ExpungementHeader.defaultProps = {
  partner: undefined,
};

export default ExpungementHeader;
