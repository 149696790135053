import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import React, { PureComponent } from 'react';
import { WrappedMDropdownProps } from './WrappedComp.types';

export type DropdownListItem = { id: string; label: string };

type Props = {
  valueOnInit?: (val?: boolean) => void;
};

class ReduxMCombobox extends PureComponent<WrappedMDropdownProps & Props, {}> {
  componentDidMount() {
    const { input, valueOnInit } = this.props;
    valueOnInit && valueOnInit(input?.value);
  }

  render() {
    const {
      id,
      titleText,
      label,
      disabled,
      invalid,
      items,
      input,
      meta,
      onChange,
    } = this.props;
    const { touched, error } = meta;
    const value = input?.value;

    const changeFunc = (event: any) => {
      const item = event.selectedItem ? event.selectedItem.id : null;
      input?.onChange(item);
      if (onChange !== undefined) {
        onChange(item, event, value);
      }
    };
    const selectedItem = items.find(item => item.id === value);

    const shouldFilterItem = (event: any) => {
      const { item, inputValue } = event;
      if (!inputValue || inputValue === '') {
        return true;
      }

      return item.label.toLowerCase().startsWith(inputValue.toLowerCase());
    };

    const onFocus = (event: any) => {
      // Chrome seems to be ignoring autocomplete off. If autocomplete is set to a random string
      // then nothing is displayed. The combobox only allows autocomplete to be boolean. Use
      // onfocus event to set to off.
      // https://stackoverflow.com/questions/12374442/chrome-ignores-autocomplete-off
      // eslint-disable-next-line no-param-reassign
      event.target.autocomplete = 'no';
    };

    return (
      <M.ComboBox
        className='mastodon-combobox-container'
        light={false}
        id={id}
        titleText={titleText || <span>&nbsp;</span>}
        label={label}
        disabled={disabled}
        items={items}
        invalid={(touched && !!error) || invalid}
        invalidText={touched && error && i18n.getStr(error)}
        placeholder={label}
        onChange={changeFunc}
        shouldFilterItem={shouldFilterItem}
        selectedItem={selectedItem}
        onFocus={onFocus}
        autoComplete='off'
      />
    );
  }
}

export default ReduxMCombobox;
