/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { M } from '@dashboard-experience/mastodon';
import { Translate as T } from 'react-redux-i18n';

const InputNonTranslate = ({ aria = '', className, icon, label, value }) => {
  return (
    <div className={`${className} inputs-container`} key={label}>
      <h3 className='input-label' aria-label={aria || value}>
        <T value={label} />
        {icon && <M.Icon className='icon' icon={icon} />}
      </h3>
      <div className='input-value' aria-label={value}>
        {value}
      </div>
    </div>
  );
};

InputNonTranslate.propTypes = {
  aria: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  value: PropTypes.string.isRequired,
};

export default InputNonTranslate;
