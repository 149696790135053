import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import ArgyleReviewManualEmploymentExpandable from './ArgyleReviewManualEmploymentExpandable';

const ArgyleReviewManualEmployments = ({
  maximumAllowedEmployers,
  manualEmployments,
  selectedManualEmployments,
  numSelectedArgyleEmployments,
  isPreExpanded,
  handleManualEmploymentSelected,
  handleManualEmploymentUnselected,
}) => {
  const maxAllowedEmployersReached = () => {
    return !!(
      maximumAllowedEmployers &&
      selectedManualEmployments.length + numSelectedArgyleEmployments >=
        maximumAllowedEmployers
    );
  };

  const anyManualEmploymentsUnselected = () => {
    return selectedManualEmployments.length < manualEmployments.length;
  };

  return (
    <>
      <h3>
        <T value='components.EmploymentForm.ArgyleForm.reviewComponents.addedManually' />
      </h3>

      {anyManualEmploymentsUnselected() && (
        <p className='manual-removal-warning'>
          <T value='components.EmploymentForm.ArgyleForm.reviewComponents.manualRemovalWarning' />
        </p>
      )}

      {manualEmployments.map((employment, index) => {
        const isPreSelected = true;

        return (
          <ArgyleReviewManualEmploymentExpandable
            key={`argyle-review-employment-${employment.addedAt}`}
            disabled={false}
            initialExpanded={isPreExpanded}
            initialSelected={isPreSelected}
            maxAllowedEmployers={maximumAllowedEmployers}
            maxAllowedEmployersReached={maxAllowedEmployersReached()}
            employment={employment}
            index={index}
            onSelected={selectedManualEmployment => {
              handleManualEmploymentSelected(selectedManualEmployment);
            }}
            onUnselected={unselectedManualEmployment => {
              handleManualEmploymentUnselected(unselectedManualEmployment);
            }}
          />
        );
      })}
    </>
  );
};

ArgyleReviewManualEmployments.propTypes = {
  maximumAllowedEmployers: PropTypes.number,
  manualEmployments: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedManualEmployments: PropTypes.arrayOf(PropTypes.object).isRequired,
  numSelectedArgyleEmployments: PropTypes.number.isRequired,
  isPreExpanded: PropTypes.bool.isRequired,
  handleManualEmploymentSelected: PropTypes.func.isRequired,
  handleManualEmploymentUnselected: PropTypes.func.isRequired,
};

ArgyleReviewManualEmployments.defaultProps = {
  maximumAllowedEmployers: null, // Unlimited max employers.
};

export default ArgyleReviewManualEmployments;
