import React from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues } from 'redux-form';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';

import { get } from 'lodash';
import ReduxMRadio from '../international/Fields/FieldComponents/WrappedMastodonComps/Radio';

const titleMap: { [key: string]: string } = {
  'scoped_requirements.CA.no_aliases':
    'international.labels.scopedRequirements.CA.aliases',
};

const ChoiceCard = ({
  id,
  disabled,
  onChange,
  title,
  fieldValues,
}: {
  id: string;
  disabled: Boolean | undefined;
  onChange: Function | undefined;
  title: string;
  fieldValues: { string: string | number | boolean | undefined };
}) => {
  const fieldValue = get(fieldValues, id);
  const key = `scoped_requirements.CA.${id.split('_').at(-1)}`;
  const arrayValue = get(fieldValues, key);

  // This is necessary because navigating away from the page somehow wipes out the value of the boolean.
  // TODO: investigate this issue

  const choiceCardValue =
    fieldValue === undefined
      ? arrayValue?.length > 0 || undefined
      : !fieldValue;

  const yesId = `${id}_yes`;
  const noId = `${id}_no`;
  return (
    <div className='choice-card-container'>
      {titleMap[id] && (
        <div className='choice-card-title'>{i18n.getStr(titleMap[id])}</div>
      )}
      <div className='choice-card-text'>{title}</div>
      <div className='choice-card-button-container'>
        <M.Card
          data-testid={yesId}
          className={`choice-card ${
            choiceCardValue ? 'selected-choice-card' : ''
          }`}
          onClick={() => document.getElementById(yesId)?.click()}
          role='button'
        >
          <Field
            id={yesId}
            name={id}
            component={(field: any) => {
              return (
                <ReduxMRadio
                  labelText={i18n.getStr('buttons.yes')}
                  value={false}
                  onChange={onChange}
                  {...field}
                  input={{
                    ...field.input,
                    checked: choiceCardValue !== undefined && !!choiceCardValue,
                  }}
                />
              );
            }}
            type='radio'
            disabled={disabled}
            onChange={onChange}
            value={false}
          />
        </M.Card>
        <M.Card
          className={`choice-card ${
            choiceCardValue !== undefined && !choiceCardValue
              ? 'selected-choice-card'
              : ''
          }`}
          data-testid={noId}
          onClick={() => document.getElementById(noId)?.click()}
          role='button'
        >
          <Field
            id={noId}
            name={id}
            component={(field: any) => {
              return (
                <ReduxMRadio
                  labelText={i18n.getStr('buttons.no')}
                  value
                  onChange={onChange}
                  {...field}
                  input={{
                    ...field.input,
                    checked: choiceCardValue !== undefined && !choiceCardValue,
                  }}
                />
              );
            }}
            onChange={onChange}
            type='radio'
            disabled={disabled}
            value
          />
        </M.Card>
      </div>
    </div>
  );
};

export default connect((state: any) => ({
  fieldValues: getFormValues('intlForm')(state) as any,
}))(ChoiceCard);
