import { requestFilePolicy } from 'actions';
import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';

const useRequestFilePolicy = () => {
  const [filestackClientSecurity, setFilestackClientSecurity] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    requestFilePolicy()
      .then(securityJson => {
        setFilestackClientSecurity(securityJson);
        setIsLoading(false);
      })
      .catch(err => {
        Sentry.captureException(
          'Error retrieving Filestack security policy',
          err,
        );
      });
  }, []);

  return {
    isLoading,
    filestackClientSecurity,
  };
};

export { useRequestFilePolicy as default };
