/* eslint-disable react/require-default-props */
/* eslint-disable no-shadow */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate as T } from 'react-redux-i18n';
import { change, Field, formValueSelector } from 'redux-form';
import * as KeyCode from 'keycode-js';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { TextInputGroup, Radio, Checkbox } from './fields';
import Panel from './Panel';
import * as V from '../lib/validations';
import {
  requestLocations,
  updateSearchZipcode,
  updateSearchExtendedHours,
  manualLocationSelect,
  clearNavigationInterrupt,
  registerNavigationInterrupt,
  openModal,
  closeModal,
  handleRequireMailingAddress,
  selectOneEightHundredFlow,
} from '../actions';
import getAvailability from '../lib/formatters/locationAvailability';
import { formatPhoneNumber } from '../lib/helpers';
import GhostLoader from './GhostLoader';
import Map from './Map';

const ElectronicLocationTag = () => (
  <p className='location-type'>
    <i className='fa fa-bolt' />
    <T value='components.ScheduleComponent.electronic.tag' />
  </p>
);

const PaperLocationTag = () => (
  <p className='location-type'>
    <i className='mailbox' />
    <T value='components.ScheduleComponent.nonElectronic.tag' />
  </p>
);

const locationsStats = locations => {
  if (!locations) return {};
  if (locations.length === 0) return {};

  const distances = locations.map(loc => loc.distance);

  return {
    hasAdditionalLocations: !!locations[locations.length - 1]
      .isAdditionalLocation,
    minDistance: Math.min(...distances),
    maxDistance: Math.max(...distances),
    averageDistance:
      distances.reduce((dist1, dist2) => dist1 + dist2) / locations.length,
  };
};

const LocationPanelList = ({
  locations,
  examSlugs,
  selectedLocation,
  showTypes,
  searchedZipcode,
  stats,
}) => {
  return (
    <span>
      {locations &&
        locations.map((location, i) => {
          const {
            siteId,
            siteName,
            address1,
            address2,
            distance,
            distanceUnit,
            phoneNumber,
            electronicallyEnabled,
            isAdditionalLocation,
          } = location;
          const fullAddress = address1 + (address2 ? ` ${address2} ` : '');
          const checked = selectedLocation
            ? location.siteId === selectedLocation.siteId
            : false;
          const value = {
            siteId,
            zipcode: searchedZipcode,
            examSlugs,
            isAdditionalLocation,
            distance,
            siteAddress: {
              siteName,
              address: fullAddress,
              phoneNumber,
            },
            ...stats,
          };
          const className = `location-panel row col-lg-12 ${
            checked ? 'selected' : ''
          }`;
          const businessHours = getAvailability(location);
          const businessDaysStr = businessHours.daysOpen.join(' - ');
          const TypeTag = electronicallyEnabled
            ? ElectronicLocationTag
            : PaperLocationTag;

          return (
            <Field
              key={`field-${i + 1}`}
              name='location'
              component={Radio}
              validate={[V.required]}
              props={{ checked, value, className }}
            >
              <Panel className='col-lg-12'>
                <p className='distance float-right'>
                  {distance} {distanceUnit}
                </p>
                <p className='name'>
                  <strong>{`${i + 1}. ${siteName}`}</strong>
                  {showTypes && <TypeTag />}
                </p>
                <p className='address'>{fullAddress}</p>
                {!checked && (
                  <p className='business-hours-short float-right'>
                    OPEN {businessDaysStr.toUpperCase()}
                  </p>
                )}
                <p className='phone-number'>{formatPhoneNumber(phoneNumber)}</p>
                {checked && (
                  <div className='business-hours'>
                    {businessHours &&
                      businessHours.dayBlocks.map((block, idx) => (
                        <div key={`days-${idx + 1}`}>
                          <p className='days'>
                            <strong>{block.daySpanFull.join(' - ')}</strong>
                          </p>
                          <p className='hours'>{block.businessHours}</p>
                        </div>
                      ))}
                  </div>
                )}
              </Panel>
            </Field>
          );
        })}
    </span>
  );
};

LocationPanelList.propTypes = {
  locations: PropTypes.array,
  examSlugs: PropTypes.array,
  selectedLocation: PropTypes.object,
  showTypes: PropTypes.bool,
  searchedZipcode: PropTypes.string,
  stats: PropTypes.object,
};

const NonElectronicConfirmationDialogue = ({
  titleKey,
  bodyKey,
  closeModal,
  confirmAction,
  backButtonKey,
  confirmButtonKey,
}) => {
  return (
    <div>
      <div className='header-blue p-4'>
        <h4>
          <T value={titleKey} />
        </h4>
      </div>
      <div className='p-4'>
        <T value={bodyKey} dangerousHTML />
      </div>
      <div className='footer-grey p-4 d-flex justify-content-around'>
        <button
          type='button'
          className='btn btn-secondary'
          onClick={closeModal}
        >
          <i className='fa fa-angle-left' />
          &nbsp;
          <T value={backButtonKey} />
        </button>
        <button
          type='button'
          className='btn btn-secondary'
          onClick={confirmAction}
        >
          <T value={confirmButtonKey} />
          &nbsp;
          <i className='fa fa-angle-right' />
        </button>
      </div>
    </div>
  );
};

NonElectronicConfirmationDialogue.propTypes = {
  titleKey: PropTypes.string.isRequired,
  bodyKey: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  confirmAction: PropTypes.func.isRequired,
  backButtonKey: PropTypes.string.isRequired,
  confirmButtonKey: PropTypes.string.isRequired,
};

export const LocationsAlertMessage = ({ title, details, body, blocker }) => (
  <div className='card-section no-locations'>
    <div className='col-md-12'>
      <div className='alert alert-danger scheduling-error' role='alert'>
        <div>
          <i className='fa fa-warning' />
          <b>
            &nbsp;
            <T value={title} />
          </b>
        </div>
        <p>
          {details ? <T value={details} dangerousHTML /> : <span>{body}</span>}
        </p>
        {blocker && (
          <Field
            name='location-selector-blocker'
            component='input'
            type='hidden'
            validate={V.required}
          />
        )}
      </div>
    </div>
  </div>
);

LocationsAlertMessage.propTypes = {
  title: PropTypes.string,
  details: PropTypes.string,
  body: PropTypes.string,
  blocker: PropTypes.bool,
};

const OneEightHundredLink = styled.a`
  color: ${colors.navy02} !important;
  cursor: pointer;
  font-weight: 800;
  &:hover {
    text-decoration: underline;
  }
`;

export class LocationSelectorComponent extends Component {
  static propTypes = {
    extendedHoursConfigured: PropTypes.bool,
    appointmentContext: PropTypes.bool,
    clearNavigationInterrupt: PropTypes.func,
    defaultZipcode: PropTypes.string,
    examSlugs: PropTypes.array,
    handleRequireMailingAddress: PropTypes.func,
    locations: PropTypes.array,
    manualLocationSelect: PropTypes.func,
    openModal: PropTypes.func,
    closeModal: PropTypes.func,
    ready: PropTypes.bool,
    registerNavigationInterrupt: PropTypes.func,
    requestLocations: PropTypes.func,
    searchedZipcode: PropTypes.string,
    selectedLocation: PropTypes.object,
    timeout: PropTypes.bool,
    updateSearchZipcode: PropTypes.func,
    updateSearchExtendedHours: PropTypes.func,
    selectOneEightHundredFlow: PropTypes.func,
    warning: PropTypes.string,
    zipcode: PropTypes.string,
    showLocationsWithExtendedHoursOnly: PropTypes.bool,
    oneEightHundredFlowConfigured: PropTypes.bool,
    isParsProgram: PropTypes.bool,
    changeReduxForm: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.confirmNonElectronicLocation = this.confirmNonElectronicLocation.bind(
      this,
    );
  }

  componentDidMount() {
    const {
      updateSearchZipcode,
      requestLocations,
      registerNavigationInterrupt,
      locations,
      zipcode,
      defaultZipcode,
    } = this.props;

    registerNavigationInterrupt(this.confirmNonElectronicLocation);

    if (!locations && !zipcode && defaultZipcode) {
      updateSearchZipcode(defaultZipcode);
      requestLocations();
    }
  }

  componentWillUnmount() {
    const { clearNavigationInterrupt } = this.props;
    clearNavigationInterrupt();
  }

  handleKeyDown = e => {
    if (e.keyCode === KeyCode.KEY_RETURN) {
      this.handleSearchClick(e);
    }
  };

  handleNoLocationsWorkClick = () => {
    const { openModal, closeModal } = this.props;

    openModal(NonElectronicConfirmationDialogue, {
      titleKey:
        'components.ScheduleComponent.nonElectronicManualCall.confirmation.title',
      bodyKey:
        'components.ScheduleComponent.nonElectronicManualCall.confirmation.body',
      backButtonKey: 'buttons.back',
      confirmButtonKey: 'buttons.confirm',
      confirmAction: () => {
        this.clickOneEightHundredFlow();
        closeModal();
      },
    });
  };

  selectedLocation() {
    const { locations, selectedLocation } = this.props;
    return (
      locations &&
      selectedLocation &&
      locations.filter(l => l.siteId === selectedLocation.siteId)[0]
    );
  }

  confirmNonElectronicLocation() {
    const {
      openModal,
      handleRequireMailingAddress,
      isParsProgram,
    } = this.props;
    const location = this.selectedLocation();

    if (!location) return false;
    if (location && !location.electronicallyEnabled) {
      if (isParsProgram)
        openModal(NonElectronicConfirmationDialogue, {
          titleKey:
            'components.ScheduleComponent.nonElectronicWithPaperwork.confirmation.title',
          bodyKey:
            'components.ScheduleComponent.nonElectronicWithPaperwork.confirmation.body',
          backButtonKey: 'buttons.back',
          confirmButtonKey: 'buttons.confirm',
          confirmAction: () =>
            handleRequireMailingAddress({ requireMail: false }),
        });
      else {
        openModal(NonElectronicConfirmationDialogue, {
          titleKey:
            'components.ScheduleComponent.nonElectronic.confirmation.title',
          bodyKey:
            'components.ScheduleComponent.nonElectronic.confirmation.body',
          backButtonKey: 'buttons.changeLocation',
          confirmButtonKey: 'buttons.addAddress',
          confirmAction: () =>
            handleRequireMailingAddress({ requireMail: true }),
        });
      }
    } else {
      handleRequireMailingAddress({ requireMail: false });
    }
    return true;
  }

  handleSearchClick(e) {
    const { requestLocations, zipcode } = this.props;
    const error = V.zipcodeRequiredFormat(zipcode);
    e && e.preventDefault();
    if (!error) requestLocations();
  }

  showAlertMessage() {
    const {
      ready,
      warning,
      timeout,
      locations,
      appointmentContext,
    } = this.props;

    if (!ready) return null;
    if (warning && timeout && !appointmentContext) {
      return (
        <LocationsAlertMessage
          title='components.ErrorPage.title'
          details='components.ScheduleComponent.noConnectivity'
        />
      );
    }
    if (warning) {
      return (
        <LocationsAlertMessage
          title='components.ErrorPage.title'
          body={warning}
          blocker
        />
      );
    }
    if (locations && locations.length === 0) {
      return (
        <LocationsAlertMessage
          title='components.ScheduleComponent.noLocationsFound.title'
          details='components.ScheduleComponent.noLocationsFound.details'
          blocker
        />
      );
    }
    return null;
  }

  showLocationTypes() {
    const locations = this.visibleLocations();

    const paperClinics = locations.filter(loc => !loc.electronicallyEnabled);
    return paperClinics.length > 0;
  }

  visibleLocations() {
    const { locations } = this.props;
    if (!locations) return [];

    return locations;
  }

  searchInputValidaton() {
    const { zipcode } = this.props;
    const error = V.zipcodeRequiredFormat(zipcode);
    return { error, touched: true };
  }

  clickOneEightHundredFlow() {
    const { selectOneEightHundredFlow, changeReduxForm } = this.props;
    changeReduxForm('applyform', 'oneEightHundredFlowSelected', true);
    changeReduxForm('applyform', 'location', null);
    changeReduxForm('applyform', 'extendedHoursClinicsOnly', false);
    selectOneEightHundredFlow();
  }

  clickExtendedHoursCheckbox(event) {
    const {
      updateSearchExtendedHours,
      changeReduxForm,
      requestLocations,
    } = this.props;
    const val = event.target.checked;
    changeReduxForm('applyform', 'extendedHoursClinicsOnly', val);
    updateSearchExtendedHours(val);
    requestLocations();
  }

  render() {
    const {
      extendedHoursConfigured,
      ready,
      zipcode,
      selectedLocation,
      // eslint-disable-next-line no-shadow
      manualLocationSelect,
      // eslint-disable-next-line no-shadow
      updateSearchZipcode,
      searchedZipcode,
      examSlugs,
      showLocationsWithExtendedHoursOnly,
      oneEightHundredFlowConfigured,
    } = this.props;
    const locations = this.visibleLocations();
    const stats = locationsStats(locations);
    return (
      <div>
        <p>
          <strong>
            <T value='labels.zipcodeSearch' />
          </strong>
        </p>
        <div className='row'>
          <div className='col-md-6 col-lg-12'>
            <TextInputGroup
              input={{ type: 'text' }}
              name='zipcode_search'
              meta={this.searchInputValidaton()}
              placeholder='placeholders.zipcode'
              onChange={event => updateSearchZipcode(event.target.value)}
              buttonText='buttons.locationSearch'
              buttonClass='btn btn-primary'
              buttonIconClass='fa fa-search'
              buttonOnClick={this.handleSearchClick}
              onKeyDown={this.handleKeyDown}
              value={zipcode}
              disabled={!ready}
            />
          </div>
          {extendedHoursConfigured && (
            <div className='col-md-6 col-lg-12'>
              <Checkbox
                input={{
                  onChange: event => this.clickExtendedHoursCheckbox(event),
                }}
                name='clinicsWithExtendedHoursOnly'
                label='labels.extendedClinicHours'
                value={showLocationsWithExtendedHoursOnly}
                meta={{}}
              />
            </div>
          )}
        </div>
        {this.showAlertMessage() || (
          <div className='selection-results row flex-lg-row-reverse'>
            <div className='col-lg-6 col-sm-12'>
              <GhostLoader loading={!ready} loadingClassName='loading-map'>
                <Map
                  locations={locations}
                  selectedLocation={selectedLocation}
                  handleLocationSelected={location =>
                    manualLocationSelect(location)
                  }
                  searchedZipcode={searchedZipcode}
                  examSlugs={examSlugs}
                  stats={stats}
                />
              </GhostLoader>
            </div>
            <div className='col-lg-6 col-sm-12 container-fluid'>
              <GhostLoader loading={!ready} loadingClassName='loading-location'>
                <LocationPanelList
                  locations={locations}
                  selectedLocation={selectedLocation}
                  showTypes={this.showLocationTypes()}
                  searchedZipcode={searchedZipcode}
                  examSlugs={examSlugs}
                  stats={stats}
                />
              </GhostLoader>
            </div>
          </div>
        )}
        {oneEightHundredFlowConfigured && (
          <OneEightHundredLink onClick={this.handleNoLocationsWorkClick}>
            <T value='components.ScheduleComponent.locations.noLocationsWork' />
          </OneEightHundredLink>
        )}
      </div>
    );
  }
}

const selector = formValueSelector('applyform');

const mapStateToProps = (state, props) => {
  const appointmentContext = !!state.configuration.routeParams.appointmentId;
  const {
    ready,
    locations,
    zipcode,
    warning,
    searchedZipcode,
    timeout,
    showLocationsWithExtendedHoursOnly,
  } = state.healthScreening;
  const defaultZipcode = selector(state, 'zipcode');
  const selectedLocation = selector(state, 'location');
  return {
    ...props,
    defaultZipcode,
    locations,
    ready,
    selectedLocation,
    warning,
    timeout,
    zipcode,
    searchedZipcode,
    appointmentContext,
    showLocationsWithExtendedHoursOnly,
  };
};

const ConnectedLocationSelector = connect(mapStateToProps, {
  changeReduxForm: change,
  requestLocations,
  updateSearchZipcode,
  updateSearchExtendedHours,
  manualLocationSelect,
  clearNavigationInterrupt,
  registerNavigationInterrupt,
  openModal,
  closeModal,
  handleRequireMailingAddress,
  selectOneEightHundredFlow,
})(LocationSelectorComponent);

export default ConnectedLocationSelector;
