import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';

import DrugAlcoholClearinghouseDisclosureLearnMoreModal from './DrugAlcoholClearinghouseDisclosureLearnMoreModal';
import { Checkbox } from './fields';
import * as V from '../lib/validations';

import '../css/DrugAlcoholClearinghouseDisclosure.scss';

const DrugAlcoholClearinghouseDisclosure = ({ company }) => {
  return (
    <div>
      {Disclosure(company)}
      {Consent(company)}
    </div>
  );
};

const Disclosure = company => {
  const [showLearnMoreModal, setShowLearnMoreModal] = useState(false);

  return (
    <div className='card clearfix mb-3'>
      <div className='card-block'>
        <span className='custom-text' role='button' tabIndex='0'>
          <h2 data-testid='disclosure-header-content'>
            <T value='components.DrugAlcoholClearinghouseDisclosure.Disclosure.contentHeader' />
            <M.Tooltip
              /* eslint-disable-next-line react/display-name */
              renderIcon={React.forwardRef((props, ref) => (
                <M.Icon icon='InformationFilled' />
              ))}
            >
              <p>
                <T value='components.DrugAlcoholClearinghouseDisclosure.Disclosure.toolTip' />
              </p>
            </M.Tooltip>
          </h2>
          <p className='content-spacing' data-testid='disclosure-content'>
            <T
              value='components.DrugAlcoholClearinghouseDisclosure.Disclosure.content'
              company={company}
            />
          </p>
          <a
            href='#learnMore'
            data-toggle='modal'
            onClick={() => setShowLearnMoreModal(true)}
            data-testid='learn-more-link'
          >
            {i18n.getStr(
              'components.DrugAlcoholClearinghouseDisclosure.LearnMore.buttonText',
            )}
          </a>
          <DrugAlcoholClearinghouseDisclosureLearnMoreModal
            open={showLearnMoreModal}
            onClose={() => setShowLearnMoreModal(false)}
          />
        </span>
      </div>
    </div>
  );
};

const Consent = company => {
  const paragraphs = ['firstParagraph', 'secondParagraph', 'thirdParagraph'];

  return (
    <div className='card clearfix mb-3'>
      <div className='card-block'>
        <span className='custom-text' role='button' tabIndex='0'>
          <h2 data-testid='consent-header'>
            <T value='components.DrugAlcoholClearinghouseDisclosure.Consent.contentHeader' />
          </h2>
          {paragraphs.map(paragraph => (
            <p
              className='content-spacing'
              key={paragraph}
              data-testid={paragraph}
            >
              <T
                value={`components.DrugAlcoholClearinghouseDisclosure.Consent.${paragraph}`}
                company={company}
              />
            </p>
          ))}
          <hr />
          <div
            className='col-md-12 consent-checkbox-container'
            data-testid='consent-checkbox'
          >
            <Field
              type='checkbox'
              name='drugAlcoholClearinghouseDisclosure'
              label='components.DrugAlcoholClearinghouseDisclosure.Checkbox.label'
              component={Checkbox}
              validate={[V.requiredCheckbox]}
              labelFeedback
              boldLabel
            />
          </div>
        </span>
      </div>
    </div>
  );
};

DrugAlcoholClearinghouseDisclosure.propTypes = {
  company: PropTypes.string.isRequired,
};

export default DrugAlcoholClearinghouseDisclosure;
