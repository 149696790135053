import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { Checkbox } from './fields';

import * as V from '../lib/validations';

import HTMLText from './HTMLText';
import Panel from './Panel';

const InvestigativeDisclosure = ({ content }) => (
  <Panel title='components.InvestigativeDisclosure.title' icon='fa-file-text'>
    <div className='scrollable'>
      <HTMLText content={content} />
    </div>

    <hr />

    <div className='col-md-12'>
      <Field
        type='checkbox'
        name='investigativeDisclosure'
        label='components.InvestigativeDisclosure.label'
        component={Checkbox}
        validate={[V.requiredCheckbox]}
        labelFeedback
        boldLabel
      />
    </div>
  </Panel>
);

InvestigativeDisclosure.propTypes = {
  content: PropTypes.string.isRequired,
};

export default InvestigativeDisclosure;
