/* eslint-disable no-empty-function */
/* eslint-disable camelcase */
export type ScreeningType =
  | 'global'
  | 'criminal'
  | 'education'
  | 'employment'
  | 'right_to_work'
  | 'motor_vehicle_report'
  | 'credit_report';
export type Types =
  | 'string'
  | 'boolean'
  | 'number'
  | 'date'
  | 'array'
  | 'object'
  | 'upload'
  | 'document';
export type TypeFormat = 'date' | 'uri' | 'radio' | 'country-iso-alpha2';

export interface FetchRequirementsResponse {
  [key: string]: any;
  schema?: JsonSchema;
}
export interface JsonSchemaDefinition {
  $id: string;
  $ref: string;
  $schema: string;
  description?: string;
  // can type only be object or should it be set to Types?
  type: 'object';
  document_uri?: string;
  constant: undefined;
  // key -> property name
  properties: { [key: string]: JsonSchemaProperty };
  required: string[];
  allOf: JsonSchemaDefinition[];
}

export const GEN_REGION = 'general';
export const DOC_SCREENING = 'documents';

// eslint-disable-next-line import/prefer-default-export
export const GLOBAL_SCREENING = 'global';
export const GLOBAL_REGION = 'Global';

export interface Region {
  name: string;
  country?: string;
}

export interface Source {
  scope: 'country';
  screening_type: ScreeningType;
  region: Region;
}

export interface JsonSchemaProperty {
  type: Types;
  // used to denote contents of array
  items?: JsonSchemaDefinition;
  // {
  //   type: Types;
  //   $ref: string;
  // };
  constant: string;
  sources?: Source[];
  format?: TypeFormat;
  description?: string;
  optional?: boolean;
  required?: string[];
  properties?: { [id: string]: JsonSchemaProperty };
  allOf: undefined;
}

export interface JsonSchemaDownload {
  type: 'object';
  document_url: string;
}
export interface JsonSchemaPropUpload {
  filename: string;
  mimetype: string;
  url: string;
}

export interface Document {
  name: string;
  sources: Source[];
}

export const INFORMATION = 'information';

export const SCOPED_REQUIREMENTS = 'scoped_requirements';
export interface ScopedRequirements {
  type: 'object';
  properties: {
    [alpha2Code: string]: {
      type: 'object';
      properties: {
        [fieldId: string]: JsonSchemaProperty;
      };
    };
  };
}

export type ManyOneOfs = {
  oneOf: {
    // sets of required properties, one of which must be met
    required: string[];
  }[];
}[];

export interface JsonSchema {
  definitions: { [key: string]: JsonSchemaDefinition };
  $schema: string;
  $id: string;
  type: string;
  properties: {
    [id: string]: JsonSchemaProperty;
  };
  required?: string[];
  sources: Source[];
  missing: any[];
  allOf: ManyOneOfs;
  request: {
    screening_types: ScreeningType[];
    regions: { country: string }[];
  };
}

export type Screening =
  | typeof INFORMATION
  | 'motor_vehicle_report'
  | 'education'
  | 'employment'
  | 'right_to_work'
  | 'identity_document_validation'
  | 'credit_report';

export type DomesticFormInternationalAddress = {
  countryCode?: string;
  street?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  startDate?: string;
  endDate?: string;
};

export type InternationalAddressData = {
  start_date: string;
  end_date: string;
  current_address: boolean;
  zipcode: string;
  state: string;
  country_code: string;
};
