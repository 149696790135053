/* eslint-disable react/prop-types */
import React, { FC } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';

import HTMLText from 'components/HTMLText';
import { Screening } from '../../types/schema.types';

type Props = {
  screening: Screening;
};

const RegionRequirementsHeader: FC<Props> = ({ screening }) => (
  <M.Container
    rows={[
      <>
        <h2
          key={`screening-${screening}-title`}
          className='region-requirements-header-text'
        >
          {i18n.getStr(`pages.${screening}`)}
        </h2>
        <HTMLText
          key={`screening-${screening}-header-text`}
          content={i18n.getStr(`international.headerText.${screening}`)}
          customClassName='html-text'
        />
      </>,
    ]}
  />
);

export default RegionRequirementsHeader;
