/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';

const Text = ({ content }) => <span>{content}</span>;

Text.propTypes = {
  content: PropTypes.string,
};

export default Text;
