import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { Translate as T } from 'react-redux-i18n';
import { Field } from 'redux-form';
import Panel from '../Panel';
import { Text } from '../fields';
import ControlFormImage from './assets/custody_control_form.png';
import '../../css/CustodyControlForm.scss';

const ReloadPage = styled.a`
  color: ${colors.navy02} !important;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const CustodyControlForm = () => (
  <Panel title='components.CustodyControlForm.title'>
    <p>
      <T value='components.CustodyControlForm.body' dangerousHTML />
    </p>
    <img
      src={ControlFormImage}
      className='control-form-img'
      alt='Custody Control Form'
    />
    <Field
      type='text'
      name='custodyControlFormNumber'
      label='components.CustodyControlForm.label'
      component={Text}
    />
    <ReloadPage
      onClick={e => {
        e.preventDefault();
        window.location.reload();
      }}
    >
      <T value='components.CustodyControlForm.revertBack' />
    </ReloadPage>
  </Panel>
);

export default CustodyControlForm;
