/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { replace } from 'lodash';

import { I18n } from 'react-redux-i18n';
import { groupClass } from './helper';
import FeedbackLabel from './FeedbackLabel';
import Label from './Label';

const Textarea = ({
  autoFocus,
  disabled,
  input,
  label,
  rows,
  labelAsRequired,
  meta: { touched, error, warning },
  placeholder,
  type,
}) => {
  return (
    <div className={groupClass(touched, error)}>
      <Label
        label={label}
        htmlFor={input.name}
        labelAsRequired={labelAsRequired}
      />
      <textarea
        {...input}
        id={replace(input.name, '.', '-')}
        className='form-control'
        placeholder={I18n.t(placeholder || label)}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        disabled={disabled}
        rows={rows}
      />
      <FeedbackLabel {...{ touched, error, warning }} />
    </div>
  );
};

Textarea.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  input: PropTypes.object,
  label: PropTypes.string,
  labelAsRequired: PropTypes.bool,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  type: PropTypes.string,
};
const mapStateToProps = ({ i18n }) => ({ i18n });

export default connect(mapStateToProps)(Textarea);
