/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';
import { replace } from 'lodash';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { groupClass } from './helper';
import FeedbackLabel from './FeedbackLabel';
import Label from './Label';

const Select = ({
  disabled,
  input,
  options,
  label,
  meta: { touched, error, warning },
}) => {
  return (
    <div className={groupClass(touched, error)}>
      <Label label={label} htmlFor={input.name} />
      <select
        {...input}
        id={replace(input.name, '.', '-')}
        className='form-control'
        disabled={disabled}
        aria-label={replace(input.name, '.', ' ')}
      >
        {options.map((option, i) => (
          <option
            key={`option-${i + 1}`}
            value={option.value}
            disabled={option.disabled}
          >
            {option.i18n ? I18n.t(option.name) : option.name}
          </option>
        ))}
      </select>
      <FeedbackLabel {...{ touched, error, warning }} />
    </div>
  );
};

Select.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.object,
  options: PropTypes.array,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.bool,
  }),
};

const mapStateToProps = ({ i18n }) => ({ i18n });

export default connect(mapStateToProps)(Select);
