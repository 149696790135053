import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';

function MeasureOneEntryPoint({ setShowLearnMoreModal, openMeasureOne }) {
  return (
    <>
      <div className='row'>
        <div className='col-md-10'>
          <h2>
            <span role='img' aria-label='Congratulations'>
              🎉
            </span>
            &nbsp;
            {i18n.getStr(
              'i18nInternational.educationField.measureOne.qualifiedTitle',
            )}
          </h2>
        </div>
        <div className='col-md-2'>
          <p className='m1-est-time'>
            <i className='fa fa-clock-o' />
            {'  '}
            {i18n.getStr(
              'i18nInternational.educationField.measureOne.m1RequiredTime',
            )}
          </p>
        </div>
      </div>
      <br />
      <div className='row'>
        <div className='col-md-12'>
          <span>
            {i18n.getStr(
              'i18nInternational.educationField.measureOne.m1LoginMessage',
            )}
            &nbsp;
          </span>
          <M.Link
            style={{ fontSize: '100%' }}
            href='#'
            onClick={() => {
              setShowLearnMoreModal();
            }}
          >
            {i18n.getStr(
              'i18nInternational.educationField.measureOne.m1LearnMoreModalButton',
            )}
          </M.Link>
        </div>
      </div>
      <br />
      <div className='row'>
        <div className='col-md-12'>
          <button
            type='button'
            className='primary mastodon-button bx--btn bx--btn--sm bx--btn--primary'
            onClick={openMeasureOne}
          >
            {i18n.getStr(
              'i18nInternational.educationField.measureOne.m1LoginButton',
            )}
          </button>
          <div className='m1-widget-parent'>
            <m1-link />
          </div>
        </div>
      </div>
      <br />
    </>
  );
}

MeasureOneEntryPoint.propTypes = {
  setShowLearnMoreModal: PropTypes.func.isRequired,
  openMeasureOne: PropTypes.func.isRequired,
};

export default MeasureOneEntryPoint;
