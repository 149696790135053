import _ from 'lodash';
import { change } from 'redux-form';
import { moment } from '@international/mastodon-i18n';
import * as ActionTypes from './employmentActionTypes';
import { SHORT_DATE_FORMAT } from '../../constants';
import { EMPLOYMENT_INPUT_MODE_ARGYLE } from '../../lib/employment/constants';

// Convert Argyle employment into Apply form employment.
const buildArgyleEmploymentForForm = argyleEmployment => {
  return {
    inputMode: EMPLOYMENT_INPUT_MODE_ARGYLE,
    addedAt: Date.now(),
    // Add Apply Form-specific fields missing from the Argyle employment.
    type: 'employer',
    country: 'US',
    // Map only the fields required by Apply form employment from the Argyle employment.
    argyleEmploymentId: argyleEmployment.argyleEmploymentId,
    startDate: argyleEmployment.startDate,
    endDate: argyleEmployment.endDate,
    currentPosition: argyleEmployment.currentPosition,
    name: argyleEmployment.name,
    position: argyleEmployment.position,
    contractType: argyleEmployment.contractType,
    doNotContact: true,
  };
};

const acceptArgyleEmployments = (
  acceptedArgyleEmployments,
  acceptedManualEmployments,
) => (dispatch, getState) => {
  const {
    form: {
      applyform: {
        values: { employment },
      },
    },
  } = getState();

  // Carry over existing gaps on form.
  const existingGapsOnForm = employment.filter(employmentOrGapOnForm => {
    return employmentOrGapOnForm.type === 'gap';
  });

  const existingArgyleEmploymentsOnForm = employment.filter(
    employmentOrGapOnForm => employmentOrGapOnForm.argyleEmploymentId != null,
  );

  // Builds Argyle employments to be added to the form.
  const argyleEmploymentsForForm = acceptedArgyleEmployments.map(
    acceptedArgyleEmployment => {
      const argyleEmploymentOnForm = existingArgyleEmploymentsOnForm.find(
        empOnForm =>
          empOnForm.argyleEmploymentId ===
          acceptedArgyleEmployment.argyleEmploymentId,
      );
      return (
        // If the accepted Argyle employment already exists on form, re-use the
        // Form version, in case any fields have been overridden by user.
        argyleEmploymentOnForm ||
        // If the accepted Argyle employment is newly selected, convert it to
        // the Form format.
        buildArgyleEmploymentForForm(acceptedArgyleEmployment)
      );
    },
  );

  // Sort combined Argyle + manual employments + gaps, in reverse chronological order.
  const allEmploymentsAndGaps = _.orderBy(
    [
      ...argyleEmploymentsForForm,
      ...existingGapsOnForm,
      ...acceptedManualEmployments,
    ],
    [
      employmentOrGap =>
        employmentOrGap.endDate
          ? moment(employmentOrGap.endDate, SHORT_DATE_FORMAT)
          : null,
      employmentOrGap =>
        employmentOrGap.startDate
          ? moment(employmentOrGap.startDate, SHORT_DATE_FORMAT)
          : null,
    ],
    ['desc', 'desc'],
  );

  dispatch(change('applyform', 'employment', allEmploymentsAndGaps));
};

const addArgyleConnectedAccountId = argyleAccountId => dispatch => {
  dispatch({
    type: ActionTypes.ADD_ARGYLE_CONNECTED_ACCOUNT_ID,
    payload: {
      newArgyleAccountId: argyleAccountId,
    },
  });
};

const addArgyleAccountEmployments = argyleAccountEmployments => dispatch => {
  dispatch({
    type: ActionTypes.ADD_ARGYLE_ACCOUNT_EMPLOYMENTS,
    payload: {
      newArgyleAccountEmployments: argyleAccountEmployments,
    },
  });
};

const onArgyleLinkClose = () => dispatch => {
  dispatch({
    type: ActionTypes.ARGYLE_LINK_CLOSED,
  });
};

const overridePosition = (positionFieldName, newPosition) => dispatch => {
  dispatch(change('applyform', positionFieldName, newPosition));
};

const resetArgyleData = () => dispatch => {
  dispatch({ type: ActionTypes.RESET_ARGYLE_DATA });
};

const setArgyleState = argyleState => dispatch => {
  dispatch({
    type: ActionTypes.SET_ARGYLE_STATE,
    payload: {
      argyleCurrentState: argyleState,
    },
  });
};

export {
  acceptArgyleEmployments,
  addArgyleConnectedAccountId,
  addArgyleAccountEmployments,
  onArgyleLinkClose,
  overridePosition,
  resetArgyleData,
  setArgyleState,
};
