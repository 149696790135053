import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { change, Field, formValueSelector } from 'redux-form';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import HTMLText from './HTMLText';
import '../css/DomesticDocumentUpload.scss';
import { Select, Upload } from './fields';
import * as V from '../lib/validations';
import DocumentTypeDetailsModal from './DocumentTypeDetailsModal';
import DocumentList from './DocumentList';

class DomesticDocumentUpload extends Component {
  static propTypes = {
    candidateDisclosureText: PropTypes.string.isRequired,
    descriptionText: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    documents: PropTypes.arrayOf(PropTypes.object),
    dropdownOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    fieldName: PropTypes.string.isRequired,
    handleDisclosableDocuments: PropTypes.func.isRequired,
    modalBodyContent: PropTypes.node.isRequired,
    selectedDocumentType: PropTypes.string,
    titleText: PropTypes.string.isRequired,
  };

  static defaultProps = {
    documents: [],
    selectedDocumentType: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      showDocTypeDetailsModal: false,
    };
    this.setShowDocTypeDetailsModal = this.setShowDocTypeDetailsModal.bind(
      this,
    );
    this.updateDocType = this.updateDocType.bind(this);
    this.removeDocument = this.removeDocument.bind(this);
  }

  setShowDocTypeDetailsModal = showModal => {
    this.setState({ showDocTypeDetailsModal: showModal });
  };

  updateDocType = ({ target: { value } }) => {
    const { documents, dispatch, fieldName } = this.props;
    if (documents && value) {
      for (let i = 0; i < documents.length; i += 1) {
        dispatch(
          change(
            'applyform',
            `${fieldName}.documents[${i}].documentType`,
            value,
          ),
        );
      }
    }
  };

  removeDocument = value => {
    const { documents, dispatch, fieldName } = this.props;
    const newDocs = documents.filter(doc => doc.handle !== value.handle);
    dispatch(change('applyform', `${fieldName}.documents`, newDocs));
  };

  render() {
    const {
      candidateDisclosureText,
      descriptionText,
      documents,
      dropdownOptions,
      fieldName,
      handleDisclosableDocuments,
      modalBodyContent,
      selectedDocumentType,
      titleText,
    } = this.props;
    const { showDocTypeDetailsModal } = this.state;

    return (
      <div className='domestic-doc-upload-section'>
        <div className='doc-section-header'>
          <HTMLText className='subtitle' content={titleText} />
          <HTMLText content={descriptionText} />
        </div>

        <div className='row doc-options-section'>
          <div className='col-md-5 options'>
            <Field
              component={Select}
              options={dropdownOptions}
              name={`${fieldName}.documentType`}
              onChange={this.updateDocType}
              label={null}
              validate={
                !isEmpty(documents)
                  ? [
                      V.required,
                      value => V.validSelection(value, dropdownOptions),
                    ]
                  : null
              }
            />
          </div>
          <div className='col-md-5 options modal-col'>
            <a
              href='#docTypeModal'
              data-toggle='modal'
              onClick={() => this.setShowDocTypeDetailsModal(true)}
            >
              {i18n.getStr(
                'components.domesticDocumentUpload.openAcceptedDocumentsModalText',
              )}
            </a>
            <DocumentTypeDetailsModal
              isOpen={showDocTypeDetailsModal}
              handleClose={() => this.setShowDocTypeDetailsModal(false)}
              modalBodyContent={modalBodyContent}
            />
          </div>
        </div>

        <div className='candidate-disclosure-section'>
          <M.Icon icon='Idea' />
          <HTMLText content={candidateDisclosureText} />
        </div>

        {isEmpty(documents) && (
          <div
            className='doc-upload-btn col-md-4'
            data-testid='domestic-document-upload-btn'
          >
            <Field
              component={Upload}
              disabled={!selectedDocumentType}
              documentType={selectedDocumentType}
              hideMeta
              icon='CloudUpload'
              name={`${fieldName}.documents`}
              onChange={handleDisclosableDocuments}
              options={{ maxFiles: 1 }}
              mobileUploadButtonText={i18n.getStr(
                'components.domesticDocumentUpload.uploadButtonText',
              )}
              uploadButtonText={i18n.getStr(
                'components.domesticDocumentUpload.uploadButtonText',
              )}
              type='upload'
              useMastodonIcon
            />
          </div>
        )}

        {!isEmpty(documents) && (
          <DocumentList
            documents={documents}
            removeDocument={this.removeDocument}
          />
        )}
      </div>
    );
  }
}
const selector = formValueSelector('applyform');

const mapStateToProps = (state, ownProps) => {
  const { fieldName } = ownProps;
  const selectedDocumentType = selector(state, `${fieldName}.documentType`);
  const documents = selector(state, `${fieldName}.documents`);
  return {
    documents,
    selectedDocumentType,
  };
};

export default connect(mapStateToProps)(DomesticDocumentUpload);
