import React from 'react';
import PropTypes from 'prop-types';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';

import HTMLText from './HTMLText';

import '../css/DrugAlcoholClearinghouseDisclosureLearnMoreModal.scss';

function DrugAlcoholClearinghouseDisclosureLearnMoreModal({ open, onClose }) {
  return (
    <M.ComposedModal open={open} onClose={onClose}>
      <M.ModalHeader closeModal={onClose}>
        <h2>
          {i18n.getStr(
            'components.DrugAlcoholClearinghouseDisclosure.LearnMore.header',
          )}
        </h2>
      </M.ModalHeader>
      <M.ModalBody>
        <div className='m1-learn-more-modal'>
          <div className='row'>
            <div className='col-md-12' data-testid='content-header'>
              <h2>
                {i18n.getStr(
                  'components.DrugAlcoholClearinghouseDisclosure.LearnMore.contentHeader',
                )}
              </h2>
            </div>
            <div className='col-md-12 m1-waiting-results-modal-description-padding font-size-14'>
              <div className='margin-bottom-1rem' data-testid='description'>
                {i18n.getStr(
                  'components.DrugAlcoholClearinghouseDisclosure.LearnMore.content.description',
                )}
              </div>
              <div>
                <ul>
                  <li data-testid='limited-query'>
                    {i18n.getStr(
                      'components.DrugAlcoholClearinghouseDisclosure.LearnMore.content.limitedQuery',
                    )}
                  </li>
                  <li data-testid='full-query'>
                    {i18n.getStr(
                      'components.DrugAlcoholClearinghouseDisclosure.LearnMore.content.fullQuery',
                    )}
                  </li>
                </ul>
              </div>
              <div data-testid='avoid-delays'>
                <HTMLText
                  content={i18n.getStr(
                    'components.DrugAlcoholClearinghouseDisclosure.LearnMore.content.avoidDelays',
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </M.ModalBody>
    </M.ComposedModal>
  );
}

DrugAlcoholClearinghouseDisclosureLearnMoreModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DrugAlcoholClearinghouseDisclosureLearnMoreModal;
