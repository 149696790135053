import { i18n } from '@international/mastodon-i18n';
import React from 'react';

import { CURRENT_SCHOOL_DISALLOWED } from 'lib/validations/messages';
import setDocumentContextAttributes from 'lib/utils/documentUtils';
import HTMLText from 'components/HTMLText';
import {
  GED_VALUE,
  HIGHSCHOOL_VALUE,
  LOWER_EDUCATION_LEVELS,
  OTHER_DEGREE_VALUE,
  US_COUNTRY_VALUE,
} from '../../constants';
import * as O from '../../lib/options';
import * as V from '../../lib/validations';

export const isDegreeLowerEducation = degree =>
  LOWER_EDUCATION_LEVELS.includes(degree);

export const isDegreeHigherEducation = degree =>
  !isDegreeLowerEducation(degree);

export const isDegreeGED = degree => degree === GED_VALUE;

export const isDegreeHighSchool = degree => degree === HIGHSCHOOL_VALUE;

export const isDegreeOther = degree => degree === OTHER_DEGREE_VALUE;

export const validateCharLength = value => V.fieldMaxLengthFormat(value, 100);

export const disallowCurrentSchools = formValue => {
  const isSchoolCurrent = formValue === 'true';
  if (isSchoolCurrent) {
    return CURRENT_SCHOOL_DISALLOWED;
  }
  return null;
};

export const isCountryDomestic = country => country === US_COUNTRY_VALUE;

// OCH = Out of Country, refers to non-domestic.
export const isCountryOCH = country => !isCountryDomestic(country);

export const getSchoolNameLabel = degree => {
  if (isDegreeHighSchool(degree)) {
    return 'labels.schoolNameHighSchool';
  }
  if (isDegreeGED(degree)) {
    return 'labels.testingSiteGED';
  }
  if (isDegreeOther(degree)) {
    return 'labels.schoolName';
  }
  return 'labels.schoolNameUniversity';
};

/**
 * Update this version date any time
 * components.domesticDocumentUpload.candidateDisclosurePrompt.education is updated
 */
const CANDIDATE_EDUCATION_DOCUMENT_DISCLOSURE_VERSION = '2023-10-03';
export const handleDisclosableDocuments = (_, documents) => {
  setDocumentContextAttributes(documents, {
    candidateDisclosureVersion: CANDIDATE_EDUCATION_DOCUMENT_DISCLOSURE_VERSION,
  });
};

/**
 * TODO EVER-6897 MDV project:
 * if there's time at the end of the project, revisit the below 4 methods
 * and DomesticDocumentUpload component. too much unnecessary complexity here
 */
const generateText = (keyItem, contentPrefix) => {
  return (
    <HTMLText
      className='option'
      key={`item-${keyItem}`}
      content={i18n.getStr(`${contentPrefix}.${keyItem}`)}
    />
  );
};

const getEducationBodyText = degreeType => {
  // keys are in translation.json files under
  // 'components.domesticDocumentUpload.documentTypeDetailsModal.education'
  const keyMap = {
    GED: ['diploma', 'certificate', 'transcript'],
    HighSchool: ['diploma', 'certificate', 'transcript'],
    degree: ['degree', 'diploma', 'certificate', 'transcript'],
    default: ['degree', 'certificate', 'transcript'],
  };
  const i18nKeys = keyMap[degreeType] || keyMap.default;
  const valuePrefix =
    'components.domesticDocumentUpload.documentTypeDetailsModal.education';
  return (
    <>
      {i18nKeys.map(key => {
        return generateText(key, valuePrefix);
      })}
    </>
  );
};

export const getDocumentUploadComponentTranslatedText = degreeType => {
  return {
    candidateDisclosureText: i18n.getStr(
      'components.domesticDocumentUpload.candidateDisclosurePrompt.education',
    ),
    descriptionText: i18n.getStr(
      'components.domesticDocumentUpload.descriptionText.education',
    ),
    modalBodyContent: getEducationBodyText(degreeType),
    titleText: i18n.getStr(
      'components.domesticDocumentUpload.sectionTitle.education',
    ),
  };
};

export const getEducationDropdownOptions = degree => {
  let options;
  switch (degree) {
    case 'GED':
    case 'HighSchool':
      options = O.gedDocuments;
      break;
    case 'degree': // represents the 'other' education level option
      options = O.educationDocuments;
      break;
    default:
      // see full list of higher education degree types in src/lib/options/degrees.js
      // the remaining degree types are all higher education
      options = O.higherEducationDocuments;
      break;
  }
  return options;
};
