import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import * as V from '../lib/validations';

import { Checkbox } from './fields';

import HTMLText from './HTMLText';
import Panel from './Panel';

const InternationalConsent = ({ content }) => (
  <Panel title='international.InternationalConsent.title' icon='fa-file-text'>
    <div className='scrollable'>
      <HTMLText content={content} />
    </div>

    <hr />
    <div className='col-md-12'>
      <Field
        type='checkbox'
        name='euConsent'
        label='international.InternationalConsent.label'
        component={Checkbox}
        boldLabel
        validate={[V.required]}
      />
    </div>
  </Panel>
);

InternationalConsent.propTypes = {
  content: PropTypes.string.isRequired,
};

export default InternationalConsent;
