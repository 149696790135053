import insertArgyleEmployment from './insertArgyleEmployment';

const insertUnverifiedArgyleEmployment = () => dispatch => {
  const argyleEmployment = {
    contractType: 'full_time',
    contractTypeVerified: true,
    name: 'Fake Unverified',
    nameVerified: true,
    position: 'SWE',
    positionVerified: true,
    startDate: null,
    startDateVerified: false,
    endDate: '03/2001',
    endDateVerified: true,
    currentPosition: false,
    argyleEmploymentId: 'unverified',
    isVerified: false,
  };

  dispatch(insertArgyleEmployment(argyleEmployment));
};

export default insertUnverifiedArgyleEmployment;
