/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { formValueSelector, Field } from 'redux-form';
import { connect } from 'react-redux';
import HTMLText from './HTMLText';
import Panel from './Panel';
import { Checkbox } from './fields';
import * as V from '../lib/validations';

class CriminalSelfDisclosureInstructions extends Component {
  static propTypes = {
    introduction: PropTypes.string,
    instructions: PropTypes.string,
    criminalSelfDisclosureAccepted: PropTypes.bool,
    noScroll: PropTypes.bool,
  };

  render() {
    const {
      introduction,
      instructions,
      criminalSelfDisclosureAccepted,
      noScroll,
    } = this.props;

    return (
      <Panel>
        <HTMLText content={introduction} />
        <br />
        <div
          className={`${!criminalSelfDisclosureAccepted && 'expanded'} ${
            noScroll ? '' : 'scrollable'
          }`}
        >
          <HTMLText content={instructions} />
        </div>

        <hr />

        <div className='col-md-12'>
          <Field
            type='checkbox'
            name='criminalSelfDisclosureAccepted'
            label='components.CriminalSelfDisclosure.instructions.label'
            component={Checkbox}
            validate={[V.requiredCheckbox]}
            labelFeedback
            boldLabel
          />
        </div>
      </Panel>
    );
  }
}

const selector = formValueSelector('applyform');
const ConnectedCriminalSelfDisclosureInstructions = connect(state => ({
  criminalSelfDisclosureAccepted: selector(
    state,
    'criminalSelfDisclosureAccepted',
  ),
}))(CriminalSelfDisclosureInstructions);

export default ConnectedCriminalSelfDisclosureInstructions;
