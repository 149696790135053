import React from 'react';
import { Field } from 'redux-form';
import { Translate as T } from 'react-redux-i18n';

import { Select, Text } from './fields';

import { SHORT_DATE_FORMAT } from '../constants';

import * as N from '../lib/normalizations';
import * as O from '../lib/options';
import * as V from '../lib/validations';

import Panel from './Panel';

const LastInternationalAddressForm = () => (
  <Panel
    title='international.LastInternationalAddressForm.title'
    icon='fa-home'
  >
    <p className='alert-full-name' role='alert'>
      <i className='fa fa-exclamation-circle' />{' '}
      <T
        value='international.LastInternationalAddressForm.alert'
        dangerousHTML
      />
    </p>

    <div className='row'>
      <div className='col-md-4'>
        <Field
          type='text'
          name='lastInternationalAddress.street'
          label='labels.street'
          component={Text}
          validate={[V.required]}
        />
      </div>

      <div className='col-md-4'>
        <Field
          type='text'
          name='lastInternationalAddress.city'
          label='labels.city'
          component={Text}
          validate={[V.required]}
        />
      </div>

      <div className='col-md-4'>
        <Field
          type='text'
          name='lastInternationalAddress.zipcode'
          label='labels.zipcode'
          component={Text}
          validate={[V.required]}
        />
      </div>
    </div>

    <div className='row'>
      <div className='col-md-4'>
        <Field
          type='select'
          name='lastInternationalAddress.countryCode'
          label='labels.country'
          component={Select}
          validate={[V.required]}
          options={O.countriesWithoutUS}
        />
      </div>

      <div className='col-md-4'>
        <Field
          type='text'
          name='lastInternationalAddress.state'
          label='international.labels.addressState'
          component={Text}
          validate={[V.required]}
        />
      </div>

      <div className='col-md-4'>
        <Field
          type='text'
          name='lastInternationalAddress.startDate'
          label='labels.residentFrom'
          component={Text}
          placeholder='placeholders.mmYY'
          normalize={N.monthYear}
          validate={[
            V.required,
            V.validDate(SHORT_DATE_FORMAT),
            V.dateInThePast(SHORT_DATE_FORMAT),
          ]}
        />
      </div>
    </div>
  </Panel>
);

export default LastInternationalAddressForm;
