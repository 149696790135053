/* eslint-disable react/prop-types */
import React, { FC } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';

import OneOfDropdown from '../OneOfDropdown';
import { INFORMATION, Screening } from '../../types/schema.types';

type Props = {
  complexRequirements: string[][] | null;
  screening: Screening;
};

const RegionDropdown: FC<Props> = ({ complexRequirements, screening }) => (
  <>
    {screening === INFORMATION && complexRequirements && (
      <M.Container
        rows={[
          <h2 id='region-header-id' key='region-header'>
            {i18n.getStr('i18nInternational.screeningPage.identification')}
          </h2>,
          <OneOfDropdown key='OneOf' reqs={complexRequirements} />,
        ]}
      />
    )}
  </>
);

export default RegionDropdown;
