import AddressField from './AddressField';
import DriverLicenseField from './DriverLicenseField';
import EducationField from './EducationField';
import EmploymentField from './EmploymentField';
import RightToWorkField from './RightToWorkField';
import CreditReportField from './CreditReportField';

const specializedFields: {
  [key: string]: any; // Specialized Field Class
} = {
  driver_licenses: DriverLicenseField,
  address: AddressField,
  education: EducationField,
  employers: EmploymentField,
  right_to_works: RightToWorkField,
  credit_report: CreditReportField,
};

export default specializedFields;
