import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import PropTypes from 'prop-types';
import React from 'react';

const DocumentTypeDetailsModal = ({
  handleClose,
  isOpen,
  modalBodyContent,
}) => {
  const headerText = i18n.getStr(
    'components.domesticDocumentUpload.documentTypeDetailsModal.headerText',
  );

  return (
    <M.ComposedModal
      open={isOpen}
      size='sm'
      className='doc-types-modal'
      onClose={handleClose}
      id='docTypeModal'
    >
      <M.ModalHeader closeModal={handleClose}>
        <h3>{headerText}</h3>
      </M.ModalHeader>
      <M.ModalBody className='body-content'>{modalBodyContent}</M.ModalBody>
    </M.ComposedModal>
  );
};

DocumentTypeDetailsModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  modalBodyContent: PropTypes.node.isRequired,
};

export default DocumentTypeDetailsModal;
