import React from 'react';
import styled from 'styled-components';
import Logo from '../assets/checkr.svg';

const CheckrHeaderWrapper = styled.div`
  display: flex;
  padding-left: 124px;
  padding-right: 54px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  background-color: var(--navy, #002853);
`;

const NavElementsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AnchorWrapper = styled.a`
  color: var(--white, #FFF);
  cursor: pointer;
  text-align: center;
  font-family: Helvetica Neue;
  background-color: none;
  text-decoration: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  padding: 8px 16px
  margin-left: 16px;

  &:visited,
  &:active,
  &:focus {
    color: var(--white, #FFF);
    text-decoration: none;
  }
`;

const CheckrHeader = () => {
  return (
    <CheckrHeaderWrapper>
      <div className='logo'>
        <img src={Logo} alt='Checkr logo' />
      </div>
      <NavElementsWrapper>
        {/*
          TODO: Create new LanguageToggle where style can be adjusted to work
          as footer and header
        */}
        {/* <SelectWrapper>
          <M.Icon icon="Translate" color="#fff" />
          <M.Select
            noLabel
            defaultValue='English'
            size='sm'
            style={selectStyle}
          >
            <option value="en" selected>English</option>
            <option value="es">Español</option>
          </M.Select>
        </SelectWrapper> */}
        <AnchorWrapper
          href='https://help.checkr.com/hc/en-us/categories/360002478633-Candidates?_gl=1*15rxjo8*_ga*NDA2NzkxMzYzLjE2OTIxMjkzMjM.*_ga_V7EME6CVPL*MTY5MzI2MDUzMS4xMC4xLjE2OTMyNjA1NDcuNDQuMC4w'
          target='_blank'
          rel='noopener noreferrer'
          id='help'
        >
          Help
        </AnchorWrapper>
      </NavElementsWrapper>
    </CheckrHeaderWrapper>
  );
};

export default CheckrHeader;
