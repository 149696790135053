import React from 'react';
import { Field } from 'redux-form';
import { Checkbox } from './fields';
import * as V from '../lib/validations';
import Panel from './Panel';

const ExpungementConsent = () => (
  <Panel
    title='components.ExpungementConsent.title'
    icon='fa-file-text'
    className='expungement-consent'
  >
    <div className='col-md-12'>
      <Field
        type='checkbox'
        name='expungementConsent'
        label='components.ExpungementConsent.label'
        component={Checkbox}
        validate={[V.requiredCheckbox]}
        boldLabel
      />
    </div>
  </Panel>
);

export default ExpungementConsent;
