import React from 'react';
import qs from 'query-string';
import { Validator } from 'redux-form';
import { camelCase } from 'lodash';
import { moment, i18n } from '@international/mastodon-i18n';

import { intlI18nFunc } from 'international/FormLogic/ApplyFormManager.class';

import * as V from '../../../lib/validations';
import { validateFormat } from '../../../lib/validations/helperValidations';

import { RenderableSchemaProperty, initialValueTree } from './Field.types';

import DocumentField from '../FieldComponents/SpecializedFields/DocumentField';

import {
  RelevantFieldCreator,
  RelevantComplexFieldCreator,
  RenderableElementCreator,
} from './RelevantFieldCreator.class';

import { DropdownListItem } from '../FieldComponents/WrappedMastodonComps/Dropdown';

import SpecializedFieldWrapper from '../FieldComponents/FieldWrappers/SpecializedFieldWrapper';
import specializedFields from '../FieldComponents/SpecializedFields';

import generate from './FieldGenerators';
import { dbsEmploymentSectors, licenseTypes, titles } from './SelectOptions';
import { FieldToMastodonProps } from '../FieldComponents/WrappedMastodonComps/WrappedComp.types';

import './CustomFlexRow.scss';
import {
  getStringLengthValidators,
  numbersOnlyFormat,
} from '../../../lib/validations/formatValidations';
import { getSpecializedDateFieldTranslation } from './Utils';

const labels = 'labels';
const placeholders = 'placeholders';
const FUTURE_DATE_VALIDATION_FIELDS = ['driver_license_expiration_date'];

class FieldDeterminer {
  constructor(
    public alpha2CodeToName: DropdownListItem[] = [],
    public intlI18n: intlI18nFunc, // eslint-disable-next-line no-empty-function
  ) {}

  static getInitialValues = (
    fields: RenderableSchemaProperty[],
  ): initialValueTree[] => {
    return fields
      .flatMap(field => {
        switch (field.type) {
          case 'object':
            if (field.properties) {
              return Object.values(field.properties).flatMap(f =>
                FieldDeterminer.getInitialValues([f]),
              );
            }
            break;
          case 'array':
            if (field.items) {
              return FieldDeterminer.getInitialValues([field.items]);
            }
            break;
          default:
            return [field.path.getDefaultValueForForm()];
        }
        return [];
      })
      .filter(Boolean) as initialValueTree[];
  };

  getTitle = (
    key: string,
    field: RenderableSchemaProperty,
    overrideFromInstructions?: boolean,
  ) => {
    if (
      overrideFromInstructions === true &&
      field.extended_configuration?.instructions?.title
    ) {
      return field.extended_configuration?.instructions?.title;
    }

    let keyToUse = key;
    if (field.path.formPathToParent === 'citizenship_identity') {
      if (key === 'name') {
        keyToUse = 'idType';
      }
      if (key === 'value') {
        keyToUse = 'idNumber';
      }
    }
    if (field.path?.parent?.formPathToParent === 'employers') {
      if (key === 'name') {
        keyToUse = 'employerName';
      }
      if (key === 'url') {
        keyToUse = 'employerUrl';
      }
    }
    if (field.path?.parent?.formPathToParent === 'education') {
      if (key === 'name') {
        keyToUse = 'schoolName';
      }
      if (key === 'url') {
        keyToUse = 'schoolUrl';
      }
    }

    let title = this.intlI18n(keyToUse, labels) as string;
    const specialTitle = getSpecializedDateFieldTranslation(
      key,
      field.path?.formPathToParent,
    );
    if (specialTitle) title = specialTitle;

    // We only get undefined from this function if we pass a true flag
    const showOptional = !!['no_middle_name'].includes(field.path.key);
    const omitOptional = ['share_code'].includes(field.path.key);

    if (field.path?.formPathToParent.includes('scoped_requirements.GB')) {
      return title;
    }

    return omitOptional || (field.path.isRequired && !showOptional)
      ? title
      : i18n.getStr('i18nInternational.optionalFieldText', title);
  };

  getPlaceholder = (key: string, field: RenderableSchemaProperty) => {
    let keyToUse = key;
    if (field.path?.parent?.formPathToParent === 'education') {
      if (key === 'name') {
        keyToUse = 'schoolName';
      }
    }
    if (field.path?.parent?.formPathToParent === 'employers') {
      if (key === 'name') {
        keyToUse = 'employerName';
      }
    }
    if (field.path.formPathToParent === 'citizenship_identity') {
      if (key === 'name') {
        keyToUse = 'idType';
      }
      if (key === 'value') {
        keyToUse = 'idNumber';
      }
    }
    let placeHolder = this.intlI18n(keyToUse, placeholders) as string;

    if (
      field.path?.parent?.formPathToParent ===
      'scoped_requirements.CA.violations'
    ) {
      if (key === 'date_of_sentence') {
        placeHolder = i18n.getLocalizedDateFormat(i18n.DateFormats.DATE_SLASH);
      }
    }
    if (
      [
        'dob',
        'date_of_entry',
        'driver_license_expiration_date',
        'drivers_license_issue_date',
        'passport_expiration_date',
        'passport_issue_date',
        'payslip_date',
      ].includes(key)
    ) {
      placeHolder = i18n.getLocalizedDateFormat(i18n.DateFormats.DATE_SLASH);
    }
    if (['start_date', 'end_date'].includes(key)) {
      placeHolder = i18n.getMonthYearPattern();
    }
    // We can typescript magic this later when there's time
    // We only get undefined from this function if we pass a true flag
    return placeHolder;
  };

  getDateFormatAndParse = (key: string) => {
    let stringFormat = i18n.getMonthYearPattern();
    if (
      [
        'date_of_entry',
        'date_of_sentence',
        'dob',
        'driver_license_expiration_date',
        'drivers_license_issue_date',
        'passport_expiration_date',
        'passport_issue_date',
        'payslip_date',
      ].includes(key)
    ) {
      stringFormat = i18n.getLocalizedDateFormat(i18n.DateFormats.DATE_SLASH);
    }
    if (key === 'year_awarded') {
      stringFormat = 'YYYY';
    }

    const validate = (date: string) => {
      if (date) {
        return V.validDate(stringFormat)(date);
      }
      return undefined;
    };

    return { validate };
  };

  futureDateByKeyValidator = (key: string) => {
    const stringFormat = i18n.getLocalizedDateFormat(
      i18n.DateFormats.DATE_SLASH,
    );

    const futureDateValidation = (date: string) => {
      if (FUTURE_DATE_VALIDATION_FIELDS.includes(key)) {
        return V.dateAfter(stringFormat, moment().format(stringFormat))(date);
      }
      return undefined;
    };

    return { futureDateValidation };
  };

  getItemsIfDropdown = (field: RenderableSchemaProperty) => {
    if (field.format === 'country-iso-alpha2') {
      return this.alpha2CodeToName;
    }

    /* eslint-disable camelcase */
    if (field.extended_configuration?.array_values?.length) {
      return field.extended_configuration?.array_values;
    }

    if (
      field.path.key === 'name' &&
      field.path.formPathToParent === 'citizenship_identity'
    ) {
      return [
        {
          id: 'passport_number',
          label: i18n.getStr('international.labels.passport'),
        },
        {
          id: 'drivers_license_number',
          label: i18n.getStr('international.labels.driversLicense'),
        },
        {
          id: 'national_id_number',
          label: i18n.getStr('international.labels.nationalId'),
        },
      ];
    }
    if (field.path.key === 'sex') {
      return [
        { id: 'female', label: i18n.getStr('international.labels.female') },
        { id: 'male', label: i18n.getStr('international.labels.male') },
        { id: 'other', label: i18n.getStr('international.labels.other') },
        {
          id: 'prefer_not_to_say',
          label: i18n.getStr('international.labels.preferNotToSay'),
        },
      ];
    }

    if (field.path.key === 'id_document_type') {
      return [
        { id: 'passport', label: i18n.getStr('international.labels.passport') },
        {
          id: 'national_id',
          label: i18n.getStr('international.labels.nationalGovernmentID'),
        },
        {
          id: 'driver_license',
          label: i18n.getStr('international.labels.driverLicense'),
        },
      ];
    }

    if (field.path.key === 'dbs_employment_sector') {
      return dbsEmploymentSectors.map(sector => ({
        id: sector,
        label: i18n.getStr(
          `international.dropdownLabel.employmentSectors.${camelCase(sector)}`,
        ),
      }));
    }

    if (field.path.key === 'drivers_license_type') {
      return licenseTypes.map(license_type => ({
        id: license_type,
        label: i18n.getStr(
          `international.dropdownLabel.licenseTypes.${camelCase(license_type)}`,
        ),
      }));
    }

    if (field.path.key === 'title') {
      return titles.map(title => ({
        id: title,
        label: i18n.getStr(
          `international.dropdownLabel.title.${camelCase(title)}`,
        ),
      }));
    }

    switch (field.path.key) {
      case 'country':
      case 'country_of_birth':
      case 'passport_country':
      case 'country_of_citizenship':
      case 'country_code':
        return this.alpha2CodeToName;
      default:
        return undefined;
    }
  };

  generateFieldLater = (firstDeterminer: RenderableSchemaProperty) => (
    arrayPathModifier: string,
  ) => {
    return this.generateField(firstDeterminer, arrayPathModifier);
  };

  generateField = (
    field: RenderableSchemaProperty,
    arrayPathModifier?: string,
    additionalProps?: { [key: string]: any },
  ): RenderableElementCreator => {
    if (!field || !field.path) {
      return RelevantFieldCreator.fromGenerator('', () => <></>);
    }

    let { key } = field.path;
    if (key === 'items') {
      key = field.path.formPathToParent;
    }
    const formParentId = field.path.formPathToParent;
    const fullFormId = field.path.getCombinedPath.form(arrayPathModifier);

    const baseProps = {
      id: fullFormId,
      key,
      title: this.getTitle(
        key,
        field,
        !!additionalProps?.overrideTitleFromInstructions,
      ),
      // Add back in when helper text is below input field
      // helperText: this.intlI18n(key, 'helperText'),
      placeholder: this.getPlaceholder(key, field),
      // title: `${fieldId}-title`,
      // placeholder: `${fieldId}-placeholder`,
    };

    if (baseProps.id.includes('driver_license_')) {
      baseProps.id = baseProps.id.replace('driver_license_', '');
    }

    const constExtendProps: {
      validate: Validator[];
    } = {
      validate:
        field.path.isRequired &&
        key !== 'middle_name' &&
        key !== 'no_middle_name'
          ? [V.required]
          : [],
    };

    switch (field.type) {
      case 'string': {
        const items = this.getItemsIfDropdown(field);
        const isDate = field.format === 'date';
        const isRadio = field.format === 'radio';
        if (items) {
          if (
            field.format === 'country-iso-alpha2' ||
            field.path.key === 'country_code' ||
            field.path.key === 'country'
          ) {
            return generate
              .comboboxField({
                ...baseProps,
                items,
                placeholder: i18n.getStr('international.placeholders.country'),
              })
              .extendProps({ ...constExtendProps, className: 'text-field' });
          }
          if (isRadio) {
            const { extended_configuration } = field || {};
            const options = extended_configuration?.array_values;
            return generate
              .choiceCardWithInstructionsField({
                ...baseProps,
                items,
                title: extended_configuration?.instructions?.question,
              })
              .extendProps({
                options,
                stretched: true,
                requiredField: field.path.isRequired,
              } as any);
          }
          return generate
            .dropdownField({
              ...baseProps,
              items,
            })
            .extendProps(constExtendProps);
        }
        if (isDate) {
          const { validate } = this.getDateFormatAndParse(key);
          const { futureDateValidation } = this.futureDateByKeyValidator(key);
          if (key === 'start_date' || key === 'end_date') {
            return generate
              .datePicker({
                ...baseProps,
                isMonthYear: true,
                dateFormat: i18n.getMonthYearPattern(),
              })
              .extendProps({
                ...constExtendProps,
                ...additionalProps,
                validate: constExtendProps.validate
                  .concat(validate)
                  .concat(futureDateValidation),
              });
          }
          return generate
            .inputField({
              ...baseProps,
            })
            .extendProps({
              ...constExtendProps,
              validate: constExtendProps.validate
                .concat(validate)
                .concat(futureDateValidation),
            });
        }

        // Length, and regex pattern validators get injected into the TSX field elements here
        const validatorArray = getStringLengthValidators(field);

        return generate
          .inputField({
            ...baseProps,
          })
          .extendProps({
            ...constExtendProps,
            validate: constExtendProps.validate
              .concat(validatorArray)
              .concat(getRegexValidaters(field, key)),
          });
      }
      case 'number':
        return generate
          .inputField({
            ...baseProps,
          })
          .extendProps({
            ...constExtendProps,
            validate: constExtendProps.validate.concat(
              field.pattern
                ? getRegexValidaters(field, key)
                : [numbersOnlyFormat],
            ),
          });
      // case 'date':
      case 'boolean': {
        return generate.checkboxField({
          ...baseProps,
        });
        // .extendProps(constExtendProps);
      }
      case 'array': {
        if (!field.items) {
          break;
        }

        if (isDocument(field.items)) {
          // If array's items is itself a document we want to render a multi-upload
          return renderUpload(field, baseProps, true);
        }

        // Generate field later will call generateField with the string to append
        // e.g., '[0]' or '[1]'
        const content = this.generateFieldLater(field.items);

        // Show a toast notification for form validation errors for the address component
        const showErrorNotification = fullFormId === 'address';

        // Create FieldArray w/ Content
        return generate.arrayField(fullFormId, content, showErrorNotification);
      }

      case 'object': {
        if (isDocument(field)) {
          const downloadAndUpload: RelevantFieldCreator[] = [];
          if (hasDownload(field)) {
            const isInternationalConsentForm =
              field.path?.key === 'international_consent_form';
            downloadAndUpload.push(
              renderDownload(
                field,
                this.intlI18n,
                isInternationalConsentForm
                  ? {
                      index: downloadAndUpload.length + 1,
                    }
                  : undefined,
              ),
            );
          }
          if (hasUpload(field)) {
            downloadAndUpload.push(
              renderUpload(
                field,
                baseProps,
                false,
                field.path?.key === 'international_consent_form'
                  ? {
                      index: downloadAndUpload.length + 1,
                    }
                  : undefined,
              ),
            );
          }
          const skipLabel = [
            'dbs_primary_group_1_and_secondary_group_2_document',
            'dbs_primary_group_1_document',
            'document',
            'ds_proof_of_identity_document_1',
            'ds_proof_of_identity_document_2',
            'ds_proof_of_residential_address_document',
            'id_document_back',
            'id_document_front',
            'national_insurance_document',
          ];
          const label =
            skipLabel.includes(key) || field.extended_configuration?.title
              ? ''
              : (this.intlI18n(key, labels) as string);

          return generate.documentField({
            ...baseProps,
            label,
            field,
            fullFormId,
            passThroughComponent: downloadAndUpload,
            wrapInFlexContainer: true,
          });
        }
        if (isInstructions(field)) {
          const docUri = field.document_uri || '';
          const instructionFileName =
            getDownloadFileName(docUri as string, this.intlI18n) ||
            field.path.key;
          if (field?.path?.formPathToParent === 'scoped_requirements.CA') {
            return generate.instructionsAndAccordionField({
              ...baseProps,
              title: field.extended_configuration.title,
              question: field.extended_configuration.question,
              body: field.extended_configuration.body,
              accordionTitle: field.extended_configuration.accordion_title,
              accordionBody: field.extended_configuration.accordion_body,
              isOpen: true,
            });
          }
          return generate.instructionsField({
            ...baseProps,
            title: field.extended_configuration.title,
            text: field.extended_configuration.body,
            filename: instructionFileName,
            href: docUri as string,
            isHtmlContent: true,
          });
        }
        if (isDownloadAcknowledgement(field)) {
          const docUri = field.document_uri || '';
          const downloadFileName =
            getDownloadFileName(docUri as string, this.intlI18n) ||
            field.path.key;
          const checkbox = generate
            .checkboxField({
              id: `${fullFormId}.acknowledged`,
              title: field.extended_configuration.acknowledgement,
              placeholder: '',
            })
            .render(() => ({
              validate: V.required,
            }));
          return generate.downloadAcknowledgementField({
            ...baseProps,
            title: field.extended_configuration.title,
            text: field.extended_configuration.body,
            acknowledgement: checkbox,
            filename: downloadFileName,
            href: docUri as string,
            className: 'international-download-acknowledgement-inner',
          });
        }
        if (isIdentityDocument(field)) {
          return generate.identityDocumentField({
            ...baseProps,
            arrayPathModifier,
            generate,
            generateField: this.generateField,
            properties: field.properties,
          });
        }

        return this.generateObjectFields(
          key,
          formParentId,
          field,
          arrayPathModifier,
        );
      }

      default:
        return generate
          .inputField({
            ...baseProps,
          })
          .extendProps(constExtendProps);
    }
    return generate
      .inputField({
        ...baseProps,
      })
      .extendProps(constExtendProps);
  };

  generateObjectFields = (
    key: string,
    formParentId: string,
    renderableProp: RenderableSchemaProperty,
    arrayPathModifier = '',
  ) => {
    const objProps = renderableProp.properties || {};
    if (
      Object.keys(objProps).length === 3 &&
      objProps.document &&
      objProps.country &&
      objProps.number
    ) {
      return RelevantFieldCreator.fromGenerator(
        formParentId,
        passThroughProps => (
          <DocumentField
            title={renderableProp.display_name || this.intlI18n(key, 'labels')}
            properties={objProps}
            required={renderableProp.path.isRequired}
          />
        ),
      );
    }

    const unarrayifyId = formParentId.split('[')[0];
    if (specializedFields[unarrayifyId]) {
      return RelevantFieldCreator.fromGenerator(
        formParentId,
        passThroughProps => (
          <SpecializedFieldWrapper
            key={formParentId}
            passThroughProps={passThroughProps}
            SpecializedComponent={specializedFields[unarrayifyId]}
            idToUse={formParentId}
            properties={renderableProp?.properties}
            fieldArrayModifier={arrayPathModifier}
          />
        ),
      );
    }

    const props = renderableProp?.properties || {};
    const fieldIds = Object.keys(props);

    const objField = fieldIds
      .map(k => props[k])
      .map(field => ({
        field,
        rfc: this.generateField(field, arrayPathModifier),
      }))
      .map(({ field, rfc }) => {
        const validateIfInRequiredSet = (rfcId: string) => {
          const validate: Validator | Validator[] = field.path.isRequired
            ? V.required
            : [];
          return {
            validate,
            disabled: field.path.isDisabled,
          };
        };

        return rfc.extendProps(validateIfInRequiredSet);
      });

    return RelevantComplexFieldCreator.fromComplexGenerator(
      key === 'document' ? '' : (this.intlI18n(key, labels) as string),
      fieldIds,
      // eslint-disable-next-line react/display-name
      mappedProps => {
        return objField.flatMap(rfc => {
          if (rfc instanceof RelevantFieldCreator) {
            return rfc.render(mappedProps.get(rfc.id));
          }
          return rfc.render(mappedProps);
        });
      },
    );
  };
}

function isDocument(field: RenderableSchemaProperty) {
  const remoteDoc = 'http://region-compliance.checkr.com/field_types/document';
  const localDoc = '#/definitions/document';
  return field.$id === remoteDoc || field.$id === localDoc;
}

function isInstructions(field: RenderableSchemaProperty) {
  const remoteDoc =
    'http://region-compliance.checkr.com/field_types/instructions';
  const localDoc = '#/definitions/instructions';
  return field.$id === remoteDoc || field.$id === localDoc;
}

function isDownloadAcknowledgement(field: RenderableSchemaProperty) {
  const remoteDoc =
    'http://region-compliance.checkr.com/field_types/download_acknowledgement';
  const localDoc = '#/definitions/download_acknowledgement';
  return field.$id === remoteDoc || field.$id === localDoc;
}

function isIdentityDocument(field: RenderableSchemaProperty) {
  const remoteDoc =
    'http://region-compliance.checkr.com/field_types/identity_document';
  const localDoc = '#/definitions/identity_document';
  return field.$id === remoteDoc || field.$id === localDoc;
}

function getDownloadFileName(href: string, intlI18n: intlI18nFunc) {
  const filenameRegex = /"([^"]*)"/g;
  let docName: string | null;
  docName = null;

  const { query } = qs.parseUrl(href) || {};
  if (query) {
    const contentDisposition = query['response-content-disposition'] as string;
    if (contentDisposition) {
      const [filename]: Array<string> =
        contentDisposition.match(filenameRegex) || [];
      docName = intlI18n(filename?.replace(/"/g, ''), labels) as string;
    }
  }

  return docName;
}

function hasDownload(field: RenderableSchemaProperty) {
  // eslint-disable-next-line camelcase
  return !!field?.document_uri;
}

function renderDownload(
  field: RenderableSchemaProperty,
  intlI18n: intlI18nFunc,
  stepData?: { index: number },
) {
  let docName = field.path.key;
  const href = field.document_uri || '';

  const { query } = qs.parseUrl(href) || {};
  if (query) {
    const stringToTest =
      (query['response-content-disposition'] as string) || '';
    const filenameRegex = /"([^"]*)"/g;
    const [filename] = stringToTest.match(filenameRegex) || [];
    docName = intlI18n(
      (filename && filename.replace(/"/g, '')) || docName,
      labels,
    ) as string;
  }

  return generate.downloadField({
    id: field.path.getCombinedPath.form(),
    placeholder: '',
    filename: docName,
    title: field.display_name || docName,
    href,
    stepData,
  });
}

function hasUpload(field: RenderableSchemaProperty) {
  const { key } = field.path;
  const fieldsWithoutUpload = [
    'driver_license_mvr_with_history_ca_ab_authorization_form',
  ];
  return !fieldsWithoutUpload.includes(key);
}

function renderUpload(
  field: RenderableSchemaProperty,
  baseProps: FieldToMastodonProps,
  isArray = false,
  stepData?: { index: number },
) {
  const validate = field.path.isRequired ? V.required : [];
  return generate
    .uploadField(
      {
        ...baseProps,
        title: '',
        stepData,
      },
      isArray ? 10 : 1,
    )
    .extendProps(() => ({
      validate,
    }));
}

function getRegexValidaters(field: RenderableSchemaProperty, key: string) {
  const regexArray = [];
  if (field.pattern) {
    const patternRegex = new RegExp(field.pattern);
    regexArray.push((val: string) =>
      validateFormat(val, patternRegex, `validations.${camelCase(key)}`),
    );
  }
  return regexArray;
}

export default FieldDeterminer;
