import React from 'react';
import PropTypes from 'prop-types';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

const RenderTime = ({ remainingTime }) => {
  return (
    <div className='timer'>
      <div className='value'>{remainingTime}</div>
    </div>
  );
};

RenderTime.propTypes = {
  remainingTime: PropTypes.number.isRequired,
};

const CountdownTimer = ({ duration, onComplete }) => {
  return (
    <div className='timer-wrapper'>
      <CountdownCircleTimer
        isPlaying
        size={108}
        isGrowing='true'
        rotation='counterclockwise'
        strokeWidth={4}
        duration={duration}
        colors={['#004777']}
        colorsTime={[10]}
        onComplete={onComplete}
      >
        {RenderTime}
      </CountdownCircleTimer>
    </div>
  );
};

CountdownTimer.propTypes = {
  duration: PropTypes.number,
  onComplete: PropTypes.func,
};

CountdownTimer.defaultProps = {
  duration: 90,
  onComplete: () => {},
};

export default CountdownTimer;
