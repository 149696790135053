import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import React, { PureComponent } from 'react';

import countries from 'i18n-iso-countries';

import { Validator } from 'redux-form';

import { RenderableSchemaProperty } from 'international/Fields/FieldCreationLogic/Field.types';
import {
  RenderableElementCreator,
  RenderableElementOrArray,
} from 'international/Fields/FieldCreationLogic/RelevantFieldCreator.class';

import { UnitedKingdomRegion } from '../Components/RegionRequirements/RegionOverrides';
import InternationalContext from '../InternationalContext';

import { FormFieldsToRender } from '../FormLogic/Form.types';
import { required } from '../../lib/validations';

type Props = {
  screening: string;
  region: string;
  fieldsToRender: FormFieldsToRender;
};

const interleave = (arr: any[], item: JSX.Element): JSX.Element[] =>
  ([] as JSX.Element[]).concat(...arr.map(n => [n, item])).slice(0, -1);

class RegionContainer extends PureComponent<
  Props,
  {},
  typeof InternationalContext
> {
  static contextType = InternationalContext;

  context!: React.ContextType<typeof InternationalContext>;

  render() {
    const { screening, region, fieldsToRender } = this.props;
    const intlContext = this.context;
    const {
      fieldGenerator: { generateField },
      lang,
    } = intlContext;

    const sortedFieldsToRender: RenderableSchemaProperty[] = Object.values(
      fieldsToRender || {},
    ).sort((x, y) => {
      if (x.priority && y.priority) {
        return x.priority - y.priority;
      }

      if (x.priority) {
        return -1;
      }

      if (y.priority) {
        return 1;
      }

      return 0;
    });

    const generatedFields: RenderableElementCreator[] = sortedFieldsToRender.map(
      field => generateField(field),
    );

    const renderedFields: RenderableElementOrArray[] = generatedFields.map(
      rfc => {
        const field = rfc.render((id: string) => {
          const validate:
            | Validator
            | Validator[] = intlContext.data?.required?.has(id) ? required : [];

          return {
            validate,
          };
        });
        return field;
      },
    );

    const includesCreditReport = intlContext.data?.screeningsInOrder?.includes('credit_report');
    const regionOverrides: { [key: string]: JSX.Element[] } =
      (screening === 'right_to_work' || (includesCreditReport && screening === 'information'))
        ? {}
        : {
            GB: [<UnitedKingdomRegion key='united-kingdom-region' />],
          };

    const flattenedFields: JSX.Element[] = interleave(
      regionOverrides[region] || renderedFields,
      <hr />,
    ).flat();

    const headerElem =
      region === 'Global'
        ? []
        : [
            <h2 key='region-header' className='region-header'>
              {countries.getName(region, lang.slice(0, 2)) || region}
            </h2>,
          ];
    const knownFields = [
      'ReduxMInput',
      'ReduxMCombobox',
      'ReduxDatePicker',
      'ReduxMDropdown',
      'Field',
      'Connect',
    ];
    const isInputField = (elem: any) =>
      knownFields.some(field =>
        field.includes(
          elem.props?.children &&
            (elem.props?.children[0]?.props?.component?.name ||
              elem.props?.children[0]?.props?.component?.WrappedComponent
                ?.name),
        ),
      );
    const finalOutputArray: JSX.Element[] | HTMLElement = [];
    let runningArray: JSX.Element[] = [];
    flattenedFields
      .filter(elem => {
        return Object.keys(elem.props).length > 0 || regionOverrides[region];
      })
      .map(elem => {
        if (elem.props?.children && elem.props?.children.length > 0) {
          return {
            ...elem,
            props: {
              ...elem.props,
              children: elem.props?.children.filter(
                (grandchild: React.ReactElement) =>
                  grandchild && grandchild.type !== 'span',
              ),
            },
          };
        }
        return elem;
      })
      .forEach((elem: any) => {
        if (isInputField(elem)) {
          runningArray.push(
            elem?.props?.children[0]?.key ===
              'scoped_requirements.CA.date_of_entry' ? (
              <div className='text-field'>
                <div className='field-with-subtext'>
                  {elem}
                  <div className='subtext'>
                    {i18n.getStr(
                      'international.labels.scopedRequirements.CA.dateOfEntrySubtext',
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className='text-field'>{elem}</div>
            ),
          );
        } else {
          if (runningArray.length > 0) {
            finalOutputArray.push(
              runningArray[0].props.className === 'text-field' ? (
                <div className='text-field-group'>{runningArray}</div>
              ) : (
                (runningArray as any)
              ),
            );
          }

          if (
            (elem.props?.children && elem.props?.children.length > 0) ||
            !elem.props?.children
          )
            finalOutputArray.push([elem] as any);
          runningArray = [];
        }
      });
    if (runningArray.length > 0) {
      finalOutputArray.push(
        runningArray[0].props.className === 'text-field' ? (
          <div className='text-field-group'>{runningArray}</div>
        ) : (
          (runningArray as any)
        ),
      );
    }

    return (
      <M.Container
        rows={headerElem.concat(
          <div className={region === 'Global' ? '' : 'region-container'}>
            {finalOutputArray}
          </div>,
        )}
      />
    );
  }
}

export default RegionContainer;
