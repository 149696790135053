/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import React from 'react';
import { groupClass } from './helper';

const Radio = ({
  input,
  meta,
  value,
  children,
  disabled,
  checked,
  className,
  required,
}) => {
  return (
    <label
      className={`${groupClass(meta.touched, meta.error)} ${className}`}
      htmlFor={input.id}
    >
      <input
        {...input}
        onBlur={() => {
          input.onBlur(value);
        }}
        onChange={() => {
          input.onChange(value);
        }}
        disabled={disabled}
        checked={checked}
        required={required}
        type='radio'
      />

      {children}
    </label>
  );
};

Radio.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.object,
  meta: PropTypes.object,
  value: PropTypes.object,
  required: PropTypes.bool,
};

export default Radio;
