/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, formValueSelector, arrayRemoveAll } from 'redux-form';
import { Translate as T } from 'react-redux-i18n';
import Panel from './Panel';
import ExtendedHistoryEntry from './ExtendedHistoryEntry';
import Toggle from './Toggle';

class ExtendedHistoryForm extends Component {
  static propTypes = {
    arrayRemoveAll: PropTypes.func,
    change: PropTypes.func,
    entries: PropTypes.array,
    entityInputProps: PropTypes.object,
    fieldPrefix: PropTypes.string,
    handleEntityPreload: PropTypes.func,
    icon: PropTypes.string,
    lookback: PropTypes.number,
    withVerification: PropTypes.bool,
    translationKey: PropTypes.string,
    type: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      numEntries: 0,
      entities: [],
    };

    this.formKey = this.formKey.bind(this);
    this.togglePrompt = this.togglePrompt.bind(this);
  }

  componentDidMount() {
    const { handleEntityPreload, entries } = this.props;

    this.setState({ numEntries: entries.length });

    if (handleEntityPreload) {
      handleEntityPreload().then(entities => {
        this.setState({ entities });
      });
    }
  }

  formKey(num, fieldName) {
    const { fieldPrefix } = this.props;
    return `${fieldPrefix}[${num}].${fieldName}`;
  }

  togglePrompt() {
    const { withVerification, lookback, translationKey } = this.props;
    let lookbackText;

    if (lookback === 1) {
      lookbackText = 'with1YearLookback';
    } else if (lookback < 99) {
      lookbackText = 'withOtherDefinedLookback';
    } else {
      lookbackText = 'withoutDefinedLookback';
    }

    return [
      translationKey,
      withVerification ? 'withVerification' : 'withoutVerification',
      'expandPrompt',
      lookbackText,
    ].join('.');
  }

  clearEntries() {
    // eslint-disable-next-line no-shadow
    const { arrayRemoveAll, fieldPrefix } = this.props;
    arrayRemoveAll('applyform', fieldPrefix);
  }

  expandEntriesPrompt() {
    const { numEntries } = this.state;
    const { lookback } = this.props;
    const expanded = numEntries > 0;

    const toggle = () => {
      this.setState({ numEntries: expanded ? 0 : 1 }, () => {
        const { state } = this;
        const entryExpanded = state.numEntries > 0;

        if (!entryExpanded) {
          this.clearEntries();
        }
      });
    };
    return (
      <Toggle
        text={this.togglePrompt()}
        translationArguments={{ lookback }}
        expanded={expanded}
        toggle={toggle}
      />
    );
  }

  render() {
    const { numEntries, entities } = this.state;
    const {
      // eslint-disable-next-line no-shadow
      change,
      icon,
      type,
      withVerification,
      entries,
      entityInputProps,
      translationKey,
    } = this.props;

    if (numEntries > 0) {
      return (
        <div>
          <Panel title={`${translationKey}.title`} icon={icon}>
            {this.expandEntriesPrompt()}
            <hr />
            <p>
              <T
                value={`${translationKey}.${
                  withVerification ? 'with' : 'without'
                }Verification.context`}
              />
            </p>
            {[...Array(numEntries).keys()].map(i => {
              return (
                <ExtendedHistoryEntry
                  type={type}
                  entryNum={i}
                  entry={entries[i]}
                  entities={entities}
                  key={`extended-${type}-entry-${i}`}
                  formKey={this.formKey}
                  change={change}
                  entityInputProps={entityInputProps}
                  translationKey={translationKey}
                />
              );
            })}
            <div className='row'>
              <div className='col-lg-4'>
                <div
                  className='add-entry'
                  onClick={() => this.setState({ numEntries: numEntries + 1 })}
                  role='button'
                  tabIndex='0'
                >
                  <i className='fa fa-plus-circle' aria-hidden='true' />
                  <T value={`${translationKey}.addEntry`} />
                </div>
              </div>
            </div>
          </Panel>
        </div>
      );
    }
    return (
      <div>
        <Panel title={`${translationKey}.title`} icon={icon}>
          {this.expandEntriesPrompt()}
        </Panel>
      </div>
    );
  }
}

ExtendedHistoryForm.defaultProps = {
  entries: [],
};

const selector = formValueSelector('applyform');
const ConnectedExtendedHistoryForm = connect(
  (state, { fieldPrefix }) => {
    return {
      entries: selector(state, fieldPrefix),
    };
  },
  { change, arrayRemoveAll },
)(ExtendedHistoryForm);

export default ConnectedExtendedHistoryForm;
