/* eslint-disable lines-between-class-members */
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';

import { camelCase, startCase, toLower } from 'lodash';

import React, { PureComponent } from 'react';

import { RenderableSchemaProperty } from 'international/Fields/FieldCreationLogic/Field.types';
import ResponsiveTextFieldGroupWrapper from 'international/Components/Layouts/ResponsiveTextFieldGroupWrapper';
import { intlI18nFunc } from '../../../FormLogic/ApplyFormManager.class';

import InternationalContext from '../../../InternationalContext';

import * as V from '../../../../lib/validations';

type Props = {
  title?: string;
  properties: { [prop: string]: RenderableSchemaProperty };
  required?: boolean;
};

class DocumentField extends PureComponent<Props, {}> {
  static readonly properties: { [key: string]: string } = Object.freeze({
    country: 'country',
    number: 'number',
    document: 'document',
  });

  static fieldToLabel(
    property: RenderableSchemaProperty,
    intlI18n: intlI18nFunc,
  ) {
    const path = property.path.formPathToParent.split('.');
    const field = path[path.length - 1];
    const translationKey = camelCase(field);
    const fallback = startCase(toLower(field)).replace('Id', 'ID');
    const translation = intlI18n(translationKey, 'labels', true);
    return translation || fallback;
  }

  render() {
    const { title, properties, required } = this.props;

    return (
      <InternationalContext.Consumer>
        {({ getFieldGeneratorForProperties, intlI18n }) => {
          Object.values(properties).forEach(property => {
            // eslint-disable-next-line no-param-reassign
            property.required = [];
            // eslint-disable-next-line no-param-reassign
            property.path.isRequired = false;
          });

          const gen = getFieldGeneratorForProperties(properties);
          return (
            <>
              {M.Container.buildSubContents([
                title && (
                  <h3 key='title'>{i18n.getConstStr('labels', title)}</h3>
                ),
                [
                  <ResponsiveTextFieldGroupWrapper key='document-fields'>
                    <div className='text-field'>
                      {gen(DocumentField.properties.number)
                        .extendProps(() => ({
                          labelText: `${i18n.getStr(
                            'international.labels.indexedNumber',
                            DocumentField.fieldToLabel(
                              properties.number,
                              intlI18n,
                            ),
                          )}`,
                        }))
                        .render(_ => ({
                          validate: (val, allFields) =>
                            required ? V.required(val) : undefined,
                        }))}
                    </div>

                    <div className='text-field'>
                      {gen(DocumentField.properties.country).render(_ => ({
                        validate: (val, allFields) =>
                          required ? V.required(val) : undefined,
                      }))}
                    </div>

                    <div className='text-field'>
                      {gen(DocumentField.properties.document).render(_ => ({
                        validate: (val, allFields) =>
                          required ? V.required(val) : undefined,
                      }))}
                    </div>
                  </ResponsiveTextFieldGroupWrapper>,
                ],
              ])}
            </>
          );
        }}
      </InternationalContext.Consumer>
    );
  }
}

export default DocumentField;
