import moment from 'moment/moment';
import insertArgyleEmployment from './insertArgyleEmployment';

const insertVerifiedArgyleEmployment = () => dispatch => {
  const argyleEmployment = {
    contractType: 'seasonal',
    contractTypeVerified: true,
    name: "Domino's",
    nameVerified: true,
    position: 'Multi-topping Sustenance Assembler',
    positionVerified: true,
    startDate: moment()
      .subtract(3, 'years')
      .format('MM/YYYY'),
    startDateVerified: true,
    endDate: moment()
      .subtract(1, 'months')
      .format('MM/YYYY'),
    endDateVerified: true,
    currentPosition: false,
    argyleEmploymentId: 'recent_verified',
    isVerified: true,
  };

  dispatch(insertArgyleEmployment(argyleEmployment));
};

export default insertVerifiedArgyleEmployment;
