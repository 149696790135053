import React, { ReactNode } from 'react';
import styled from 'styled-components';
import TestBanner from 'components/TestBanner';
import Footer from 'components/Footer';
import CheckrHeader from 'components/CheckrHeader';
import Loading from 'components/Loading';
// Style the parent container
// Set it as a flex box in column direction
const PageWrapper = styled.div.attrs(() => ({ className: 'row' }))`
  display: flex;
  background: #fff;
  flex-direction: column;
  min-height: 100vh;
`;

const MainWrapper = styled.div<{ stepsExists: boolean }>`
  padding: 56px 0px;
  flex-grow: 1;

  @media (max-width: 768px) {
    padding: ${props => (props.stepsExists ? '36px 16px' : '0px 16px')};
  }
`;

const StepsWrapper = styled.div.attrs(() => ({
  className: 'col-md-8 offset-md-2 col-xs-12',
}))`
  // TODO need to change Steps from having 'margin-bottom: 30px;'
  // on .cd-multi-steps
  margin-bottom: 50px;
`;

const IntroWrapper = styled.div.attrs(() => ({
  className: 'col-md-8 offset-md-2 col-xs-12',
}))`
  padding: 0px;
  margin-bottom: 16px;
  @media (max-width: 768px) {
    padding: 0px 16px;
    margin-bottom: 24px;
  }
`;

const ChildrenWrapper = styled.div.attrs(() => ({
  className: 'col-md-8 offset-md-2 col-xs-12',
}))`
  padding: 0px;

  @media (max-width: 768px) {
    padding: 0px 16px;
  }
`;

// Fetch Header dynamically
const NewHeader = React.lazy(() => import('components/NewHeader'));
interface ContainerProps {
  footer: any;
  header?: ReactNode | ReactNode[];
  intro?: ReactNode;
  isTest?: boolean;
  accountName: string;
  page?: any;
  steps?: ReactNode;
  pages?: Array<{ name: string }>;
}

const BaseLayout: React.FC<ContainerProps> = ({
  accountName,
  children,
  footer,
  header,
  intro = null,
  isTest = false,
  page,
  steps,
}) => {
  const HeaderComponent =
    !!header && Array.isArray(header) ? (
      <NewHeader components={header} accountName={accountName} />
    ) : (
      <CheckrHeader />
    );

  return (
    <React.Suspense fallback={<Loading />}>
      <PageWrapper>
        {isTest && <TestBanner />}
        {/* Show Company Logo Header or Checkr Header */}
        {HeaderComponent}
        {/* Main Container */}
        <MainWrapper stepsExists={steps !== false}>
          <StepsWrapper>{steps}</StepsWrapper>
          <IntroWrapper>{intro && intro}</IntroWrapper>
          <ChildrenWrapper>{children}</ChildrenWrapper>
        </MainWrapper>
        {footer && (
          <Footer
            currentPageName={page?.name || 'Success'}
            components={footer}
          />
        )}
      </PageWrapper>
    </React.Suspense>
  );
};

export default BaseLayout;
