/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-redux-i18n';
import { isString, omit } from 'lodash';

const FeedbackLabel = ({ touched, error, warning }) => {
  let properties = {};

  if (error && !isString(error)) {
    properties = omit(error, ['message']);
    // eslint-disable-next-line no-param-reassign
    error = error.message;
  }

  return (
    <small className='form-control-feedback'>
      {touched &&
        ((error && <Translate value={error} {...properties} />) ||
          (warning && <span>{warning}</span>))}
    </small>
  );
};

FeedbackLabel.propTypes = {
  touched: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  warning: PropTypes.string,
};

export default FeedbackLabel;
