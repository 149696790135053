/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import { Translate as T } from 'react-redux-i18n';
import { updatePartialSsn, handleIncorrectInformation } from '../actions';

import { SensitiveInput } from './fields';

import Panel from './Panel';

class AuthenticateInvitationForm extends Component {
  static propTypes = {
    handleIncorrectInformation: PropTypes.func,
    partialSsn: PropTypes.string,
    updatePartialSsn: PropTypes.func,
  };

  changePartialSsn(partialSsn) {
    const { props } = this;

    props.updatePartialSsn(partialSsn);
  }

  render() {
    // eslint-disable-next-line no-shadow
    const { handleIncorrectInformation, partialSsn } = this.props;
    const { props } = this;
    const name = ['firstName', 'middleName', 'lastName']
      .map(nameType => {
        return props[nameType];
      })
      .join(' ');

    return (
      <Panel
        title='components.AuthenticateInvitationForm.title'
        className='verify-identity'
      >
        <div className='row'>
          <div className='col-md-12'>
            <span className='pull-left'>
              <label className='font-weight-bold'>
                <T value='labels.hello' />, {name}
              </label>
            </span>
            <span className='pull-right'>
              <button
                type='button'
                className='btn btn-link'
                onClick={handleIncorrectInformation}
              >
                <T value='buttons.notYou' />
              </button>
            </span>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <Field
              type='text'
              name='partialSsn'
              label='labels.partialSsn'
              placeholder='4321'
              input={{
                name: 'partial.ssn',
                value: partialSsn || '',
                maxLength: 4,
                onChange: this.changePartialSsn.bind(this),
              }}
              component={SensitiveInput}
            />
          </div>
        </div>
      </Panel>
    );
  }
}

const selector = formValueSelector('applyform');

const ConnectedAuthenticateInvitationForm = connect(
  state => {
    return {
      firstName: selector(state, 'firstName'),
      middleName: selector(state, 'middleName'),
      lastName: selector(state, 'lastName'),
      partialSsn: state.configuration.partialSsn,
    };
  },
  { handleIncorrectInformation, updatePartialSsn },
)(AuthenticateInvitationForm);

export default ConnectedAuthenticateInvitationForm;
