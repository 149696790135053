/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { i18n } from '@international/mastodon-i18n';

import { ENV } from '../constants';
import { requestConfigurationUpdate, updateLocale } from '../actions';

import '../css/LanguageToggle.scss';

const LanguageLabel = ({ lang, icon }) => (
  <span>
    {icon && <i className={`fa fa-${icon}`} />} {lang}
  </span>
);

LanguageLabel.propTypes = {
  icon: PropTypes.string,
  lang: PropTypes.string,
};

class LanguageToggle extends Component {
  static propTypes = {
    configIsLive: PropTypes.bool,
    dropup: PropTypes.bool,
    lang: PropTypes.shape({
      abbr: PropTypes.string,
      value: PropTypes.string,
    }),
    refreshing: PropTypes.bool,
    requestConfigurationUpdate: PropTypes.func,
    supportedLanguages: PropTypes.object,
    updateLocale: PropTypes.func,
    locale: PropTypes.string,
    isInternational: PropTypes.bool,
    defaultLanguages: PropTypes.object,
    isWelcomePage: PropTypes.bool,
    candidate: PropTypes.object,
    routeParams: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  }

  handleOnSelect(lang) {
    const { props } = this;
    // this request will be called when welcome page on international flow
    // or is the current page's config tied to the API?

    if (
      (props.isInternational && props.configIsLive && props.isWelcomePage) ||
      (!props.isInternational && props.configIsLive)
    ) {
      props.requestConfigurationUpdate({
        params: { lang, updateServer: true },
      });
    } else {
      const { routeParams } = props;
      props.updateLocale(
        lang,
        routeParams,
        true,
        props.candidate,
        props?.isInternational,
      );
    }
  }

  render() {
    const {
      refreshing,
      dropup,
      locale = i18n.defaultLocale,
      isInternational,
      defaultLanguages,
    } = this.props;
    const supportedLanguages = isInternational
      ? i18n.getSupportedLocales(ENV)
      : defaultLanguages;
    const supportedLocale = supportedLanguages?.[locale]
      ? locale
      : locale?.slice(0, 2);
    const language = isInternational ? supportedLocale : locale;
    const lang = {
      abbr: language,
      value: supportedLanguages?.[language],
    };
    if (Object.keys(supportedLanguages).length <= 1) {
      return null;
    }
    const currentLanguageLabel = (
      <LanguageLabel icon='globe' lang={lang.value} />
    );
    const { isOpen } = this.state;
    return (
      <Dropdown
        className='language-toggle'
        isOpen={isOpen}
        toggle={this.toggle}
        {...{ dropup }}
      >
        <DropdownToggle caret={!refreshing}>
          {refreshing ? (
            <i className='fa fa-circle-o-notch fa-spin fa-fw' />
          ) : (
            currentLanguageLabel
          )}
        </DropdownToggle>
        <DropdownMenu>
          {Object.keys(supportedLanguages).map(abbr => (
            <DropdownItem
              className={`${abbr} ${lang.abbr === abbr ? 'selected' : ''}`}
              key={abbr}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={this.handleOnSelect.bind(this, abbr)}
            >
              <LanguageLabel lang={supportedLanguages[abbr]} />
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

const mapStateToProps = props => {
  const {
    configuration: { configuration, refreshing, routeParams },
    i18n: { locale },
  } = props;
  return {
    locale,
    refreshing,
    defaultLanguages: configuration?.layout?.supportedLanguages,
    candidate: configuration.candidate,
    routeParams,
  };
};
export default connect(mapStateToProps, {
  requestConfigurationUpdate,
  updateLocale,
})(LanguageToggle);
