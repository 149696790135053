import React from 'react';

import HTMLText from './HTMLText';

const ApplicantPayNotification = ({ content }: {content: string}) => (
  <div className='card alert-warning mb-3'>
    <div className='card-block'>
      <HTMLText content={content} />
    </div>
  </div>
);

export default ApplicantPayNotification;
