import { moment } from '@international/mastodon-i18n';

const rotateArr = arr => {
  arr.push(arr.shift());
  return arr;
};

const weekDaysShort = rotateArr(moment.weekdaysShort());
const weekDays = rotateArr(moment.weekdays());

class DaySchedule {
  constructor(day, hours) {
    this.hours = hours;
    this.bizClosedKey = `closed${day}`;
    this.bizOpenTimeKey = `${day.toLowerCase()}HoursOpen`;
    this.lunchFromKey = `${day.toLowerCase()}LunchFrom`;
    this.lunchToKey = `${day.toLowerCase()}LunchTo`;
    this.bizClosedTimeKey = `${day.toLowerCase()}HoursClose`;
  }

  formattedTime(hourKey) {
    return moment(this.hours[hourKey], 'hh').format('LT');
  }

  open() {
    return this.formattedTime(this.bizOpenTimeKey);
  }

  close() {
    return this.formattedTime(this.bizClosedTimeKey);
  }

  lunchFrom() {
    return this.formattedTime(this.lunchFromKey);
  }

  lunchTo() {
    return this.formattedTime(this.lunchToKey);
  }

  closed() {
    return this.hours[this.bizClosedKey] === 'True';
  }

  closeForLunch() {
    return (
      this.hours[this.lunchFromKey] &&
      this.hours[this.lunchFromKey].lengh > 0 &&
      this.hours[this.lunchToKey] &&
      this.hours[this.lunchToKey].lengh > 0
    );
  }
}

const getScheduleForDay = (day, hours) => {
  const schedule = new DaySchedule(day, hours);
  if (schedule.closed()) return 'Closed';
  if (schedule.closeForLunch()) {
    return `${schedule.open()} - ${schedule.lunchFrom()}, ${schedule.lunchTo()} - ${schedule.close()}`;
  }
  return `${schedule.open()} - ${schedule.close()}`;
};

// A 'block' is a group of days with the same business hours
// eg: Mon-Fri 8:00 AM - 5:00 PM, Sat-Sun 10:00 AM - 4:00pm
const getAvailability = location => {
  const daysOpen = [];
  const dayBlocks = [];

  weekDays.forEach((day, index) => {
    const businessHours = getScheduleForDay(day, location.hours);
    const curBlock = dayBlocks[dayBlocks.length - 1];

    const setDayAsOpen = () => {
      if (daysOpen.length > 1) daysOpen.pop();
      daysOpen.push(weekDaysShort[index]);
    };
    const putDayInCurrentBlock = () => {
      if (curBlock.daySpanFull.length > 1) curBlock.daySpanFull.pop();
      curBlock.daySpanFull.push(weekDays[index]);
    };
    const putDayInNewBlock = () => {
      dayBlocks.push({
        daySpanFull: [weekDays[index]],
        businessHours,
      });
    };

    if (businessHours !== 'Closed') setDayAsOpen();
    if (curBlock && curBlock.businessHours === businessHours)
      putDayInCurrentBlock();
    else putDayInNewBlock();
  });
  return { daysOpen, dayBlocks };
};

export default getAvailability;
