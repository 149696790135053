import { M, colors } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import React, { PureComponent } from 'react';
import { WrappedMCheckboxProps } from './WrappedComp.types';

type Props = {
  valueOnInit?: (val?: boolean) => void;
};
class ReduxMCheckbox extends PureComponent<WrappedMCheckboxProps & Props, {}> {
  componentDidMount() {
    const { input, valueOnInit } = this.props;
    valueOnInit && valueOnInit(input?.value);
  }

  render() {
    const {
      id,
      labelText,
      disabled,
      invalid,
      input,
      meta,
      valueOnInit,
      ...rest
    } = this.props;
    const { touched, error } = meta;
    const showInvalid = (touched && !!error) || invalid;

    return (
      <>
        <M.Checkbox
          {...rest}
          {...input}
          id={id}
          labelText={labelText || <span>&nbsp;</span>}
          disabled={disabled}
          checked={input?.value}
        />
        {showInvalid && (
          <span
            style={{
              paddingLeft: '1.625rem',
              display: 'block',
              overflow: 'visible',
              maxHeight: '12.5rem',
              fontWeight: 'normal',
              color: colors.dangerRed100,
            }}
            className='bx--form-requirement'
          >
            {touched && error && i18n.getStr(error)}
          </span>
        )}
      </>
    );
  }
}

export default ReduxMCheckbox;
