import { M } from '@dashboard-experience/mastodon';
import { MVR_PROPERTY_KEYS } from 'international/constants.ca';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { i18n } from '@international/mastodon-i18n';
import { RenderableSchemaProperty } from 'international/Fields/FieldCreationLogic/Field.types';
import { clearFormValue } from '../../../../actions/intlForm';
import * as V from '../../../../lib/validations';
import InternationalContext from '../../../InternationalContext';

import './DriverLicenseField.scss';

type Props = {
  clearFormValue: any;
  id: string;
  properties: Record<string, RenderableSchemaProperty>;
  form?: any;
};

class DriverLicenseField extends PureComponent<Props> {
  static id = 'driver_licenses';

  static readonly properties = MVR_PROPERTY_KEYS;

  render() {
    const {
      id = DriverLicenseField.id,
      properties,
      form,
      clearFormValue: clearFormValueAction,
    } = this.props;

    return (
      <InternationalContext.Consumer>
        {({ getFieldGeneratorForProperties }) => {
          const gen = getFieldGeneratorForProperties(properties, id);

          const rows = [
            gen(MVR_PROPERTY_KEYS.driver_license_country_of_issue).render(),
            gen(MVR_PROPERTY_KEYS.driver_license_province_of_issue)
              .extendProps(() => ({
                onChange: () => {
                  clearFormValueAction(
                    `${id}.mvr_with_history_${countryOfIssue}_${provinceOfIssue}_authorization_form`,
                  );
                },
              }))
              .render(),
          ];
          const countryOfIssue = get(
            form,
            `${id}.country_of_issue`,
          )?.toLowerCase();
          const provinceOfIssue = get(
            form,
            `${id}.province_of_issue`,
          )?.toLowerCase();

          if (countryOfIssue && provinceOfIssue) {
            const fieldKey = get(
              MVR_PROPERTY_KEYS,
              `driver_license_mvr_with_history_${countryOfIssue}_${provinceOfIssue}_authorization_form`,
            );
            const fieldProps = get(properties, fieldKey);
            if (fieldProps) {
              fieldProps.extended_configuration.component_style = 'info';
              const validate = fieldProps.document_uri ? V.required : [];
              rows.push(
                gen(fieldKey)
                  .extendProps(() => ({ validate }))
                  .render(),
              );
            }
            rows.push([
              <div
                key='drive_license_fill_out_details'
                className='drive_license_fill_out_details'
              >
                {gen(MVR_PROPERTY_KEYS.driver_license_number).render()}
                {gen(MVR_PROPERTY_KEYS.driver_license_class).render()}
                {gen(MVR_PROPERTY_KEYS.driver_license_expiration_date).render()}
              </div>,
              renderDriverLicenseImgExample(
                countryOfIssue,
                provinceOfIssue,
                properties,
              ),
            ]);
            rows.push(
              gen(MVR_PROPERTY_KEYS.driver_license_image_upload).render(),
            );
          }
          return <>{M.Container.buildSubContents(rows)}</>;
        }}
      </InternationalContext.Consumer>
    );
  }
}

const renderDriverLicenseImgExample = (
  country: string,
  province: string,
  props: {
    [prop: string]: RenderableSchemaProperty;
  },
) => {
  const fieldName = `driver_license_img_example_${country}_${province}`;
  const fieldProps = get(props, fieldName);
  const imgUrl = fieldProps.document_uri || '';
  return (
    <div key={fieldName}>
      <img
        src={imgUrl}
        className='driver_license_img_example'
        alt={`${i18n.getStr(
          'international.labels.driverLicense',
        )}. ${i18n.getStr(
          'labels.location',
        )}: ${country?.toUpperCase()} ${province?.toUpperCase()}`}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  form: state?.form?.intlForm?.values,
});

export default connect(mapStateToProps, {
  clearFormValue,
})(DriverLicenseField);
