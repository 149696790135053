import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Checkbox } from '../../fields';
import HTMLText from '../../HTMLText';

const Disclosure = ({ label, name, value, labelOptions }) => {
  return (
    <>
      {value && (
        <div className='scrollable' key={label}>
          <HTMLText content={value} />
        </div>
      )}
      <Field
        type='checkbox'
        name={name}
        label={label}
        labelOptions={{ ...labelOptions }}
        component={Checkbox}
        className={value ? 'disclosure-checkbox' : 'disclosure-checkbox-noline'}
        boldLabel
        disabled
      />
    </>
  );
};

Disclosure.propTypes = {
  label: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  labelOptions: PropTypes.object,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default Disclosure;
