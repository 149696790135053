import React from 'react';
import { Translate as T } from 'react-redux-i18n';

const TestBanner = () => (
  <div className='test-banner alert-warning text-center'>
    <i className='fa fa-exclamation-circle' />{' '}
    <T value='components.TestBanner.text' dangerousHTML />
  </div>
);

export default TestBanner;
