import React from 'react';
import PropTypes from 'prop-types';
import { M } from '@dashboard-experience/mastodon';
import { Translate as T } from 'react-redux-i18n';

const Address = ({ aria = '', className = '', icon = false, label, value }) => {
  const [line1, line2, line3] = value;

  return (
    <div className={`${className} inputs-container`} key={label}>
      <h3 className='input-label' aria-label={aria || value}>
        <T value={label} />
        {icon && <M.Icon className='icon' icon={icon} />}
      </h3>
      <div className='input-value'>
        {line1 && (
          <div className='address-ln1' aria-label={line1}>
            {line1}
          </div>
        )}
        {line2 && (
          <div className='address-ln2' aria-label={line2}>
            {line2}
          </div>
        )}
        {line3 && (
          <div className='address-ln3' aria-label={line3}>
            {line3}
          </div>
        )}
      </div>
    </div>
  );
};

Address.propTypes = {
  aria: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default Address;
