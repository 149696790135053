import moment from 'moment';
import insertArgyleEmployment from './insertArgyleEmployment';

const insertCurrentVerifiedArgyleEmployment = () => dispatch => {
  const argyleEmployment = {
    contractType: 'full_time',
    contractTypeVerified: true,
    name: 'Fake Current',
    nameVerified: true,
    position: 'SWE',
    positionVerified: true,
    startDate: moment()
      .subtract(3, 'years')
      .format('MM/YYYY'),
    startDateVerified: true,
    endDate: null,
    endDateVerified: true,
    currentPosition: true,
    argyleEmploymentId: 'verified',
    isVerified: true,
  };

  dispatch(insertArgyleEmployment(argyleEmployment));
};

export default insertCurrentVerifiedArgyleEmployment;
