/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import isDisabled from './helper';

import { Text } from './fields';

import * as V from '../lib/validations';

const MotherMaidenNameField = ({
  protectedInvitationFlow,
  protectedInvitationFields,
  candidate,
}) => {
  return (
    <div className='row'>
      <div className='col-md-6'>
        <Field
          type='text'
          name='motherMaidenName'
          label='labels.motherMaidenName'
          component={Text}
          validate={[V.required, V.nameFormat]}
          disabled={isDisabled({
            field: candidate.motherMaidenName,
            protect:
              protectedInvitationFlow &&
              protectedInvitationFields.includes('mother_maiden_name'),
          })}
        />
      </div>
    </div>
  );
};

MotherMaidenNameField.propTypes = {
  protectedInvitationFlow: PropTypes.bool,
  protectedInvitationFields: PropTypes.array,
  candidate: PropTypes.object,
};

export default MotherMaidenNameField;
