export default [
  { value: '', name: 'options.selectADegree', i18n: true },
  { value: 'HighSchool', name: 'options.degrees.highSchool', i18n: true },
  { value: 'GED', name: 'options.degrees.ged', i18n: true },
  { value: 'Associate', name: 'options.degrees.associate', i18n: true },
  { value: 'AA', name: 'options.degrees.associateOfArts', i18n: true },
  { value: 'AS', name: 'options.degrees.associateOfScience', i18n: true },
  {
    value: 'AAS',
    name: 'options.degrees.associateOfAppliedScience',
    i18n: true,
  },
  { value: 'Bachelor', name: 'options.degrees.bachelor', i18n: true },
  { value: 'BA', name: 'options.degrees.bachelorOfArts', i18n: true },
  { value: 'BS', name: 'options.degrees.bachelorOfScience', i18n: true },
  { value: 'Master', name: 'options.degrees.master', i18n: true },
  { value: 'MA', name: 'options.degrees.masterOfArts', i18n: true },
  { value: 'MS', name: 'options.degrees.masterOfScience', i18n: true },
  { value: 'Doctor', name: 'options.degrees.doctor', i18n: true },
  { value: 'Ph', name: 'options.degrees.doctorOfPhilosophy', i18n: true },
  { value: 'MD', name: 'options.degrees.medicalDoctor', i18n: true },
  { value: 'JD', name: 'options.degrees.jurisDoctorate', i18n: true },
  { value: 'DDS', name: 'options.degrees.doctorOfDentalSurgery', i18n: true },
  { value: 'Pharm', name: 'options.degrees.doctorOfPharmacy', i18n: true },
  { value: 'degree', name: 'options.degrees.other', i18n: true },
];

export const EducationNoneOption = {
  value: 'None',
  name: 'options.noEducation',
  i18n: true,
};
