/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { replace } from 'lodash';

import { I18n } from 'react-redux-i18n';
import { groupClass } from './helper';
import FeedbackLabel from './FeedbackLabel';
import Label from './Label';

const masked = (value, mask, maskChar) => value.replace(mask, maskChar);
/**
 * A field that masks sensitive input, while retaining formatting, unlike a normal password field
 * This is so we can have •••-••-•••• for SSN, instead of just •••••••••
 */
class SensitiveInput extends Component {
  static defaultProps = {
    /** A regexp for characters to mask */
    mask: /./g,
    /** Mask them with this */
    maskChar: '•',
  };

  static propTypes = {
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    input: PropTypes.shape({
      name: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      value: PropTypes.string,
    }),
    inputClass: PropTypes.string,
    label: PropTypes.string,
    labelAsRequired: PropTypes.bool,
    meta: PropTypes.object,
    onPaste: PropTypes.func,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func,
    mask: PropTypes.instanceOf(RegExp),
    maskChar: PropTypes.string,
  };

  state = { hidden: true };

  toggleHidden = e => {
    e.preventDefault();
    this.setState(prevState => {
      const { hidden } = prevState;
      return { hidden: !hidden };
    });
  };

  handleBlur = e => {
    e.preventDefault();
    const {
      props: { input },
    } = this;

    if (input.onBlur && typeof input.onBlur === 'function') {
      input.onBlur(input.value);
    }
  };

  handlePaste = e => {
    e.preventDefault();
    const {
      props: { input },
    } = this;
    const { clipboardData } = e;

    if (clipboardData) {
      input.onChange(clipboardData.getData('text'));
    }
  };

  handleChange = e => {
    e.preventDefault();
    const { hidden } = this.state;
    const { input, mask, maskChar } = this.props;
    const initialValue = input.value;
    const newValue = e.target.value;

    if (!hidden) return input.onChange(newValue);

    // If they select all and then start typing to overwrite the field
    if (newValue.length === 1 && newValue.charAt(0) !== maskChar)
      return input.onChange(newValue);

    // Clear the field if they remove a character, since we can't tell which character was removed
    if (newValue.length < initialValue.length) return input.onChange('');

    // Nothing changed
    if (newValue.length === initialValue.length)
      return input.onChange(initialValue);

    // Figure out which character changed, and splice it into the real value
    const maskedValue = masked(initialValue, mask, maskChar);
    for (let i = 0; i < newValue.length; i += 1) {
      if (newValue.charAt(i) !== maskedValue.charAt(i))
        return input.onChange(
          initialValue.slice(0, i) + newValue.charAt(i) + initialValue.slice(i),
        );
    }

    return input.onChange(initialValue);
  };

  render() {
    const {
      props: {
        autoFocus,
        disabled,
        input,
        inputClass,
        label,
        labelAsRequired,
        meta: { touched, error, warning },
        placeholder,
        type,
        mask,
        maskChar,
      },
      state: { hidden },
      toggleHidden,
      handleChange,
      handleBlur,
      handlePaste,
    } = this;
    const { value } = input;

    return (
      <div className={groupClass(touched, error)}>
        <div className='sensitive-label'>
          <Label
            label={label}
            htmlFor={input.name}
            labelAsRequired={labelAsRequired}
          />
          <span
            className='sensitive-toggle'
            role='button'
            tabIndex={-1}
            onClick={toggleHidden}
          >
            {hidden ? I18n.t('buttons.show') : I18n.t('buttons.hide')}
          </span>
        </div>
        <input
          {...input}
          id={replace(input.name, '.', '-')}
          value={hidden ? masked(value, mask, maskChar) : value}
          type={type}
          className={`form-control ${inputClass || ''}${
            hidden && value !== '' ? ' sensitive-input' : ''
          }`}
          placeholder={I18n.t(placeholder || label)}
          autoComplete='none'
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          disabled={disabled}
          onPaste={handlePaste}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <FeedbackLabel {...{ touched, error, warning }} />
      </div>
    );
  }
}

const mapStateToProps = ({ i18n }) => ({ i18n });

export default connect(mapStateToProps)(SensitiveInput);
