/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { Select, Text } from './fields';

import { SHORT_DATE_FORMAT } from '../constants';

import * as N from '../lib/normalizations';
import * as O from '../lib/options';
import * as V from '../lib/validations';

import isDisabled from './helper';

const CurrentAddressFields = ({
  currentAddress,
  protectedInvitationFlow,
  protectedInvitationFields,
  skipFrom,
  skipUnit,
  baseKey,
}) => {
  return (
    <>
      <div className='row'>
        <div className='col-md-6'>
          <Field
            type='text'
            name={`${baseKey}.street`}
            label='labels.street'
            component={Text}
            validate={[V.required, V.noPoBoxFormat, V.noGeneralDeliveryFormat]}
            disabled={isDisabled({
              field: currentAddress.street,
              protect:
                protectedInvitationFlow &&
                protectedInvitationFields.includes('current_address'),
            })}
          />
        </div>

        {!skipUnit ? (
          <div className='col-md-6'>
            <Field
              type='text'
              name={`${baseKey}.street2`}
              label='labels.unit'
              component={Text}
            />
          </div>
        ) : (
          <div className='col-md-6'>
            <Field
              type='text'
              name={`${baseKey}.city`}
              label='labels.city'
              component={Text}
              validate={[V.required]}
              disabled={isDisabled({
                field: currentAddress.city,
                protect:
                  protectedInvitationFlow &&
                  protectedInvitationFields.includes('current_address'),
              })}
            />
          </div>
        )}
      </div>

      <div className='row'>
        {!skipUnit && (
          <div className={protectedInvitationFlow ? 'col-md-6' : 'col-md-4'}>
            <Field
              type='text'
              name={`${baseKey}.city`}
              label='labels.city'
              component={Text}
              validate={[V.required]}
              disabled={isDisabled({
                field: currentAddress.city,
                protect:
                  protectedInvitationFlow &&
                  protectedInvitationFields.includes('current_address'),
              })}
            />
          </div>
        )}

        <div className={protectedInvitationFlow ? 'col-md-6' : 'col-md-4'}>
          <Field
            type='text'
            name={`${baseKey}.zipcode`}
            label='labels.zipcode'
            placeholder='20500'
            component={Text}
            normalize={N.zipcode}
            validate={[V.required, V.zipcodeFormat]}
            disabled={isDisabled({
              field: currentAddress.zipcode,
              protect:
                protectedInvitationFlow &&
                protectedInvitationFields.includes('current_address'),
            })}
          />
        </div>

        <div className={protectedInvitationFlow ? 'col-md-6' : 'col-md-4'}>
          <Field
            type='text'
            name={`${baseKey}.state`}
            label='labels.state'
            component={Select}
            validate={[V.required]}
            options={O.states()}
            disabled={isDisabled({
              field: currentAddress.state,
              protect:
                protectedInvitationFlow &&
                protectedInvitationFields.includes('current_address'),
            })}
          />
        </div>

        {!skipFrom &&
          !(
            protectedInvitationFlow &&
            protectedInvitationFields.includes('current_address')
          ) && (
            <div className='col-md-4'>
              <Field
                type='text'
                name={`${baseKey}.startDate`}
                label='labels.from'
                component={Text}
                placeholder='placeholders.mmYY'
                normalize={N.monthYear}
                validate={[
                  V.required,
                  V.validDate(SHORT_DATE_FORMAT),
                  V.dateInThePast(SHORT_DATE_FORMAT),
                ]}
              />
            </div>
          )}
      </div>
    </>
  );
};

CurrentAddressFields.propTypes = {
  currentAddress: PropTypes.object,
  protectedInvitationFlow: PropTypes.bool,
  protectedInvitationFields: PropTypes.array,
  skipFrom: PropTypes.bool,
  skipUnit: PropTypes.bool,
  baseKey: PropTypes.string,
};

CurrentAddressFields.defaultProps = {
  skipUnit: true,
  baseKey: 'currentAddress',
};

export default CurrentAddressFields;
