import React from 'react';

import requestEmployers from '../lib/employment/utils/employerUtils';
import ExtendedHistoryForm from './ExtendedHistoryForm';

const entityInputProps = {
  minLength: 3,
  maxResults: 20,
  onSearch: requestEmployers,
  suggest: true,
  typeaheadAsync: true,
};

const ExtendedEmploymentHistoryForm = props => (
  <ExtendedHistoryForm
    {...props}
    type='employment'
    icon='fa-briefcase'
    translationKey='components.ExtendedEmploymentHistoryForm'
    fieldPrefix='extendedEmploymentEntries'
    entityInputProps={entityInputProps}
  />
);

export default ExtendedEmploymentHistoryForm;
