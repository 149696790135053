import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Translate } from 'react-redux-i18n';
import {
  FORM_BUSY,
  FORM_IDLE,
  SET_NEXT_BUTTON_HIDDEN,
  SET_PREVIOUS_BUTTON_HIDDEN,
  requestConfigurationUpdate,
} from 'actions';
import { fetchIdentityVerificationPii } from 'actions/identityVerification';
import StartIdvComponent from './StartIdvComponent';
import IdentityVerificationResults from './IdentityVerificationResults';
import { SOCURE_JS_URL, SOCURE_SDK_KEY } from '../../constants';

const IdvContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 24px 24px 24px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #e1e6eb;
`;

const IdvHeader = styled.h1`
  margin-bottom: unset !important;
`;

const SocureSdkContainer = styled.div`
  align-self: center;
`;

const IdentityVerificationProcessor = ({
  idvComplete,
  candidateId,
  invitationId,
  disableNavigation,
  showContinueButton,
  enableNavigation,
  updateConfiguration,
}) => {
  const [socureEnabled, setSocureEnabled] = useState(false);
  const [socureCompleted, setSocureCompleted] = useState(idvComplete);
  const [verificationCompleted, setVerificationCompleted] = useState(false);
  const [shouldPollForResponse, setShouldPollForResponse] = useState(
    idvComplete,
  );
  const [extractedPii, setExtractedPii] = useState({});
  const timerIdRef = useRef(null);

  useEffect(() => {
    const pollingCallbackFn = () => {
      fetchIdentityVerificationPii(candidateId, invitationId).then(response => {
        if (response?.status === 'complete') {
          setShouldPollForResponse(false);
          setExtractedPii(response.parsedResponse || {});
          setVerificationCompleted(true);
          updateConfiguration({ updateCandidate: true });
          enableNavigation();
        }
      });
    };
    const startPolling = () => {
      pollingCallbackFn();
      timerIdRef.current = setInterval(pollingCallbackFn, 10000);
    };

    const stopPolling = () => {
      clearInterval(timerIdRef.current);
    };

    if (shouldPollForResponse) {
      startPolling();
    } else {
      stopPolling();
    }
    return stopPolling;
  }, [
    candidateId,
    enableNavigation,
    invitationId,
    shouldPollForResponse,
    showContinueButton,
    updateConfiguration,
  ]);

  function onSuccess(response) {
    setSocureCompleted(true);
    showContinueButton();
    setShouldPollForResponse(true);
  }

  function onError(error) {
    setSocureCompleted(true);
    showContinueButton();
    setShouldPollForResponse(true);
  }

  useEffect(() => {
    disableNavigation();
  }, [disableNavigation]);

  useEffect(() => {
    if (idvComplete) {
      showContinueButton();
    }
  }, [idvComplete, showContinueButton]);

  function mountSocureJs() {
    let script = document.querySelector(`script[src="${SOCURE_JS_URL}"]`);
    if (!script) {
      script = document.createElement('script');
      script.src = SOCURE_JS_URL;
      script.type = 'application/javascript';
      script.async = true;

      script.addEventListener(
        'load',
        () => {
          setSocureEnabled(true);
          initializeSocure();
        },
        {
          once: true,
        },
      );

      document.head.appendChild(script);
    } else {
      setSocureEnabled(true);
      initializeSocure();
    }
  }

  function initializeSocure() {
    // eslint-disable-next-line no-undef
    SocureInitializer.init(SOCURE_SDK_KEY).then(lib => {
      lib
        .init(SOCURE_SDK_KEY, '#websdk', {
          onSuccess,
          onError,
          qrCodeNeeded: true,
        })
        .then(function() {
          lib.start(2, {
            customerUserId: invitationId,
          });
        });
    });
  }

  function enableSocure(event) {
    event.preventDefault();
    mountSocureJs();
  }

  return (
    <IdvContainer id='mastodon' data-testid='idv-container'>
      <IdvHeader data-testid='idv-header'>
        <Translate value='components.IdentityVerificationProcessor.header' />
      </IdvHeader>
      {!socureCompleted &&
        (socureEnabled ? (
          <SocureSdkContainer id='websdk' />
        ) : (
          <StartIdvComponent handleClick={enableSocure} />
        ))}
      {socureCompleted && (
        <IdentityVerificationResults
          verificationCompleted={verificationCompleted}
          extractedPii={extractedPii}
        />
      )}
    </IdvContainer>
  );
};

StartIdvComponent.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

IdentityVerificationProcessor.propTypes = {
  idvComplete: PropTypes.bool.isRequired,
  invitationId: PropTypes.string.isRequired,
  candidateId: PropTypes.string.isRequired,
  disableNavigation: PropTypes.func.isRequired,
  showContinueButton: PropTypes.func.isRequired,
  enableNavigation: PropTypes.func.isRequired,
  updateConfiguration: PropTypes.func.isRequired,
};

const ConnectedIdentityVerificationProcessor = connect(
  state => {
    const { invitation, candidate } = state.configuration.configuration;
    const invitationId = invitation.id;
    const candidateId = candidate.id;
    return {
      invitationId,
      candidateId,
    };
  },
  dispatch => {
    return {
      disableNavigation: () => {
        dispatch({ type: FORM_BUSY });
        dispatch({ type: SET_NEXT_BUTTON_HIDDEN, payload: true });
        dispatch({ type: SET_PREVIOUS_BUTTON_HIDDEN, payload: true });
      },
      showContinueButton: () => {
        dispatch({ type: SET_NEXT_BUTTON_HIDDEN, payload: false });
      },
      enableNavigation: () => {
        dispatch({ type: FORM_IDLE });
      },
      updateConfiguration: options => {
        dispatch(requestConfigurationUpdate(options));
      },
    };
  },
)(IdentityVerificationProcessor);
export default ConnectedIdentityVerificationProcessor;
