import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { Checkbox } from './fields';

import * as V from '../lib/validations';

import HTMLText from './HTMLText';
import Panel from './Panel';

const Disclosure = ({ content, noScroll, noConfirmation, i18nOverrides }) => (
  <Panel
    title='components.Disclosure.title'
    i18nOverrides={i18nOverrides}
    icon='fa-file-text'
  >
    <div className={noScroll ? '' : 'scrollable'}>
      <HTMLText content={content} />
    </div>

    {!noConfirmation && (
      <div>
        <hr />

        <div className='col-md-12'>
          <Field
            type='checkbox'
            name='disclosure'
            label='components.Disclosure.label'
            component={Checkbox}
            validate={[V.requiredCheckbox]}
            labelFeedback
            boldLabel
          />
        </div>
      </div>
    )}
  </Panel>
);

Disclosure.propTypes = {
  content: PropTypes.string.isRequired,
  i18nOverrides: PropTypes.object,
  noScroll: PropTypes.bool,
  noConfirmation: PropTypes.bool,
};

Disclosure.defaultProps = {
  i18nOverrides: {},
  noScroll: false,
  noConfirmation: false,
};

export default Disclosure;
