import React from 'react';
import PropTypes from 'prop-types';
import { M, Download16 } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import HTMLText from 'components/HTMLText';
import './Instructions.scss';

const Instructions = ({ title, text, filename, href, isHtmlContent }) => {
  return (
    <div className='instructions-container'>
      <div className='instructions-icon-container'>
        <i className='fa fa-2x fa-exclamation-circle' />
      </div>
      <div className='instructions-inner'>
        {title && <div className='instructions-title'>{title}</div>}

        {!isHtmlContent && <div className='instructions-text'>{text}</div>}
        {isHtmlContent && (
          <div className='instructions-text'>
            <HTMLText customClassName='instructions-text' content={text} />
          </div>
        )}
        {href && (
          <div>
            <M.Button
              kind='secondary'
              href={href}
              download={filename}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Download16 />
              &nbsp;{i18n.getStr('i18nInternational.instructions.download')}
            </M.Button>
          </div>
        )}
      </div>
    </div>
  );
};

Instructions.propTypes = {
  title: PropTypes.string,
  text: PropTypes.any,
  filename: PropTypes.string,
  href: PropTypes.string,
  isHtmlContent: PropTypes.bool,
};

Instructions.defaultProps = {
  title: '',
  text: '',
  filename: '',
  href: '',
  isHtmlContent: false,
};

export default Instructions;
