const WELCOME_PAGE = {
  checkrWelcome: 'welcome',
  getStartedButton: 'get-started-button',
  cookieBanner: '#onetrust-accept-btn-handler',
  companyLogo: 'company-logo',
  progressBar: 'progress-bar',
  getStarted: 'get-started',
  accountMoreInformation: 'account-more-information',
  gatherInformation: 'gather-information',
  infoBulletContainer: 'info-bullet-container',
  nextButton: 'continue_start',
};

export default WELCOME_PAGE;
