import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Translate as T } from 'react-redux-i18n';
import { camelCase, kebabCase } from 'lodash';
import styled from 'styled-components';

import { M } from '@dashboard-experience/mastodon';
import { i18n, moment } from '@international/mastodon-i18n';

import { ARGYLE_EMPLOYMENT_DATE_FORMAT } from '../../constants';
import { getEmploymentKeyPart } from '../../lib/employment/utils/employmentUtils';

const EMPLOYMENT_DATE_FORMAT_IN_TITLE = 'YYYY';
const EMPLOYMENT_DATE_FORMAT_IN_TABLE = 'MMMM YYYY';

const TooltipDefinition = styled(M.TooltipDefinition)`
  button {
    &:focus {
      // Remove blue outline on focus.
      outline: none !important;
    }
  }
`;
const StyledCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  label {
    margin-bottom: 0;
  }
`;

const tableHeaders = [
  // "key" key-value pair is expected by M.DataTable's rows prop.
  // "key" value is kebab-case'd into className to style the column in CSS.
  { key: 'fieldName', name: 'Field name' },
  { key: 'fieldValue', name: 'Field value' },
];

const NoInfoAdded = () => (
  <span className='no-info-added'>
    <T value='components.EmploymentForm.ArgyleForm.reviewComponents.noInfoAdded' />
  </span>
);

const getLocalizedLongMonthYear = (monthYear, outputFormat) => {
  return i18n.getLocalizedMonthYear(
    moment(monthYear, ARGYLE_EMPLOYMENT_DATE_FORMAT),
    outputFormat,
  );
};

const getTableContent = (rows, getTableProps) => {
  return (
    <M.TableContainer>
      <M.Table {...getTableProps()}>
        <M.TableBody>
          {rows.map(row => (
            <M.TableRow key={row.id}>
              {row.cells.map(cell => (
                <M.TableCell
                  key={cell.id}
                  className={`argyle-cell ${kebabCase(cell.info.header)}`}
                >
                  {cell.value}
                </M.TableCell>
              ))}
            </M.TableRow>
          ))}
        </M.TableBody>
      </M.Table>
    </M.TableContainer>
  );
};

const ArgyleReviewManualEmploymentExpandable = ({
  disabled,
  initialExpanded,
  initialSelected,
  maxAllowedEmployers,
  maxAllowedEmployersReached,
  employment,
  index,
  onSelected,
  onUnselected,
}) => {
  const keyPart = getEmploymentKeyPart(employment);
  const employmentTitle =
    employment.name ??
    i18n.getStr(
      'components.EmploymentForm.ArgyleForm.reviewComponents.manualEntryTitle',
      [index + 1],
    );

  const getStartDateTableDisplay = () => {
    return employment.startDate
      ? getLocalizedLongMonthYear(
          employment.startDate,
          EMPLOYMENT_DATE_FORMAT_IN_TABLE,
        )
      : '?';
  };

  const getStartDateTitleDisplay = () => {
    return employment.startDate
      ? getLocalizedLongMonthYear(
          employment.startDate,
          EMPLOYMENT_DATE_FORMAT_IN_TITLE,
        )
      : '?';
  };

  const getEndDateTableDisplay = () => {
    if (employment.currentPosition) {
      return i18n.getStr(
        'components.EmploymentForm.ArgyleForm.argyleEmploymentFields.present',
      );
    }

    // Not current position.
    return employment.endDate
      ? getLocalizedLongMonthYear(
          employment.endDate,
          EMPLOYMENT_DATE_FORMAT_IN_TABLE,
        )
      : '?';
  };

  const getEndDateTitleDisplay = () => {
    if (employment.currentPosition) {
      return i18n.getStr(
        'components.EmploymentForm.ArgyleForm.argyleEmploymentFields.present',
      );
    }

    // Not current position.
    return employment.endDate
      ? getLocalizedLongMonthYear(
          employment.endDate,
          EMPLOYMENT_DATE_FORMAT_IN_TITLE,
        )
      : '?';
  };

  const getContractTypeDisplay = () => {
    return (
      i18n.getStr(`options.contracts.${camelCase(employment.contractType)}`) ??
      'N/A'
    );
  };

  // Convert employment into rows prop for M.DataTable.
  // For manual employments, we only display a row if its field has a value.
  const getRows = () => {
    const rows = [];

    if (employment.startDate) {
      rows.push({
        id: `emp-${keyPart}-start-date-row`,
        fieldName: i18n.getStr(
          'components.EmploymentForm.ArgyleForm.argyleEmploymentFields.startDate',
        ),
        fieldValue: getStartDateTableDisplay(),
      });
    }

    if (employment.currentPosition || employment.endDate) {
      rows.push({
        id: `emp-${keyPart}-end-date-row`,
        fieldName: i18n.getStr(
          'components.EmploymentForm.ArgyleForm.argyleEmploymentFields.endDate',
        ),
        fieldValue: getEndDateTableDisplay(),
      });
    }

    if (employment.contractType) {
      rows.push({
        id: `emp-${keyPart}-contract-type-row`,
        fieldName: i18n.getStr(
          'components.EmploymentForm.ArgyleForm.argyleEmploymentFields.contractType',
        ),
        fieldValue: getContractTypeDisplay(),
      });
    }

    if (employment.position) {
      rows.push({
        id: `emp-${keyPart}-position-row`,
        fieldName: i18n.getStr(
          'components.EmploymentForm.ArgyleForm.argyleEmploymentFields.position',
        ),
        fieldValue: employment.position,
      });
    }

    return rows;
  };

  const TooltipContent = (
    <div>
      <div className='tooltip-heading'>
        <T value='components.EmploymentForm.ArgyleForm.reviewComponents.maxEmploymentsAddedTooltip.heading' />
      </div>
      <div>
        {i18n.getStr(
          'components.EmploymentForm.ArgyleForm.reviewComponents.maxEmploymentsAddedTooltip.details',
          maxAllowedEmployers ?? '?',
        )}
      </div>
    </div>
  );

  const [includeInHistory, setIncludeInHistory] = useState(initialSelected);

  useEffect(() => {
    setIncludeInHistory(initialSelected);
  }, [initialSelected]);

  const shouldDisableCheckbox = () => {
    return maxAllowedEmployersReached && !includeInHistory;
  };

  const handleOnChange = checked => {
    if (checked) {
      setIncludeInHistory(true);
      onSelected(employment);
    } else {
      setIncludeInHistory(false);
      onUnselected(employment);
    }
  };

  const getTitle = () => {
    const checkboxId = `include-argyle-emp-checkbox-${keyPart}`;
    const tooltipId = `include-argyle-emp-checkbox-tooltip-${keyPart}`;

    const CheckboxComponent = (
      <StyledCheckboxWrapper>
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label htmlFor={checkboxId}>
          {i18n.getStr(
            'components.EmploymentForm.ArgyleForm.reviewComponents.includeInHistory',
          )}
        </label>
        <M.Checkbox
          id={checkboxId}
          checked={includeInHistory}
          disabled={shouldDisableCheckbox()}
          onChange={(value, _id, _event) => handleOnChange(value)}
        />
      </StyledCheckboxWrapper>
    );

    const disableCheckbox = shouldDisableCheckbox();

    return (
      <span className={`title ${disabled ? 'no-checkbox' : 'has-checkbox'}`}>
        <span className='main-title-items'>
          <span className='employer-name'>{employmentTitle}</span>
          {employment.startDate ||
          employment.currentPosition ||
          employment.endDate ? (
            <span className='date-range'>
              {getStartDateTitleDisplay()}
              <> &ndash; </>
              {getEndDateTitleDisplay()}
            </span>
          ) : (
            <></>
          )}
        </span>
        {!disabled && (
          <>
            {/* eslint-disable-next-line max-len */}
            {/* https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/main/docs/rules/no-static-element-interactions.md#case-the-event-handler-is-only-being-used-to-capture-bubbled-events */}
            {/* Suppresses warning on requiring "role" attribute when using onClick on static span. */}
            {/* Deactivating this rule is allowed because the onClick is only used for capturing bubbled events. */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <span
              className={`include-in-history-checkbox-container ${
                disableCheckbox ? 'disabled' : ''
              }`}
              onClick={event => {
                // User has clicked the "Include in history" checkbox.
                //
                // Prevents the outer Expandable from detecting it has been clicked
                // which would unnecessarily re-render the unchanged contents with
                // a distracting fade-in animation.
                event.stopPropagation();
              }}
            >
              {!disableCheckbox ? (
                CheckboxComponent
              ) : (
                <TooltipDefinition
                  align='end'
                  direction='top'
                  highlighted={false}
                  id={tooltipId}
                  tooltipText={TooltipContent}
                >
                  {CheckboxComponent}
                </TooltipDefinition>
              )}
            </span>
          </>
        )}
      </span>
    );
  };

  const fieldRows = getRows();

  return (
    <div className='employment-expandable'>
      <M.Grid>
        <M.Container
          type={disabled ? 'official' : 'shadow'}
          className='argyle-expandable-container'
          rows={[
            <M.Expandable
              key={`argyle-grid-container-expandable-${keyPart}`}
              title={getTitle()}
              initialExpanded={initialExpanded}
            >
              {fieldRows.length > 0 ? (
                <M.DataTable
                  headers={tableHeaders}
                  rows={getRows()}
                  render={({ rows, getTableProps }) =>
                    getTableContent(rows, getTableProps)
                  }
                />
              ) : (
                <NoInfoAdded />
              )}
            </M.Expandable>,
          ]}
        />
      </M.Grid>
    </div>
  );
};

ArgyleReviewManualEmploymentExpandable.propTypes = {
  disabled: PropTypes.bool.isRequired,
  initialExpanded: PropTypes.bool.isRequired,
  initialSelected: PropTypes.bool.isRequired,
  maxAllowedEmployers: PropTypes.number,
  maxAllowedEmployersReached: PropTypes.bool.isRequired,
  employment: PropTypes.shape({
    id: PropTypes.string,
    addedAt: PropTypes.number,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    currentPosition: PropTypes.bool,
    name: PropTypes.string,
    contractType: PropTypes.string,
    position: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onSelected: PropTypes.func.isRequired,
  onUnselected: PropTypes.func.isRequired,
};

ArgyleReviewManualEmploymentExpandable.defaultProps = {
  maxAllowedEmployers: null,
};

export default ArgyleReviewManualEmploymentExpandable;
