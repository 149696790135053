import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { i18n } from '@international/mastodon-i18n';
import {
  M,
  CheckmarkFilled16,
  HelpFilled16,
} from '@dashboard-experience/mastodon';

function ResultsModal({ open, candidateSchool, onAccept, onClose, onError }) {
  const [isCompleteResult, setIsCompleteResult] = useState(true);

  const keys = [
    'name',
    'degree',
    'year_awarded',
    'major',
    'start_date',
    'end_date',
    'provider',
    'provider_id',
  ];

  if (!isCompleteResult) {
    for (const key in keys) {
      if (!candidateSchool[key]) {
        setIsCompleteResult(false);
      }
    }
  }

  return (
    <div className='m1-learn-more-modal'>
      <div className='row'>
        <div className='col-md-12'>
          <h2>
            {isCompleteResult
              ? i18n.getStr(
                  'i18nInternational.educationField.measureOne.m1ReviewResultsReceivedModal.resultsRecievedTitle',
                )
              : i18n.getStr(
                  'i18nInternational.educationField.measureOne.m1ReviewResultsReceivedModal.resultsIncompleteRecievedTitle',
                )}
          </h2>
        </div>
        <div className='col-md-12 m1-waiting-results-modal-description-padding'>
          <span className='m1-learn-more-modal-span'>
            {isCompleteResult
              ? i18n.getStr(
                  'i18nInternational.educationField.measureOne.m1ReviewResultsReceivedModal.resultsRecievedDescription',
                )
              : i18n.getStr(
                  'i18nInternational.educationField.measureOne.m1ReviewResultsReceivedModal.resultsIncompleteRecievedDescription',
                )}
          </span>
        </div>
      </div>
      <div className='container m1-results-modal-container'>
        <div className='row'>
          <div
            className={`col-md-12 ${
              isCompleteResult
                ? 'm1-results-university-name-green'
                : 'm1-results-university-name-gray'
            }`}
          >
            <h3>
              {candidateSchool.name === undefined
                ? 'N/A'
                : candidateSchool.name}
            </h3>
          </div>
        </div>
      </div>
      <div className='container m1-results-modal-container'>
        <table className='table'>
          <tbody>
            <tr>
              <td className='td-border-top-none'>
                {candidateSchool.start_date === undefined ? (
                  <HelpFilled16 />
                ) : (
                  <CheckmarkFilled16 className='m1-green-icon' />
                )}
                &nbsp;
                {i18n.getStr(
                  'i18nInternational.educationField.measureOne.m1ReviewResultsReceivedModal.startDate',
                )}
              </td>
              <td className='td-border-top-none'>
                {candidateSchool.start_date === undefined
                  ? 'N/A'
                  : candidateSchool.start_date}
              </td>
            </tr>
            <tr>
              <td>
                {candidateSchool.end_date === undefined ? (
                  <HelpFilled16 />
                ) : (
                  <CheckmarkFilled16 className='m1-green-icon' />
                )}
                &nbsp;
                {i18n.getStr(
                  'i18nInternational.educationField.measureOne.m1ReviewResultsReceivedModal.endDate',
                )}
              </td>
              <td>
                {candidateSchool.end_date === undefined
                  ? 'N/A'
                  : candidateSchool.end_date}
              </td>
            </tr>
            <tr>
              <td>
                {candidateSchool.degree === undefined ? (
                  <HelpFilled16 />
                ) : (
                  <CheckmarkFilled16 className='m1-green-icon' />
                )}
                &nbsp;
                {i18n.getStr(
                  'i18nInternational.educationField.measureOne.m1ReviewResultsReceivedModal.degree',
                )}
              </td>
              <td>
                {candidateSchool.degree === undefined
                  ? 'N/A'
                  : candidateSchool.degree}
              </td>
            </tr>
            <tr>
              <td className='m1-results-table-left-without-border'>
                {candidateSchool.major === undefined ? (
                  <HelpFilled16 />
                ) : (
                  <CheckmarkFilled16 className='m1-green-icon' />
                )}
                &nbsp;
                {i18n.getStr(
                  'i18nInternational.educationField.measureOne.m1ReviewResultsReceivedModal.major',
                )}
              </td>
              <td className='m1-results-table-right-without-border'>
                {candidateSchool.major === undefined
                  ? 'N/A'
                  : candidateSchool.major}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='container'>
        <div className='row m1-cancel-and-enter-info-button'>
          <M.Button
            className='secondary'
            onClick={() => {
              onError('');
            }}
          >
            {i18n.getStr(
              'i18nInternational.educationField.measureOne.m1ReviewResultsReceivedModal.enterInformationButton',
            )}
          </M.Button>
          <M.Button
            onClick={() => {
              onAccept();
              onClose();
            }}
          >
            {i18n.getStr(
              'i18nInternational.educationField.measureOne.m1ReviewResultsReceivedModal.acceptButton',
            )}
          </M.Button>
        </div>
      </div>
    </div>
  );
}

ResultsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  candidateSchool: PropTypes.object.isRequired,
  onAccept: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default ResultsModal;
