/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { change, Field } from 'redux-form';
import { i18n } from '@international/mastodon-i18n';
import { Translate as T } from 'react-redux-i18n';

import {
  RemoveFieldButton,
  Select,
  Text,
  TypeaheadInput,
  Upload,
} from 'components/fields';
import DomesticDocumentUpload from 'components/DomesticDocumentUpload';
import { requestCities } from 'actions';
import Panel from 'components/Panel';
import * as O from '../../lib/options';
import * as N from '../../lib/normalizations';
import * as V from '../../lib/validations';
import {
  disallowCurrentSchools,
  getDocumentUploadComponentTranslatedText,
  getEducationDropdownOptions,
  getSchoolNameLabel,
  handleDisclosableDocuments,
  isCountryDomestic,
  isCountryOCH,
  isDegreeGED,
  isDegreeHigherEducation,
  isDegreeHighSchool,
  isDegreeLowerEducation,
  validateCharLength,
} from './utils';
import { FULL_DATE_FORMAT } from '../../constants';

class School extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    documentCollection: PropTypes.bool.isRequired,
    fieldLength: PropTypes.number.isRequired,
    fieldName: PropTypes.string.isRequired,
    handleOnRemove: PropTypes.func.isRequired,
    i: PropTypes.number.isRequired,
    requiresCompletedDegrees: PropTypes.bool.isRequired,
    school: PropTypes.object.isRequired,
    typeaheadSchools: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      cities: [],
    };
  }

  handleCountryChange = country => {
    const {
      fieldName,
      dispatch,
      school: { degree },
    } = this.props;

    // country + degree drive form fields + validation, so clear the form appropriately
    dispatch(change('applyform', fieldName, { country, degree }));
  };

  handleCurrentSchoolChange = isCurrentSchool => {
    const { fieldName, dispatch } = this.props;

    if (isCurrentSchool === 'true') {
      // these fields disable when school is current
      dispatch(change('applyform', `${fieldName}.endDate`, null));
      dispatch(change('applyform', `${fieldName}.yearAwarded`, null));
    }
  };

  handleDegreeChange = degree => {
    const {
      fieldName,
      dispatch,
      school: { country },
    } = this.props;

    // country + degree drive form fields + validation, so clear the form appropriately
    dispatch(change('applyform', fieldName, { country, degree }));
  };

  handleDomesticStateChange = state => {
    const {
      school: { country },
    } = this.props;

    if (state && isCountryDomestic(country)) {
      // the monolith states endpoint returns U.S. states only
      requestCities(state).then(response =>
        this.setState({ cities: response }),
      );
    }
  };

  /**
   * TODO we need to consolidate domestic and OCH document upload experiences someday,
   * but today is not the day. preferably we should be using the DomesticDocumentUpload
   * component, but that component is unnecessarily complex and requires a separate refactor.
   */
  renderDocumentUploadSection = () => {
    const {
      dispatch,
      fieldName,
      school: { country, degree },
    } = this.props;

    const {
      candidateDisclosureText,
      descriptionText,
      modalBodyContent,
      titleText,
    } = getDocumentUploadComponentTranslatedText(degree);

    return (
      <>
        {isCountryDomestic(country) && (
          <>
            <DomesticDocumentUpload
              {...{
                candidateDisclosureText,
                descriptionText,
                dispatch,
                dropdownOptions: getEducationDropdownOptions(degree),
                fieldName,
                handleDisclosableDocuments,
                modalBodyContent,
                titleText,
              }}
            />
            <br />
          </>
        )}
        {isCountryOCH(country) && isDegreeLowerEducation(degree) && (
          <>
            <div className='row'>
              <div className='col-md-12'>
                <strong>
                  {isDegreeHighSchool(degree) && (
                    <T value='labels.highSchoolDiplomaImg' />
                  )}
                  {isDegreeGED(degree) && <T value='labels.gedDiplomaImg' />}
                </strong>
                <p>
                  <strong>
                    <T value='components.domesticDocumentUpload.candidateDisclosurePrompt.education' />
                  </strong>
                </p>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12 col-md-6'>
                <Field
                  type='upload'
                  name={`${fieldName}.documents`}
                  component={Upload}
                  onChange={handleDisclosableDocuments}
                  options={{ maxFiles: 1 }}
                  boldLabel
                  handleRemoveDocuments={() =>
                    dispatch(
                      change('applyform', `${fieldName}.documents`, null),
                    )
                  }
                />
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  handleRemoveSchool = () => {
    const { i, handleOnRemove } = this.props;
    handleOnRemove(i);
  };

  render() {
    const {
      documentCollection,
      fieldLength,
      fieldName,
      requiresCompletedDegrees,
      typeaheadSchools,
      school: { country, current, degree },
    } = this.props;
    const { cities } = this.state;

    const typeaheadSchoolOptions = isDegreeHighSchool(degree)
      ? typeaheadSchools.highSchools
      : typeaheadSchools.higherEducationSchools;

    return (
      <Panel>
        <div className='row'>
          <div className='col-md-12'>
            <h4 className='card-block-heading'>
              {i18n.getStr('components.Schools.relevantEducation')}
            </h4>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <Field
              type='text'
              name={`${fieldName}.country`}
              label='labels.country'
              component={Select}
              validate={[V.required]}
              options={O.countries}
              onChange={({ target: { value } }) =>
                this.handleCountryChange(value)
              }
            />
          </div>
          <div className='col-md-6'>
            <Field
              type='select'
              name={`${fieldName}.degree`}
              label='labels.schoolDegree'
              component={Select}
              validate={[V.required]}
              options={O.degrees}
              onChange={({ target: { value } }) =>
                this.handleDegreeChange(value)
              }
            />
          </div>
        </div>
        {country && degree && (
          <>
            <div className='row'>
              <div className='col-md-6'>
                <Field
                  type='text'
                  name={`${fieldName}.name`}
                  label={getSchoolNameLabel(degree)}
                  component={isCountryDomestic(country) ? TypeaheadInput : Text}
                  validate={!isDegreeGED(degree) ? [V.required] : null}
                  // below are TypeaheadInput component specific fields, not Text
                  options={typeaheadSchoolOptions}
                  suggest
                  noResultsText={
                    <T
                      value='components.EducationInformationForm.noSchoolWarning'
                      className='d-inline-block small text-muted p-2'
                    />
                  }
                />
              </div>
              {isDegreeHigherEducation(degree) && (
                <div className='col-md-6'>
                  <Field
                    type='select'
                    name={`${fieldName}.current`}
                    label='labels.schoolCurrent'
                    options={[
                      {},
                      { name: i18n.getStr('buttons.no'), value: false },
                      { name: i18n.getStr('buttons.yes'), value: true },
                    ]}
                    component={Select}
                    onChange={({ target: { value } }) =>
                      this.handleCurrentSchoolChange(value)
                    }
                    validate={
                      requiresCompletedDegrees
                        ? [V.required, disallowCurrentSchools]
                        : [V.required]
                    }
                  />
                </div>
              )}
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <Field
                  type={isCountryDomestic(country) ? 'select' : 'text'}
                  name={`${fieldName}.state`}
                  label={
                    isCountryDomestic(country)
                      ? 'labels.schoolState'
                      : 'international.labels.schoolState'
                  }
                  component={isCountryDomestic(country) ? Select : Text}
                  onChange={
                    isCountryDomestic(country)
                      ? ({ target: { value } }) =>
                          this.handleDomesticStateChange(value)
                      : undefined
                  }
                  validate={[V.required]}
                  options={isCountryDomestic(country) ? O.states() : []}
                />
              </div>
              <div className='col-md-6'>
                <Field
                  type='text'
                  name={`${fieldName}.city`}
                  label='labels.city'
                  component={isCountryDomestic(country) ? TypeaheadInput : Text}
                  options={isCountryDomestic(country) ? cities : []}
                  validate={[V.required]}
                />
              </div>
            </div>
            {isCountryOCH(country) && (
              <>
                <div className='row'>
                  <div className='col-md-6'>
                    <Field
                      type='text'
                      name={`${fieldName}.address`}
                      label='labels.address'
                      component={Text}
                      validate={[V.required]}
                    />
                  </div>
                  <div className='col-md-6'>
                    <Field
                      type='text'
                      name={`${fieldName}.zipcode`}
                      label='labels.postalCode'
                      component={Text}
                      validate={[V.required]}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <Field
                      type='tel'
                      name={`${fieldName}.phone`}
                      label='labels.phone'
                      component={Text}
                      validate={[V.required]}
                    />
                  </div>

                  <div className='col-md-6'>
                    <Field
                      type='text'
                      name={`${fieldName}.url`}
                      label='labels.schoolUrlOptional'
                      component={Text}
                    />
                  </div>
                </div>
              </>
            )}
            {isDegreeHigherEducation(degree) && (
              <div className='row'>
                <div className='col-md-6'>
                  <Field
                    type='text'
                    name={`${fieldName}.major`}
                    label='labels.schoolMajor'
                    component={TypeaheadInput}
                    options={O.majors}
                    validate={[V.required, validateCharLength]}
                  />
                </div>
                <div className='col-md-6'>
                  <Field
                    type='text'
                    name={`${fieldName}.minor`}
                    label='labels.schoolMinorOptional'
                    component={Text}
                  />
                </div>
              </div>
            )}
            {isCountryOCH(country) && (
              <div className='row'>
                <div className='col-md-6'>
                  <Field
                    type='text'
                    name={`${fieldName}.startDate`}
                    label='labels.startDate'
                    placeholder='placeholders.mmDDYY'
                    component={Text}
                    normalize={N.monthDayYear}
                    validate={[
                      V.required,
                      V.validDate(FULL_DATE_FORMAT),
                      V.dateInThePast(FULL_DATE_FORMAT),
                    ]}
                  />
                </div>
                <div className='col-md-6'>
                  <Field
                    type='text'
                    name={`${fieldName}.endDate`}
                    label='labels.endDate'
                    placeholder='placeholders.mmDDYY'
                    component={Text}
                    normalize={N.monthDayYear}
                    disabled={current === 'true'}
                    validate={
                      current
                        ? null
                        : [
                            V.required,
                            V.validDate(FULL_DATE_FORMAT),
                            V.dateInThePast(FULL_DATE_FORMAT),
                          ]
                    }
                  />
                </div>
              </div>
            )}
            {(isDegreeLowerEducation(degree) || isCountryOCH(country)) && (
              <div className='row'>
                <div className='col-md-6'>
                  <Field
                    type='number'
                    name={`${fieldName}.yearAwarded`}
                    label='labels.yearAwarded'
                    component={Text}
                    normalize={N.year}
                    disabled={current === 'true'}
                    validate={
                      current
                        ? null
                        : [
                            V.required,
                            V.validDate('YYYY'),
                            V.dateInThePast('YYYY'),
                          ]
                    }
                  />
                </div>
                <div className='col-md-6'>
                  <Field
                    type='text'
                    name={`${fieldName}.nameOnDiploma`}
                    label={
                      isDegreeHighSchool(degree)
                        ? 'labels.nameOnDiplomaOptional'
                        : 'labels.nameOnGEDOptional'
                    }
                    component={Text}
                  />
                </div>
              </div>
            )}
            {documentCollection && this.renderDocumentUploadSection()}
          </> /** ------ END non-country and degree School component form fields ------ * */
        )}
        {fieldLength > 1 && (
          <RemoveFieldButton
            handleOnClick={this.handleRemoveSchool}
            textValue={i18n.getStr('components.Schools.removeEducation')}
            showIcon={false}
            className='btn btn-secondary'
          />
        )}
      </Panel> /** ------ END all School component form fields ------ * */
    );
  }
}

export default School;
