import React from 'react';
import { i18n, moment } from '@international/mastodon-i18n';
import _, { get } from 'lodash';

import * as V from '../../../../lib/validations';
import generate from '../../../Fields/FieldCreationLogic/FieldGenerators';
import InternationalContext, {
  InternationalContextProps,
} from '../../../InternationalContext';

export const DoubleBreak = () => (
  <>
    <br />
    <br />
  </>
);

export const SectionDivider = () => (
  <>
    <br />
    <hr />
    <br />
  </>
);

export const SectionHeader = ({
  label,
  hasDivider,
}: {
  label: string;
  hasDivider: boolean;
}) => (
  <>
    {hasDivider && <SectionDivider />}
    <b>{label}</b>
  </>
);

const passportDateValidator = (val: string, all: any) => {
  const dateFormat = i18n.getLocalizedDateFormat(i18n.DateFormats.DATE_SLASH);
  const expirationDate = moment(
    get(all, 'scoped_requirements.GB.passport_expiration_date'),
    dateFormat,
    true,
  );
  const issueDate = moment(
    get(all, 'scoped_requirements.GB.passport_issue_date'),
    dateFormat,
    true,
  );
  if (
    expirationDate.isValid() &&
    issueDate.isValid() &&
    expirationDate.isSameOrBefore(issueDate)
  ) {
    return 'i18nInternational.validations.issueDateMustPrecedeExpirationDate';
  }
  return undefined;
};

const passportIssueDateValidator = (val: string) => {
  const dateFormat = i18n.getLocalizedDateFormat(i18n.DateFormats.DATE_SLASH);
  return val && V.dateInThePast(dateFormat)(val);
};

const renderMap: { [key: string]: any } = {
  default: {
    validate: [V.required],
  },
  national_insurance_number: {},
  national_insurance_document: {},
  passport_expiration_date: {
    validate: [V.required, passportDateValidator],
  },
  passport_issue_date: {
    validate: [V.required, passportDateValidator, passportIssueDateValidator],
  },
};

const createGridRow = (gen: any, fields: string[]) =>
  fields.map(field => (
    <div className='text-field' key={field} style={{ flexDirection: 'column' }}>
      {field === 'national_insurance_document' && (
        <span className='bx--label'>
          {i18n.getStr(
            'international.regionOverrides.titles.nationalInsuranceDocument',
          )}
        </span>
      )}
      {gen(field).render(() => renderMap[field] || renderMap.default)}
    </div>
  ));

const getFieldRowOutput = (gen: any, fieldRow: any) => {
  if (Array.isArray(fieldRow)) {
    return createGridRow(gen, fieldRow);
  }
  if (typeof fieldRow === 'string') return createGridRow(gen, [fieldRow]);
  if (fieldRow.label && fieldRow.class)
    return (
      <span className={fieldRow.class}>{i18n.getStr(fieldRow.label)}</span>
    );
  if (fieldRow.label) return i18n.getStr(fieldRow.label);
  return fieldRow;
};

export const createGrid = (
  gen: any,
  fieldRows: any[],
  hasSpacing = false,
  hasDivider = false,
) =>
  fieldRows.map(fieldRow =>
    fieldRow.sectionHeader ? (
      <SectionHeader
        label={i18n.getStr(fieldRow.sectionHeader)}
        hasDivider={hasDivider}
      />
    ) : (
      <>{getFieldRowOutput(gen, fieldRow)}</>
    ),
  );

export const getPastNames = (
  scopedRequirementProperties: any,
  intlContext: InternationalContextProps,
) => {
  let pastNamesContent = {};
  let hasPastNames = false;

  if (_.has(scopedRequirementProperties, 'aliases')) {
    hasPastNames = true;
    const pastNames = _.get(scopedRequirementProperties, 'aliases', {});
    const isFieldArray = pastNames?.type === 'array' && pastNames?.items;
    if (isFieldArray) {
      pastNamesContent = generate
        .arrayField(
          'aliases',
          intlContext.fieldGenerator.generateFieldLater(pastNames.items),
          true,
        )
        .render();
    }
  }

  return {
    pastNamesContent,
    hasPastNames,
  };
};
