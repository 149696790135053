// Professions, or fields of work, used by Professional License Verification (PLV).
// Generated from Atlas-supported cert org keywords, minus location-based keywords.
// See https://gitlab.checkrhq.net/-/snippets/335 for the script to generate the values.
//
// This list should be updated when Atlas introduces new keywords, or deprecates old keywords,
// for their cert orgs. Currently, there is no mechanism in place for us to be notified when
// that happens.
//
// As a future improvement, it may make sense to dynamically fetch and cache this list from
// Atlas into our backend PLV Service.
export default [
  '911 Law Enforcement Officer',
  'ACLS',
  'AED',
  'AHA',
  'Accountancy',
  'Accountants',
  'Accounting',
  'Accounting Firms',
  'Acting ALF- Administrator-in-Training',
  'Acupuncture',
  'Acupuncturist',
  'Adjuster',
  'Advanced Emergency Medical Tech',
  'Agriculture',
  'Alarms',
  'Alcohol',
  'American Heart Association',
  'American Red Cross',
  'Amusement',
  'Anesthesiologist Assistant',
  'Appliance Refinishing',
  'Appliance Repair',
  'Appliances',
  'Appraisal Management Company',
  'Appraisals',
  'Appraiser',
  'Architect',
  'Architecture',
  'Art Therapist',
  'Asbestos',
  'Asbestos Removal',
  'Assisted Living Facility Administrator',
  'Athlete Agent',
  'Athletic Commission',
  'Athletic Trainer',
  'Auction Business',
  'Auctioneer',
  'Audiologist',
  'Auto',
  'Automotive',
  'BLS',
  'Banking',
  'Barber',
  'Bedding',
  'Behavior Analysts',
  'Big Game Guides and Transporters',
  'Billing and Coding Specialist',
  'Bingo',
  'Board of Pharmacy Specialties',
  'Boiler',
  'Bottlers',
  'Boxing',
  'Broker',
  'Building Code Administrator',
  'Building Inspector',
  'Business CSR',
  'Business License',
  'CAPM',
  'CBCS',
  'CCAr',
  'CCDA',
  'CCDP',
  'CCENT',
  'CCIE Security',
  'CCMA',
  'CCNA',
  'CCNA Data Center',
  'CCNA Security',
  'CCNA Video',
  'CCNP',
  'CCNP Data Center',
  'CCNP Security',
  'CCSI',
  'CCT',
  'CCT Data Center',
  'CEHRS',
  'CET',
  'CLIA',
  'CMA',
  'CMAA',
  'CNA',
  'CPA',
  'CPCT/A',
  'CPR',
  'CPT',
  'CPhT',
  'California Acupuncture Board',
  'California Architects Board',
  'California Board of Accountancy',
  'California Board of Barbering and Cosmetology',
  'California Board of Behavioral Sciences',
  'California Bureau of Automotive Repair',
  'California Cemetery and Funeral Bureau',
  'Caregiver',
  'Carnival',
  'Cemetery Regulation',
  'Certified EMS Organization',
  'Certified Hemodialysis Technician',
  'Certified Nurse Aide',
  'Certified Nursing Assistant',
  'Certified Public Accountant',
  'Charitable Clinic',
  'Charitable Gaming',
  'Charities',
  'Child Care',
  'Chiropractics',
  'Chiropractor',
  'Cisco',
  'Clinical Laboratory',
  'Closing Out Sales',
  'Coast Guard',
  'Collection Agency',
  'Combative Sports',
  'Commercial Contractor',
  'Communications',
  'Community Health Worker',
  'CompTIA',
  'Concert Promoter',
  'Concrete',
  'Condominium',
  'Continuing Education',
  'Continuing Education Provider',
  'Contractors',
  'Controlled Substance',
  'Controlled Substance Registration',
  'Cosmetologist',
  'Cosmetology',
  'Counselor',
  'Court Reporter',
  'Crematories',
  'DEA License',
  'DEA Number',
  'Dance Therapy',
  'Dentist',
  'Dentistry',
  'Dietitian',
  'Dispensing Opticians',
  'Doctor',
  'Drinking Water System Operators',
  'Driving Business',
  'Drug Distribution',
  'Drug Establishments',
  'EMS',
  'EPA',
  'Elder Care',
  'Electrical',
  'Electrician',
  'Electrologist',
  'Electronic Repair',
  'Electronics',
  'Elevator',
  'Embalmer',
  'Emergency Medical Services',
  'Emergency Medical Services Instructor',
  'Emergency Medical Tech',
  'Engineer',
  'Esthetician',
  'Excavator',
  'Excise Tax',
  'FDA',
  'FDA Drug Establishments',
  'FINRA',
  'Fantasy Contests',
  'Farm Labor',
  'Finance',
  'Fire',
  'Fire Protection',
  'First Aid',
  'Flat Glass',
  'Food',
  'Food Manufacturing Establishment',
  'Food Warehouses',
  'Foresters',
  'Fuel',
  'Fuel Taxpayers',
  'Fundraising',
  'Funeral Branch Establishment',
  'Funeral Director',
  'Funeral Establishment',
  'Funeral Home',
  'Funeral Service',
  'Gaming',
  'Gas',
  'Gas Fitter',
  'Gas Station',
  'General Contractor',
  'Genetic Counselor',
  'Geologist',
  'Geology',
  'Global',
  'Gym',
  'HVAC',
  'Hairdresser',
  'Healthcare',
  'Hearing Aid',
  'Home Builders',
  'Home Health Aide',
  'Home Health Care',
  'Home Improvement',
  'Home Inspector',
  'Homemaker',
  "Homeowners' Associations",
  'Household Goods',
  'Humane Society',
  'Hygienist',
  'Hypnotists',
  'Hypodermic Needle and Syringe',
  'IN',
  'IT (Technology)',
  'Immunization',
  'Insurance',
  'Interior Design',
  'Interior Designer',
  'Investment/Securities',
  'Irrigation Sprinkler',
  'Itinerant Vendors',
  'JFS',
  'Kick Boxing',
  'Labor Organizations',
  'Land Sales',
  'Land Surveying',
  'Land Surveyor',
  'Landscape Architect',
  'Landscaping',
  'Language Pathologist Assistant',
  'Lawn Care',
  'Lead',
  'Life Support',
  'Liquor',
  'Locksmith',
  'Lottery Sales Agents',
  'Manufactured Home',
  'Manufacturing',
  'Mariner',
  'Marital and Family Associate',
  'Marital and Family Therapist',
  'Marriage and Family Associate',
  'Marriage and Family Therapist',
  'Martial Arts Club',
  'Massage Therapist',
  'Massage Therapy',
  'Mechanical Contractor',
  'Medical Certificate',
  'Medical Equipment Supplier',
  'Medical Gas',
  'Medical Marijuana',
  'Medicated Animal Feed Manufacture',
  'Medication Aide',
  'Medicine',
  'Mental Health',
  'Midwife',
  'Midwifery',
  'Mobile Home',
  'Mold-Related Services',
  'Mortician',
  'Mortuary Science',
  'Moving',
  'NHA',
  'NPI',
  'NPR',
  'Nail Technician',
  'Naturopathy',
  'Nurse',
  'Nursing',
  'Nursing Assistant',
  'Nursing Home',
  'Nursing Home Administration',
  'Nutritionists',
  'Occupational Therapist',
  'Occupational Therapist Assistant',
  'Occupational Therapy',
  'Optometrist',
  'Optometry',
  'Orthotics',
  'Orthotist',
  'Osteopathic Physician',
  'Osteopathy',
  'Outfitters',
  'Outsourcing Facility',
  'PALS',
  'PMI-ACP',
  'PMI-PBA',
  'PMI-RMP',
  'PMI-SP',
  'PMP',
  'PRINCE2',
  'Paramedic',
  'Passenger Tramway',
  'Pawnbroker',
  'Pedorthist',
  'Perfusionist',
  'Pest Control',
  'PfMP',
  'PgMP',
  'Pharmaceutical',
  'Pharmacist',
  'Pharmacy',
  'Pharmacy Intern',
  'Pharmacy Specialties',
  'Pharmacy Support Persons',
  'Pharmacy Technician',
  'Physical Therapist',
  'Physical Therapist Assistant',
  'Physical Therapy',
  'Physician',
  'Physician Assistant',
  'Pilot',
  'Plumber',
  'Plumbing',
  'Podiatrist',
  'Podiatry',
  'Point of Sale Security',
  'Polysomnographic',
  'Private Investigator',
  'Producer',
  'Prosthetist',
  'Psychologist',
  'Psychology',
  'Public Accounting',
  'Public Safety',
  'Public Service',
  'RHCJD',
  'Radio License',
  'Radiographer',
  'Radiologic Technologist',
  'Radiologic Technology',
  'Radon',
  'Raffle',
  'Real Estate',
  'Real Estate Agents',
  'Real Estate Appraisers',
  'Real Estate Broker',
  'Real Estate Salesperson',
  'Recreation Therapist',
  'Registered Entity Contractor',
  'Registered Individual Contractor',
  'Research Psychoanalyst',
  'Residential Builders',
  'Residential Care Administrator',
  'Residential Care Facility',
  'Respiratory Care Practitioner',
  'Respiratory Care Professional',
  'Respiratory Therapist',
  'Respiratory Therapy',
  'Reupholster',
  'Roofing',
  'SRNA',
  'Salon',
  'Sanitarian',
  'Security Guard',
  'Sewage',
  'Sewer Cleaning',
  'Sheet Metal',
  'Shorthand Reporter',
  'Sign Language',
  'Ski Areas',
  'Social Work',
  'Social Worker',
  'Speech Language Pathologist Assistant',
  'Speech-Language Pathologist',
  'Speech-Language Pathology',
  'Sprinklers',
  'Subcontractors',
  'Surgical Technologist',
  'Surveyor',
  'Swimming Pool',
  'TV License',
  'Tanning',
  'Tattoo Technician',
  'Tax',
  'Telecommunication',
  'Telemedicine',
  'Telemedicine Business Registration',
  'Therapy',
  'Third Party Logistics',
  'Timeshares',
  'Tobacco',
  'Towing Carrier',
  'Transportation',
  'Travel Agency',
  'USDOT',
  'Underground Storage Tank Worker',
  'Veterinarian',
  'Veterinary Medicine',
  'Veterinary Technician',
  'Water and Wastewater Professionals',
  'Weighing Devices',
  'Well Drilling',
  'Working with Children',
  'Yellow Fever Stamp Permit',
  'Youth Camps',
];
