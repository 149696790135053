import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import React, { PureComponent } from 'react';
import { WrappedMDropdownProps } from './WrappedComp.types';

export type DropdownListItem = Record<'id' | 'label', string>;

type Props = {
  valueOnInit?: (val?: boolean) => void;
};
class ReduxMDropdown extends PureComponent<WrappedMDropdownProps & Props, {}> {
  componentDidMount() {
    const { input, valueOnInit } = this.props;
    valueOnInit && valueOnInit(input?.value);
  }

  render() {
    const {
      id,
      titleText,
      label,
      disabled,
      invalid,
      items,
      input,
      meta,
    } = this.props;
    const { touched, error } = meta;
    const value = input?.value;

    const changeFunc = (event: any) => {
      const item = event.selectedItem.id;
      input?.onChange(item, event, value);
    };
    const selectedItem = items.find(item => item.id === value);
    return (
      <M.Dropdown
        id={id}
        titleText={titleText || <span>&nbsp;</span>}
        label={label}
        disabled={disabled}
        items={items}
        invalid={(touched && !!error) || invalid}
        invalidText={touched && error && i18n.getStr(error)}
        onChange={changeFunc}
        selectedItem={selectedItem}
      />
    );
  }
}

export default ReduxMDropdown;
