/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';

import HTMLText from './HTMLText';
import Panel from './Panel';

const TextPanel = ({ content }) => (
  <Panel>
    <HTMLText content={content} />
  </Panel>
);

TextPanel.propTypes = {
  content: PropTypes.string,
};

export default TextPanel;
