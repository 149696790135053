/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactMapGL, { Marker, NavigationControl } from 'react-map-gl';
import WebMercatorViewport from 'viewport-mercator-project';
import { isString } from 'lodash';
import { MAPBOX_API_TOKEN } from '../constants';
import Panel from './Panel';

const parseCoordinate = coord => {
  return isString(coord) ? parseFloat(coord) : coord;
};
class Map extends PureComponent {
  static propTypes = {
    handleLocationSelected: PropTypes.func,
    locations: PropTypes.array,
    examSlugs: PropTypes.array,
    selectedLocation: PropTypes.object,
    searchedZipcode: PropTypes.string,
    stats: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      viewport: {
        width: 400,
        height: 400,
        zoom: 12,
        latitude: 37.773972,
        longitude: -122.431297,
      },
    };
    this.calculateViewport = this.calculateViewport.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.calculateViewport());
    this.calculateViewport();
  }

  componentWillReceiveProps(newProps) {
    this.calculateViewport(newProps);
  }

  viewportCenterViewOnLocation = (viewport, location) => {
    return {
      ...viewport,
      latitude: parseCoordinate(location.latitude),
      longitude: parseCoordinate(location.longitude),
    };
  };

  calculateViewport = (props = this.props) => {
    const { locations, changedViewport, selectedLocation } = props;
    const selectedLocationFull = this.selectedLocation(
      locations,
      selectedLocation,
    );

    if (changedViewport) {
      return this.setState({ viewport: changedViewport });
    }

    let { viewport } = this.state;

    if (this.container) {
      viewport.width = this.container.clientWidth;
      viewport.height = Math.min(this.container.clientHeight, 400);
    }

    if (selectedLocationFull) {
      viewport = this.viewportCenterViewOnLocation(
        viewport,
        selectedLocationFull,
      );
    } else if (locations && locations.length > 0) {
      if (locations.length === 1) {
        viewport = this.viewportCenterViewOnLocation(viewport, locations[0]);
      } else {
        const latitudes = locations.map(l => parseCoordinate(l.latitude));
        const longitudes = locations.map(l => parseCoordinate(l.longitude));
        const { latitude, longitude, zoom } = new WebMercatorViewport(
          viewport,
        ).fitBounds(
          [
            [Math.min(...longitudes), Math.min(...latitudes)],
            [Math.max(...longitudes), Math.max(...latitudes)],
          ],
          { padding: 20, offset: [-40, -40] },
        );
        viewport.longitude = longitude;
        viewport.latitude = latitude;
        viewport.zoom = zoom;
      }
    }
    return this.setState({ viewport });
  };

  selectedLocation = (locations, selectedLocation) => {
    return (
      locations &&
      selectedLocation &&
      locations.filter(l => l.siteId === selectedLocation.siteId)[0]
    );
  };

  render() {
    const {
      locations,
      selectedLocation,
      handleLocationSelected,
      searchedZipcode,
      examSlugs,
      stats,
    } = this.props;
    const containerRef = c => {
      this.container = c;
      return this.container;
    };
    const { viewport } = this.state;

    return (
      <div className='location-selector-map-container' ref={containerRef}>
        <Panel className='location-selector-map'>
          <ReactMapGL
            {...viewport}
            onViewportChange={changedViewport =>
              this.calculateViewport({ changedViewport })
            }
            mapboxApiAccessToken={MAPBOX_API_TOKEN}
          >
            <NavigationControl
              className='control-panel'
              onViewportChange={changedViewport =>
                this.calculateViewport({ changedViewport })
              }
              showCompass={false}
            />

            {locations &&
              locations.map((location, i) => {
                const {
                  latitude,
                  longitude,
                  siteId,
                  isAdditionalLocation,
                  distance,
                } = location;
                const selected =
                  selectedLocation && selectedLocation.siteId === siteId;
                return (
                  <Marker
                    key={siteId}
                    latitude={parseCoordinate(latitude)}
                    longitude={parseCoordinate(longitude)}
                    className={selected ? 'selected' : ''}
                  >
                    <div
                      className={`lab-location ${selected && 'selected'}`}
                      onClick={() =>
                        handleLocationSelected({
                          siteId,
                          zipcode: searchedZipcode,
                          examSlugs,
                          isAdditionalLocation,
                          distance,
                          ...stats,
                        })
                      }
                      role='button'
                      tabIndex='0'
                    >
                      {i + 1}
                    </div>
                  </Marker>
                );
              })}
          </ReactMapGL>
        </Panel>
      </div>
    );
  }
}

export default Map;
