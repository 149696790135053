/* eslint-disable react/require-default-props */
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import PropTypes from 'prop-types';

import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';

import { contracts } from '../../lib/options';
import ArgyleAddPositionContainer from './ArgyleAddPositionContainer';
import ArgyleEditPositionContainer from './ArgyleEditPositionContainer';

const checkmarkIcon = (
  <M.Icon icon='CheckmarkFilled' className='icon checkmark' />
);
const questionMarkIcon = (
  <M.Icon icon='HelpFilled' className='icon question-mark' />
);
const unableToVerify = (
  <span className='unable-to-verify'>
    <T value='components.EmploymentForm.ArgyleForm.argyleEmploymentFields.unableToVerify' />
  </span>
);

const getOriginalPositionText = argyleEmployment =>
  argyleEmployment?.position || '';

const getPositionComponents = (employmentObject, argyleEmployment) => {
  const originalPosition = getOriginalPositionText(argyleEmployment);

  if (argyleEmployment.positionVerified) {
    // Verified position has not been edited.
    if (employmentObject.position === originalPosition) {
      return employmentObject.position;
    }

    // Verified position has been edited.
    return (
      <div className='overridden-position-container'>
        <span className='overridden-position'>{originalPosition}</span>
        <span className='overriding-position'>{employmentObject.position}</span>
      </div>
    );
  }

  // Position has been added.
  if (employmentObject.position?.trim()?.length > 0) {
    return (
      <div className='overridden-position-container'>
        <span className='overridden-position'>
          <T value='components.EmploymentForm.ArgyleForm.argyleEmploymentFields.unableToVerify' />
        </span>
        <span className='overriding-position'>{employmentObject.position}</span>
      </div>
    );
  }

  // Position has not been added.
  return unableToVerify;
};

function getEmploymentFieldData(employmentObject, argyleEmployment) {
  const { contractType } = employmentObject;
  const contractObject = contracts.find(elem => elem.value === contractType);
  const contractTypeFormatted = i18n.getStr(contractObject?.name);
  return {
    name: {
      value: argyleEmployment.nameVerified
        ? employmentObject.name
        : unableToVerify,
      verified: argyleEmployment.nameVerified,
    },
    startDate: {
      value: argyleEmployment.startDateVerified
        ? employmentObject.startDate
        : unableToVerify,
      verified: argyleEmployment.startDateVerified,
    },
    endDate: {
      // eslint-disable-next-line no-nested-ternary
      value: argyleEmployment.endDateVerified
        ? employmentObject.currentPosition
          ? i18n.getStr(
              `components.EmploymentForm.ArgyleForm.argyleEmploymentFields.present`,
            )
          : employmentObject.endDate
        : unableToVerify,
      verified: argyleEmployment.endDateVerified,
    },
    contractType: {
      value: argyleEmployment.contractTypeVerified
        ? contractTypeFormatted
        : unableToVerify,
      verified: argyleEmployment.contractTypeVerified,
    },
    position: {
      value: getPositionComponents(employmentObject, argyleEmployment),
      verified: argyleEmployment.positionVerified,
    },
  };
}

function buildRows(employment, argyleEmployment) {
  return Object.entries(
    getEmploymentFieldData(employment, argyleEmployment),
  ).map(row => {
    const fieldName = row[0];
    const fieldNameText = i18n.getStr(
      `components.EmploymentForm.ArgyleForm.argyleEmploymentFields.${row[0]}`,
    );
    const fieldValue = row[1].value;
    const fieldVerified = row[1].verified;
    const componentKeyPart = `${employment.argyle_employment_id}-${fieldName}`;
    return (
      <M.TableRow key={`argyle-form-row-${componentKeyPart}`}>
        <M.TableCell key={`$argyle-form-cell-1-${componentKeyPart}`}>
          {fieldVerified ? checkmarkIcon : questionMarkIcon}
          {fieldNameText}
        </M.TableCell>
        <M.TableCell key={`$argyle-form-cell-2-${componentKeyPart}`}>
          {fieldValue}
        </M.TableCell>
      </M.TableRow>
    );
  });
}

const EmploymentArgyleForm = ({ employment, fieldName, argyleEmployment }) => {
  const originalPosition = getOriginalPositionText(argyleEmployment);

  return (
    <div
      className='employment-argyle-form'
      key={`employment-argyle-form-${employment.argyle_employment_id}`}
    >
      <M.TableContainer className='verified-employment-table'>
        <M.Table>
          <M.TableBody>{buildRows(employment, argyleEmployment)}</M.TableBody>
        </M.Table>
      </M.TableContainer>

      {argyleEmployment.positionVerified ? (
        <ArgyleEditPositionContainer
          key={`argyle-edit-position-${employment.argyleEmploymentId}`}
          employment={employment}
          fieldName={fieldName}
          originalPosition={originalPosition}
        />
      ) : (
        <ArgyleAddPositionContainer
          key={`argyle-add-position-${employment.argyleEmploymentId}`}
          employment={employment}
          fieldName={fieldName}
        />
      )}
    </div>
  );
};

EmploymentArgyleForm.propTypes = {
  employment: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  argyleEmployment: PropTypes.object.isRequired,
};

export default EmploymentArgyleForm;
