import {
  M,
  Upload16,
  CheckmarkFilled16,
  colors,
} from '@dashboard-experience/mastodon';

import PropTypes from 'prop-types';
import React from 'react';
import * as filestack from 'filestack-js/build/browser/filestack.min';
import { connect } from 'react-redux';
import { i18n } from '@international/mastodon-i18n';
import { Translate, I18n } from 'react-redux-i18n';
import { isEmpty } from 'lodash';
import * as Sentry from '@sentry/react';
import { useRequestFilePolicy } from '../../hooks';

import { groupClass, initUploadI18n } from './helper';
import FeedbackLabel from './FeedbackLabel';
import Label from './Label';
import RemoveFieldButton from './RemoveFieldButton';

import { apiRequest } from '../../actions/helper';
import { FILE_STACK_API_KEY } from '../../constants';

import { isMobileClient } from '../../lib/helpers';
import './upload-styles.scss';

const Upload = ({
  boldLabel,
  defaultButtonClass,
  disabled,
  documentType,
  handleRemoveDocuments,
  hideMeta,
  icon,
  input,
  international,
  label,
  meta: { touched, error, warning },
  mobileUploadButtonText,
  options = {},
  successText,
  uploadButtonText,
  useMastodonIcon,
  v1,
}) => {
  const { isLoading, filestackClientSecurity } = useRequestFilePolicy();

  /**
   * @name scheduleDeleteFile
   * @function
   * @memberOf Upload
   * @description Triggers delayed file deletion from filestack via
   *              Checkr api
   * @returns {promise} The delete request
   * @param {object} file - The file object returned from filestack
   */
  const scheduleDeleteFile = file => {
    const path = 'delete_file';
    const body = { url: file.url };
    if (international) {
      body.international = true;
    }
    const fileOptions = {
      method: 'POST',
      body: JSON.stringify(body),
    };

    return apiRequest(path, fileOptions);
  };

  /**
   * Map of MIME types to human-readable names.
   *
   * This list is derived from monolith Ruby code defined in `DocumentValidator::MIME_TYPE_EXTENSIONS`.
   *
   * @return {object}
   */
  const mimeNameMap = {
    'application/pdf': 'PDF',
    'image/jpeg': 'JPEG',
    'image/png': 'PNG',
    'image/gif': 'GIF',
    'image/heic': 'HEIC',
    'image/tiff': 'TIFF',
    'image/bmp': 'BMP',
  };

  /**
   * @name handleSuccess
   * @function
   * @memberOf Upload
   * @description Updates the UI after successful file upload to filestack
   * @param {object} e - The event
   */
  const handleSuccess = e => {
    const { filesFailed, filesUploaded } = e;
    const { maxFiles } = options;
    const newFiles = (input.value || []).concat(filesUploaded);

    if (documentType) {
      newFiles.forEach(file => {
        // eslint-disable-next-line no-param-reassign
        file.documentType = documentType;
      });
    }

    while (maxFiles && newFiles.length > maxFiles) {
      newFiles.shift();
    }

    input.onChange(newFiles);

    if (filesFailed?.length > 0) {
      Sentry.captureException(
        `File Stack: File upload failed for ${filesFailed.length} files`,
      );
    }
  };

  /**
   * Validates MIME type of uploaded file using magic byte number, not extension.
   *
   * @param {object} uploadedFileData File data.
   * @param {object} uploaderOptions Options.
   *
   * @throws {Error} For unsupported MIME type.
   *
   * @returns {Promise<void>}
   */
  const validateMimeType = async (uploadedFileData, uploaderOptions) => {
    const acceptableTypes = Object.values(mimeNameMap).join(', ');
    const uploadedMimeType = await uploaderOptions.mimeFromMagicBytes(
      uploadedFileData.originalFile,
    );
    const extension = uploadedFileData.name.split('.').pop();

    if (
      !uploaderOptions.acceptMime.includes(uploadedMimeType) ||
      extension.toLowerCase() === 'jfif'
    ) {
      const errorMsg =
        extension.toLowerCase() === 'jfif'
          ? 'Unsupported file extension JFIF.'
          : `Unsupported file type ${uploadedMimeType}.`;

      throw new Error(`${errorMsg} Accepted types: ${acceptableTypes}`);
    }
  };

  const FileStackDefaultPickerOptions = {
    accept: Object.keys(mimeNameMap),
    storeTo: { location: 'S3' },
    fromSources: ['local_file_system'],
    maxFiles: 10,
    disableTransformer: true,
    uploadInBackground: false,
    onFileUploadFinished: scheduleDeleteFile,
    onUploadDone: handleSuccess,
    acceptFn: validateMimeType,
    customText: initUploadI18n({ isMobileClient }),
    container: '#filestack',
    lang: i18n.getLocale().substring(0, 2),
    onFileSelected(file) {
      const { filename } = file || {};
      const extension = filename.split('.').pop();
      if (filename.length > 150) {
        return { ...file, name: `${filename.slice(0, 150)}.${extension}` };
      }
      return file;
    },
  };

  const filestackPickerOptions = {
    ...FileStackDefaultPickerOptions,
    ...options,
  };

  let desktopButtonText = I18n.t(uploadButtonText);
  let mobileButtonText = I18n.t(mobileUploadButtonText);
  let buttonClass = defaultButtonClass;

  if (touched && error) {
    buttonClass = 'danger';
  } else if (
    input.value.length === filestackPickerOptions.minFiles ||
    input.value.length === filestackPickerOptions.maxFiles
  ) {
    desktopButtonText = I18n.t(successText);
    mobileButtonText = I18n.t(successText);
    buttonClass = 'success';
  }
  if (isLoading || isEmpty(filestackClientSecurity)) {
    return <M.LoadingInline />;
  }
  const client = filestack.init(FILE_STACK_API_KEY, {
    security: filestackClientSecurity,
  });

  /**
   * @name openPicker
   * @function
   * @memberOf Upload
   * @description Opens the filestack picker
   * @param {object} e - The click event
   */
  const openPicker = e => {
    e.preventDefault();
    client.picker(filestackPickerOptions).open();
  };
  const removeUpload = i => {
    if (Array.isArray(input?.value)) {
      input.onChange(
        [].concat(input.value.slice(0, i), input.value.slice(i + 1)),
      );
    } else {
      input.onChange(null);
    }
  };

  if (v1) {
    if (options.maxFiles === 1) {
      // If v1 is only requesting one-file the schema wants an object not an array
      filestackPickerOptions.maxFiles = 1;
      filestackPickerOptions.onUploadDone = e => {
        input.onChange(e.filesUploaded[0]);
      };
    }

    const valsToRender =
      input?.value &&
      (Array.isArray(input?.value) ? input.value : [input.value]);

    return (
      <>
        {valsToRender.length ? (
          valsToRender.map((val, i) => (
            <div className='v1-success-block' key={val.filename}>
              <div className='upload-text'>
                <CheckmarkFilled16 />
                &nbsp;
                <span>
                  <strong>{val.filename}</strong>
                </span>
              </div>
              <M.Button kind='tertiary' onClick={() => removeUpload(i)}>
                {I18n.t('buttons.remove')}
              </M.Button>
            </div>
          ))
        ) : (
          <M.Button
            kind='secondary'
            onClick={e => {
              openPicker(e);
            }}
          >
            <Upload16 />
            &nbsp;{I18n.t('buttons.upload')}
            {/* {isMobileClient.any() && <span>{mobileButtonText}</span>}
          {!isMobileClient.any() && <span>{desktopButtonText}</span>} */}
          </M.Button>
        )}
        <span
          className='bx--form-requirement'
          style={{
            display: 'block',
            overflow: 'visible',
            maxHeight: '12.5rem',
            fontWeight: 'normal',
            paddingLeft: '.625rem',
            color: colors.dangerRed100,
          }}
        >
          {touched && error && I18n.t(error)}
        </span>
      </>
    );
  }
  return (
    <div className={groupClass(touched, error)}>
      {boldLabel ? (
        <Label label={label} htmlFor={input.name} boldLabel />
      ) : (
        <Label label={label} htmlFor={input.name} />
      )}
      <button
        className={`form-control btn btn-${buttonClass}`}
        disabled={disabled}
        onClick={e => {
          openPicker(e);
        }}
        type='button'
      >
        {icon && useMastodonIcon ? (
          <M.Icon icon={icon} size={16} />
        ) : (
          icon && <i className={`fa ${icon}`} />
        )}
        &nbsp;
        {isMobileClient.any() && <span>{mobileButtonText}</span>}
        {!isMobileClient.any() && <span>{desktopButtonText}</span>}
      </button>
      <div id='filestack' />
      <FeedbackLabel {...{ touched, error, warning }} />
      {!hideMeta && (
        <div className='text-muted small'>
          <Translate
            value='components.upload.document'
            count={input.value ? input.value.length : 0}
            maxFiles={filestackPickerOptions.maxFiles}
          />
          <div className='upload-text'>
            {input.value &&
              input.value.map((doc, i) => (
                <div key={`value-${i + 1}`}>
                  <strong>{doc.filename}</strong>
                </div>
              ))}
          </div>
          {handleRemoveDocuments && !isEmpty(input.value) && (
            <RemoveFieldButton
              handleOnClick={handleRemoveDocuments}
              className='btn btn-sm btn-remove-docs'
              id='remove-documents'
              textValue='buttons.removeDocuments'
            />
          )}
        </div>
      )}
    </div>
  );
};

Upload.propTypes = {
  boldLabel: PropTypes.bool,
  defaultButtonClass: PropTypes.string,
  disabled: PropTypes.bool,
  documentType: PropTypes.string,
  handleRemoveDocuments: PropTypes.func,
  hideMeta: PropTypes.bool,
  icon: PropTypes.string,
  input: PropTypes.object,
  international: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  mobileUploadButtonText: PropTypes.string,
  options: PropTypes.object,
  successText: PropTypes.string,
  uploadButtonText: PropTypes.string,
  useMastodonIcon: PropTypes.bool,
  v1: PropTypes.bool,
};

Upload.defaultProps = {
  boldLabel: false,
  defaultButtonClass: 'primary',
  disabled: false,
  documentType: null,
  handleRemoveDocuments: null,
  hideMeta: false,
  icon: 'fa-cloud-upload',
  input: {},
  international: false,
  label: null,
  meta: {},
  mobileUploadButtonText: 'components.upload.mobileButtonText',
  options: {},
  successText: 'components.upload.successText',
  uploadButtonText: 'components.upload.desktopButtonText',
  useMastodonIcon: false,
  v1: false,
};

const mapStateToProps = ({ i18n: { locale } }) => ({ locale });

export default connect(mapStateToProps)(Upload);
