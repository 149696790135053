import React from 'react';
import { Field } from 'redux-form';

import { Checkbox } from './fields';

import * as V from '../lib/validations';

import HTMLText from './HTMLText';
import Panel from './Panel';

const Article23aDisclosure = ({ content }: {content: string}) => (
  <Panel title='components.Article23aDisclosure.title' icon='fa-file-text'>
    <div>
      <HTMLText content={content} />
    </div>
    <hr />
    <div className='col-md-12'>
      <Field
        type='checkbox'
        name='article23aDisclosure'
        label='components.Article23aDisclosure.label'
        component={Checkbox}
        validate={[V.requiredCheckbox]}
        labelFeedback
        boldLabel
      />
    </div>
  </Panel>
);

export default Article23aDisclosure;
