import { M } from '@dashboard-experience/mastodon';

import React, { useContext } from 'react';
import { Validator } from 'redux-form';

import { required } from '../../../../lib/validations';

import {
  RenderableSchemaProperty,
  PassThroughProps,
} from '../../FieldCreationLogic/Field.types';

import InternationalContext from '../../../InternationalContext';

const SpecializedFieldWrapper = ({
  SpecializedComponent,
  idToUse,
  properties = {},
  fieldArrayModifier = '',
  passThroughProps,
}: {
  SpecializedComponent: any;
  idToUse: string;
  passThroughProps: PassThroughProps;
  properties?: { [prop: string]: RenderableSchemaProperty };
  fieldArrayModifier?: string;
}) => {
  const intlContext = useContext(InternationalContext);
  const { generateField } = intlContext.fieldGenerator;

  const unhandledProperties = Object.keys(properties)
    .filter(key => !SpecializedComponent.properties[key])
    .map(key => properties[key]);

  return (
    <>
      <SpecializedComponent
        id={fieldArrayModifier || idToUse}
        properties={properties}
        {...passThroughProps}
      />
      {M.Container.buildSubContents(
        unhandledProperties
          .map(field => generateField(field))
          .flatMap(rfc => {
            const field = rfc.render(id => {
              const validate:
                | Validator
                | Validator[] = intlContext.data?.required?.has(id)
                ? required
                : [];

              return {
                validate,
              };
            });

            return field;
          }),
      )}
    </>
  );
};

export default SpecializedFieldWrapper;
