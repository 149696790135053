/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Field } from 'redux-form';
import { Translate as T } from 'react-redux-i18n';
import { ButtonSelect, Upload, groupClass } from './fields';
import * as V from '../lib/validations';
import {
  EXAM_TYPES,
  EXAM_SELECTIONS,
  HealthScreeningContext,
} from '../lib/utils/healthScreeningUtils';
import FeedbackLabel from './fields/FeedbackLabel';

export const ExamFormControl = ({
  options,
  slug,
  input: { name, onChange, value },
  meta: { touched, error, warning },
}) => {
  const documentUploadEnabled = value === EXAM_SELECTIONS.uploadDocumentation;
  const { requireWaiverDocument, handleDocumentUpload } = useContext(
    HealthScreeningContext,
  );
  const minDocumentValidation = (documents, { examSelection }) =>
    requireWaiverDocument &&
    examSelection &&
    examSelection[slug] === EXAM_SELECTIONS.uploadDocumentation
      ? V.required1Document(documents)
      : undefined;

  return (
    <div>
      <div className={`row align-items-center ${groupClass(touched, error)}`}>
        <div className='col-md-9'>
          <Field
            name={`${name}.buttonSelect`}
            component={ButtonSelect}
            options={options}
            selectedValue={value}
            type='button'
            onClick={onChange}
            className='form-group-exam-selection'
          />
        </div>
        <div className='col-md-3'>
          <FeedbackLabel {...{ touched, error, warning }} />
          {documentUploadEnabled && requireWaiverDocument && (
            <div className='text-muted pt-4 row'>
              <div className='col-md-1'>
                <i className='fa fa-cloud-upload mr-1' aria-hidden='true' />
              </div>
              <div className='col-md pl-1'>
                <T value='components.Health.exams.options.uploadProof' />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='d-flex'>
        <div className='w-25'>
          <Field
            name={`examWaiverUpload.${slug}`}
            component={Upload}
            disabled={!documentUploadEnabled}
            defaultButtonClass='secondary'
            onChange={handleDocumentUpload(slug)}
            validate={minDocumentValidation}
          />
        </div>
      </div>
    </div>
  );
};

ExamFormControl.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
  }).isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  meta: PropTypes.object,
  slug: PropTypes.string.isRequired,
};

const Exam = ({ exam, examType, includeHorizontalRule }) => {
  // eslint-disable-next-line no-shadow
  const options = [EXAM_SELECTIONS.uploadDocumentation];

  if (exam.counterpartSlug) {
    options.push(EXAM_SELECTIONS.testForImmunity);
  }

  if (examType === EXAM_TYPES.vaccine) {
    options.push(EXAM_SELECTIONS.scheduleVaccine);
  } else if (examType === EXAM_TYPES.physical) {
    options.push(EXAM_SELECTIONS.scheduleTest);
  } else {
    throw new Error(`Unexpected exam type: ${examType}`);
  }

  const { handleToggle } = useContext(HealthScreeningContext);

  return (
    <div className='row align-items-center'>
      <div className='col-md-2 font-weight-bold'>{exam.label}</div>
      <div className='col-md-10'>
        <Field
          name={`examSelection.${exam.name}`}
          component={ExamFormControl}
          validate={[V.required]}
          onChange={handleToggle(exam.name)}
          slug={exam.name}
          options={options}
        />
        {includeHorizontalRule && <hr />}
      </div>
    </div>
  );
};

Exam.propTypes = {
  exam: PropTypes.object.isRequired,
  examType: PropTypes.string.isRequired,
  includeHorizontalRule: PropTypes.bool.isRequired,
};

export default Exam;
