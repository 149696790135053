import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import qs from 'query-string';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import { apiRequest } from '../../actions/helper';
import {
  addArgyleAccountEmployments,
  setArgyleState,
} from '../../actions/employment/employmentActions';
import {
  argyleDebugLog,
  argyleErrorLog,
} from '../../lib/employment/utils/employmentUtils';
import {
  EMPLOYMENT_ANALYTICS_EVENTS,
  EMPLOYMENT_ANALYTICS_PROPERTIES,
} from '../../lib/employment/analytics_events';

const ArgyleReviewLoadingTable = () => {
  const tableRowLabels = [
    'startDate',
    'endDate',
    'contractType',
    'position',
  ].map(function(label) {
    return i18n.getStr(
      `components.EmploymentForm.ArgyleForm.argyleEmploymentFields.${label}`,
    );
  });
  return (
    <M.TableContainer className='polling-table-container'>
      <M.Table className='polling-table'>
        <M.TableHead id='polling-table-head'>
          <M.TableRow>
            <M.TableCell className='label'>
              <M.LoadingBlock className='gradient-loading' />
            </M.TableCell>
            <M.TableCell className='value' />
          </M.TableRow>
        </M.TableHead>
        <M.TableBody id='polling-table-body'>
          {tableRowLabels.map(function(label) {
            return (
              <M.TableRow key={`${label}`}>
                <M.TableCell key={`${label}_label`} className='label'>
                  {label}
                </M.TableCell>
                <M.TableCell className='value' key={`${label}_gradient`}>
                  <M.LoadingBlock className='gradient-loading' />
                </M.TableCell>
              </M.TableRow>
            );
          })}
        </M.TableBody>
      </M.Table>
    </M.TableContainer>
  );
};

const loadArgyleAccountEmployments = async argyleAccountIds => {
  const params = { account_ids: argyleAccountIds };
  const query = qs.stringify(params);
  const path = `apply/argyle/employments?${query}`;

  // this will throw if the server doesn't return OK
  return apiRequest(path).then(json => {
    return json.data.argyleAccountEmployments;
  });
};

// todo: adl - make this stuff configurable and tweak timing/review with UX
const SECONDS = 1_000;
const POLL_INTERVAL = 10 * SECONDS;
const MAX_POLLS = 5;

const ArgyleReviewPollingBody = ({
  dispatch,
  argyleConnectedAccountIds,
  argyleAccountEmployments,
  trackArgyleAnalyticsEvent,
}) => {
  const [pollCount, setPollCount] = useState(1);

  // This effect polls our backend for Argyle Employments.
  useEffect(() => {
    // for cleaning up an in-flight poll later if needed
    const abortController = new AbortController();

    const bumpPollCount = newAccountEmployments => {
      const allFoundArgyleAccountEmployments = _.union(
        newAccountEmployments,
        argyleAccountEmployments,
      );

      const foundEmploymentIds = allFoundArgyleAccountEmployments
        .map(account => account.argyleEmployments)
        .flat()
        .map(employment => employment.argyleEmploymentId);

      const foundAccountIds = allFoundArgyleAccountEmployments.map(
        ae => ae.argyleAccountId,
      );

      const unsyncedAccountIds = argyleConnectedAccountIds.filter(
        id => !foundAccountIds.includes(id),
      );

      function logArgyleErrorAndTrackEvent(message, eventName) {
        argyleErrorLog(message, { captureSentryException: true });
        trackArgyleAnalyticsEvent(eventName);
      }

      argyleDebugLog(
        `Request to increase pollCount ${pollCount} -> ${pollCount + 1}`,
      );
      argyleDebugLog(
        'All found Argyle Accounts',
        allFoundArgyleAccountEmployments,
      );
      argyleDebugLog('unsyncedAccountIds', unsyncedAccountIds);

      if (pollCount >= MAX_POLLS) {
        // we didn't get all the employments we were looking for but we've exceeded MAX_POLLS.
        // if we've loaded _any_ Argyle Employments, switch to `reviewing`.
        // otherwise, switch to `polling-timeout`
        const newArgyleState =
          foundEmploymentIds.length > 0 ? 'reviewing' : 'polling-timeout';

        argyleDebugLog(
          `MAX_POLLS ${MAX_POLLS} exceeded. Advancing to ${newArgyleState}`,
        );

        if (newArgyleState === 'polling-timeout') {
          logArgyleErrorAndTrackEvent(
            `Candidate timed out polling for Argyle Accounts: ${unsyncedAccountIds.join(
              ', ',
            )}`,
            EMPLOYMENT_ANALYTICS_EVENTS.ARGYLE_POLLING_TIMEOUT,
          );
        } else if (unsyncedAccountIds.length > 0) {
          logArgyleErrorAndTrackEvent(
            `Candidate timed out after finding ${foundAccountIds.length}/${
              argyleConnectedAccountIds.length
            } Argyle Accounts:
  Synced accounts: ${foundAccountIds.join(', ')};
  Unsynced accounts: ${unsyncedAccountIds.join(', ')}`,
            EMPLOYMENT_ANALYTICS_EVENTS.ARGYLE_POLLING_TIMEOUT,
          );
        }

        // todo: adl - we may want to do something more advanced if we loaded some but not all
        // Argyle Accounts here
        // (like show the review modal with a warning/notification/toast about something having gone wrong?)
        dispatch(setArgyleState(newArgyleState));
      } else {
        setPollCount(p => p + 1);
      }
    };

    const timeoutId = setTimeout(
      () => {
        argyleDebugLog(`Poll #${pollCount} (timeoutId ${timeoutId})`);
        if (pollCount === 1) {
          trackArgyleAnalyticsEvent(
            EMPLOYMENT_ANALYTICS_EVENTS.ARGYLE_POLLING_STARTED,
            {
              [EMPLOYMENT_ANALYTICS_PROPERTIES.ARGYLE_ACCOUNT_IDS]: argyleConnectedAccountIds,
            },
          );
        }

        // `accountIdsToPoll` can be an empty list in here, but I think only when using our
        // Argyle Dev Tool for polling in certain scenarios.
        // we'll just let this thing poll to timeout in those scenarios for now
        const accountIdsToPoll = argyleConnectedAccountIds.filter(
          id => !argyleAccountEmployments.some(e => e.argyleAccountId === id),
        );
        argyleDebugLog('Polling for Argyle Accounts', accountIdsToPoll);
        loadArgyleAccountEmployments(accountIdsToPoll)
          .then(newAccountEmployments => {
            if (abortController.signal.aborted) {
              // abort!
              argyleDebugLog(
                'Fetch was aborted. Discarding',
                newAccountEmployments,
              );
              return;
            }

            const foundAccountIds = newAccountEmployments.map(
              account => account.argyleAccountId,
            );
            argyleDebugLog(
              `Found ${foundAccountIds.length} new Argyle Accounts:`,
              newAccountEmployments,
            );
            if (foundAccountIds.length > 0) {
              // todo: adl - maybe we should dispatch the list of these all at once instead of once per account?
              newAccountEmployments.forEach(ae => {
                dispatch(addArgyleAccountEmployments(ae));
              });

              const accountIdsMissingAfterPoll = accountIdsToPoll.filter(
                id => !foundAccountIds.includes(id),
              );
              const foundEmploymentIds = argyleAccountEmployments
                .map(account => account.argyleEmployments)
                .flat()
                .map(employment => employment.argyleEmploymentId);
              const newFoundEmploymentIds = newAccountEmployments
                .map(account => account.argyleEmployments)
                .flat()
                .map(employment => employment.argyleEmploymentId);
              const allFoundEmploymentIds = _.union(
                foundEmploymentIds,
                newFoundEmploymentIds,
              );
              if (accountIdsMissingAfterPoll.length > 0) {
                argyleDebugLog(
                  `Still missing ${accountIdsMissingAfterPoll.length} Arygle Account(s):`,
                  accountIdsMissingAfterPoll,
                );
                bumpPollCount(newAccountEmployments);
              } else {
                // if any found employments, go to reviewing, otherwise go to polling-timeout
                const newState =
                  allFoundEmploymentIds.length > 0
                    ? 'reviewing'
                    : 'polling-timeout';
                argyleDebugLog(
                  `No more Argyle Accounts to poll. Found ${allFoundEmploymentIds.length} Argyle Employments. ` +
                    `Advancing to ${newState}.`,
                );
                dispatch(setArgyleState(newState));
                trackArgyleAnalyticsEvent(
                  EMPLOYMENT_ANALYTICS_EVENTS.ARGYLE_POLLING_COMPLETED,
                  {
                    [EMPLOYMENT_ANALYTICS_PROPERTIES.ARGYLE_EMPLOYMENT_IDS]: allFoundEmploymentIds,
                  },
                );
              }
            } else {
              argyleDebugLog('No new Argyle Accounts found this poll');
              bumpPollCount(newAccountEmployments);
            }
          })
          .catch(error => {
            // This could be network errors or server errors.
            argyleErrorLog(
              `Error getting Argyle Employments on poll #${pollCount}/${MAX_POLLS}`,
              {
                error,
                captureSentryException: true,
              },
            );
            // Continue to poll until we timeout
            bumpPollCount([]);
          });

        // wait POLL_INTERVAL and then poll (10 seconds unless this is the first poll or we've exceeded MAX_POLLS...?)
      },
      pollCount === 1 ? 0 : POLL_INTERVAL,
    );

    // return cleanup function:
    // clear the timeout on cancel/unmount
    // If this component unmounts (say because the user clicks "cancel"/"verify manually instead"
    // to abort), the last iteration of polling will probably still be in flight. Cleanup could
    // look like sending a cancellation to the fetch API, or we could just let the request resolve.
    // If that happens, we may end up adding Argyle Employments into state but the user won't
    // see them on the Review Modal unless they come back later.
    return () => {
      argyleDebugLog(`Cleaning up (timeoutId ${timeoutId})`);
      clearTimeout(timeoutId);
      abortController.abort();
    };

    // this useEffect hook runs once on component mount, and again every time pollCount changes.
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [pollCount]);
  // note: these are the dependencies required by the React linter rules, but we believe the
  // only dependency we _really_ want here is `pollCount`. React warns about the missing dependencies in
  // react-hooks/exhaustive-deps, and we don't want to risk obscure stale closure bugs if we disable the
  // warning and remove any of these, so we'll suffer the additional re-renders instead. It doesn't appear to cause
  // any negative functional behavior other than additional cleanups in our logs.
  // more info: https://github.com/facebook/react/issues/22879
  // update: after further review of React dev guide (https://react.dev/learn/separating-events-from-effects), the
  // "right" solution to this problem is to update argyleAccountEmployments and argyleConnectedAccoundIds in an
  // "Effect Event" such that they don't need to be included in this useEffect's dependency array. This feature is
  // not yet available in React, and in the meantime, we determine that we are safe to explicitly omit these
  // dependencies and disable the react-hooks/exhaustive-deps lint warning.
  // }, [
  //   pollCount,
  //   argyleAccountEmployments,
  //   argyleConnectedAccountIds,
  //   dispatch,
  // ]);

  return (
    <M.ModalBody className='polling-modal-body'>
      <div className='row'>
        <h2>
          {i18n.getStr(
            `components.EmploymentForm.ArgyleForm.loadingModal.loadingHeader`,
          )}
        </h2>
        <div className='row'>
          <div className='col spinner'>
            <M.LoadingInline id='icon-spinner' />
          </div>
          <div className='col text'>
            {i18n.getStr(
              `components.EmploymentForm.ArgyleForm.loadingModal.loadingText`,
            )}
          </div>
        </div>
      </div>
      <ArgyleReviewLoadingTable />
    </M.ModalBody>
  );
};

ArgyleReviewPollingBody.propTypes = {
  dispatch: PropTypes.func.isRequired,
  trackArgyleAnalyticsEvent: PropTypes.func.isRequired,
  argyleConnectedAccountIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  argyleAccountEmployments: PropTypes.arrayOf(
    PropTypes.shape({
      argyleAccountId: PropTypes.string,
      argyleEmployments: PropTypes.arrayOf(PropTypes.object),
    }),
  ).isRequired,
};

export default connect()(ArgyleReviewPollingBody);
