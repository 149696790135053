const selfDisclosureOffenseLevels = [
  {
    value: '',
    name: 'options.selectOffenseLevel',
    i18n: true,
  },
  {
    value: 'Felony',
    name: 'options.selfDisclosureOffenseLevels.felony',
    i18n: true,
  },
  {
    value: 'Misdemeanor',
    name: 'options.selfDisclosureOffenseLevels.misdemeanor',
    i18n: true,
  },
  {
    value: 'Other',
    name: 'options.selfDisclosureOffenseLevels.other',
    i18n: true,
  },
];

export default selfDisclosureOffenseLevels;
