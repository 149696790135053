/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as SignaturePad from 'signature_pad';

import { IS_TEST_MODE } from '../../constants';

import { groupClass } from './helper';
import FeedbackLabel from './FeedbackLabel';

class Signature extends Component {
  static propTypes = {
    input: PropTypes.shape({
      onBlur: PropTypes.func,
      onChange: PropTypes.func.isRequired,
      value: PropTypes.string,
    }),
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
      warning: PropTypes.string,
    }),
  };

  constructor(props) {
    super(props);
    this.clearCanvas = this.clearCanvas.bind(this);
    this.initCanvas = this.initCanvas.bind(this);
    this.padChanged = this.padChanged.bind(this);
  }

  componentDidMount() {
    const {
      input: { onChange, onBlur },
    } = this.props;

    this.canvas = document.querySelector('canvas');
    // eslint-disable-next-line new-cap
    this.pad = new SignaturePad.default(this.canvas, {
      dotSize: 1,
      onEnd: this.padChanged,
    });

    this.resizeCanvas();
    this.initCanvas();

    if (IS_TEST_MODE) {
      onBlur();
      onChange(this.pad.toDataURL());
    }
  }

  clearCanvas() {
    const {
      pad,
      props: { input },
    } = this;

    pad.clear();
    input.onChange(null);
  }

  isCanvasValid() {
    const { canvas, pad } = this;

    if (pad.isEmpty()) {
      return false;
    }

    const context = canvas.getContext('2d');
    const { width, height } = canvas;
    const { data } = context.getImageData(0, 0, width, height);

    let count = 0;
    for (let x = 0; x < width; x += 1) {
      for (let y = 0; y < height; y += 1) {
        /**
         * `getImageData` returns a `Uint8ClampedArray` with 4 values (one
         * per component of the RGBA model) for each pixel. As the alpha
         * channel is the last value of the group, an offset of 3 must be
         * applied to access the value.
         */
        if (data[(width * y + x) * 4 + 3]) {
          count += 1;
        }
      }
    }

    const threshold = 0.35; // Percent of canvas filled
    return (count / (width * height)) * 100 > threshold;
  }

  padChanged() {
    const {
      props: { input },
      pad,
    } = this;

    input.onBlur();

    if (this.isCanvasValid()) {
      input.onChange(pad.toDataURL());
    }
  }

  initCanvas() {
    const {
      pad,
      props: {
        input: { value },
      },
    } = this;

    pad.fromDataURL(value);
  }

  resizeCanvas() {
    const { canvas } = this;

    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);
  }

  render() {
    const {
      input,
      meta: { touched, error, warning },
    } = this.props;

    return (
      <div>
        <div className={groupClass(touched, error)}>
          <div className='form-control'>
            <canvas className='signature-pad' />
          </div>
          <FeedbackLabel {...{ touched, error, warning }} />
        </div>
        {(touched || input.value) && (
          <div>
            <button
              type='button'
              className='btn btn-xs btn-secondary'
              onClick={this.clearCanvas}
            >
              clear
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default Signature;
