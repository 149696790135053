import React, { PureComponent } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import { connect } from 'react-redux';

import ApplyFormManager from '../../FormLogic/ApplyFormManager.class';
import InternationalContext from '../../InternationalContext';
import InternationalHelper from '../../InternationalHelper';
import { JsonSchema, Screening } from '../../types/schema.types';
import RegionRequirementsHeader from './RegionRequirementsHeader';
import RegionContainers from './RegionContainers';
import RegionDropdown from './RegionDropdown';

import {
  ApplyForm,
  FormRegionsToRender,
  FormToRender,
} from '../../FormLogic/Form.types';

type Props = {
  screening: Screening;
  applyForm: ApplyForm; // this comes from ApplyFormManager.data
  schema: JsonSchema; // this comes from domestic pages component props
  translations: Record<string, Record<string, string>>;
  isInternational: boolean;
};

type State = {
  intlContextForDomestic: ApplyFormManager;
  locale: string;
};

class RegionRequirements extends PureComponent<
  Props,
  State,
  typeof InternationalContext
> {
  static contextType = InternationalContext;

  context!: React.ContextType<typeof InternationalContext>;

  state = {
    intlContextForDomestic: {} as ApplyFormManager,
    locale: i18n.getLocale(),
  };

  componentDidUpdate() {
    const { isInternational } = this.props;
    if (isInternational) return;
    const { locale } = this.state;
    if (locale !== i18n.getLocale()) {
      const { schema } = this.props;
      InternationalHelper.countryListDomestic(schema, this);
    }
  }

  getRegionsToRender(form: FormToRender): FormRegionsToRender {
    const { screening } = this.props;
    const { screenings } = form;

    return screenings[screening] ? screenings[screening].regions : {};
  }

  render() {
    const { intlContextForDomestic } = this.state;
    const { applyForm, schema, screening } = this.props;
    const applyFormWithParsedSchema = applyForm || intlContextForDomestic.data;

    if (!applyFormWithParsedSchema) {
      InternationalHelper.countryListDomestic(schema, this); // Load intlContextForDomestic data
      return <M.LoadingSpinner />;
    }

    const { regionsInOrder, complexRequirements } = applyFormWithParsedSchema;

    const regionsToRender = this.getRegionsToRender(
      applyFormWithParsedSchema.applyForm,
    );
    const context = Object.keys(this.context).length
      ? this.context
      : intlContextForDomestic;

    return (
      <InternationalContext.Provider value={context}>
        <div id='mastodon'>
          {screening !== 'right_to_work' && (
            <RegionRequirementsHeader {...{ screening }} />
          )}
          <RegionContainers
            {...{ regionsInOrder, regionsToRender, screening }}
          />
          <RegionDropdown {...{ complexRequirements, screening }} />
        </div>
      </InternationalContext.Provider>
    );
  }
}

const mapStateToProps = (state: any) => ({
  translations: state?.i18n?.translations,
  isInternational:
    state?.configuration?.configuration?.format === 'international',
});

export default i18n.renderTranslation()(
  connect(mapStateToProps)(RegionRequirements),
);
