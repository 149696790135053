import React from 'react';
import { Field } from 'redux-form';
import { Translate as T } from 'react-redux-i18n';
import { concat, sortBy } from 'lodash';

import { Select } from './fields';
import Panel from './Panel';

import * as O from '../lib/options';
import * as V from '../lib/validations';

const CitizenshipInformation = () => {
  return (
    <Panel title='components.CitizenshipInformation.title' icon='fa-book'>
      <div className='row'>
        <div className='col-md-6'>
          <Field
            type='select'
            name='citizenship'
            label='labels.countryOfCitizenship'
            component={Select}
            validate={[V.required]}
            options={concat(
              O.countries[0],
              sortBy(O.countries.slice(1), 'name'),
            )}
          />
          <T value='components.CitizenshipInformation.dualCitizenship' />
        </div>
      </div>
    </Panel>
  );
};

export default CitizenshipInformation;
