/* eslint-disable camelcase */
import { M } from '@dashboard-experience/mastodon';
import { i18n, moment } from '@international/mastodon-i18n';

import React, { PureComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, change } from 'redux-form';

import {
  Provider as FlagrProvider,
  Switch,
  Case,
} from '@dashboard-experience/react-flagr';

import {
  addressHistoryNumYearsRequired,
  getAddressHistoryYears,
} from 'international/Pages/utils';
import { RenderableSchemaProperty } from '../../FieldCreationLogic/Field.types';
import {
  intlAddressHistoryStartDateValidator,
  intlAddressHistoryEndDateValidator,
  intlAddressStateValidator,
} from '../../../../lib/validations';
import { clearFormValue } from '../../../../actions/intlForm';
import { setAddressHistoryRequirementSatisfied } from '../../../../actions';
import InternationalContext from '../../../InternationalContext';
import './AddressField.scss';
import { InternationalAddressData } from '../../../types/schema.types';
import parseGooglePlacesResult from '../../../../lib/utils/googlePlacesAutoCompleteUtils';
import {
  FLAGR_URL,
  ADDRESS_AUTOCOMPLETE_FLAGR_KEY,
  ENV,
} from '../../../../constants';

type Props = {
  addresses: InternationalAddressData[];
  clearFormValue: any;
  id: string;
  accountId?: string;
  partnerAccountUriName?: string;
  properties: Record<string, RenderableSchemaProperty>;
  screeningSettings: any;
  dispatch: Function;
  errors: any;
  setAddressHistoryRequirementSatisfied: Function;
  addressHistoryRequirementSatisfied: boolean;
  addressHistoryYears: number;
};

type State = {
  countryCode: string;
  streetAddress: string;
  city: string;
  state: string;
  zipcode: string;
  addressIndex: number | null;
  currentResidence: boolean;
  showSuccessToast: boolean;
};

const initialState = {
  currentResidence: false,
  countryCode: '',
  streetAddress: '',
  city: '',
  state: '',
  zipcode: '',
  addressIndex: null,
  showSuccessToast: false,
};
const SuccessToastNotification = ({
  numYearsRequired,
  dispatch,
  setAddressHistoryRequirementSatisfied: setAddressHistoryRequirementSatisfiedAction,
  setShowSuccessToast,
}: {
  numYearsRequired: number;
  dispatch: Function;
  setAddressHistoryRequirementSatisfied: Function;
  setShowSuccessToast: Function;
}) => {
  useEffect(
    () => () => {
      setShowSuccessToast();
    },
    [setShowSuccessToast],
  );
  return (
    <M.ToastNotification
      kind='success'
      title={i18n.getStr(
        'i18nInternational.addressField.successToastNotificiationTitle',
      )}
      subtitle={i18n.getStr(
        'i18nInternational.addressField.successToastNotificationMessage',
        numYearsRequired,
      )}
      timeout={3000}
      className='success-toast-notification'
    />
  );
};

export class AddressField extends PureComponent<Props, State> {
  static contextType = InternationalContext;

  static id = 'address';

  static readonly properties: { [key: string]: string } = Object.freeze({
    street: 'street',
    street2: 'street2',
    city: 'city',
    state: 'state',
    zipcode: 'zipcode',
    country_code: 'country_code',
    start_date: 'start_date',
    end_date: 'end_date',
  });

  static autocompleteList: {
    [key: string]: google.maps.places.Autocomplete;
  } = {};

  context!: React.ContextType<typeof InternationalContext>;

  constructor(props: Props) {
    super(props);
    const { id } = this.props;
    const addressIndexString: string = id.match(/address\[(\d*)\]/)![1];
    const addressIndex = parseInt(addressIndexString, 10);
    const currentResidence = addressIndex === 0;

    this.state = {
      ...initialState,
      addressIndex,
      currentResidence,
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { countryCode } = this.state;
    if (prevState.countryCode !== countryCode) {
      this.autocompleteInit(countryCode);
    }
    const {
      dispatch,
      addresses,
      id,
      screeningSettings,
      setAddressHistoryRequirementSatisfied: setAddressHistoryRequirementSatisfiedAction,
      addressHistoryRequirementSatisfied,
      addressHistoryYears,
    } = this.props;
    const fieldsFilled = addresses.every(elem => elem);
    const rangeStartDate = moment(
      addresses[addresses.length - 1]?.start_date,
      i18n.getMonthYearPattern(),
      true,
    );
    const rangeEndDate = moment(new Date(), i18n.getMonthYearPattern, true);
    const numYearsRequired = addressHistoryNumYearsRequired(
      screeningSettings,
      addressHistoryYears,
    );
    const { currentResidence, addressIndex, showSuccessToast } = this.state;
    const hasErrors =
      !rangeStartDate.isValid() ||
      !rangeEndDate.isValid() ||
      intlAddressHistoryStartDateValidator(addresses, numYearsRequired) ||
      intlAddressHistoryEndDateValidator(addresses, numYearsRequired) ||
      addresses?.some(elem =>
        intlAddressStateValidator(elem?.state, addresses),
      );
    if (!currentResidence && addressIndex && addresses[addressIndex - 1]) {
      if (
        !addresses[addressIndex] ||
        !addresses[addressIndex].end_date ||
        prevProps.addresses[addressIndex - 1]?.start_date !==
          addresses[addressIndex - 1]?.start_date
      ) {
        dispatch(
          change(
            'intlForm',
            `${id}.end_date`,
            addresses[addressIndex - 1]?.start_date,
          ),
        );
      }
    }
    if (
      rangeEndDate.diff(rangeStartDate, 'months') >= numYearsRequired * 12 &&
      !addressHistoryRequirementSatisfied &&
      !hasErrors &&
      fieldsFilled
    ) {
      setAddressHistoryRequirementSatisfiedAction(true)(dispatch);
      if (!showSuccessToast && addressIndex === addresses.length - 1) {
        // eslint-disable-next-line react/no-did-update-set-state
        setTimeout(() => this.setState({ showSuccessToast: true }), 1000);
      }
    } else if (
      addressHistoryRequirementSatisfied &&
      rangeEndDate.diff(rangeStartDate, 'months') < numYearsRequired * 12
    ) {
      setAddressHistoryRequirementSatisfied(false)(dispatch);
      // eslint-disable-next-line react/no-did-update-set-state
      if (showSuccessToast) this.setState({ showSuccessToast: false });
    }
  }

  setCurrentResidence = (currentResidence: boolean, fieldId: string) => {
    const { clearFormValue: clearFormValueAction } = this.props;
    this.setState({ currentResidence });
    if (currentResidence) {
      clearFormValueAction(`${fieldId}.end_date`);
    }
  };

  autocompleteInit = (countryCode: string) => {
    // Autocomplete is only enabled based on the country
    if (!countryCode) {
      return;
    }

    const { id = AddressField.id } = this.props;

    AddressField.autocompleteList[
      `${id}-street-autocomplete`
    ] = new google.maps.places.Autocomplete(
      document.getElementById(`${id}-street-autocomplete`) as HTMLInputElement,
      {
        fields: ['address_components'],
        componentRestrictions: { country: [countryCode] },
        types: ['address'],
      },
    );

    AddressField.autocompleteList[`${id}-street-autocomplete`].addListener(
      'place_changed',
      this.handlePlaceSelect,
    );
  };

  handlePlaceSelect = () => {
    const { id = AddressField.id } = this.props;

    const addressObject = AddressField.autocompleteList[
      `${id}-street-autocomplete`
    ]?.getPlace();

    if (addressObject) {
      const [address1, city, stateAd, postcode] = parseGooglePlacesResult(
        addressObject.address_components,
      );
      this.setState({
        streetAddress: address1,
        city,
        state: stateAd,
        zipcode: postcode,
      });
    }
  };

  resetAddressValues = () => {
    this.setState({
      streetAddress: '',
      city: '',
      state: '',
      zipcode: '',
    });
  };

  render() {
    const {
      currentResidence,
      streetAddress,
      city,
      state,
      zipcode,
      addressIndex,
      showSuccessToast,
    } = this.state;
    const {
      addresses,
      id = AddressField.id,
      properties,
      accountId,
      partnerAccountUriName,
      dispatch,
      screeningSettings,
      addressHistoryYears,
    } = this.props;
    const numYearsRequired = addressHistoryNumYearsRequired(
      screeningSettings,
      addressHistoryYears,
    );

    const { fieldGenerator, getFieldGeneratorForProperties } = this.context;
    const gen = getFieldGeneratorForProperties(properties, id);
    const renderer = (elems: any) => (
      <div
        className={`text-field-group ${
          currentResidence
            ? 'xl-text-field-group-after-single-item'
            : 'xl-text-field-group-after-two-items'
        }`}
      >
        {elems?.map((elem: React.ReactElement, index: number) => (
          <div
            className={
              elem?.key === 'end-date-wrapper'
                ? 'field-with-subtext'
                : 'text-field'
            }
            key={`${elem?.key || elem?.props?.children[0]?.key || index}`}
          >
            {elem}
          </div>
        ))}
      </div>
    );

    const defaultRows = [
      gen(AddressField.properties.country_code).render(),
      gen(AddressField.properties.street).render(),
      gen(AddressField.properties.street2).render(),
      gen(AddressField.properties.city).render(),
      gen(AddressField.properties.state).render(() => {
        return {
          validate: (val: any, all: any) => {
            return intlAddressStateValidator(val, all.address);
          },
        };
      }),

      gen(AddressField.properties.zipcode).render(),
    ];

    const autoCompleteRows = [
      gen(AddressField.properties.country_code)
        .extendProps(() => ({
          onChange: (selectedItem: any) => {
            this.setState({
              countryCode: selectedItem ? String(selectedItem) : selectedItem,
            });
            this.resetAddressValues();
          },
          valueOnInit: (val: any) => {
            this.setState({ countryCode: val });
          },
        }))
        .render(),
      gen(AddressField.properties.street)
        .extendProps(() => ({
          id: `${id}-street-autocomplete`,
          value: streetAddress,
          valueOnInit: val => {
            this.setState({ streetAddress: val });
          },
        }))
        .render(),
      gen(AddressField.properties.street2).render(),
      gen(AddressField.properties.city)
        .extendProps(() => ({
          value: city,
          valueOnInit: val => {
            this.setState({ city: val });
          },
        }))
        .render(),
      gen(AddressField.properties.state)
        .extendProps(() => ({
          value: state,
          valueOnInit: val => {
            this.setState({ state: val });
          },
        }))
        .render(() => {
          return {
            validate: (val: any, all: any) => {
              return intlAddressStateValidator(val, all.address);
            },
          };
        }),

      gen(AddressField.properties.zipcode)
        .extendProps(() => ({
          value: zipcode,
          className: 'custom-zipcode',
          valueOnInit: val => {
            this.setState({ zipcode: val });
          },
        }))
        .render(),
      gen(AddressField.properties.start_date).render(() => ({
        validate: (_: any, all: any) => {
          return intlAddressHistoryStartDateValidator(
            all.address,
            numYearsRequired,
          );
        },
      })),
    ];

    if (!currentResidence) {
      autoCompleteRows.push(
        <React.Fragment key='end-date-wrapper'>
          {gen(AddressField.properties.end_date).render(() => {
            const field = AddressField.properties.end_date;
            const title = fieldGenerator.getTitle(field, properties[field]);
            return {
              disabled: true,
              labelText: title,
              validate: (_: any, all: any) => {
                return intlAddressHistoryEndDateValidator(
                  all.address,
                  numYearsRequired,
                );
              },
            };
          })}
        </React.Fragment>,
      );
    }

    const showDefaultAddressCode = () => {
      return (
        <M.Container className='grid-container form-container'>
          {renderer(defaultRows)}
        </M.Container>
      );
    };

    const showAutoCompleteAddressCode = () => {
      return (
        <M.Container className='grid-container form-container'>
          {renderer(autoCompleteRows)}
        </M.Container>
      );
    };

    const toggleShowSuccessToast = () =>
      this.setState({ showSuccessToast: !showSuccessToast });

    return (
      <>
        <FlagrProvider
          url={FLAGR_URL}
          entityId={accountId}
          entityContext={{
            env: ENV,
            partner_account_uri_name: partnerAccountUriName,
          }}
        >
          {showSuccessToast && (
            <SuccessToastNotification
              numYearsRequired={numYearsRequired}
              dispatch={dispatch}
              setAddressHistoryRequirementSatisfied={
                setAddressHistoryRequirementSatisfied
              }
              setShowSuccessToast={toggleShowSuccessToast}
            />
          )}
          <div className='address-container'>
            {
              <div className='current-address-button-container'>
                <span>
                  {currentResidence
                    ? i18n?.getStr(
                        'i18nInternational.addressField.currentAddressLabel',
                      )
                    : i18n?.getStr(
                        'i18nInternational.addressField.previousAddressLabel',
                        `#${addressIndex}` || ' ',
                      )}
                </span>
                <M.Button
                  kind='tertiary'
                  onClick={() => {
                    const { addressIndex: currentAddressIndex } =
                      this.state || {};
                    this.resetAddressValues();
                    if (
                      (currentAddressIndex || addresses.length) <
                      addresses.length - 1
                    ) {
                      dispatch(
                        change(
                          'intlForm',
                          `address[${currentAddressIndex! + 1}].end_date`,
                          null,
                        ),
                      );
                    }
                    const countryCodeField = document.querySelector(
                      `#${CSS.escape(
                        `address[${currentAddressIndex}].country_code`,
                      )} + button`,
                    ) as HTMLButtonElement;
                    if (countryCodeField) {
                      countryCodeField.click();
                    }
                  }}
                  disabled={
                    addresses &&
                    addressIndex &&
                    (!addresses[addressIndex] ||
                      (Object.keys(addresses[addressIndex] || {}).length ===
                        1 &&
                        addresses[addressIndex].end_date))
                  }
                >
                  {i18n?.getStr(
                    'i18nInternational.addressField.clearButtonLabel',
                  )}
                </M.Button>
              </div>
            }
            <Switch flagKey={ADDRESS_AUTOCOMPLETE_FLAGR_KEY}>
              <Case variant='on'>{showAutoCompleteAddressCode()}</Case>
              <Case // for functional tests
                flagKey='mock-flag'
              >
                {showAutoCompleteAddressCode()}
              </Case>
              <Case>{showDefaultAddressCode()}</Case>
            </Switch>
          </div>
        </FlagrProvider>
      </>
    );
  }
}

const ConnectedAddressField = connect(
  (state: { configuration: any; internationalAddressHistory: any }): any => ({
    screeningSettings:
      state?.configuration?.configuration?.package?.['screening_settings'],
    accountId: state?.configuration?.configuration?.account?.id,
    partnerAccountUriName: state?.configuration?.routeParams?.company,
    addresses: formValueSelector('intlForm')(state, 'address') || [],
    addressHistoryRequirementSatisfied:
      state?.internationalAddressHistory?.addressHistoryRequirementSatisfied,
    addressHistoryYears: getAddressHistoryYears(
      state?.configuration?.configuration.schema?.properties,
    ),
  }),
  dispatch => ({
    clearFormValue,
    setAddressHistoryRequirementSatisfied,
    dispatch,
  }),
)(AddressField);

export default ConnectedAddressField;
