import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import { Checkbox } from './fields';
import Panel from './Panel';
import * as V from '../lib/validations';

const HealthScreeningConsent = ({ company }) => (
  <Panel title='components.HealthScreenConsent.title' icon='fa-medkit'>
    <div className='col-md-12'>
      <Field
        type='checkbox'
        name='healthScreenConsent'
        label='components.HealthScreenConsent.label'
        labelOptions={{ company }}
        validate={[V.requiredCheckbox]}
        component={Checkbox}
        boldLabel
      />
    </div>
  </Panel>
);

HealthScreeningConsent.propTypes = {
  company: PropTypes.string.isRequired,
};

export default HealthScreeningConsent;
