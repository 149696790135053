import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { Field } from 'redux-form';
import Panel from '../Panel';
import { Text } from '../fields';
import * as V from '../../lib/validations';
import * as N from '../../lib/normalizations';

export const ParsReporterInformationForm = () => (
  <Panel title='components.ParsReporterInformationForm.title'>
    <p>
      <T value='components.ParsReporterInformationForm.headerText' />
    </p>
    <br />
    <Field
      type='text'
      name='reporterFullName'
      label='labels.reporterFullName'
      component={Text}
      validate={[V.required, V.nameFormat, V.fullNameValidation]}
      normalize={N.smartPunctuation}
    />
    <Field
      type='email'
      name='reporterEmail'
      label='labels.reporterEmail'
      component={Text}
      validate={[V.required, V.emailFormat]}
    />
  </Panel>
);

export default ParsReporterInformationForm;
