import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Translate as T } from 'react-redux-i18n';

import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';

import { overridePosition as overridePositionAction } from '../../actions/employment/employmentActions';

const ArgyleEditPositionContainer = ({
  employment,
  fieldName,
  originalPosition,
  overridePosition,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newPosition, setNewPosition] = useState(employment.position || '');

  const handleOnSave = () => {
    setIsEditing(false);

    const sanitizedNewPosition = newPosition.trim();

    // If candidate entered blank value, revert to original position.
    const effectiveNewPosition = _.isEmpty(newPosition.trim())
      ? originalPosition
      : sanitizedNewPosition;

    overridePosition(`${fieldName}.position`, effectiveNewPosition);

    setNewPosition(effectiveNewPosition);
  };

  const handleOnCancel = () => {
    setIsEditing(false);
  };

  const handleOnKeyDown = event => {
    switch (event.key) {
      case 'Enter':
        handleOnSave();
        break;
      case 'Escape':
        handleOnCancel();
        break;
      default:
    }
  };

  const renderEditing = () => {
    return (
      <div id='editingArgylePosition'>
        <input
          type='text'
          className='form-control has-success new-position'
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          value={newPosition}
          onChange={changeEvent => setNewPosition(changeEvent.target.value)}
          onKeyDown={handleOnKeyDown}
        />
        <M.Button
          kind='tertiary'
          size='default'
          className='cancel editing'
          onClick={handleOnCancel}
        >
          <M.Icon icon='Close' className='cancel icon' size={20} />
        </M.Button>
        <M.Button
          kind='tertiary'
          size='default'
          className='save editing'
          onClick={handleOnSave}
        >
          <M.Icon icon='Checkmark' className='save icon' size={20} />
        </M.Button>
      </div>
    );
  };

  const renderNonEditing = () => {
    return (
      <div
        className='last-position-link'
        role='button'
        tabIndex={0}
        onClick={() => setIsEditing(true)}
      >
        <span className='position-link'>{employment.position}</span>{' '}
        <M.Icon icon='Edit' className='icon' />
      </div>
    );
  };

  return (
    <div id='mastodon' className='edit-position'>
      <M.Container type='info'>
        <div className='heading'>
          {i18n.getStr(
            'components.EmploymentForm.ArgyleForm.editPosition.editYourPositionHeading',
            [employment.name],
          )}
        </div>

        <div className='subheading'>
          <T value='components.EmploymentForm.ArgyleForm.editPosition.editYourPositionSubheading' />
        </div>

        <div className='last-position-held-label'>
          <T value='components.EmploymentForm.ArgyleForm.editPosition.lastPositionHeld' />
        </div>

        {isEditing ? renderEditing() : renderNonEditing()}
      </M.Container>
    </div>
  );
};

ArgyleEditPositionContainer.propTypes = {
  employment: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  originalPosition: PropTypes.string.isRequired,
  overridePosition: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  overridePosition: overridePositionAction,
};

export default connect(null, mapDispatchToProps)(ArgyleEditPositionContainer);
