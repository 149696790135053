/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { replace } from 'lodash';
import NumberFormat from 'react-number-format';

import { I18n } from 'react-redux-i18n';
import { groupClass } from './helper';
import FeedbackLabel from './FeedbackLabel';
import Label from './Label';

const Phone = ({
  autoFocus,
  disabled,
  input,
  inputClass,
  label,
  labelAsRequired,
  meta: { touched, error, warning },
  placeholder,
  type,
  onPaste,
}) => {
  return (
    <div className={groupClass(touched, error)}>
      <Label
        label={label}
        htmlFor={input.name}
        labelAsRequired={labelAsRequired}
      />
      <NumberFormat
        {...input}
        id={replace(input.name, '.', '-')}
        type={type}
        className={`form-control ${inputClass || ''}`}
        placeholder={I18n.t(placeholder || label)}
        autoFocus={autoFocus}
        disabled={disabled}
        format='(###) ###-####'
        onPaste={onPaste}
      />
      <FeedbackLabel {...{ touched, error, warning }} />
    </div>
  );
};

Phone.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  input: PropTypes.object,
  inputClass: PropTypes.string,
  label: PropTypes.string,
  labelAsRequired: PropTypes.bool,
  meta: PropTypes.object,
  onPaste: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};
const mapStateToProps = ({ i18n }) => ({ i18n });

export default connect(mapStateToProps)(Phone);
