/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { Translate as T } from 'react-redux-i18n';
import { change, Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Panel from '../Panel';
import { Checkbox, Phone, ReCaptchaV2, Select, Text } from '../fields';
import * as V from '../../lib/validations';
import * as N from '../../lib/normalizations';
import * as VM from '../../lib/validations/messages';
import * as O from '../../lib/options';
import isDisabled from '../helper';

export class ParsEmployeeInformationForm extends Component {
  render() {
    const { middleName, noMiddleName, changeReduxForm } = this.props;
    const onNoMiddleNameUpdate = ({ target: { checked } }) => {
      if (checked) {
        changeReduxForm('applyform', 'middleName', null);
      }
    };

    const middleNameIsDisabled =
      isDisabled({
        field: middleName,
        protect: false,
      }) || noMiddleName;

    return (
      <Panel title='components.ParsEmployeeInformationForm.title'>
        <p>
          <T value='components.ParsEmployeeInformationForm.headerText' />
        </p>
        <br />
        <div className='row'>
          <div className='col-lg-4'>
            <Field
              type='text'
              name='firstName'
              label='labels.employeeFirstName'
              component={Text}
              validate={[V.required, V.nameFormat]}
              normalize={N.smartPunctuation}
            />
          </div>
          <div className='col-lg-4'>
            <Field
              type='text'
              name='middleName'
              label='labels.employeeMiddleName'
              component={Text}
              validate={[V.nameFormat]}
              normalize={N.smartPunctuation}
              disabled={middleNameIsDisabled}
            />
            <Field
              type='checkbox'
              name='noMiddleName'
              label='labels.employeeNoMiddleName'
              className='inline-checkbox top-inline bottom-inline middle-name'
              onChange={onNoMiddleNameUpdate}
              component={Checkbox}
              validate={(checked, { middleName }) =>
                !middleName && !checked && VM.REQUIRED
              }
            />
          </div>
          <div className='col-lg-4'>
            <Field
              type='text'
              name='lastName'
              label='labels.employeeLastName'
              component={Text}
              validate={[V.required, V.nameFormat, V.lastNameFormat]}
              normalize={N.smartPunctuation}
            />
          </div>
        </div>
        <label key='dob-label'>
          <T value='labels.dob' />
        </label>
        <div className='row' key='dob-row'>
          <div className='col-md-4'>
            <Field
              type='select'
              name='dob.month'
              component={Select}
              validate={[V.required]}
              options={O.months()}
            />
          </div>

          <div className='col-md-4'>
            <Field
              type='select'
              name='dob.day'
              component={Select}
              validate={[V.required]}
              options={O.days()}
            />
          </div>

          <div className='col-md-4'>
            <Field
              type='select'
              name='dob.year'
              component={Select}
              validate={[V.required]}
              options={O.years(18, 100)}
            />
          </div>
        </div>
        <Field
          type='tel'
          name='phone'
          label='labels.employeePhone'
          validate={[V.required, V.phoneNumberFormat]}
          component={Phone}
        />
        <Field
          type='email'
          name='email'
          label='labels.employeeEmail'
          component={Text}
          validate={[V.emailFormat]}
        />
        <Field
          name='recaptchaCode'
          component={ReCaptchaV2}
          validate={V.required}
        />
      </Panel>
    );
  }
}

const selector = formValueSelector('applyform');
const ConnectedParsEmployeeInformation = connect(
  (state, props) => {
    return {
      noMiddleName: selector(state, 'noMiddleName'),
      middleName: selector(state, 'middleName'),
    };
  },
  { changeReduxForm: change },
)(ParsEmployeeInformationForm);

ParsEmployeeInformationForm.propTypes = {
  middleName: PropTypes.string,
  noMiddleName: PropTypes.bool,
  changeReduxForm: PropTypes.func,
};

ParsEmployeeInformationForm.defaultProps = {
  middleName: null,
  noMiddleName: false,
  changeReduxForm: () => {},
};

export default ConnectedParsEmployeeInformation;
