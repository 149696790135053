/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate as T } from 'react-redux-i18n';
import {
  getRequiredExams,
  getSelectedExams,
  getParsPackagePurpose,
} from '../../actions';
import Panel from '../Panel';

class ParsWelcome extends Component {
  static propTypes = {
    exams: PropTypes.string,
    purpose: PropTypes.string,
  };

  render() {
    const { exams, purpose } = this.props;
    return (
      <Panel>
        <h1>
          <T value='components.ParsWelcome.title' />
        </h1>
        <p>
          <T
            value='components.ParsWelcome.body'
            exams={exams}
            purpose={purpose}
            dangerousHTML
          />
        </p>
      </Panel>
    );
  }
}

const ConnectedParsWelcome = connect((state, props) => {
  const exams = getRequiredExams(state).concat(getSelectedExams(state));
  const purpose = getParsPackagePurpose(state);
  const examLabels = [...new Set([...exams.map(exam => exam.label)])].join(
    ', ',
  );
  return {
    exams: examLabels,
    purpose,
    ...props,
  };
})(ParsWelcome);

export { ParsWelcome as TestableParsWelcome };

export default ConnectedParsWelcome;
