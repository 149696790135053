import {
  addArgyleAccountEmployments,
  addArgyleConnectedAccountId,
} from '../../../../actions/employment/employmentActions';

// This should assign a unique argyleEmploymentId to each inserted Argyle Employment
const insertArgyleEmployment = argyleEmployment => dispatch => {
  const employment = argyleEmployment;
  const now = Date.now();
  const argyleAccountId = `argyle-dev-account_${now}`;
  employment.argyleEmploymentId = argyleEmployment.argyleEmploymentId.concat(
    `_${now}`,
  );
  employment.addedAt = now;
  dispatch(addArgyleConnectedAccountId(argyleAccountId));
  dispatch(
    addArgyleAccountEmployments({
      argyleAccountId,
      argyleEmployments: [employment],
    }),
  );
};

export default insertArgyleEmployment;
