import { MEASURE_ONE_DEBUG_LOGS_ENABLED } from '../../../constants';
import { apiRequest } from '../../../actions/helper';

const measureOneDebugLog = (message, ...optionalParams) => {
  if (MEASURE_ONE_DEBUG_LOGS_ENABLED === true) {
    /* eslint-disable-next-line no-console */
    console.log(message, ...optionalParams);
  }
};

const loadMeasureOneCandidateSchools = async (
  invitationToken,
  measureOneDataRequestId,
) => {
  const path = `apply/measure_one/${invitationToken}/${measureOneDataRequestId}/candidate_schools`;

  return apiRequest(path)
    .then(json => {
      return json;
    })
    .catch(error => {
      measureOneDebugLog('Server error', error);
    });
};

export { loadMeasureOneCandidateSchools, measureOneDebugLog };
